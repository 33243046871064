import React, { useEffect } from 'react'

import {
  DateTimePicker as MUIDateTimePicker,
  DateTimePickerProps as MUIDateTimePickerProps,
} from '@mui/x-date-pickers/DateTimePicker'
import dayjs, { Dayjs } from 'dayjs'
import { get } from 'lodash'

import { useFormContext } from '@redwoodjs/forms'

interface DatePickerProps<TDate> extends MUIDateTimePickerProps<TDate> {
  name: string
  label: string
  required?: boolean
  error?: boolean
  helperText?: string
}

const DateTimePicker: React.FC<DatePickerProps<Date>> = ({
  name,
  label,
  required = false,
  slotProps = { textField: {} },
  ...otherProps
}) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext()
  const valStr = watch(name)
  const value: Dayjs = React.useMemo(
    () => (valStr ? dayjs(valStr) : null),
    [valStr]
  )

  const fieldError = get(errors, name.split('.'))?.message as string | undefined
  const { onChange, ...rest } = register(name, { required })

  // TODO: Check if this local override is doing anything, does not look like
  // this is getting invoked
  const onChangeHandler = (date: Dayjs | null) => {
    onChange({
      target: { name, value: date?.isValid() ? date?.toISOString() : null },
    })
  }

  useEffect(() => {
    // allows for clearing the value
    if (!value) {
      onChangeHandler(value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <MUIDateTimePicker
      {...rest}
      onChange={onChangeHandler}
      label={label}
      value={value}
      slotProps={{
        ...slotProps,
        field: {
          clearable: true,
        },
        textField: {
          ...slotProps.textField,
          error: Boolean(fieldError),
          helperText: fieldError,
        },
      }}
      {...otherProps}
    />
  )
}

export default DateTimePicker
