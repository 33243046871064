import React from 'react'

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Button, Drawer, IconButton } from '@mui/material'

import { Form, useForm } from '@redwoodjs/forms'

import ChildTasks from './ChildTasks'
import GeneralInformation from './GeneralInformation'
import PerformanceInformation from './PerformanceInformation'

interface LineItemDetailsDrawerProps {
  open: boolean
  onClose: () => void
  onCreateTaskClick: () => void
  onResetClick: () => void
}

export type TaskType = 'scheduled' | 'squawk' | 'discrepancy' | 'mel'

const CreateNewTaskDrawer: React.FC<LineItemDetailsDrawerProps> = ({
  open,
  onClose,
}) => {
  const [taskType, setTaskType] = React.useState<TaskType>('scheduled')
  const formMethods = useForm({
    defaultValues: {
      ata: '',
      itemName: '',
    },
  })
  return (
    <Drawer disablePortal anchor={'right'} open={open} sx={{ zIndex: 1202 }}>
      <div className="relative h-full">
        <div
          className="h-full overflow-auto p-2 pb-12	"
          style={{ width: '450px' }}
        >
          <IconButton onClick={onClose} className="mb-1">
            <ArrowForwardIosIcon />
          </IconButton>
          <Form formMethods={formMethods} className="flex flex-col gap-3">
            <GeneralInformation
              selectedTask={taskType}
              onTaskChange={(task: TaskType) => setTaskType(task)}
            />
            <PerformanceInformation />
            <ChildTasks />
          </Form>
        </div>
        <div className="absolute bottom-0 left-0 right-0 z-[1] flex justify-between bg-[#FAFAFA] p-2">
          <Button variant="outlined" color="base">
            Reset
          </Button>
          <Button variant="contained">Create Task</Button>
        </div>
      </div>
    </Drawer>
  )
}

export default CreateNewTaskDrawer
