import React, { useEffect, useState, useRef } from 'react'

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { Paper } from '@mui/material'
import clsx from 'clsx'

type ModalContentProps = {
  children: React.ReactNode
  showScrollArrow?: boolean
  animateArrow?: boolean
  classes?: {
    root?: string
    pointer?: string
    pointerAnimation?: string
    pointerContainer?: string
  }
}

const ModalContent: React.FC<ModalContentProps> = ({
  children,
  showScrollArrow = true,
  animateArrow = true,
  classes = {},
}) => {
  const [showBottomArrow, setShowBottomArrow] = useState(false)
  const contentRef = useRef<HTMLDivElement>(null)

  const handleScroll = () => {
    const element = contentRef.current
    if (element) {
      setShowBottomArrow(
        element.scrollTop + element.clientHeight < element.scrollHeight
      )
    }
  }

  useEffect(() => {
    const element = contentRef.current
    if (element) {
      handleScroll() // Initial check
      element.addEventListener('scroll', handleScroll)
      return () => {
        element.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  return (
    <div
      className={clsx('relative max-h-[60vh] overflow-auto', classes?.root)}
      ref={contentRef}
    >
      {showScrollArrow && showBottomArrow && (
        <div
          className={clsx(
            'fixed bottom-10 left-1/2 z-10 -translate-x-1/2 transform',
            classes?.pointerContainer
          )}
        >
          <Paper
            elevation={3}
            className={clsx(
              'flex items-center justify-center rounded-full p-1',
              animateArrow
                ? classes?.pointerAnimation
                  ? classes?.pointerAnimation
                  : 'animate-pulse'
                : '',
              classes?.pointer
            )}
          >
            <ArrowDownwardIcon className="text-gray-500" />
          </Paper>
        </div>
      )}
      {children}
    </div>
  )
}

export default ModalContent
