import React from 'react'

import AddIcon from '@mui/icons-material/Add'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Button, Chip, IconButton, Typography } from '@mui/material'

import TextField from 'src/components/MUI/TextField'

interface TaskCardProps {
  onDeleteClick: () => void
}

const TaskCard: React.FC<TaskCardProps> = ({ onDeleteClick }) => {
  return (
    <div className="my-2 flex flex-col gap-2">
      <div className="flex items-center justify-between">
        <Typography variant="subtitle2" fontWeight="bold">
          Child Task #0001
        </Typography>
        <IconButton onClick={onDeleteClick}>
          <DeleteOutlineIcon />
        </IconButton>
      </div>
      <TextField
        // className="col-span-2"
        // name={`${prefix}Hours`}
        name="ata"
        label="ATA"
        size="small"
        // onChange={handleChange}
        // onBlur={handleBlur}
        // onFocus={onFocus}
        autoComplete="off"
        // error={!!errors[`${prefix}Hours`]}
        // helperText={errors[`${prefix}Hours`]?.message}
      />
      <TextField
        // className="col-span-2"
        // name={`${prefix}Hours`}
        name="ata"
        label="Name"
        size="small"
        // onChange={handleChange}
        // onBlur={handleBlur}
        // onFocus={onFocus}
        autoComplete="off"
        // error={!!errors[`${prefix}Hours`]}
        // helperText={errors[`${prefix}Hours`]?.message}
      />
      <TextField placeholder="Description" name="ata" multiline rows={4} />
    </div>
  )
}

const ChildTasks = () => {
  const [childTasks, setChildTasks] = React.useState([{}])

  const onChildTaskDelete = (idx) => {
    const newChildTasks = [...childTasks]
    newChildTasks.splice(idx, 1)
    setChildTasks(newChildTasks)
  }

  return (
    <div className="rounded border border-solid border-gray-300 p-1">
      <div className="flex items-center gap-2">
        <Typography variant="subtitle1" fontWeight="bold">
          Add Child Tasks
        </Typography>
        <Chip label="Optional" size="small" variant="outlined" />
      </div>
      <Typography variant="caption">
        If there are child tasks, add them to the list
      </Typography>
      {childTasks.map((childTask, idx) => (
        <TaskCard key={idx} onDeleteClick={() => onChildTaskDelete(idx)} />
      ))}

      <Button
        className="w-fit"
        variant="outlined"
        color="base"
        startIcon={<AddIcon />}
        onClick={() => setChildTasks([...childTasks, {}])}
      >
        Add Child Task
      </Button>
    </div>
  )
}

export default ChildTasks
