import useQuery from './useQuery'

const GET_CURRENT_USER = gql`
  query GetCurrentUserWithSignature {
    currentUser {
      id
      signatureFile {
        id
        url
      }
    }
  }
`

const useUserIdWithSignature = () => {
  const { data, hasLoaded } = useQuery(GET_CURRENT_USER)

  return {
    id: data?.currentUser?.id,
    signatureFile: data?.currentUser?.signatureFile,
    hasLoaded,
  }
}

export default useUserIdWithSignature
