import { ThemeProvider } from '@mui/material/styles'
import { StyledEngineProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Uploady from '@rpldy/uploady'
import { Provider } from 'react-redux'

import { RedwoodProvider } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'

import Sentry from 'src/lib/sentry'
import FatalErrorPage from 'src/pages/FatalErrorPage'
import Routes from 'src/Routes'

import { AuthProvider, useAuth } from './auth'
import CustomApolloLink from './CustomApolloLink'
import { InMemoryCache } from '@apollo/client'
import store from './store'
import theme from './theme'
import './i18n/i18n'

import 'src/lib/logger'
import 'src/lib/datadogRum'

import './index.css'
import '@liveblocks/react-ui/styles.css'

const ID_WARNING_BLACKLIST = new Set([
  'AircraftUsageParameter',
  'UserTenantMembership',
  'Mutation',
  'PageInfo',
  'MaintenanceItemPage',
  'ComplianceLedgerStats',
  'ProductsPage',
])
const cache = new InMemoryCache({
  dataIdFromObject: (object) => {
    if (!object.__typename) {
      console.warn('Missing __typename for object:', object)
      return null // Store without normalization
    }

    switch (object.__typename) {
      case 'TenantFeature': {
        if (!object.orgSlug || !object.featureName) {
          console.warn('TenantFeature missing orgSlug or featureName:', object)
          return null
        }
        return `TenantFeature:${object.orgSlug}:${object.featureName}`
      }

      default: {
        if (!object.id && !ID_WARNING_BLACKLIST.has(object.__typename)) {
          console.warn(`Missing ID for ${object.__typename}:`, object)
        }
        return object.id ? `${object.__typename}:${object.id}` : null
      }
    }
  },
})

const App = () => {
  return (
    <Sentry.ErrorBoundary fallback={FatalErrorPage}>
      <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
        <AuthProvider>
          <RedwoodApolloProvider
            useAuth={useAuth}
            graphQLClientConfig={{ link: CustomApolloLink, cache }}
          >
            <Uploady>
              <Provider store={store}>
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Routes />
                    </LocalizationProvider>
                  </ThemeProvider>
                </StyledEngineProvider>
              </Provider>
            </Uploady>
          </RedwoodApolloProvider>
        </AuthProvider>
      </RedwoodProvider>
    </Sentry.ErrorBoundary>
  )
}

export default App
