import { Divider, Typography } from '@mui/material'
import React, { useMemo, useEffect, useState } from 'react'
import { MassagedComplianceActivity } from 'src/components/Compliance/SelectedTasksManager'
import { InlineTextField } from '../InlineFields/InlineTextField'
import {
  UPDATE_COMPLIANCE_ACTIVITY_MUTATION,
  UPDATE_CUSTOM_INTERNAL_WORK_ITEM_MUTATION,
  UPDATE_INTERNAL_WORK_ITEM_MUTATION,
} from './queries'
import { useMutation } from '@redwoodjs/web'
import { InlineSelectField } from '../InlineFields/InlineSelectField'
import {
  GET_COMPLIANCE_LEDGER_BASE_FIELDS,
  GET_COMPLIANCE_LEDGER_TASK_LIST,
} from 'src/pages/BulkCompliancePageV2/queries'
import useGetUsers from 'src/hooks/requests/useGetUsers'
import {
  centsToDollars,
  minutesToHours,
  minutesToHoursStr,
} from 'src/utils/helpers'
import {
  GET_WORK_ORDER_BASE_FIELDS,
  GET_WORK_ORDER_CUSTOM_TASK_LIST,
  GET_WORK_ORDER_TASK_LIST,
} from 'src/pages/WorkOrderCompliancePageV2/queries'
import WorkItemAttachments from 'src/pages/WorkOrderCompliancePageV2/components/sections/AttachmentsSection/WorkItemAttachments'
import CustomWorkItemAttachments from 'src/pages/WorkOrderCompliancePageV2/components/sections/AttachmentsSection/CustomWorkItemAttachments'
import BulkComplianceAttachmentsSection from 'src/pages/BulkCompliancePageV2/components/sections/AttachmentsSection'

interface AccountingState {
  rate: number | null
  rateType: 'HOURLY_RATE' | 'FLAT_RATE' | null
  workMinutes: number | null
  partCost: number | null
}

const getSubtotal = (
  rateInput: number | null,
  rateTypeInput: 'HOURLY_RATE' | 'FLAT_RATE' | null,
  workMinutesInput: number | null,
  partCostInput: number | null
) => {
  const rate = rateInput ? parseFloat(centsToDollars(rateInput)) : 0
  const rateType = rateTypeInput
  const workMinutes = workMinutesInput
    ? parseFloat(minutesToHours(workMinutesInput))
    : 0
  const partCost = partCostInput ? parseFloat(centsToDollars(partCostInput)) : 0

  if (!rate || !rateType) return `$${partCost.toFixed(2)}`

  switch (rateType) {
    case 'FLAT_RATE':
      return `$${(rate + partCost).toFixed(2)}`
    case 'HOURLY_RATE':
      return `$${(workMinutes * rate + partCost).toFixed(2)}`
    default:
      return '--'
  }
}

const ItemForm = ({
  row,
  showCorrectiveAction,
  showParts = true,
  showAccounting,
  readonly,
  isWorkOrder,
  isCustom,
  showNotes,
}: {
  row: MassagedComplianceActivity
  showCorrectiveAction: boolean
  showParts?: boolean
  showAccounting: boolean
  readonly: boolean
  isWorkOrder: boolean
  isCustom: boolean
  showNotes: boolean
}) => {
  const { data: usersData } = useGetUsers({
    variables: {
      filter: {
        roles: ['ADMIN', 'MECHANIC', 'DIRECTOR_OF_MAINTENANCE'],
      },
    },
    skip: !showAccounting,
  })

  const userOptions = useMemo(
    () =>
      usersData?.users?.map((user) => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
      })) || [],
    [usersData]
  )

  const [updateComplianceActivity] = useMutation(
    UPDATE_COMPLIANCE_ACTIVITY_MUTATION,
    {
      update: (
        cache,
        {
          data: {
            updateComplianceActivity: { id, updatedAt, complianceLedgerId },
          },
        }
      ) => {
        const existingData = cache.readQuery({
          query: GET_COMPLIANCE_LEDGER_BASE_FIELDS,
          variables: { id: complianceLedgerId },
        })

        cache.writeQuery({
          query: GET_COMPLIANCE_LEDGER_BASE_FIELDS,
          variables: { id: complianceLedgerId },
          data: {
            complianceLedger: {
              ...existingData?.complianceLedger,
              updatedAt,
            },
          },
        })

        const existingTasksList = cache.readQuery({
          query: GET_COMPLIANCE_LEDGER_TASK_LIST,
          variables: { id: complianceLedgerId },
        })

        cache.writeQuery({
          query: GET_COMPLIANCE_LEDGER_TASK_LIST,
          variables: { id: complianceLedgerId },
          data: {
            complianceActivities: existingTasksList?.complianceActivities.map(
              (item) =>
                item.id === id ? { ...item, ...updateComplianceActivity } : item
            ),
          },
        })
      },
    }
  )
  const [updateInternalWorkItem] = useMutation(
    UPDATE_INTERNAL_WORK_ITEM_MUTATION,
    {
      update: (
        cache,
        {
          data: {
            updateInternalWorkItem: { id, updatedAt, workOrderId },
          },
        }
      ) => {
        const existingData = cache.readQuery({
          query: GET_WORK_ORDER_BASE_FIELDS,
          variables: { id: workOrderId },
        })

        cache.writeQuery({
          query: GET_WORK_ORDER_BASE_FIELDS,
          variables: { id: workOrderId },
          data: {
            internalWorkOrder: {
              ...existingData?.internalWorkOrder,
              updatedAt,
            },
          },
        })

        const existingTasksList = cache.readQuery({
          query: GET_WORK_ORDER_TASK_LIST,
          variables: { id: workOrderId },
        })

        cache.writeQuery({
          query: GET_WORK_ORDER_TASK_LIST,
          variables: { id: workOrderId },
          data: {
            internalWorkItems: existingTasksList?.internalWorkItems.map(
              (item) =>
                item.id === id ? { ...item, ...updateInternalWorkItem } : item
            ),
          },
        })
      },
    }
  )

  const [updateCustomInternalWorkItem] = useMutation(
    UPDATE_CUSTOM_INTERNAL_WORK_ITEM_MUTATION,
    {
      update: (
        cache,
        {
          data: {
            updateCustomInternalWorkItem: { id, updatedAt, workOrderId },
          },
        }
      ) => {
        const existingData = cache.readQuery({
          query: GET_WORK_ORDER_BASE_FIELDS,
          variables: { id: workOrderId },
        })

        cache.writeQuery({
          query: GET_WORK_ORDER_BASE_FIELDS,
          variables: { id: workOrderId },
          data: {
            internalWorkOrder: {
              ...existingData?.internalWorkOrder,
              updatedAt,
            },
          },
        })

        const existingTasksList = cache.readQuery({
          query: GET_WORK_ORDER_CUSTOM_TASK_LIST,
          variables: { id: workOrderId },
        })

        cache.writeQuery({
          query: GET_WORK_ORDER_CUSTOM_TASK_LIST,
          variables: { id: workOrderId },
          data: {
            customInternalWorkItems:
              existingTasksList?.customInternalWorkItems.map((item) =>
                item.id === id
                  ? { ...item, ...updateCustomInternalWorkItem }
                  : item
              ),
          },
        })
      },
    }
  )

  let updateMutation
  if (!isWorkOrder) {
    updateMutation = updateComplianceActivity
  } else {
    if (isCustom) {
      updateMutation = updateCustomInternalWorkItem
    } else {
      updateMutation = updateInternalWorkItem
    }
  }

  const [accountingState, setAccountingState] = useState<AccountingState>({
    rate: row?.rate,
    rateType: row?.rateType,
    workMinutes: row.workMinutes,
    partCost: row.partCost,
  })

  useEffect(() => {
    setAccountingState({
      rate: row?.rate,
      rateType: row?.rateType,
      workMinutes: row.workMinutes,
      partCost: row.partCost,
    })
  }, [row])

  const subtotal = useMemo(() => {
    return getSubtotal(
      accountingState.rate,
      accountingState.rateType,
      accountingState.workMinutes,
      accountingState.partCost
    )
  }, [accountingState])

  const getWorkItemAttachments = (workItem: MassagedComplianceActivity) => {
    if (isWorkOrder && !isCustom) {
      return (
        <WorkItemAttachments
          workItemId={workItem.id}
          variant={readonly ? 'readonly' : 'review'}
        />
      )
    }
    if (isWorkOrder && isCustom) {
      return (
        <CustomWorkItemAttachments
          workItemId={workItem.id}
          variant={readonly ? 'readonly' : 'review'}
        />
      )
    }
    if (!isWorkOrder) {
      return (
        <BulkComplianceAttachmentsSection
          complianceActivityId={workItem.id}
          variant={readonly ? 'readonly' : 'review'}
        />
      )
    }
    return null
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="grid grid-cols-[max-content_1fr] items-center gap-x-2 gap-y-1">
        <Typography variant="body2" color="text.secondary">
          Description
        </Typography>
        <InlineTextField
          name={`${row.id}.description`}
          initialValue={row.description}
          onSubmit={async (value) => {
            await updateMutation({
              variables: {
                id: row.id,
                input: {
                  description: value,
                },
              },
            })
          }}
          multiline
          disabled={readonly}
        />
        {showCorrectiveAction && (
          <>
            <Typography variant="body2" color="text.secondary">
              Corrective Action
            </Typography>
            <InlineTextField
              name={`${row.id}.correctiveAction`}
              initialValue={row.correctiveAction}
              multiline
              disabled={readonly}
              onSubmit={async (value) => {
                await updateMutation({
                  variables: {
                    id: row.id,
                    input: {
                      correctiveAction: value,
                    },
                  },
                })
              }}
            />
          </>
        )}
        {showNotes && (
          <>
            <Typography variant="body2" color="text.secondary">
              Notes
            </Typography>
            <InlineTextField
              name={`${row.id}.notes`}
              initialValue={row.notes}
              disabled={readonly}
              multiline
              onSubmit={async (value) => {
                await updateMutation({
                  variables: {
                    id: row.id,
                    input: {
                      notes: value,
                    },
                  },
                })
              }}
            />
          </>
        )}
      </div>
      <Divider />
      {showParts && (
        <div className="flex gap-1">
          <div className="flex flex-1 flex-col gap-1">
            <Typography variant="body2">REMOVED PART</Typography>

            {!row.partsTransaction?.removedPartNumber ? (
              <Typography variant="body2" color="text.secondary">
                N/A
              </Typography>
            ) : (
              <div className="flex gap-1">
                <div className="flex flex-1 flex-col">
                  <Typography variant="body2" color="text.secondary">
                    Part Number
                  </Typography>
                  <InlineTextField
                    name={`${row.id}.parts.removed.partNumber`}
                    initialValue={row.partsTransaction?.removedPartNumber}
                    disabled
                  />
                </div>

                <div className="flex flex-1 flex-col">
                  <Typography variant="body2" color="text.secondary">
                    Serial Number
                  </Typography>
                  <InlineTextField
                    name={`${row.id}.parts.removed.serialNumber`}
                    initialValue={row.partsTransaction?.removedSerialNumber}
                    disabled
                  />
                </div>
                <div className="flex flex-1 flex-col">
                  <Typography variant="body2" color="text.secondary">
                    Reason
                  </Typography>
                  <InlineSelectField
                    name={`${row.id}.parts.removed.reason`}
                    initialValue={row.partsTransaction?.removalReason}
                    options={[
                      { value: 'Scheduled', label: 'Scheduled' },
                      {
                        value: 'Premature Removal',
                        label: 'Premature Removal',
                      },
                      {
                        value: 'Maintenance Issue',
                        label: 'Maintenance Issue',
                      },
                      { value: 'Life Limit Item', label: 'Life Limit Item' },
                      { value: 'Timed Item', label: 'Timed Item' },
                      { value: 'Failure', label: 'Failure' },
                      { value: 'Worn', label: 'Worn' },
                      { value: 'Other', label: 'Other' },
                    ]}
                    disabled
                  />
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-1 flex-col gap-1">
            <Typography variant="body2">INSTALLED PART</Typography>
            {!row.partsTransaction?.addedPartNumber ? (
              <Typography variant="body2" color="text.secondary">
                N/A
              </Typography>
            ) : (
              <div className="flex gap-1">
                <div className="flex flex-1 flex-col">
                  <Typography variant="body2" color="text.secondary">
                    Part Number
                  </Typography>
                  <InlineTextField
                    name={`${row.id}.parts.added.partNumber`}
                    initialValue={row.partsTransaction?.addedPartNumber}
                    disabled
                  />
                </div>

                <div className="flex flex-1 flex-col">
                  <Typography variant="body2" color="text.secondary">
                    Serial Number
                  </Typography>
                  <InlineTextField
                    name={`${row.id}.parts.added.serialNumber`}
                    initialValue={row.partsTransaction?.addedSerialNumber}
                    disabled
                  />
                </div>
                <div className="flex flex-1 flex-col ">
                  <Typography variant="body2" color="text.secondary">
                    Reason
                  </Typography>
                  <InlineSelectField
                    name={`${row.id}.parts.added.status`}
                    initialValue={row.partsTransaction?.installedStatus}
                    options={[
                      { value: 'Altered', label: 'Altered' },
                      { value: 'Inspected', label: 'Inspected' },
                      { value: 'Modified', label: 'Modified' },
                      { value: 'New', label: 'New' },
                      { value: 'Not Specified', label: 'Not Specified' },
                      { value: 'Overhauled', label: 'Overhauled' },
                      { value: 'Other', label: 'Other' },
                      { value: 'Repaired', label: 'Repaired' },
                      { value: 'Serviceable', label: 'Serviceable' },
                    ]}
                    disabled
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {showAccounting && (
        <div className="flex flex-col gap-1">
          <Typography variant="body2">ACCOUNTING</Typography>
          <div className="grid grid-cols-6 gap-1">
            <div className="flex basis-1/6 flex-col">
              <Typography variant="body2" color="text.secondary">
                Mechanic
              </Typography>
              <InlineSelectField
                name={`${row.id}.accounting.mechanic`}
                initialValue={row.workedBy?.id}
                options={userOptions}
                disabled={readonly}
                onSubmit={async (value) => {
                  await updateMutation({
                    variables: {
                      id: row.id,
                      input: {
                        workedById: value,
                      },
                    },
                  })
                }}
              />
            </div>
            <div className="flex basis-1/6 flex-col">
              <Typography variant="body2" color="text.secondary">
                Rate
              </Typography>
              <InlineTextField
                name={`${row.id}.accounting.rate`}
                initialValue={centsToDollars(accountingState.rate)}
                disabled={readonly}
                onSubmit={async (value) => {
                  const rateInCents = Math.round(parseFloat(value) * 100)
                  setAccountingState((prev) => ({
                    ...prev,
                    rate: rateInCents,
                  }))
                  await updateMutation({
                    variables: {
                      id: row.id,
                      input: { rate: rateInCents },
                    },
                  })
                }}
              />
            </div>
            <div className="flex basis-1/6 flex-col">
              <Typography variant="body2" color="text.secondary">
                Rate Type
              </Typography>
              <InlineSelectField
                name={`${row.id}.rateType`}
                initialValue={accountingState.rateType}
                disabled={readonly}
                options={[
                  { value: 'HOURLY_RATE', label: 'Hourly' },
                  { value: 'FLAT_RATE', label: 'Flat' },
                ]}
                onSubmit={async (value) => {
                  setAccountingState((prev) => ({
                    ...prev,
                    rateType: value,
                  }))
                  await updateMutation({
                    variables: { id: row.id, input: { rateType: value } },
                  })
                }}
              />
            </div>
            {showParts && (
              <div className="flex basis-1/6 flex-col">
                <Typography variant="body2" color="text.secondary">
                  Part Cost
                </Typography>
                <InlineTextField
                  name={`${row.id}.partCost`}
                  initialValue={centsToDollars(accountingState.partCost)}
                  disabled={readonly}
                  onSubmit={async (value) => {
                    const partCostInCents = Math.round(parseFloat(value) * 100)
                    setAccountingState((prev) => ({
                      ...prev,
                      partCost: partCostInCents,
                    }))
                    await updateMutation({
                      variables: {
                        id: row.id,
                        input: {
                          partCost: partCostInCents,
                        },
                      },
                    })
                  }}
                />
              </div>
            )}
            <div className="flex basis-1/6 flex-col">
              <Typography variant="body2" color="text.secondary">
                Hours (total)
              </Typography>
              <InlineTextField
                name={`${row.id}.workMinutes`}
                initialValue={minutesToHoursStr(row?.workMinutes)}
                disabled
                onSubmit={async (value) => {
                  await updateMutation({
                    variables: {
                      id: row.id,
                      input: {
                        workMinutes: Math.round(parseFloat(value) * 60),
                      },
                    },
                  })
                }}
              />
            </div>
            <div className="flex basis-1/6 flex-col">
              <Typography variant="body2" color="text.secondary">
                Subtotal
              </Typography>
              <InlineTextField
                name={`${row.id}.subtotal`}
                initialValue={subtotal}
                disabled
              />
            </div>
          </div>
        </div>
      )}
      <Divider />
      {getWorkItemAttachments(row)}
    </div>
  )
}

export default ItemForm
