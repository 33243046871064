import React, { useState, useMemo } from 'react'
import { Typography, Popover, Button, Paper, Modal } from '@mui/material'
import {
  HoverableLink,
  HighlightedTypography,
} from 'src/components/MUI/StyledComponents'
import { capitalizeFirstLetter } from 'src/utils/helpers'
import CitationViewer from 'src/components/CitationViewer'
import { MaintenanceItem } from 'types/graphql'

interface AtaCodeCellProps {
  row: MaintenanceItem
}

// Separate component for reference content to prevent unnecessary re-renders
const ReferenceContent = React.memo(
  ({
    references,
    onViewCitation,
  }: {
    references: any[]
    onViewCitation: (event: React.MouseEvent, reference: any) => void
  }) => (
    <div
      className="max-w-[350px] p-4"
      onClick={(event) => event.stopPropagation()}
    >
      {references.map((reference, index) => (
        <div
          key={reference.id}
          className={index < references.length - 1 ? 'mb-4' : ''}
        >
          <Typography variant="subtitle2">{reference.name}</Typography>
          <Typography variant="body2" className="mb-2">
            {reference.extractedText}
          </Typography>
          <Button
            size="small"
            onClick={(event) => onViewCitation(event, reference)}
          >
            View Citation
          </Button>
        </div>
      ))}
    </div>
  )
)
ReferenceContent.displayName = 'ReferenceContent'

ReferenceContent.displayName = 'ReferenceContent'

// Separate component for citation viewer modal to prevent unnecessary re-renders
const CitationViewerModal = React.memo(
  ({
    selectedReference,
    onClose,
  }: {
    selectedReference: any
    onClose: (event: React.MouseEvent) => void
  }) => (
    <Modal
      open={!!selectedReference}
      onClose={onClose}
      aria-labelledby="citation-viewer-modal"
    >
      <Paper
        className="absolute left-1/2 top-1/2 max-h-[90vh] max-w-[90vw] -translate-x-1/2 -translate-y-1/2 overflow-auto p-4"
        onClick={(event) => event.stopPropagation()}
      >
        {selectedReference && (
          <CitationViewer
            fileUrl={selectedReference.referenceSource.fileEntry.url}
            pageNumbers={selectedReference.pageNumbers}
            onClose={onClose}
          />
        )}
      </Paper>
    </Modal>
  )
)
CitationViewerModal.displayName = 'CitationViewerModal'

CitationViewerModal.displayName = 'CitationViewerModal'

const AtaCodeCell: React.FC<AtaCodeCellProps> = React.memo(({ row }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [selectedReference, setSelectedReference] = useState(null)

  const references = row.references
  const hasReference = references && references.length > 0

  // Memoize content string to prevent unnecessary re-renders
  const ataCodeContent = useMemo(
    () => `${row.ataCode} ${row.manufactureCode}`,
    [row.ataCode, row.manufactureCode]
  )

  // Memoize component description to prevent unnecessary re-renders
  const componentDescription = useMemo(
    () =>
      `${capitalizeFirstLetter(
        row?.trackedByComponent.name
      )} ${capitalizeFirstLetter(row.maintenanceType)}`,
    [row?.trackedByComponent.name, row.maintenanceType]
  )

  const handlePopoverToggle = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handleViewCitation = (event: React.MouseEvent, reference: any) => {
    event.stopPropagation()
    setSelectedReference(reference)
    handlePopoverClose()
  }

  const handleCloseCitation = (event: React.MouseEvent) => {
    event.stopPropagation()
    setSelectedReference(null)
  }

  // feature check done in the BE
  // If feature is disabled or there's no reference and we're done loading, render minimal content
  if (!hasReference) {
    return (
      <div className="flex flex-col">
        <HighlightedTypography variant="body2">
          {ataCodeContent}
        </HighlightedTypography>
        <Typography variant="body2" className="text-black/60">
          {componentDescription}
        </Typography>
      </div>
    )
  }

  return (
    <div className="flex flex-col">
      <HoverableLink
        variant="body2"
        onClick={handlePopoverToggle}
        className="cursor-pointer"
      >
        {ataCodeContent}
      </HoverableLink>
      <Typography variant="body2" className="text-black/60">
        {componentDescription}
      </Typography>

      <Popover
        id="reference-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ReferenceContent
          references={references}
          onViewCitation={handleViewCitation}
        />
      </Popover>

      <CitationViewerModal
        selectedReference={selectedReference}
        onClose={handleCloseCitation}
      />
    </div>
  )
})

AtaCodeCell.displayName = 'AtaCodeCell'

export default AtaCodeCell
