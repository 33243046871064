import { Button, FormHelperText, Typography } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import { get } from 'lodash'
import { User } from 'types/graphql'
import { useState, useMemo } from 'react'
import Searchbar from './Searchbar/Searchbar'
import DropdownFilter from './DropdownFilter/DropdownFilter'
interface PlainFilterContentProps {
  mechanics: User[]
  userIdType: 'id' | 'clerkId'
  gridView?: boolean
  gridCols?: number
  mechanicFilterData: any
  disableClearAll?: boolean
  error?: boolean
  helperText?: string
  enableSearch?: boolean
  setMechanicFilter: (id: string, checked: boolean) => void
  selectAllMechanicsFilter?: () => void
  resetMechanicFilter: () => void
}
interface MechanicFilterProps extends PlainFilterContentProps {
  variant?: 'popover' | 'plain'
  dropdownStyles?: React.CSSProperties
}

const PlainFilterContent: React.FC<PlainFilterContentProps> = ({
  mechanics,
  userIdType,
  mechanicFilterData,
  gridView = false,
  gridCols = 4,
  disableClearAll = false,
  error,
  helperText,
  setMechanicFilter,
  resetMechanicFilter,
  selectAllMechanicsFilter,
  enableSearch = false,
}) => {
  const [searchTerm, setSearchTerm] = useState('')

  const filteredMechanics = useMemo(() => {
    if (!searchTerm) return mechanics
    return mechanics.filter((mechanic) =>
      `${mechanic.firstName} ${mechanic.lastName}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    )
  }, [mechanics, searchTerm])

  return (
    <>
      {enableSearch && (
        <Searchbar
          placeholder="Search mechanics..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="mb-2 w-full"
        />
      )}
      <div className="flex w-full items-center justify-between">
        <Typography variant="caption" color={error ? 'error' : ''}>
          <Typography variant="body1">
            {error ? 'Mechanics *' : 'Mechanics'}
          </Typography>
        </Typography>
        <div className="flex">
          {selectAllMechanicsFilter && (
            <Button
              variant="text"
              onClick={selectAllMechanicsFilter}
              sx={{ textTransform: 'none', marginRight: 1 }}
            >
              Select all
            </Button>
          )}
          {!disableClearAll && (
            <Button
              variant="text"
              onClick={() => resetMechanicFilter()}
              sx={{ textTransform: 'none' }}
            >
              Clear all
            </Button>
          )}
        </div>
      </div>
      <FormGroup>
        <div
          className={gridView ? `grid grid-cols-${gridCols} ` : 'flex flex-col'}
        >
          {filteredMechanics.map((mechanic) => {
            return (
              <FormControlLabel
                key={mechanic[userIdType]}
                control={
                  <Checkbox
                    key={
                      mechanicFilterData[mechanic[userIdType]] ??
                      mechanic[userIdType]
                    }
                    checked={get(mechanicFilterData, mechanic[userIdType])}
                    onChange={(e) =>
                      setMechanicFilter(mechanic[userIdType], e.target.checked)
                    }
                  />
                }
                label={`${mechanic.firstName} ${mechanic.lastName
                  .charAt(0)
                  .toUpperCase()}`}
              />
            )
          })}
        </div>
        <FormHelperText error={error} className="pb-2">
          {helperText}
        </FormHelperText>
      </FormGroup>
    </>
  )
}

const MechanicFilter: React.FC<MechanicFilterProps> = ({
  variant = 'popover',
  dropdownStyles,
  error,
  helperText,
  ...rest
}) => {
  if (variant === 'plain') {
    return (
      <PlainFilterContent error={error} helperText={helperText} {...rest} />
    )
  }
  return (
    <div>
      <DropdownFilter
        error={error}
        label="Mechanics"
        dropdownStyles={dropdownStyles}
      >
        <PlainFilterContent {...rest} />
      </DropdownFilter>
      {helperText && (
        <FormHelperText error={error} className="py-1">
          {helperText}
        </FormHelperText>
      )}
    </div>
  )
}

export default MechanicFilter
