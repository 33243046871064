import React from 'react'

import { List, ListItem, ListItemText, Tooltip } from '@mui/material'

const TaskCountTextWithToolip = ({ taskDetial, tasks }) => {
  return (
    <>
      <Tooltip
        title={
          <List disablePadding={true}>
            {tasks.map((task) => (
              <ListItem key={task.id}>
                <ListItemText primary={task.mtxItem?.title ?? task.title} />
              </ListItem>
            ))}
          </List>
        }
      >
        <span
          style={{
            cursor: 'pointer',
            textDecorationLine: 'underline',
            textDecorationStyle: 'dotted',
          }}
        >
          {taskDetial}
        </span>
      </Tooltip>
    </>
  )
}

export default TaskCountTextWithToolip
