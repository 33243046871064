import React from 'react'

import styled from '@emotion/styled'
import { CircularProgress, IconButton, Typography, alpha } from '@mui/material'
import {
  FolderIcon as FolderClosedIcon,
  FolderOpenIcon,
  FileIcon,
  LinkIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  FileDownIcon,
  FolderDownIcon,
  FolderOpenDotIcon,
  CloudDownloadIcon,
  ExternalLinkIcon,
} from 'lucide-react'
import { NodeApi, NodeRendererProps } from 'react-arborist'

import theme from 'src/theme'

import { TreeViewNode, useFileTree } from '.'
import { Link } from '@redwoodjs/router'

export const StyledTreeNodeBase = styled.div`
  padding: 2px 8px;
  display: flex;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  &.active-node {
    background-color: rgba(5, 115, 237, 0.1);
    &:hover {
      background-color: rgba(5, 115, 237, 0.2);
    }
  }
`

const StyledTreeNode = styled(StyledTreeNodeBase)`
  align-items: center;
  min-width: max-content;
  border-left: 1px dashed ${alpha(theme.palette.text.primary, 0.4)};
`

const useNodeIcon = (node: NodeApi<TreeViewNode>) => {
  switch (node.data.fileType) {
    case 'file':
      return () => <FileIcon fill="#D7BBA8" size={12} />
    case 'imported-file':
      return () => <FileDownIcon fill="#D7BBA8" size={12} />
    case 'link':
      return () => <LinkIcon fill="#82B9F6" size={12} />
    case 'imported-root':
      return () => <CloudDownloadIcon fill="#B0BEC5" size={12} />
    case 'folder': {
      if (node.isOpen) {
        return () => <FolderOpenIcon size={12} />
      }
      return () => <FolderClosedIcon fill="" size={12} />
    }
    case 'imported-folder': {
      if (node.isOpen) {
        return () => <FolderOpenDotIcon size={12} />
      }
      return () => <FolderDownIcon fill="#A3C19A" size={12} />
    }
    default:
      return () => <FolderClosedIcon fill="" size={12} />
  }
}

const CustomNode: React.FC<NodeRendererProps<TreeViewNode>> = ({
  node,
  style,
  dragHandle,
}) => {
  const { data } = node
  const { activeNode, activeNodeDataLoading, setActiveNode } = useFileTree()

  const Icon = useNodeIcon(node)
  const childrenLoading = activeNodeDataLoading && activeNode?.id === data.id

  const handleClick = () => {
    if (childrenLoading) return
    if (node.isEditing) return
    node.toggle()
    setActiveNode(node)
  }
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleClick()
    }
  }

  const getActiveClass = () => {
    if (activeNode && activeNode.id === data.id) {
      return 'active-node'
    }
    return ''
  }
  return (
    <StyledTreeNode
      ref={dragHandle}
      className={`${getActiveClass()}`}
      role="button"
      tabIndex={0}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      style={{
        marginLeft: Number(style.paddingLeft) - 18,
        paddingLeft: 18,
        cursor: childrenLoading ? 'not-allowed' : 'pointer',
      }}
    >
      <div
        className="flex items-center gap-1"
        style={{ opacity: childrenLoading ? 0.5 : 1 }}
      >
        {node.isLeaf ? (
          <div style={{ width: 12 }} />
        ) : node.isOpen ? (
          <ChevronDownIcon size={12} />
        ) : (
          <ChevronRightIcon size={12} />
        )}
        <Icon />
        {node.isEditing ? (
          <input
            autoFocus
            autoCorrect="off"
            autoComplete="off"
            spellCheck="false"
            type="text"
            className="pr-2"
            defaultValue={node.data.name}
            onBlur={() => node.reset()}
            onKeyDown={(e) => {
              if (e.key === 'Escape') {
                node.reset()
              }
              if (e.key === 'Enter') {
                node.submit(e.currentTarget.value)
              }
            }}
          />
        ) : (
          <div
            role="button"
            tabIndex={0}
            onClick={(e) => {
              if (
                e.detail === 3 &&
                (activeNode?.data?.folderType === 'CUSTOM' ||
                  activeNode?.data?.fileType === 'file')
              ) {
                node.edit()
              }
            }}
            onKeyDown={() => {}}
          >
            <Typography variant="body2" className="pr-2">
              {data.name}
            </Typography>
          </div>
        )}
      </div>
      {childrenLoading && <CircularProgress size={12} thickness={5} />}
      {data?.payload?.targetLink && (
        <IconButton
          size="small"
          style={{ marginLeft: 'auto' }}
          component={Link}
          to={data.payload.targetLink}
          onClick={(e) => e.stopPropagation()}
        >
          <ExternalLinkIcon size={16} />
        </IconButton>
      )}
    </StyledTreeNode>
  )
}

export default CustomNode
