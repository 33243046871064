import clsx from 'clsx'
import React from 'react'

interface DrawerActionsProps {
  leftActions?: React.ReactNode
  rightActions?: React.ReactNode
  className?: string
}

const DrawerActions: React.FC<DrawerActionsProps> = ({
  leftActions,
  rightActions,
  className,
}) => {
  return (
    <div
      className={clsx(
        'sticky flex items-center justify-between border-0 border-t border-solid border-black border-opacity-10 px-4 py-2',
        className
      )}
    >
      {leftActions}
      {rightActions}
    </div>
  )
}

export default DrawerActions
