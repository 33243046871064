import { useQuery } from '@redwoodjs/web'
import { FileUploadButtonProps } from 'src/components/FileUploadButton'
import WorkflowAttachmentsSection from 'src/components/workflow/WorkflowAttachmentsSection'
import { GET_CUSTOM_INTERNAL_WORK_ITEM_ATTACHMENTS } from '../../../queries'
import { useMemo } from 'react'

const CustomWorkItemAttachments = ({
  workItemId,
  variant,
  flashSaved,
  flashError,
}: {
  workItemId: string
  variant?: 'default' | 'review' | 'readonly'
  flashSaved?: (text?: string) => void
  flashError?: (text?: string) => void
}) => {
  const { data: customWorkItemData, refetch: refetchCustomWorkItem } = useQuery(
    GET_CUSTOM_INTERNAL_WORK_ITEM_ATTACHMENTS,
    {
      variables: { id: workItemId },
      notifyOnNetworkStatusChange: false,
      fetchPolicy: 'cache-first',
      skip: !workItemId,
    }
  )

  const attachments = useMemo(() => {
    return (
      customWorkItemData?.customInternalWorkItem?.attachedFiles?.map(
        (file) => file?.file
      ) ?? []
    )
  }, [customWorkItemData])

  const attachables: FileUploadButtonProps['attachables'] = [
    {
      id: workItemId,
      type: 'CustomInternalWorkItem',
    },
  ]

  return (
    <WorkflowAttachmentsSection
      data={attachments}
      attachables={attachables}
      refetchAttachments={refetchCustomWorkItem}
      flashSaved={flashSaved}
      flashError={flashError}
      variant={variant}
    />
  )
}

export default CustomWorkItemAttachments
