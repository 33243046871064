import { useRoutePaths } from '@redwoodjs/router'
import { DISABLED_COMMENT_ROUTES } from 'src/components/Comments/useComments'
import getCommentRoomRoute from 'src/components/Comments/getCommentRoomRoute'

const usePageHasComments = (pathname: string) => {
  const allRoutePaths = useRoutePaths()
  const { roomRoute } = getCommentRoomRoute(pathname, allRoutePaths)
  return {
    hasComments: !DISABLED_COMMENT_ROUTES.find((route) => route === roomRoute),
  }
}

export default usePageHasComments
