import React from 'react'

import { InternalWorkItem } from 'types/graphql'

import DiscrepanciesTable from './Table'

interface DiscrepanciesTabProps {
  internalWorkItem: InternalWorkItem
  aircraftUsageLogId: string
  refreshWorkOrder: () => void
}

const Discrepancies: React.FC<DiscrepanciesTabProps> = ({
  internalWorkItem,
  aircraftUsageLogId,
  refreshWorkOrder,
}) => {
  return (
    <div>
      <DiscrepanciesTable
        internalWorkItem={internalWorkItem}
        aircraftUsageLogId={aircraftUsageLogId}
        refreshWorkOrder={refreshWorkOrder}
      />
    </div>
  )
}

export default Discrepancies
