import { useForm } from 'react-hook-form'
import FilterPopover from '../FilterPopover/FilterPopover'
import {
  AutocompleteField,
  AutocompleteOption,
} from '../MUI/Autocomplete/Autocomplete'
import { ataCodeOptions } from 'src/constants'
import useQuery from 'src/hooks/useQuery'
import { GET_TRACKED_BY_COMPONENTS } from '../FilterPopover/DueListFilterPopover'
import Loading from '../Loading'
import { useOrgName } from 'src/hooks/useOrgName'

export interface ReviewFormFilters {
  ataCodes: string[]
  trackedBy: string[]
}

const sections = ['ATA Code', 'Tracked By']

interface ReviewFormFilterPopoverProps {
  filtersAppliedCount: number
  onSubmit: (filters: ReviewFormFilters) => void
  onClear: () => void
  aircraftId: string
}

const ReviewFormFilterPopover: React.FC<ReviewFormFilterPopoverProps> = ({
  filtersAppliedCount,
  onSubmit,
  onClear,
  aircraftId,
}) => {
  const formMethods = useForm<ReviewFormFilters>({
    defaultValues: {
      ataCodes: [],
      trackedBy: [],
    },
  })

  const handleSubmit = (data: ReviewFormFilters) => {
    onSubmit(data)
  }

  let buttonText = 'FILTER BY'

  if (filtersAppliedCount > 0) {
    buttonText += ` (${filtersAppliedCount})`
  }

  const clearAllFilters = () => {
    formMethods.reset()
    onClear()
  }

  return (
    <FilterPopover<ReviewFormFilters>
      sections={sections}
      initialFilterSection={sections[0]}
      buttonText={buttonText}
      formMethods={formMethods}
      onSubmit={handleSubmit}
      buttonColor={filtersAppliedCount > 0 ? 'primary' : 'base'}
      clearAllFilters={clearAllFilters}
    >
      {(currentSection) => {
        switch (currentSection) {
          case 'ATA Code':
            return <ATACodeForm />
          case 'Tracked By':
            return <TrackedByForm aircraftId={aircraftId} />
          default:
            return null
        }
      }}
    </FilterPopover>
  )
}

const ATACodeForm = () => {
  return (
    <div className="m-2 flex flex-col gap-1">
      <AutocompleteField
        name="ataCodes"
        options={ataCodeOptions as AutocompleteOption[]}
        multiple
        className="w-full"
        label=""
      />
    </div>
  )
}

const TrackedByForm = ({ aircraftId }: { aircraftId: string }) => {
  const orgSlug = useOrgName()
  const { data: trackedByData, loading } = useQuery(GET_TRACKED_BY_COMPONENTS, {
    variables: {
      aircraftComponentFilters: {
        orgSlug,
        aircraftId,
        isPrimary: true,
      },
    },
  })

  const trackedByOptions = [
    ...new Set(
      trackedByData?.aircraftComponents?.map((component) => component.name) ??
        []
    ),
  ].map((name) => ({
    label: name,
    value: name,
  }))

  if (loading) return <Loading />

  return (
    <div className="m-2 flex flex-col gap-1">
      <AutocompleteField
        name="trackedBy"
        options={trackedByOptions as AutocompleteOption[]}
        multiple
        className="w-full"
        label=""
      />
    </div>
  )
}

export default ReviewFormFilterPopover
