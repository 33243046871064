import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

import { WorkOrdersFiltersData } from 'src/types'
import { isNotEmpty } from 'src/utils/helpers'

import { reset } from '../sharedActions'

type PageName = 'myorders' | 'scheduled' | 'completed' | 'all'

export const initialFilterData: WorkOrdersFiltersData = {
  searchTerm: '',
  aircrafts: {},
  dateFilter: {},
}

export interface WorkOrdersSearchParams {
  searchTerm?: string
  aircrafts?: string
  df_startDate?: string
  df_endDate?: string
  df_exact?: boolean
}

export function getWorkOrdersSearchParams(
  filtersData: WorkOrdersFiltersData
): WorkOrdersSearchParams {
  const { searchTerm, aircrafts: aircraftFilter, dateFilter } = filtersData
  const selectedAircrafts = Object.keys(aircraftFilter).filter(
    (acId) => aircraftFilter[acId]
  )
  const getDateFilterSearchParam = () => {
    if (dateFilter?.startDate && dateFilter?.endDate) {
      return {
        df_startDate: dayjs(dateFilter.startDate).format('YYYY-MM-DD'),
        df_endDate: dayjs(dateFilter.endDate).format('YYYY-MM-DD'),
        df_exact: dateFilter.exact ?? false,
      }
    }
    if (dateFilter?.startDate) {
      return {
        df_startDate: dayjs(dateFilter.startDate).format('YYYY-MM-DD'),
      }
    }
    return {}
  }
  return {
    ...(searchTerm && { searchTerm }),
    ...(selectedAircrafts.length > 0 && {
      aircrafts: selectedAircrafts.join(','),
    }),
    ...getDateFilterSearchParam(),
  }
}

export function areWorkOrdersPrevDepValuesReset(
  prevDeps: WorkOrdersFiltersData
): boolean {
  const {
    searchTerm: prevSearchTerm,
    aircrafts: prevAircrafts,
    dateFilter,
  } = prevDeps

  return (
    isNotEmpty(prevSearchTerm) ||
    isNotEmpty(prevAircrafts) ||
    isNotEmpty(dateFilter)
  )
}

export function updateWorkOrdersPrevDeps(
  filtersData: WorkOrdersFiltersData
): WorkOrdersFiltersData {
  return {
    searchTerm: filtersData.searchTerm,
    aircrafts: filtersData.aircrafts,
    dateFilter: filtersData.dateFilter,
  }
}

export function createWorkOrdersFiltersObject(
  searchParams: URLSearchParams
): WorkOrdersFiltersData {
  const getParam = (key) => searchParams.get(key)
  const getParamArray = (key) => getParam(key)?.split(',') || []
  const searchTerm = getParam('searchTerm') || initialFilterData.searchTerm
  const aircrafts = getParam('aircrafts')
    ? Object.fromEntries(getParamArray('aircrafts').map((acId) => [acId, true]))
    : initialFilterData.aircrafts
  const df_startDate = getParam('df_startDate')
  const df_endDate = getParam('df_endDate')
  const df_exact = getParam('df_exact')
  const dateFilter = {
    ...(df_startDate && { startDate: dayjs(df_startDate).toISOString() }),
    ...(df_endDate && { endDate: dayjs(df_endDate).toISOString() }),
    ...(df_exact && { exact: df_exact === 'true' }),
    ...(df_startDate && df_endDate && { isRange: true }),
  }

  return {
    ...initialFilterData,
    searchTerm,
    aircrafts,
    dateFilter,
  }
}

interface WorkOrdersFilterSliceState {
  pages: {
    [name: string]: {
      data: WorkOrdersFiltersData
    }
  }
}

const initialState: WorkOrdersFilterSliceState = {
  pages: {
    myorders: {
      data: initialFilterData,
    },
    scheduled: {
      data: initialFilterData,
    },
    completed: {
      data: initialFilterData,
    },
    all: {
      data: initialFilterData,
    },
  },
}

const workOrdersFiltersSlice = createSlice({
  name: 'workOrdersFilters',
  initialState,
  reducers: {
    setWorkOrdersFiltersData(
      state,
      // action: PayloadAction<WorkOrdersFiltersData>
      action: PayloadAction<{ name: PageName; data: WorkOrdersFiltersData }>
    ): void {
      // state.data = action.payload
      const { name, data } = action.payload
      state.pages[name].data = data
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reset, () => initialState)
  },
})

export const { setWorkOrdersFiltersData } = workOrdersFiltersSlice.actions

export default workOrdersFiltersSlice.reducer
