import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Modal, Paper, Typography } from '@mui/material'

import Button from 'src/components/MUI/Button'
import IconButton from 'src/components/MUI/IconButton'
import { useDispatch } from 'src/hooks/useDispatch'
import { useSelector } from 'src/hooks/useSelector'
import { closeModal } from 'src/slices/modal'
import { downloadPdfFromUrl } from 'src/utils/helpers'

const modalName = 'sendPurchaseOrderModal'

type SendPurchaseOrderModalProps = {
  urlForPdfDownload: string
  vendorEmail: string
  fileName: string
}

const SendPurchaseOrderModal: React.FC<SendPurchaseOrderModalProps> = ({
  urlForPdfDownload,
  vendorEmail,
  fileName,
}) => {
  const dispatch = useDispatch()

  const open =
    useSelector((state) => state.modal.modals?.[modalName]?.isOpen) ?? false

  // change UI based on whether the file is auto downloaded or not
  const autoDownloaded = useSelector(
    (state) => state.modal.modals?.[modalName]?.data?.autoDownloaded
  )

  const handleClose = () => {
    dispatch(closeModal({ name: modalName }))
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform rounded-xl">
        <div className="p-3 pb-3">
          {/* title and close button */}
          <div className="mb-2 flex items-center justify-between gap-1">
            <Typography variant="h6" component="h3">
              Instructions for Sending Your Downloaded File via Email
            </Typography>

            <IconButton onClick={handleClose}>
              <CloseRoundedIcon />
            </IconButton>
          </div>

          <div>
            <ol className="list-decimal">
              {/* <Typography component="li" variant="body1">
                <span className="font-semibold">
                  Locate Your Downloaded File:
                </span>
                <br />
                Go to your &quot;Downloads&quot; folder to find the file that
                was downloaded
              </Typography> */}
              <Typography component="li" variant="body1">
                <span className="font-semibold">
                  Click the &quot;Send to Vendor&quot; Button:
                </span>
                <br />
                This will open your default email client with a new email draft
                addressed to the vendor.
              </Typography>
              <Typography component="li" variant="body1">
                <span className="font-semibold">Add Your Attachment:</span>
                <br />
                attach the file in your &quot;Downloads&quot; folder.
              </Typography>
              <Typography component="li" variant="body1">
                <span className="font-semibold">Review and Send:</span>
                <br />
                Double-check all the information and attached files.
              </Typography>
            </ol>

            <Typography variant="body2" className="my-4 text-center italic">
              Note: You are responsible for sending the email. We do not send
              the email on your behalf.
            </Typography>
          </div>

          {/* bottom buttons */}
          <div className="flex flex-col gap-2">
            <Button
              onClick={async () => {
                downloadPdfFromUrl(urlForPdfDownload, fileName)
              }}
              fullWidth
              color="base"
              size="large"
              variant="outlined"
            >
              {autoDownloaded ? 'Re-' : ''}Download the File
            </Button>
            <Button
              onClick={() => {
                dispatch(closeModal({ name: modalName }))
              }}
              fullWidth
              size="large"
              href={`mailto:${vendorEmail}`}
              LinkComponent={'a'}
            >
              Send to Vendor
            </Button>
          </div>
        </div>
      </Paper>
    </Modal>
  )
}

export default SendPurchaseOrderModal
