import { QueryHookOptions } from '@apollo/client'

import CORE_AIRCRAFT_USAGE_LOG_FRAGMENT from 'src/fragments/AircraftUsageLog'

import useQuery from '../useQuery'

const GET_AIRCRAFT_BY_ID = gql`
  ${CORE_AIRCRAFT_USAGE_LOG_FRAGMENT}
  query GetAircraftById($id: String!) {
    aircraft(id: $id) {
      id
      name
      model
      tailNumber
      serialNumber
      orgSlug
      onboardStatus
      createdAt
      updatedAt
      AircraftComponent(isPrimary: true) {
        id
        name
        isPrimary
        cyclesSinceNew
        hoursSinceNew
        serialNumber
        partNumber
      }
      AircraftUsageLog {
        ...CoreAircraftUsageLogFields
      }
    }
  }
`

const useGetAircraftById = (id: string, options?: QueryHookOptions) => {
  return useQuery(GET_AIRCRAFT_BY_ID, { variables: { id }, ...options })
}

export default useGetAircraftById
