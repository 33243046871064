/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import { Tree, CreateHandler, RenameHandler } from 'react-arborist'
import {
  AttachedFile,
  DocHubFolderMetadataPayload,
  DocHubFolderType,
  DocHubWebLink,
  SupabaseFile,
} from 'types/graphql'

import CustomNode from './CustomNode'
import { TreeProvider, useFileTree } from './TreeContext'
import {
  CollapseButton,
  CreateFileButton,
  CreateFolderButton,
} from './TreeControlButtons'
import { isFolderType } from './utils'
import ReviewItemNode from './ReviewItemNode'

export type FileType =
  | 'folder'
  | 'imported-folder'
  | 'imported-root'
  | 'file'
  | 'imported-file'
  | 'link'

export interface TreeViewNode {
  id: string
  folderId?: string
  name: string
  fileType: FileType
  folderType?: DocHubFolderType
  file?: AttachedFile | SupabaseFile
  link?: DocHubWebLink
  payload?: DocHubFolderMetadataPayload
  areChildrenLoaded?: boolean
  children?: TreeViewNode[]
}

interface TreeProps {
  items: TreeViewNode[]
  onCreateNode?: CreateHandler<TreeViewNode>
  onRenameNode?: RenameHandler<TreeViewNode>
  className?: string
  height: number
  width?: number
  openByDefault?: boolean
  type?: 'docHub' | 'review'
}

const FileTree: React.FC<TreeProps> = ({
  items,
  onCreateNode,
  onRenameNode,
  className,
  height,
  width,
  openByDefault = false,
  type = 'docHub',
}) => {
  const { treeApiRef } = useFileTree()

  return (
    <Tree
      disableMultiSelection
      ref={treeApiRef}
      className={className}
      data={items}
      openByDefault={openByDefault}
      height={height}
      width={width}
      onCreate={onCreateNode}
      onRename={onRenameNode}
      {...(type === 'review' && { rowHeight: 40 })}
    >
      {type === 'docHub'
        ? (node) => <CustomNode {...node} />
        : (node) => <ReviewItemNode {...node} />}
    </Tree>
  )
}

export {
  FileTree,
  TreeProvider,
  CollapseButton,
  CreateFileButton,
  CreateFolderButton,
  useFileTree,
  isFolderType,
}
