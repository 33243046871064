import { InputAdornment } from '@mui/material'
import { Controller } from 'react-hook-form'

import TextField, { TextFieldProps } from 'src/components/MUI/TextField'

interface DollarCentsTextFieldProps extends TextFieldProps {
  name: string
  label: string
  disabled?: boolean
  control: any
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
}

const DollarCentsTextField: React.FC<DollarCentsTextFieldProps> = ({
  name,
  label,
  disabled,
  control,
  onBlur,
  ...otherProps
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, ...field } }) => (
        <TextField
          {...field}
          onBlur={onBlur}
          disabled={disabled}
          size="small"
          label={label}
          variant="outlined"
          type="number"
          inputProps={{ min: 0, step: 0.01 }}
          InputProps={{
            //conditionally render $ if no label or no value present
            startAdornment:
              label === '' || value !== '' ? (
                <InputAdornment position="start">$</InputAdornment>
              ) : null,
          }}
          {...otherProps}
        />
      )}
    />
  )
}

export default DollarCentsTextField
