import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { WorkCompletedFiltersData } from 'src/types'
import { isNotEmpty } from 'src/utils/helpers'

import { reset } from '../sharedActions'

export const initialFilterData: WorkCompletedFiltersData = {
  searchTerm: '',
  aircrafts: {},
  timestamp: null,
  ledgerStatus: {
    SIGN: true,
    COMPLETED: true,
    COMPLETED_LOGBOOK: true,
  },
}

export interface WorkCompletedSearchParams {
  searchTerm?: string
  aircrafts?: string
  timestamp?: string
  ledgerStatus?: string
}
export function getWorkCompletedSearchParams(
  filtersData: WorkCompletedFiltersData
): WorkCompletedSearchParams {
  const {
    searchTerm,
    aircrafts: aircraftFilter,
    timestamp,
    ledgerStatus,
  } = filtersData
  const selectedAircrafts = Object.keys(aircraftFilter).filter(
    (acId) => aircraftFilter[acId]
  )
  const selectedLedgerStatus = Object.keys(ledgerStatus).filter(
    (status) => ledgerStatus[status]
  )
  return {
    ...(searchTerm && { searchTerm }),
    ...(selectedAircrafts.length > 0 && {
      aircrafts: selectedAircrafts.join(','),
    }),
    ...(timestamp && { timestamp }),
    ...(selectedLedgerStatus.length < 3 && {
      ledgerStatus: selectedLedgerStatus.join(','),
    }),
  }
}

export function areWorkCompletedPrevDepValuesReset(
  prevDeps: WorkCompletedFiltersData
): boolean {
  const {
    searchTerm: prevSearchTerm,
    aircrafts: prevAircrafts,
    timestamp: prevTimestamp,
    ledgerStatus: prevLedgerStatus,
  } = prevDeps

  return (
    isNotEmpty(prevSearchTerm) ||
    isNotEmpty(prevAircrafts) ||
    isNotEmpty(prevTimestamp) ||
    isNotEmpty(prevLedgerStatus)
  )
}

export function updateWorkCompletedPrevDeps(
  filtersData: WorkCompletedFiltersData
): WorkCompletedFiltersData {
  return {
    searchTerm: filtersData.searchTerm,
    aircrafts: filtersData.aircrafts,
    timestamp: filtersData.timestamp,
    ledgerStatus: filtersData.ledgerStatus,
  }
}

export function createWorkCompletedFiltersObject(
  searchParams: URLSearchParams
): WorkCompletedFiltersData {
  const getParam = (key) => searchParams.get(key)
  const getParamArray = (key) => getParam(key)?.split(',') || []
  const searchTerm = getParam('searchTerm') || initialFilterData.searchTerm
  const aircrafts = getParam('aircrafts')
    ? Object.fromEntries(getParamArray('aircrafts').map((acId) => [acId, true]))
    : initialFilterData.aircrafts
  const timestamp = getParam('timestamp')
    ? isNaN(Date.parse(getParam('timestamp')))
      ? initialFilterData.timestamp
      : getParam('timestamp')
    : initialFilterData.timestamp
  const ledgerStatus = getParam('ledgerStatus')
    ? Object.fromEntries(
        getParamArray('ledgerStatus').map((status) => [status, true])
      )
    : initialFilterData.ledgerStatus
  return {
    ...initialFilterData,
    searchTerm,
    aircrafts,
    timestamp,
    ledgerStatus,
  }
}

interface WorkCompletedFilterSliceState {
  data: WorkCompletedFiltersData
}

const initialState: WorkCompletedFilterSliceState = {
  data: initialFilterData,
}

const workCompletedFiltersSlice = createSlice({
  name: 'eLogbooksFilters',
  initialState,
  reducers: {
    setWorkCompletedFiltersData(
      state,
      action: PayloadAction<WorkCompletedFiltersData>
    ): void {
      state.data = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reset, () => initialState)
  },
})

export const { setWorkCompletedFiltersData } = workCompletedFiltersSlice.actions

export default workCompletedFiltersSlice.reducer
