import WorkflowPartsTransactionSection from 'src/components/workflow/WorkflowPartsTransactionSection'
import useQuery from 'src/hooks/useQuery'
import { GET_PARTS_INFORMATION } from '../queries'
import { PartsTransaction } from 'types/graphql'

interface PartTransactionSectionProps {
  disableRequestPart?: boolean
  maintenanceItemId: string
  partsTransaction: PartsTransaction
  onDelete: () => void
}

const PartTransactionSection = ({
  disableRequestPart = false,
  maintenanceItemId,
  partsTransaction,
  onDelete,
}: PartTransactionSectionProps) => {
  const { data, loading } = useQuery(GET_PARTS_INFORMATION, {
    variables: { id: maintenanceItemId },
    skip: !maintenanceItemId,
  })

  const currentPart = data?.maintenanceItem?.aircraftComponent

  return (
    <div>
      <WorkflowPartsTransactionSection
        disableRequestPart={disableRequestPart}
        data={data}
        loading={loading}
        currentPart={currentPart}
        partsTransaction={partsTransaction}
        onDelete={onDelete}
      />
    </div>
  )
}

export default PartTransactionSection
