import { gql } from '@apollo/client'

import { getSortedFilteredComponents } from 'src/components/AircraftTimeModal/CreateTimeForm'

import useQuery from '../useQuery'

const GET_AIRCRAFT_BY_ID = gql`
  query GetAircraftComponentById($id: String!) {
    aircraft(id: $id) {
      id
      AircraftComponent(isPrimary: true) {
        id
        name
        isPrimary
        isIntegral
        cyclesSinceNew
        hoursSinceNew
        serialNumber
        partNumber
      }
    }
  }
`

function useTrackingComponents(aircraftId: string) {
  const { data, loading, error, hasLoaded } = useQuery(GET_AIRCRAFT_BY_ID, {
    variables: { id: aircraftId },
    skip: !aircraftId, // Skip the query if aircraftId is not provided
  })

  const trackingComponents = React.useMemo(() => {
    if (data && data.aircraft && data.aircraft.AircraftComponent) {
      return [
        ...getSortedFilteredComponents(data.aircraft.AircraftComponent, true),
        ...getSortedFilteredComponents(data.aircraft.AircraftComponent, false),
      ]
    } else {
      return []
    }
  }, [data])

  const trackingComponentOptions = React.useMemo(() => {
    return trackingComponents.map((component) => ({
      value: { id: component.id, name: component.name },
      label: component.name,
    }))
  }, [trackingComponents])

  return {
    trackingComponentOptions,
    trackingComponents,
    loading,
    hasLoaded,
    error,
  }
}

export default useTrackingComponents
