import { useQuery } from '@redwoodjs/web'
import { useMemo } from 'react'
import { FileUploadButtonProps } from 'src/components/FileUploadButton'
import WorkflowAttachmentsSection from 'src/components/workflow/WorkflowAttachmentsSection'
import { GET_COMPLIANCE_ACTIVITY_ATTACHMENTS } from '../../queries'

const AttachmentsSection = ({
  complianceActivityId,
  flashSaved,
  flashError,
  variant,
}: {
  complianceActivityId: string
  variant?: 'default' | 'review' | 'readonly'
  flashSaved?: (text?: string) => void
  flashError?: (text?: string) => void
}) => {
  const { data, refetch } = useQuery(GET_COMPLIANCE_ACTIVITY_ATTACHMENTS, {
    variables: { id: complianceActivityId },
    skip: !complianceActivityId,
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-first',
  })

  const attachables: FileUploadButtonProps['attachables'] = [
    { id: complianceActivityId, type: 'ComplianceActivity' },
  ]

  const attachments = useMemo(
    () =>
      data?.complianceActivity?.attachedFiles?.map((file) => ({
        ...file?.file,
        category: 'ComplianceActivity',
      })) ?? [],
    [data]
  )

  return (
    <WorkflowAttachmentsSection
      data={attachments}
      attachables={attachables}
      refetchAttachments={refetch}
      flashSaved={flashSaved}
      flashError={flashError}
      variant={variant}
    />
  )
}

export default AttachmentsSection
