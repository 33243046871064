/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext, useState, useCallback } from 'react'

import { NodeApi, TreeApi } from 'react-arborist'

import { isFolderType, TreeViewNode } from '.'

interface TreeContextProps {
  expandedNodes: string[]
  activeNode: NodeApi<TreeViewNode> | undefined
  otherContextData: any
  activeNodeDataLoading: boolean
  treeApiRef: React.RefObject<TreeApi<TreeViewNode>>
  setActiveNodeDataLoading: React.Dispatch<React.SetStateAction<boolean>>
  setOtherContextData: React.Dispatch<React.SetStateAction<any>>
  setActiveNode: React.Dispatch<
    React.SetStateAction<NodeApi<TreeViewNode> | undefined>
  >
  handleExpand: (id: string) => void
  setExpandedNodes: React.Dispatch<React.SetStateAction<string[]>>
  reset: () => void
}

interface TreeProviderProps {
  children: React.ReactNode
  initialSelectedId?: string
}

const TreeContext = createContext<TreeContextProps | null>(null)

const useFileTree = () => {
  const context = useContext(TreeContext)
  if (!context) {
    throw new Error('useFileTree must be used within a TreeProvider')
  }
  return context
}

const TreeProvider: React.FC<TreeProviderProps> = ({ children }) => {
  const [activeNode, setActiveNodeApi] = useState<
    NodeApi<TreeViewNode> | undefined
  >()
  const [otherContextData, setOtherContextData] = useState<any>({})
  const [activeNodeDataLoading, setActiveNodeDataLoading] =
    useState<boolean>(false)
  const treeApiRef = React.useRef<TreeApi<TreeViewNode>>(null)
  const [expandedNodes, setExpandedNodes] = useState<string[]>([])

  const handleExpand = useCallback((id: string) => {
    setExpandedNodes((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    )
  }, [])

  const setActiveNode = (node: NodeApi<TreeViewNode> | undefined) => {
    setActiveNodeApi(node)
    if (isFolderType(node.data.fileType)) handleExpand(node?.data.id)
  }

  const reset = () => {
    setExpandedNodes([])
    setOtherContextData({})
    setActiveNodeApi(undefined)
  }

  return (
    <TreeContext.Provider
      value={{
        expandedNodes,
        treeApiRef,
        activeNodeDataLoading,
        setActiveNodeDataLoading,
        otherContextData,
        setOtherContextData,
        activeNode,
        setActiveNode,
        handleExpand,
        setExpandedNodes,
        reset,
      }}
    >
      {children}
    </TreeContext.Provider>
  )
}

export { TreeProvider, TreeContext, useFileTree }
