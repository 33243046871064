import React, { useCallback } from 'react'
import { InternalWorkItem } from 'types/graphql'
import { CustomInternalWorkItem } from 'types/graphql'
import CustomWorkItemDetails from './CustomWorkItemDetails'
import WorkItemDetails from './WorkItemDetails'
import useWorkOrderVitals from 'src/hooks/useWorkOrderVitals'

interface WorkOrderDetailDrawerProps {
  workOrderId: string
  switchToPreviousTask?: () => void
  switchToNextTask?: () => void
  hasNextTask?: boolean
  hasPreviousTask?: boolean
}

const WorkOrderDetailsDrawer = ({
  workOrderId,
  switchToPreviousTask,
  switchToNextTask,
  hasNextTask,
  hasPreviousTask,
}: WorkOrderDetailDrawerProps) => {
  const {
    workOrderState: { detailDrawerTaskId, detailDrawerCustomTaskId },
  } = useWorkOrderVitals()
  if (detailDrawerCustomTaskId && !detailDrawerTaskId) {
    return (
      <CustomWorkItemDetails
        workOrderId={workOrderId}
        onBack={switchToPreviousTask}
        onNext={switchToNextTask}
      />
    )
  }
  if (detailDrawerTaskId && !detailDrawerCustomTaskId) {
    return (
      <WorkItemDetails
        workOrderId={workOrderId}
        onBack={switchToPreviousTask}
        onNext={switchToNextTask}
        hasNextTask={hasNextTask}
        hasPreviousTask={hasPreviousTask}
      />
    )
  }
  return null
}

export default WorkOrderDetailsDrawer
