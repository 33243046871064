import { useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@mui/material'
import { Dialog, DialogTitle, DialogContent } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'

import { Form, useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'

import TextField from 'src/components/MUI/TextField'
import { useOrgName } from 'src/hooks/useOrgName'
import useQuery from 'src/hooks/useQuery'
import { GET_VENDORS } from 'src/pages/PurchaseOrderPage/queries'
import { closeModal } from 'src/slices/modal'

import SelectDropdown from '../MUI/SelectDropdown'

import { schema } from './AddVendor'
import { CREATE_VENDOR, GET_VENDOR_BY_ID, UPDATE_VENDOR } from './queries'

const modalName = 'vendorEditorModal'

type VendorEditorModalProps = {
  onComplete?: (value: any) => void
}

const VendorEditorModal: React.FC<VendorEditorModalProps> = ({
  onComplete,
}) => {
  const orgSlug = useOrgName()
  const dispatch = useDispatch()

  const isOpen =
    useSelector((state: any) => state.modal.modals[modalName]?.isOpen) ?? false

  const id = useSelector(
    (state: any) => state.modal.modals[modalName]?.data?.shippingMethodId
  )

  const formMethods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  })

  const [createVendor] = useMutation(CREATE_VENDOR, {
    update: (cache, { data: { createVendor: vendor } }) => {
      const { vendors } = cache.readQuery({
        query: GET_VENDORS,
      })

      cache.writeQuery({
        query: GET_VENDORS,
        data: { vendors: [...vendors, vendor] },
      })

      //automatically set the vendor in the PO form if created from there
      onComplete?.({
        id: vendor.id,
        name: vendor.name,
      })
    },
  })
  const [updateVendor] = useMutation(UPDATE_VENDOR)

  const { data, hasLoaded } = useQuery(GET_VENDOR_BY_ID, {
    variables: { id },
    skip: !id,
  })

  useEffect(() => {
    // if we load data because we're in update mode, reset the form with the new data
    if (data) {
      const { __typename, id: _id, ...vendor } = data.vendor

      formMethods.reset(vendor)
    }
  }, [data, formMethods])

  const handleClose = () => {
    dispatch(closeModal({ name: modalName }))
    formMethods.reset({
      name: '',
      email: '',
      phoneNumber: '',
      website: '',
      type: '',
    })
  }

  const handleSubmit = async (values) => {
    if (id) {
      await updateVendor({
        variables: {
          id,
          input: { ...values, orgSlug: undefined },
        },
      })
    } else {
      await createVendor({
        variables: {
          input: { ...values, orgSlug },
        },
      })
    }
    // return to the list view and unset id
    handleClose()
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth>
      <DialogTitle>{id ? 'Edit Vendor' : 'Add Vendor'}</DialogTitle>
      <DialogContent className="pt-1">
        <Form
          onSubmit={handleSubmit}
          formMethods={formMethods}
          className="flex flex-col gap-2"
        >
          <TextField name="name" label="Name" fullWidth />
          <TextField name="email" label="Email" fullWidth />
          <TextField name="phoneNumber" label="Phone Number" fullWidth />
          <TextField name="website" label="Website" fullWidth />
          <SelectDropdown
            size="medium"
            name="type"
            label="Type"
            options={[
              { label: 'Customer', value: 'CUSTOMER' },
              { label: 'Supplier', value: 'SUPPLIER' },
              { label: 'Manufacturer', value: 'MANUFACTURER' },
            ]}
          />
          <div className="flex justify-end gap-2">
            <Button size="large" onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button
              size="large"
              type="submit"
              variant="contained"
              fullWidth
              color="primary"
            >
              {id ? 'Edit Vendor' : 'Add Vendor'}
            </Button>
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

export default VendorEditorModal
