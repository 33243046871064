export const GET_WORK_ORDERS_FOR_SELECTOR = gql`
  query GetWorkOrdersForAddToWorkOrderButton($orgSlug: String!) {
    internalWorkOrders(statuses: [DRAFT, IN_PROGRESS], orgSlug: $orgSlug) {
      id
      name
      vendor
      shop
      aircraft {
        id
        tailNumber
      }
      workItems {
        id
      }
      status
    }
  }
`

export const CREATE_WORK_ORDER = gql`
  mutation CreateWorkOrderForAddToWorkOrderButton(
    $maintenanceItemIds: [String!]!
    $aircraftId: String!
    $orgSlug: String!
  ) {
    createInternalWorkOrder(
      input: {
        maintenanceItemIds: $maintenanceItemIds
        aircraftId: $aircraftId
        orgSlug: $orgSlug
      }
    ) {
      id
    }
  }
`
