import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store'
import {
  setDirty,
  setTouched,
  startSubmit,
  submitSuccess,
  submitError,
  startValidation,
  setValidation,
  clearField,
} from '../slices/inline'
import * as yup from 'yup'

export interface FieldRenderProps {
  /** Current error message if any */
  error?: string | null
  /** Whether the field is valid */
  isValid?: boolean
  /** Whether the field has unsaved changes */
  isDirty: boolean
  /** Whether the user has interacted with the field */
  isTouched: boolean
  /** Whether the field is currently submitting */
  isSubmitting: boolean
  /** Whether the field is currently validating */
  isValidating: boolean
  /** Last successful submission timestamp */
  lastSubmitted?: string | null
  /** Handler for value changes */
  onChange: (value: any, event?: React.ChangeEvent<any>) => void
  /** Handler for blur events */
  onBlur: (value: any) => void
  /** Trigger manual validation */
  validate: (value: any) => Promise<boolean>
  /** Trigger manual submission */
  submit: (value: any) => Promise<void>

  /** Add new utility methods */
  setters: {
    /** Mark field as dirty/clean */
    setDirty: (isDirty: boolean) => void
    /** Mark field as touched/untouched */
    setTouched: (isTouched: boolean) => void
    /** Set validation state directly */
    setValidation: (isValid: boolean, error?: string | null) => void
  }

  /** Handler for key down events */
  onKeyDown?: (event: React.KeyboardEvent) => void

  /** Current field value */
  value: any
}

export interface InlineFieldWrapperProps {
  /** Unique identifier for the field */
  name: string

  /** Render prop function that receives field state and handlers */
  children: (props: FieldRenderProps) => React.ReactNode

  /** Schema validation - can be Yup schema or custom validation function */
  validation?: ((value: any) => Promise<boolean> | boolean) | yup.Schema<any>

  /** Handler for submitting changes */
  onSubmit?: (value: any) => Promise<void>

  /** Called when validation or submission fails */
  onError?: (error: string) => void

  /** Called after successful submission */
  onSuccess?: () => void

  /** When to trigger validation/submission */
  triggerOn?: {
    /** Validate while typing (with debounce) - for text inputs */
    typing?: boolean
    /** Validate/submit on change - for selects, dates, etc. */
    change?: boolean
    /** Validate/submit on blur */
    blur?: boolean
    /** Validate/submit on enter key */
    enter?: boolean
  }
  /** Debounce delay for typing validation (ms) */
  debounceMs?: number

  /** Initial value for the field */
  initialValue: any
}

export const InlineFieldWrapper: React.FC<InlineFieldWrapperProps> = ({
  name,
  children,
  initialValue,
  schemaValidation,
  logicalValidation,
  onSubmit,
  onError,
  onSuccess,
  triggerOn = { change: false, typing: true, blur: true, enter: true },
  debounceMs = 300,
}) => {
  const dispatch = useDispatch()
  const fieldState = useSelector(
    (state: RootState) => state.inline.fields[name]
  )

  const [value, setValue] = useState<any>(initialValue)
  const [previousValue, setPreviousValue] = useState<any>(initialValue)

  useEffect(() => {
    setValue(initialValue)
    setPreviousValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    return () => {
      dispatch(clearField({ name }))
    }
  }, [dispatch, name])

  const validate = useCallback(
    async (value: any) => {
      if (!schemaValidation && !logicalValidation) return true

      dispatch(startValidation({ name }))

      try {
        // Handle schema validation
        if (schemaValidation && 'validate' in schemaValidation) {
          await schemaValidation.validate(value)
        } else if (typeof schemaValidation === 'function') {
          const isSchemaValid = await schemaValidation(value)
          if (!isSchemaValid) {
            throw new Error('Failed schema validation')
          }
        }

        // Handle logical validation
        if (logicalValidation) {
          const isLogicalValid = await logicalValidation(value)
          if (!isLogicalValid) {
            dispatch(
              setValidation({
                name,
                valid: false,
                error: 'Failed logical validation',
              })
            )
            return false
          }
        }

        dispatch(setValidation({ name, valid: true }))
        return true
      } catch (error) {
        dispatch(
          setValidation({
            name,
            valid: false,
            error: error instanceof Error ? error.message : 'Validation failed',
          })
        )
        return false
      }
    },
    [dispatch, name, schemaValidation, logicalValidation]
  )

  const submit = useCallback(
    async (submitValue: any) => {
      if (!onSubmit) return

      dispatch(startSubmit({ name }))

      try {
        await onSubmit(submitValue)
        setPreviousValue(submitValue)
        dispatch(submitSuccess({ name }))
        onSuccess?.()
      } catch (error) {
        const errorMessage =
          error instanceof Error ? error.message : 'Submission failed'
        dispatch(submitError({ name, error: errorMessage }))
        onError?.(errorMessage)
      }
    },
    [dispatch, name, onSubmit, onSuccess, onError]
  )

  const handleChange = useCallback(
    async (newValue: any, event?: React.ChangeEvent<any>) => {
      setValue(newValue)

      // Compare with previous value instead of initialValue
      const hasChanged = newValue !== previousValue

      if (hasChanged) {
        dispatch(setDirty({ name, dirty: true }))
      } else {
        dispatch(setDirty({ name, dirty: false }))
      }

      if (triggerOn.change && hasChanged) {
        const isValid = await validate(newValue)
        if (isValid && onSubmit) {
          await submit(newValue)
          setPreviousValue(newValue)
        }
      } else if (triggerOn.typing && hasChanged) {
        // Debounced validation for text inputs
        // Debounce logic would go here
      }
    },
    [dispatch, name, validate, submit, onSubmit, triggerOn, previousValue]
  )

  const handleBlur = useCallback(
    async (value: any) => {
      dispatch(setTouched({ name, touched: true }))

      if (triggerOn.blur && fieldState?.dirty) {
        const isValid = await validate(value)
        if (isValid && onSubmit) {
          await submit(value)
        }
      }
    },
    [
      dispatch,
      name,
      validate,
      submit,
      onSubmit,
      triggerOn.blur,
      fieldState?.dirty,
    ]
  )

  const handleKeyDown = useCallback(
    async (event: React.KeyboardEvent) => {
      if (event.key === 'Enter' && triggerOn.enter && fieldState?.dirty) {
        event.preventDefault()
        const isValid = await validate(value)
        if (isValid && onSubmit) {
          await submit(value)
        }
      }
    },
    [
      dispatch,
      name,
      value,
      validate,
      submit,
      onSubmit,
      triggerOn.enter,
      fieldState?.dirty,
    ]
  )

  const setters = {
    setDirty: useCallback(
      (isDirty: boolean) => {
        dispatch(setDirty({ name, dirty: isDirty }))
      },
      [dispatch, name]
    ),

    setTouched: useCallback(
      (isTouched: boolean) => {
        dispatch(setTouched({ name, touched: isTouched }))
      },
      [dispatch, name]
    ),

    setValidation: useCallback(
      (isValid: boolean, error?: string | null) => {
        dispatch(setValidation({ name, valid: isValid, error }))
      },
      [dispatch, name]
    ),
  }

  const renderProps: FieldRenderProps = {
    value,
    error: fieldState?.error,
    isValid: fieldState?.valid,
    isDirty: fieldState?.dirty ?? false,
    isTouched: fieldState?.touched ?? false,
    isSubmitting: fieldState?.submitting ?? false,
    isValidating: fieldState?.validating ?? false,
    lastSubmitted: fieldState?.lastSubmitted,
    onChange: handleChange,
    onBlur: handleBlur,
    validate,
    submit,
    setters,
    onKeyDown: handleKeyDown,
  }

  return <>{children(renderProps)}</>
}
