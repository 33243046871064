import Button from 'src/components/MUI/Button'

import { EditRounded } from '@mui/icons-material'
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import { Divider, IconButton, Typography } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import { useMutation } from '@redwoodjs/web'
import useModal from 'src/hooks/useModal'
import { AircraftComponent, PartsTransaction } from 'types/graphql'
import { DELETE_PARTS_TRANSACTION } from './queries'

interface WorkflowPartsTransactionSectionProps {
  data: { complianceActivity?: { purchaseOrderItem?: { id: string } } }
  disableRequestPart?: boolean
  loading: boolean
  partsTransaction: PartsTransaction
  currentPart: AircraftComponent
  flashSaved?: () => void
  flashError?: () => void
  /**
   * Optional callback to handle deletion instead of the default mutation.
   * If provided, this will be called instead of executing the deletePartsTransaction mutation.
   */
  onDelete?: () => void
}

const WorkflowPartsTransactionSection = ({
  data,
  loading,
  disableRequestPart,
  partsTransaction,
  onDelete,
  currentPart,
  flashSaved,
  flashError,
}: WorkflowPartsTransactionSectionProps) => {
  const [deletePartsTransaction] = useMutation(DELETE_PARTS_TRANSACTION, {
    update: (cache, { data: mutationData }) => {
      cache.evict({
        id: cache.identify(mutationData?.deletePartsTransaction),
      })
    },
  })

  const { handleOpen: handleOpenPartTransaction } = useModal(
    'partTransactionDrawer'
  )
  const { handleOpen: handleOpenRequestPartDrawer } =
    useModal('requestPartDrawer')

  return (
    <div className="flex flex-col items-start gap-2 px-3">
      <div className="flex w-full items-center justify-between">
        <Typography variant="h6">Components</Typography>
        {!partsTransaction && (
          <div className="flex items-center gap-2">
            <Button
              startIcon={<BuildCircleOutlinedIcon />}
              variant="text"
              color="primary"
              onClick={handleOpenPartTransaction}
            >
              Add Component
            </Button>

            {!disableRequestPart && (
              <>
                <Divider orientation="vertical" flexItem />
                <Button
                  variant="text"
                  color="primary"
                  onClick={handleOpenRequestPartDrawer}
                  disabled={!!data?.complianceActivity?.purchaseOrderItem}
                  disabledTooltip="a part has already been requested"
                >
                  Request Component
                </Button>
              </>
            )}
          </div>
        )}
      </div>
      {!partsTransaction &&
        (currentPart?.partNumber ? (
          <CurrentPartInfo
            partNumber={currentPart?.partNumber}
            serialNumber={currentPart?.serialNumber}
          />
        ) : (
          <div className="flex w-full items-center justify-center">
            <Typography variant="body1">
              No associated component found
            </Typography>
          </div>
        ))}
      {(loading || partsTransaction) && (
        <PartTable
          title="Updates"
          loading={loading}
          newPartNumber={partsTransaction?.addedPartNumber}
          newSerialNumber={partsTransaction?.addedSerialNumber}
          previousPartNumber={partsTransaction?.removedPartNumber}
          previousSerialNumber={partsTransaction?.removedSerialNumber}
          onEdit={handleOpenPartTransaction}
          onDelete={() => {
            if (onDelete) {
              onDelete()
            } else {
              deletePartsTransaction({
                variables: { id: partsTransaction?.id },
                onCompleted: () => {
                  flashSaved?.()
                },
                onError: () => {
                  flashError?.()
                },
              })
            }
          }}
        />
      )}
      <Divider flexItem variant="fullWidth" className="my-2 border-b-2" />
    </div>
  )
}

const CurrentPartInfo = ({
  partNumber,
  serialNumber,
}: {
  partNumber: string
  serialNumber: string
}) => {
  return (
    <div className="flex w-full flex-col items-start gap-2">
      <Typography variant="caption">Current</Typography>
      <div className="flex w-full items-center gap-2 rounded-lg border border-solid border-black/10 p-2">
        <div className="flex flex-col">
          <Typography variant="body2">Number</Typography>
          <Typography variant="body2">{partNumber}</Typography>
        </div>
        <div className="flex flex-col">
          <Typography variant="body2">Serial Number</Typography>
          <Typography variant="body2">{serialNumber}</Typography>
        </div>
      </div>
    </div>
  )
}

const PartTable = ({
  newPartNumber,
  newSerialNumber,
  previousPartNumber,
  previousSerialNumber,
  onEdit,
  onDelete,
  loading = false,
  title,
}: {
  newPartNumber: string
  newSerialNumber: string
  previousPartNumber: string
  previousSerialNumber: string
  onEdit: () => void
  onDelete: () => void
  loading: boolean
  title: string
}) => {
  return (
    <div className="flex w-full flex-col items-start gap-2">
      <Typography variant="caption">{title}</Typography>
      <div className="w-full overflow-hidden rounded-lg border border-solid border-black/10">
        {/* Header Row */}
        <div className="grid h-7 grid-cols-5 border-b bg-gray-50 py-1 font-medium">
          <div className="grid grid-rows-2 gap-y-1 px-2">
            <Typography variant="body2" className="font-medium" color="error">
              Removed
            </Typography>
            <Typography variant="caption" className="font-medium">
              Part Number
            </Typography>
          </div>

          <div className="grid grid-rows-2 gap-y-1 px-2">
            <Typography
              variant="body2"
              className="font-medium"
              color="error"
            ></Typography>
            <Typography variant="caption" className="font-medium">
              Serial Number
            </Typography>
          </div>
          <div className="grid grid-rows-2 gap-y-1 px-2">
            <Typography variant="body2" className="font-medium" color="success">
              Installed
            </Typography>
            <Typography variant="caption" className="font-medium">
              Part Number
            </Typography>
          </div>
          <div className="grid grid-rows-2 gap-y-1 px-2">
            <Typography
              variant="body2"
              className="font-medium"
              color="success"
            ></Typography>
            <Typography variant="caption" className="font-medium">
              Serial Number
            </Typography>
          </div>
          <div className="flex items-center justify-end px-2"></div>
        </div>
        {/* Data Row */}
        <div className="grid grid-cols-5 bg-white py-1">
          <div className="flex items-center px-3">
            <Typography variant="body2">
              {loading ? <Skeleton width={120} /> : previousPartNumber ?? '-'}
            </Typography>
          </div>
          <div className="flex items-center px-3">
            <Typography variant="body2">
              {loading ? <Skeleton width={120} /> : previousSerialNumber ?? '-'}
            </Typography>
          </div>
          <div className="flex items-center px-3">
            <Typography variant="body2">
              {loading ? <Skeleton width={120} /> : newPartNumber ?? '-'}
            </Typography>
          </div>
          <div className="flex items-center px-3">
            <Typography variant="body2">
              {loading ? <Skeleton width={120} /> : newSerialNumber ?? '-'}
            </Typography>
          </div>
          <div className="flex items-center justify-end gap-1 px-3">
            {loading ? (
              <Skeleton variant="circular" width={40} height={40} />
            ) : (
              <>
                {onEdit && (
                  <IconButton onClick={onEdit}>
                    <EditRounded />
                  </IconButton>
                )}
                {onDelete && (
                  <IconButton onClick={onDelete}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default WorkflowPartsTransactionSection
