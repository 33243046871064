import React, { useState, useEffect } from 'react'

import { TextField, InputAdornment } from '@mui/material'

import { useFormContext } from '@redwoodjs/forms'

import { roundToPrecision } from 'src/utils/helpers'

const NumericAccruedTextField = ({
  name,
  label,
  initialValue,
  disabled,
  error,
  isAccruedMode,
  resetToggle,
  parentHandleChange,
  valueStepper,
  ...otherProps
}) => {
  const [startAdornment, setStartAdornment] = useState('')
  const [endAdornment, setEndAdornment] = useState('')
  const { setValue, watch } = useFormContext()
  const formValue = watch(name)
  const [formPreviouslyRendered, setFormPreviouslyRendered] = useState(false)

  useEffect(() => {
    isAccruedMode ? setValue(name, 0) : setValue(name, initialValue)
    updateAdornments(initialValue, initialValue)
  }, [])
  useEffect(() => {
    isAccruedMode ? setValue(name, 0) : setValue(name, initialValue)
    updateAdornments(initialValue, formValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetToggle])

  useEffect(() => {
    if (formPreviouslyRendered) {
      if (isAccruedMode) {
        //Going from false to true
        const delta = roundToPrecision(parseFloat(formValue) - initialValue)
        setValue(name, delta)
      } else {
        //Going from true to false
        const total = roundToPrecision(parseFloat(formValue) + initialValue)
        setValue(name, total)
      }
    }
    setFormPreviouslyRendered(true)
    updateAdornments(initialValue, formValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAccruedMode])

  useEffect(() => {
    updateAdornments(initialValue, formValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue, formValue])

  const updateAdornments = (baseValue, currentValue) => {
    if (isAccruedMode) {
      setStartAdornment(`${baseValue} + `)
      const accruedValue = parseFloat(currentValue || 0) || 0
      setEndAdornment(`= ${roundToPrecision(baseValue + accruedValue)}`)
    } else {
      setStartAdornment('')
      const delta = roundToPrecision(parseFloat(currentValue || 0) - baseValue)
      setEndAdornment(delta >= 0 ? `(+ ${delta})` : `(${delta})`)
    }
  }

  const handleChange = (event) => {
    const newValue = event.target.value
    setValue(name, newValue)
    otherProps?.onChange(event)
  }

  const handleKeyStroke = (event) => {
    const { key } = event
    const isDigit = key != ' ' && !isNaN(Number(key))
    const valueKey = key === '.' || isDigit
    const operationKey =
      event.keyCode === 8 || // backspace
      event.keyCode === 9 || // tab
      event.keyCode === 37 || // left arrow
      event.keyCode === 39 || // right arrow
      (event.keyCode === 9 && event.shiftKey) || // shift + tab, aka reverse tab
      (['r', 'x', 'c', 'v', 'a'].includes(key) &&
        (event.metaKey || event.ctrlKey)) // support refresh, cut, copy, paste

    const isAllowedKey = valueKey || operationKey

    // Only operation key is allowed after 2 decimal points
    if (
      event.target.value.indexOf('.') !== -1 &&
      event.target.value.split('.')[1].length > 1 &&
      !operationKey
    ) {
      event.preventDefault()
    }
    if (!isAllowedKey) {
      event.preventDefault()
    }
  }

  const handleBlur = (event) => {
    const finalValue = parseFloat(event.target.value) || 0
    const processedValue = isAccruedMode
      ? initialValue + finalValue
      : finalValue
    setValue(name, finalValue)
    if (parentHandleChange) {
      parentHandleChange({
        target: {
          name,
          value: event.target.value,
          absoluteValue: processedValue,
          incrementValue: processedValue - initialValue,
          inputType: isAccruedMode ? 'increment' : 'absolute',
        },
      })
    }
    otherProps?.onBlur(event)
  }

  return (
    <TextField
      className="col-span-3"
      name={name}
      label={label}
      value={formValue}
      onKeyDown={handleKeyStroke}
      onChange={handleChange}
      onBlur={handleBlur}
      size="small"
      type="number"
      autoComplete="off"
      disabled={disabled}
      error={!!error}
      helperText={error || ''}
      slotProps={{
        input: {
          startAdornment: startAdornment && (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ),
          endAdornment: endAdornment && (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ),
        },

        htmlInput: {
          step: valueStepper,
        },

        inputLabel: { shrink: true },
        formHelperText: { className: 'whitespace-pre-wrap' },
      }}
    />
  )
}

export default NumericAccruedTextField
