import { FC, useCallback, useMemo } from 'react'

import { gql, useApolloClient, useQuery } from '@apollo/client'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import WatchLaterRoundedIcon from '@mui/icons-material/WatchLaterRounded'
import { Popover, Typography } from '@mui/material'
import { PurchaseOrderItem } from 'types/graphql'

import { routes } from '@redwoodjs/router'

import useModal from 'src/hooks/useModal'
import { useOrgName } from 'src/hooks/useOrgName'

import Button from './MUI/Button'
import RequestPartModal from './RequestPartModal'
import useHasPermission from 'src/hooks/useHasPermission'
import { Permissions } from '@wingwork/common/src/constants'

export const GET_INTERNAL_WORK_ITEM_BY_ID = gql`
  query GetInternalWorkItemById($id: String!) {
    internalWorkItem(id: $id) {
      id
      purchaseOrderItem {
        id
        rejectedAt
        purchaseOrder {
          id
          status
        }
      }
    }
  }
`

// export const GET_COMPLIANCE_ACTIVITY_BY_ID = gql`
//   query GetComplianceActivityById($id: String!) {
//     complianceActivity(id: $id) {
//       id
//       purchaseOrderItem {
//         id
//         rejectedAt
//         purchaseOrder {
//           id
//           status
//         }
//       }
//     }
//   }
// `

type RequestPartButtonProps = {
  workItemId?: string
  complianceActivityId?: string
  onCompleted?: (item: PurchaseOrderItem) => void
}

const RequestPartButton: FC<RequestPartButtonProps> = ({
  workItemId,
  complianceActivityId,
  onCompleted,
}) => {
  const orgName = useOrgName()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const { handleOpen } = useModal('requestPartsModal')
  const client = useApolloClient()

  const { data, loading, error } = useQuery(
    // TODO: uncomment when ready for requesting parts from compliance
    // workItemId ? GET_INTERNAL_WORK_ITEM_BY_ID : GET_COMPLIANCE_ACTIVITY_BY_ID,
    GET_INTERNAL_WORK_ITEM_BY_ID,
    {
      variables: { id: workItemId || complianceActivityId },
      // TODO: uncomment when ready for requesting parts from compliance
      // skip: !workItemId && !complianceActivityId,
      skip: !workItemId,
    }
  )

  const purchaseOrderItem = useMemo(() => {
    return workItemId
      ? data?.internalWorkItem?.purchaseOrderItem
      : data?.complianceActivity?.purchaseOrderItem
  }, [data, workItemId])

  const hasPOItems = useMemo(() => {
    return (
      purchaseOrderItem &&
      purchaseOrderItem.length > 0 &&
      !purchaseOrderItem.every((item) => !!item.rejectedAt) // if all previous items are rejected, let them add another
    )
  }, [purchaseOrderItem])

  const arePOItemsCompleted = useMemo(() => {
    if (!hasPOItems) {
      return false
    }

    const statuses = purchaseOrderItem
      .filter((item) => !item.rejectedAt)
      .map((item) => item.purchaseOrder?.status)

    return statuses.every((status) => status === 'COMPLETED')
  }, [purchaseOrderItem, hasPOItems])

  const poIcon = useMemo(() => {
    if (!hasPOItems) {
      return null
    }

    return arePOItemsCompleted ? (
      <CheckCircleRoundedIcon />
    ) : (
      <WatchLaterRoundedIcon />
    )
  }, [hasPOItems, arePOItemsCompleted])

  const handleClick = useCallback(
    (e) => {
      if (hasPOItems && arePOItemsCompleted) {
        setAnchorEl(e.currentTarget)
      } else {
        handleOpen({ workItemId, complianceActivityId })
      }
    },
    [
      hasPOItems,
      arePOItemsCompleted,
      handleOpen,
      workItemId,
      complianceActivityId,
    ]
  )

  const handleCompleted = useCallback(
    (newItem: PurchaseOrderItem) => {
      // TODO: uncomment when ready for requesting parts from compliance
      // const query = workItemId
      //   ? GET_INTERNAL_WORK_ITEM_BY_ID
      //   : GET_COMPLIANCE_ACTIVITY_BY_ID
      // const id = workItemId || complianceActivityId
      const query = GET_INTERNAL_WORK_ITEM_BY_ID
      const id = workItemId

      // Update the cache
      const existingData = client.readQuery({
        query,
        variables: { id },
      })

      const updatedData = {
        ...existingData,
        [workItemId ? 'internalWorkItem' : 'complianceActivity']: {
          ...existingData[
            workItemId ? 'internalWorkItem' : 'complianceActivity'
          ],
          purchaseOrderItem: [
            ...existingData[
              workItemId ? 'internalWorkItem' : 'complianceActivity'
            ].purchaseOrderItem,
            newItem,
          ],
        },
      }

      client.writeQuery({
        query,
        variables: { id },
        data: updatedData,
      })

      onCompleted?.(newItem)
    },
    [client, workItemId, complianceActivityId, onCompleted]
  )

  const canRequestItems = useHasPermission(
    Permissions.purchaseOrder.requestItems
  )

  return (
    <>
      <Button
        size="small"
        endIcon={poIcon}
        disabled={loading || (hasPOItems && !arePOItemsCompleted)}
        disabledTooltip={hasPOItems ? 'Parts have been requested' : undefined}
        onClick={handleClick}
        locked={!canRequestItems}
        lockedTooltip="You do not have permission"
      >
        Request Part
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        className="mt-1"
        elevation={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className="flex flex-col gap-2 p-2">
          <Typography variant="body1">
            The PO including this part has been completed!
          </Typography>
          <Button
            fullWidth
            onClick={() => {
              setAnchorEl(null)
              window.open(
                routes.purchaseOrder({
                  id: purchaseOrderItem[0]?.purchaseOrder?.id,
                  orgName,
                }),
                '_blank'
              )
            }}
          >
            View PO
          </Button>
        </div>
      </Popover>
      <RequestPartModal onCompleted={handleCompleted} />
    </>
  )
}

export default RequestPartButton
