import { navigate, routes } from '@redwoodjs/router'
import { Redirect } from '@redwoodjs/router'
import { toast } from '@redwoodjs/web/toast'

import Loading from 'src/components/Loading'
import useQuery from 'src/hooks/useQuery'

import PurchaseOrderDraftPage from '../PurchaseOrderDraftPage/PurchaseOrderDraftPage'
import PurchaseOrderOpenPage from '../PurchaseOrderOpenPage/PurchaseOrderOpenPage'
import { GET_PURCHASE_ORDER_STATUS } from '../PurchaseOrderDraftPage/queries'

interface PurchaseOrderDetailPageProps {
  id: string
  orgName: string
}

const PurchaseOrderDetailPage = (props: PurchaseOrderDetailPageProps) => {
  const { id, orgName } = props
  const { data, error } = useQuery(GET_PURCHASE_ORDER_STATUS, {
    variables: {
      id,
    },
  })
  const { purchaseOrder: po } = data ?? {}

  if (error) {
    toast.error('Failed to load purchase order')
    navigate(routes.purchaseOrders({ orgName }), { replace: true })
  }

  if (po === undefined) {
    return <Loading />
  } else if (po.status === 'DRAFT') {
    return <PurchaseOrderDraftPage {...props} />
  } else if (po.status === 'CANCELLED') {
    toast.error('This purchase order has been cancelled.')
    return (
      <Redirect
        to={routes.purchaseOrders({ orgName })}
        options={{ replace: true }}
      />
    )
  } else {
    return <PurchaseOrderOpenPage {...props} />
  }
}

export default PurchaseOrderDetailPage
