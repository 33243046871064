import { useLazyQuery } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Paper, Stack, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { AircraftComponent } from 'types/graphql'
import * as yup from 'yup'

import { Form, useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import LoadingOverlay from 'src/components/LoadingOverlay'
import { useDispatch } from 'src/hooks/useDispatch'
import { useOrgName } from 'src/hooks/useOrgName'
import useQuery from 'src/hooks/useQuery'
import { closeModal } from 'src/slices/modal'
import { formatDateForDisplay, roundToPrecision } from 'src/utils/helpers'

import PrimSecTextCombo from '../common/PrimSecTextCombo'
import Button from '../MUI/Button'
import Checkbox from '../MUI/Checkbox'
import DatePicker from '../MUI/DatePicker'
import NumericAccruedTextField from '../NumericAccruedTextField/NumericAccruedTextField'

import { GET_COMPONENT_DATA } from './CreateTimeV2'
import SelectTime from './SelectTime'
import { wwAppColors } from 'src/theme'
import coreAircraftUsageLogFragment from 'src/fragments/AircraftUsageLog'

const GET_AIRCRAFT_USAGE_PARAMETER = gql`
  query GetAircraftUsageParameterToCreateTime($id: String!) {
    aircraft(id: $id) {
      id
      AircraftUsageParameter {
        id
        dailyFlyingTime
        dailyLandings
      }
    }
  }
`

const CREATE_AIRCRAFT_USAGE_LOG_WITH_COMPONENTS = gql`
  ${coreAircraftUsageLogFragment}
  mutation CreateAircraftUsageLogWithComponents(
    $input: CreateAircraftUsageLogWithComponentsInput!
  ) {
    createAircraftUsageLogWithComponents(input: $input) {
      ...CoreAircraftUsageLogFields
    }
  }
`

const UPDATE_AIRCRAFT_USAGE_LOG_WITH_COMPONENTS = gql`
  mutation UpdateAircraftUsageLogWithComponents(
    $input: UpdateAircraftUsageLogWithComponentsInput!
  ) {
    updateAircraftUsageLogWithComponents(input: $input) {
      id
      usageAsOf
    }
  }
`

interface CreateTimeFormProps {
  aircraftId: string
  isAccruedMode: boolean
  tailNumber: string
  mostRecentUpdateDate: Date
  componentData: AircraftComponent[]
  aircraftUsageLogId?: string
  aircraftUsageAsOf?: string
  disablePick?: boolean
  trackedByComponentName?: string
  onSuccess?: (aircraftUsageLogId: string) => void
}

const APPLY_ALL_CHECKBOX = 'applyAllCheckbox'
const OVERRIDE_WARNING_ERRORS = 'overrideWarningErrors'

export const getSortedFilteredComponents = (componentList, isIntegral) => {
  return [...componentList]
    .filter((component) => component.isIntegral === isIntegral)
    .sort((a, b) => a.name.localeCompare(b.name))
}

const componentToFormValues = (component, isUpdateMode) => {
  return {
    componentId: component.id,
    usageLogId: component.ComponentUsageLog[0]?.id,
    name: component.name,
    usageAsOf: isUpdateMode ? component.ComponentUsageLog[0]?.usageAsOf : null,
    initialHoursValue: component.ComponentUsageLog[0]?.totalTimeSinceNew ?? 0,
    initialCyclesValue: component.ComponentUsageLog[0]?.cycleSinceNew ?? 0,
    hoursValue: component.ComponentUsageLog[0]?.totalTimeSinceNew ?? 0,
    cyclesValue: component.ComponentUsageLog[0]?.cycleSinceNew ?? 0,
  }
}

const generateFormValuesFromComponents = (componentData, isUpdateMode?) => {
  if (componentData === undefined) {
    return {
      integralFields: [],
      supplementalFields: [],
      applyAllCheckbox: true,
      overrideWarningErrors: false,
    }
  }
  const integralFields = getSortedFilteredComponents(componentData, true).map(
    (component) => {
      return componentToFormValues(component, isUpdateMode)
    }
  )
  const supplementalFields = getSortedFilteredComponents(
    componentData,
    false
  ).map((component) => {
    return componentToFormValues(component, isUpdateMode)
  })
  return {
    integralFields,
    supplementalFields,
    applyAllCheckbox: true,
    overrideWarningErrors: false,
  }
}

const CreateTimeForm: React.FC<CreateTimeFormProps> = ({
  aircraftId,
  isAccruedMode,
  tailNumber,
  mostRecentUpdateDate,
  componentData,
  aircraftUsageLogId,
  aircraftUsageAsOf,
  disablePick,
  trackedByComponentName,
  onSuccess,
}) => {
  const isUpdateMode = !!aircraftUsageLogId
  const dispatch = useDispatch()
  const [componentDataBeforeSelectedDate, setComponentDataBeforeSelectedDate] =
    React.useState(undefined)
  const [componentDataAfterSelectedDate, setComponentDataAfterSelectedDate] =
    React.useState(undefined)
  const [fieldEnableTracker, setFieldEnableTracker] = React.useState({})
  const [resetToggle, setResetToggle] = React.useState(false)
  const [isPastDateEntryMode, setIsPastDateEntryMode] = React.useState(false)
  const orgSlug = useOrgName()

  const [dailyFlyingTime, setDailyFlyingTime] = React.useState(0)
  const [dailyLandings, setDailyLandings] = React.useState(0)
  const [ignorableErrors, setIgnorableErrors] = React.useState(false)

  const {
    data: aircraftUsageLogToUpdate,
    loading: aircraftUsageLogToUpdateLoading,
  } = useQuery(GET_COMPONENT_DATA, {
    variables: { aircraftId, usageAsOf: aircraftUsageAsOf },
    skip: !isUpdateMode,
  })

  useQuery(GET_COMPONENT_DATA, {
    variables: {
      aircraftId: aircraftId,
      // NOTE: Removing one day to ensure we get the previous usage log and not the same one we are updating
      usageAsOf: dayjs(aircraftUsageAsOf).subtract(1, 'day').toISOString(),
      after: false,
    },
    skip:
      !isUpdateMode || !aircraftUsageAsOf || !!componentDataBeforeSelectedDate,
    onCompleted: (data) => {
      if (
        data.aircraftComponents.some(
          (component) => component.ComponentUsageLog.length > 0
        )
      ) {
        setComponentDataBeforeSelectedDate(data.aircraftComponents)
      }
    },
  })

  useQuery(GET_COMPONENT_DATA, {
    variables: {
      aircraftId: aircraftId,
      usageAsOf: aircraftUsageAsOf,
      after: true,
    },
    skip:
      !isUpdateMode || !aircraftUsageAsOf || !!componentDataAfterSelectedDate,
    onCompleted: (data) => {
      if (
        data.aircraftComponents.some(
          (component) => component.ComponentUsageLog.length > 0
        )
      ) {
        setComponentDataAfterSelectedDate(data.aircraftComponents)
      }
    },
  })

  const {
    data: aircraftUsageParameterData,
    loading: isAircraftUsageParameterDataLoading,
  } = useQuery(GET_AIRCRAFT_USAGE_PARAMETER, {
    variables: { id: aircraftId },
  })

  // The form components should be re-rendered when componentDataBeforeSelectedDate changes
  const { integralComponents, supplementalComponents } = React.useMemo(() => {
    // Determine which dataset to use based on the mode
    let data: AircraftComponent[]
    if (isPastDateEntryMode) {
      if (componentDataBeforeSelectedDate) {
        data = componentDataBeforeSelectedDate
      } else if (componentDataAfterSelectedDate) {
        data = componentDataAfterSelectedDate.map((component) => {
          return { ...component, ComponentUsageLog: [] }
        })
      } else {
        data = undefined
      }
    } else if (isUpdateMode) {
      data = aircraftUsageLogToUpdate?.aircraftComponents
    } else {
      data = componentData
    }

    // If we don't have any data (for normal mode), return empty arrays
    if (!data) {
      return { integralComponents: [], supplementalComponents: [] }
    }

    // Extract and sort integral and supplemental components
    const integralData = getSortedFilteredComponents(data, true)
    const supplementalData = getSortedFilteredComponents(data, false)

    return {
      integralComponents: integralData,
      supplementalComponents: supplementalData,
    }
  }, [
    isPastDateEntryMode,
    isUpdateMode,
    componentDataBeforeSelectedDate,
    componentDataAfterSelectedDate,
    aircraftUsageLogToUpdate,
    componentData,
  ])

  // Define validation schema based on your requirements
  const validationSchema = React.useMemo(() => {
    const isLatestUsageLog = !componentDataAfterSelectedDate
    const minFormData =
      isPastDateEntryMode || isUpdateMode
        ? generateFormValuesFromComponents(
            componentDataBeforeSelectedDate,
            isUpdateMode
          )
        : generateFormValuesFromComponents(componentData)
    const maxFormData =
      (isPastDateEntryMode || isUpdateMode) && !isLatestUsageLog
        ? generateFormValuesFromComponents(
            componentDataAfterSelectedDate,
            isUpdateMode
          )
        : undefined
    const extractInfo = (path) => {
      const regex = /^(\w+)\[(\d+)\]/
      const match = regex.exec(path)

      if (match) {
        return {
          fieldKey: match[1],
          index: parseInt(match[2], 10),
        }
      }
      return {
        fieldKey: null,
        index: null,
      }
    }

    const DAILY_USAGE_FACTOR = 5

    const componentSchema = yup.object().shape({
      hoursValue: yup
        .number()
        .typeError('Value should be of type number')
        .test(
          'hoursValue-test',
          'Value must pass hour value validations',
          function (value) {
            const isOverrideWarningErrorsChecked = getValues(
              OVERRIDE_WARNING_ERRORS
            )
            const { path, createError, parent } = this
            const { fieldKey, index } = extractInfo(path)
            const defaultValue = minFormData[fieldKey]?.[index]?.hoursValue ?? 0
            if (isAccruedMode) {
              value += minFormData[fieldKey][index].hoursValue
            }
            if (value < defaultValue) {
              return createError({
                path,
                message: `Value must be greater or equal to ${defaultValue}`,
              })
            }
            if (maxFormData) {
              const maxValue = maxFormData[fieldKey][index].hoursValue
              // maxValue can be zero in certain cases with supplemental components
              if (maxValue && value > maxValue) {
                return createError({
                  path,
                  message: `Value must be lesser or equal to ${maxValue}`,
                })
              }
            } else {
              //TODO: Implement a upper bound sanity check for numbers
              const hourDiff = value - defaultValue
              // if the user inputted a date, then we can calculate the calculated daily flying time
              if (parent.usageAsOf) {
                const specificComponentData = componentData.find(
                  (cmp) => cmp.id === parent.componentId
                )
                // If is update mode, we compare to the previous usage log (minFormData)
                const dateToCompare = isUpdateMode
                  ? minFormData[fieldKey][index].usageAsOf
                  : specificComponentData.ComponentUsageLog[0].usageAsOf

                const dateDiff = Math.max(
                  1,
                  dayjs(parent.usageAsOf).diff(
                    dayjs(dateToCompare),
                    'day',
                    true
                  )
                )
                const calculatedDailyFlyingTime = hourDiff / dateDiff

                // if the calculated daily flying time is greater than 24 hours, then we throw an error
                if (calculatedDailyFlyingTime > 24) {
                  return createError({
                    path,
                    message: `Too many hours for ${dateDiff} day${
                      dateDiff > 1 ? 's' : ''
                    }`,
                  })
                } else if (!isOverrideWarningErrorsChecked) {
                  const estimatedDailyFlyingTime =
                    dailyFlyingTime * DAILY_USAGE_FACTOR
                  if (calculatedDailyFlyingTime > estimatedDailyFlyingTime) {
                    // if the calculated daily flying time is greater than the estimated daily flying time, then we warn the user
                    setIgnorableErrors(true)
                    return createError({
                      path,
                      message: `Higher than daily usage of ${estimatedDailyFlyingTime} hour${
                        estimatedDailyFlyingTime > 1 ? 's' : ''
                      }\n(Select the checkbox to override)`,
                    })
                  }
                }
              }
            }
            return true
          }
        )
        .required(),
      cyclesValue: yup
        .number()
        .typeError('Value should be of type number')
        .integer('Value cannot be a decimal')
        .test(
          'cyclesValue-test',
          'Value must pass cycle value validations',
          function (value) {
            const isOverrideWarningErrorsChecked = getValues(
              OVERRIDE_WARNING_ERRORS
            )
            const { path, createError, parent } = this
            const { fieldKey, index } = extractInfo(path)
            const componentName = minFormData[fieldKey][index]?.name
            const defaultValue =
              minFormData[fieldKey]?.[index]?.cyclesValue ?? 0
            if (isAccruedMode) {
              value += minFormData[fieldKey][index].cyclesValue
            }
            if (value < defaultValue) {
              return createError({
                path,
                message: `Value must be greater or equal to ${defaultValue}`,
              })
            }
            if (maxFormData) {
              const maxValue = maxFormData[fieldKey][index].cyclesValue
              // maxValue can be zero in certain cases with supplemental components
              if (maxValue && value > maxValue) {
                return createError({
                  path,
                  message: `Value must be lesser or equal to ${maxValue}`,
                })
              }
            } else {
              //TODO: Implement a upper bound sanity check for numbers
              const cycleDiff = value - defaultValue
              // if the user inputted a date, then we can calculate the calculated daily cycles
              if (parent.usageAsOf) {
                const specificComponentData = componentData.find(
                  (cmp) => cmp.id === parent.componentId
                )
                // If is update mode, we compare to the previous usage log (minFormData)
                const dateToCompare = isUpdateMode
                  ? minFormData[fieldKey][index].usageAsOf
                  : specificComponentData.ComponentUsageLog[0].usageAsOf

                const dateDiff = Math.max(
                  1,
                  dayjs(parent.usageAsOf).diff(
                    dayjs(dateToCompare),
                    'day',
                    true
                  )
                )
                const calculatedDailyLandings = cycleDiff / dateDiff
                const estimatedDailyLandings =
                  dailyLandings * DAILY_USAGE_FACTOR
                if (
                  !isOverrideWarningErrorsChecked &&
                  calculatedDailyLandings > estimatedDailyLandings
                ) {
                  setIgnorableErrors(true)
                  return createError({
                    path,
                    message: `Higher than daily usage of ${estimatedDailyLandings} ${
                      componentName === 'Airframe' ? 'landing' : 'cycle'
                    }${
                      estimatedDailyLandings > 1 ? 's' : ''
                    }\n(Select the checkbox to override)`,
                  })
                }
              }
            }
            return true
          }
        )
        .required(),
      usageAsOf: yup
        .string()
        .nullable()
        .test('date-test', 'Date is required', function (value) {
          const { path, createError } = this
          const { fieldKey, index } = extractInfo(path)
          if (value === null || value === '' || value === undefined) {
            return true
          }

          const date = dayjs(value)
          if (!date.isValid()) {
            return createError({
              path,
              message: `Invalid date entered ${value}`,
            })
          }
          if (date > dayjs()) {
            return createError({
              path,
              message: `Value greater than today's date`,
            })
          }
          return true
        }),
    })

    return yup.object().shape({
      integralFields: yup
        .array()
        .of(componentSchema)
        .length(integralComponents.length),
      supplementalFields: yup
        .array()
        .of(componentSchema)
        .length(supplementalComponents.length),
      applyAllCheckbox: yup.boolean(),
      overrideWarningErrors: yup.boolean(),
    })
  }, [
    componentData,
    isPastDateEntryMode,
    componentDataBeforeSelectedDate,
    componentDataAfterSelectedDate,
    integralComponents,
    supplementalComponents,
    isAccruedMode,
  ])

  const formDefaultValues = React.useMemo(() => {
    // On updates, we want to have the default values be the pre-existing ones
    if (isUpdateMode) {
      return generateFormValuesFromComponents(
        aircraftUsageLogToUpdate?.aircraftComponents,
        isUpdateMode
      )
    }
    return generateFormValuesFromComponents(componentData)
  }, [componentData, aircraftUsageLogToUpdate, isUpdateMode])

  const formInitialValues = React.useMemo(() => {
    if (isUpdateMode) {
      return generateFormValuesFromComponents(
        aircraftUsageLogToUpdate?.aircraftComponents,
        isUpdateMode
      )
    }
    if (isPastDateEntryMode) {
      return generateFormValuesFromComponents(componentDataBeforeSelectedDate)
    }
    return generateFormValuesFromComponents(componentData)
  }, [
    componentData,
    isPastDateEntryMode,
    isUpdateMode,
    componentDataBeforeSelectedDate,
    aircraftUsageLogToUpdate,
  ])

  const formMethods = useForm({
    resolver: yupResolver(validationSchema),
  })

  React.useEffect(() => {
    formMethods.reset(formDefaultValues)
  }, [formDefaultValues])

  const {
    register,
    reset,
    watch,
    formState: { errors },
    setValue,
    clearErrors,
    getValues,
  } = formMethods
  const isApplyAllChecked = watch(APPLY_ALL_CHECKBOX)
  const [loadBeforeComponentData, { loading: isBeforeComponentDataLoading }] =
    useLazyQuery(GET_COMPONENT_DATA)
  const [loadAfterComponentData, { loading: isAfterComponentDataLoading }] =
    useLazyQuery(GET_COMPONENT_DATA)

  const handleReset = () => {
    // Reset logic
    setIsPastDateEntryMode(false)
    setFieldEnableTracker({})
    reset() // Resets to default values
    setResetToggle(!resetToggle) // Toggle to trigger reset in child components
  }
  React.useEffect(() => {
    // Performing form reset whenever isApplyAllChecked is toggled into true
    if (isApplyAllChecked) {
      handleReset()
    }

    if (!isAircraftUsageParameterDataLoading && aircraftUsageParameterData) {
      setDailyFlyingTime(
        aircraftUsageParameterData.aircraft.AircraftUsageParameter
          ?.dailyFlyingTime
      )
      setDailyLandings(
        aircraftUsageParameterData.aircraft.AircraftUsageParameter.dailyLandings
      )
    }
  }, [isApplyAllChecked, isAircraftUsageParameterDataLoading])

  const [createUsageLogMutation, { loading: saveLoading }] = useMutation(
    CREATE_AIRCRAFT_USAGE_LOG_WITH_COMPONENTS
  )
  const [updateUsageLogMutation, { loading: updateLoading }] = useMutation(
    UPDATE_AIRCRAFT_USAGE_LOG_WITH_COMPONENTS
  )

  if (!mostRecentUpdateDate) return <></>

  const handleClose = () => {
    dispatch(closeModal({ name: 'aircraftTimeModal' }))
  }

  const handleIntegralComponentValueSync = ({
    target: { name, incrementValue },
  }) => {
    if (isApplyAllChecked) {
      formInitialValues.integralFields.forEach((integralField, index) => {
        // Ignore when it's same index
        if (parseInt(name.split('.')[1]) === index) return
        const fieldName: string = name.split('.')[2]
        if (fieldName === 'hoursValue') {
          const value = roundToPrecision(
            incrementValue +
              (isAccruedMode ? 0 : integralField.initialHoursValue)
          )
          setValue(`integralFields.${index}.hoursValue`, value)
        }
        if (fieldName === 'cyclesValue') {
          const value = roundToPrecision(
            incrementValue +
              (isAccruedMode ? 0 : integralField.initialCyclesValue)
          )
          setValue(`integralFields.${index}.cyclesValue`, value)
        }
      })
    }
  }

  const formRowDataToComponentData = (component, index, usageAsOf) => {
    const dataRow = {
      componentId: component.componentId,
      componentName: component.name,
      usageAsOf: dayjs(usageAsOf).toISOString(),
      totalTimeSinceNew:
        component.hoursValue +
        (isAccruedMode
          ? formInitialValues.integralFields[index].hoursValue
          : 0),
      cycleSinceNew:
        component.cyclesValue +
        (isAccruedMode
          ? formInitialValues.integralFields[index].cyclesValue
          : 0),
      // ... other component fields
    }

    if (isUpdateMode) {
      // Updates need the usageLogId
      dataRow['usageLogId'] = component.usageLogId
    }

    return dataRow
  }

  const handleFormSubmit = async (data) => {
    let components = []
    if (data.integralFields[0].usageAsOf) {
      components = [
        ...data.integralFields.map((component, index) => {
          return formRowDataToComponentData(
            component,
            index,
            data.integralFields[0].usageAsOf
          )
        }),
      ]
    }
    components = [
      ...components,
      ...data.supplementalFields
        .filter((c) => c.usageAsOf as boolean)
        .map((component, index) => {
          return formRowDataToComponentData(
            component,
            index,
            component.usageAsOf
          )
        }),
    ]
    if (components.length === 0) {
      toast.error('No Components updated')
      return
    }

    let resultPromise = null

    if (isUpdateMode) {
      // Trigger update instead of creation
      resultPromise = updateUsageLogMutation({
        variables: {
          input: {
            // Populate with your form data
            orgSlug: orgSlug,
            aircraftId: aircraftId,
            usageLogId: aircraftUsageLogId,
            usageAsOf: data.integralFields[0].usageAsOf
              ? dayjs(data.integralFields[0].usageAsOf).toISOString()
              : null,
            components: components,
          },
        },
        onCompleted: (data) => {
          if (onSuccess) {
            onSuccess(data?.updateAircraftUsageLogWithComponents.id)
          }
          handleReset()
          handleClose()
        },
      })

      toast.promise(resultPromise, {
        loading: 'Updating usage log...',
        success: `Times for Aircraft ${tailNumber} have been updated`,
        error: `Error while updating times for Aircraft ${tailNumber}`,
      })
    } else {
      resultPromise = createUsageLogMutation({
        variables: {
          input: {
            // Populate with your form data
            orgSlug: orgSlug,
            aircraftId: aircraftId,
            usageAsOf: data.integralFields[0].usageAsOf
              ? dayjs(data.integralFields[0].usageAsOf).toISOString()
              : null,
            components: components,
          },
        },
        onCompleted: (data) => {
          if (onSuccess) {
            onSuccess(data?.createAircraftUsageLogWithComponents.id)
          }
          handleReset()
          handleClose()
        },
        update: (cache, { data: updateData }) => {
          if (updateData?.createAircraftUsageLogWithComponents) {
            // Write the new data to the fragment
            try {
              cache.writeFragment({
                id: cache.identify({
                  __typename: 'AircraftUsageLog',
                  id: updateData.createAircraftUsageLogWithComponents.id,
                }),
                fragment: coreAircraftUsageLogFragment,
                data: updateData.createAircraftUsageLogWithComponents,
              })
            } catch (error) {
              console.error('Error writing fragment:', error)
            }
          }
        },
      })

      toast.promise(resultPromise, {
        loading: 'Saving new usage log...',
        success: `New times for Aircraft ${tailNumber} have been applied`,
        error: `Error while saving new times for Aircraft ${tailNumber}`,
      })
    }

    await resultPromise
    handleReset()
    handleClose()
  }

  const setFormValuesFromComponentData = (componentData) => {
    const formData = generateFormValuesFromComponents(componentData)
    formData.integralFields.forEach((integralField, index) => {
      setValue(
        `integralFields.${index}.hoursValue`,
        integralField.initialHoursValue
      )
      setValue(
        `integralFields.${index}.cyclesValue`,
        integralField.initialCyclesValue
      )
    })
    formData.supplementalFields.forEach((supplementalField, index) => {
      setValue(
        `supplementalFields.${index}.hoursValue`,
        supplementalField.initialHoursValue
      )
      setValue(
        `supplementalFields.${index}.cyclesValue`,
        supplementalField.initialCyclesValue
      )
    })
  }

  const handleIntegralComponentDateChange = (value, _component) => {
    setFieldEnableTracker({
      ...fieldEnableTracker,
      'integralFields.0.usageAsOf': value,
    })
    supplementalComponents.forEach((_c, index) => {
      setValue(`supplementalFields.${index}.usageAsOf`, null)
    })
    if (value !== null && value < dayjs(mostRecentUpdateDate)) {
      loadBeforeComponentData({
        variables: {
          aircraftId: aircraftId,
          usageAsOf: value.toISOString(),
          after: false,
        },
        onCompleted: (data) => {
          const isDateEarlierThanOldestLog = data.aircraftComponents.some(
            (component) => component.ComponentUsageLog.length === 0
          )
          setFormValuesFromComponentData(data.aircraftComponents)
          if (!isDateEarlierThanOldestLog) {
            setComponentDataBeforeSelectedDate(data.aircraftComponents)
          } else {
            setComponentDataBeforeSelectedDate(undefined)
          }
          setIsPastDateEntryMode(true)
          clearErrors()
        },
      })
      loadAfterComponentData({
        variables: {
          aircraftId: aircraftId,
          usageAsOf: value.toISOString(),
          after: true,
        },
        onCompleted: (data) => {
          setComponentDataAfterSelectedDate(data.aircraftComponents)
          setIsPastDateEntryMode(true)
        },
      })
    } else {
      setFormValuesFromComponentData(componentData)
      setIsPastDateEntryMode(false)
    }
    setValue('integralFields.0.usageAsOf', value)
    //TODO Set Supplemental Date to this
  }
  const handleSupplementalComponentDateChange = (value, _component, index) => {
    const fieldTracker = { ...fieldEnableTracker }
    fieldTracker[`supplementalFields.${index}.usageAsOf`] = value
    setValue(`supplementalFields.${index}.usageAsOf`, value)
    setFieldEnableTracker(fieldTracker)
  }

  const FormRow = (component, index, isIntegralComponents) => {
    const showDateField =
      isIntegralComponents === false ||
      (isIntegralComponents === true && index === 0)
    const handleDatePickerChange = (value) => {
      if (isIntegralComponents)
        handleIntegralComponentDateChange(value, component)
      else handleSupplementalComponentDateChange(value, component, index)
    }

    const fieldKey = isIntegralComponents
      ? 'integralFields'
      : 'supplementalFields'

    const enableNumericFields =
      // Enable for first component always if date is present
      (isIntegralComponents &&
        index === 0 &&
        (isUpdateMode ||
          fieldEnableTracker[`integralFields.${index}.usageAsOf`] !==
            undefined)) ||
      // Enable for all integral components if isApplyAllChecked is not checked and date is present
      (isIntegralComponents &&
        !isApplyAllChecked &&
        (isUpdateMode ||
          fieldEnableTracker[`integralFields.0.usageAsOf`] !== undefined)) ||
      (!isIntegralComponents &&
        (isUpdateMode ||
          fieldEnableTracker[`supplementalFields.${index}.usageAsOf`] !==
            undefined))

    const minMaxDate =
      !isIntegralComponents &&
      fieldEnableTracker['integralFields.0.usageAsOf'] !== undefined
        ? dayjs(fieldEnableTracker['integralFields.0.usageAsOf'])
        : undefined

    return (
      <div className="flex flex-col gap-1">
        <PrimSecTextCombo
          secondaryText={component.partNumber}
          primaryText={`${component.name} - ${component.serialNumber}`}
          variant="inverted"
        />
        <div className="grid grid-cols-9 gap-1">
          {showDateField ? (
            <DatePicker
              // Without this key prop the form reset was not working
              key={`${fieldKey}.${index}.usageAsOf.${resetToggle}`}
              className="col-span-3"
              name={`${fieldKey}.${index}.usageAsOf`}
              minDate={minMaxDate}
              maxDate={minMaxDate}
              label="Date"
              disabled={isUpdateMode} // No selection if in update mode
              disableFuture
              closeOnSelect
              onChange={handleDatePickerChange}
            />
          ) : (
            <div className="col-span-3" />
          )}
          <input
            type="hidden"
            {...register(`${fieldKey}.${index}.componentId`)}
            value={component.id}
          />
          <input
            type="hidden"
            {...register(`${fieldKey}.${index}.usageLogId`)}
            value={component.ComponentUsageLog[0]?.id}
          />
          <input
            type="hidden"
            {...register(`${fieldKey}.${index}.name`)}
            value={component.name}
          />
          <NumericAccruedTextField
            required
            name={`${fieldKey}.${index}.hoursValue`}
            label="Hours"
            initialValue={
              component.ComponentUsageLog[0]?.totalTimeSinceNew || 0
            }
            error={errors[fieldKey]?.[index]?.['hoursValue']?.message}
            isAccruedMode={isAccruedMode}
            resetToggle={resetToggle}
            valueStepper={0.1}
            disabled={!enableNumericFields}
            parentHandleChange={
              isIntegralComponents
                ? handleIntegralComponentValueSync
                : undefined
            }
            {...register(`${fieldKey}.${index}.hoursValue`)}
          />

          <NumericAccruedTextField
            required
            name={`${fieldKey}.${index}.cyclesValue`}
            label={component.name === 'Airframe' ? 'Landings' : 'Cycles'}
            initialValue={component.ComponentUsageLog[0]?.cycleSinceNew || 0}
            error={errors[fieldKey]?.[index]?.['cyclesValue']?.message}
            isAccruedMode={isAccruedMode}
            resetToggle={resetToggle}
            disabled={!enableNumericFields}
            valueStepper={1}
            parentHandleChange={
              isIntegralComponents
                ? handleIntegralComponentValueSync
                : undefined
            }
            {...register(`${fieldKey}.${index}.cyclesValue`)}
          />
        </div>
      </div>
    )
  }

  const dispayHistoricUsageLogs = isPastDateEntryMode || isUpdateMode

  return (
    <>
      {(isBeforeComponentDataLoading ||
        isAfterComponentDataLoading ||
        aircraftUsageLogToUpdateLoading ||
        isAircraftUsageParameterDataLoading) && <LoadingOverlay />}

      <Form formMethods={formMethods} onSubmit={handleFormSubmit}>
        <div className="flex w-full gap-3 px-2 pb-3">
          <div
            className="w-[150px]"
            style={{
              flexBasis: '12%',
              display:
                !isAfterComponentDataLoading &&
                dispayHistoricUsageLogs &&
                componentDataBeforeSelectedDate
                  ? 'block'
                  : 'none',
            }}
          >
            <DisplayAircraftUsageLog
              components={componentDataBeforeSelectedDate}
            />
          </div>
          <div
            className="flex-shrink-0 flex-grow-0"
            style={{
              flexBasis: dispayHistoricUsageLogs
                ? componentDataBeforeSelectedDate &&
                  componentDataAfterSelectedDate
                  ? '76%'
                  : '88%'
                : '100%',
            }}
          >
            <div>
              <div className="flex flex-col gap-2">
                {integralComponents.map((component, index) => {
                  return FormRow(component, index, true)
                })}
                <Checkbox
                  className="col-span-3 select-none"
                  name={APPLY_ALL_CHECKBOX}
                  label="Apply value to all elements at once"
                  checkboxProps={{
                    defaultChecked: true,
                    tabIndex: -1,
                  }}
                />
                {ignorableErrors && (
                  <Checkbox
                    className="col-span-7 select-none text-[#d32f2f]"
                    name={OVERRIDE_WARNING_ERRORS}
                    label="Override higher than daily usage warnings"
                    checkboxProps={{
                      defaultChecked: false,
                    }}
                  />
                )}
              </div>
              <hr />
              <div>
                {supplementalComponents.map((component, index) => {
                  return FormRow(component, index, false)
                })}
              </div>
            </div>
          </div>
          <div
            className="w-[150px]"
            style={{
              flexBasis: '12%',
              display:
                !isBeforeComponentDataLoading && dispayHistoricUsageLogs
                  ? 'block'
                  : 'none',
            }}
          >
            <DisplayAircraftUsageLog
              components={componentDataAfterSelectedDate}
            />
          </div>
        </div>
        {!disablePick && (
          <SelectTime
            onSuccess={onSuccess}
            trackedByComponentName={trackedByComponentName}
          />
        )}
        <div className="absolute bottom-0 left-0 right-0 flex justify-between bg-[#F8F8F8] px-3 py-2">
          <Button variant="text" tabIndex={-1} onClick={handleClose}>
            Cancel
          </Button>
          <div>
            <Button
              className="mr-2"
              variant="outlined"
              color="base"
              tabIndex={-1}
              onClick={handleReset}
            >
              Reset
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={saveLoading || updateLoading}
            >
              {isUpdateMode ? 'Update Times' : 'Apply Times'}
            </LoadingButton>
          </div>
        </div>
      </Form>
    </>
  )
}

// Component for Displaying before and after values in the Form
const DisplayAircraftUsageLog = ({ components }) => {
  if (!components || components?.length == 0) {
    return <></>
  }
  const sortedComponents = [
    ...getSortedFilteredComponents(components, true),
    ...getSortedFilteredComponents(components, false),
  ]
  return (
    <Paper
      className="rounded-2xl"
      variant="elevation"
      sx={{ bgcolor: wwAppColors.sys.lightgrey }}
    >
      <div className="p-1">
        <Stack>
          <Typography variant="h6">
            {formatDateForDisplay(
              dayjs(sortedComponents[0].ComponentUsageLog[0].usageAsOf)
            )}
          </Typography>
          <div className="grid grid-cols-1 gap-2">
            {sortedComponents.map((component) => {
              return (
                <>
                  <PrimSecTextCombo
                    primaryText={
                      component.ComponentUsageLog[0]?.totalTimeSinceNew || '---'
                    }
                    secondaryText={`${component.name} Hours`}
                    variant="inverted"
                  />
                  <PrimSecTextCombo
                    primaryText={
                      component.ComponentUsageLog[0]?.cycleSinceNew || '---'
                    }
                    secondaryText={`${component.name} ${
                      component.name === 'Airframe' ? 'Landings' : 'Cycles'
                    }`}
                    variant="inverted"
                  />
                </>
              )
            })}
          </div>
        </Stack>
      </div>
    </Paper>
  )
}

export default CreateTimeForm
