import React, { createContext, useContext, useState, useEffect } from 'react'
import { NavIconVariety } from './NavIcon'
import { SvgIconProps } from '@mui/material'

export type Breadcrumb = {
  loading?: boolean
  label?: string
  to?: string
  icon?: NavIconVariety
  iconSize?: SvgIconProps['fontSize']
}

type BreadcrumbsContextType = {
  breadcrumbs: Breadcrumb[]
  setBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void
}

const BreadcrumbsContext = createContext<BreadcrumbsContextType | undefined>(
  undefined
)

export const BreadcrumbsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([])

  return (
    <BreadcrumbsContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>
      {children}
    </BreadcrumbsContext.Provider>
  )
}

export const useBreadcrumbs = (breadcrumbs?: Breadcrumb[]) => {
  const context = useContext(BreadcrumbsContext)
  if (!context) {
    throw new Error('useBreadcrumbs must be used within a BreadcrumbsProvider')
  }

  useEffect(() => {
    if (breadcrumbs) {
      context.setBreadcrumbs(breadcrumbs)

      return () => {
        context.setBreadcrumbs([])
      }
    }
  }, [JSON.stringify(breadcrumbs)])

  return context
}
