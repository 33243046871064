import React, { useRef } from 'react'

import { Button } from '@mui/material'
import { AttachmentType } from 'types/graphql'

import useUploadFile from 'src/hooks/requests/useUploadFile'

export type FileUploadButtonProps = {
  //component re-renders when switching tasks, pass a unique key
  //to the component that changes when you switch tasks.
  //React uses the key prop to understand which items have changed,
  // are added, or are removed and this can trigger a re-render of the component.
  key?: string // for rerender
  button?: React.ReactNode
  attachables?: { id: string; type: AttachmentType }[]
  refetchAttachments?: () => void
  accept?: string
  alternativeOnSave?: (file: File) => void
}

const FileUploadButton: React.FC<FileUploadButtonProps> = ({
  key, // for rerender
  button,
  attachables,
  refetchAttachments,
  accept,
  alternativeOnSave,
}) => {
  const fileInput = useRef(null)
  const uploadFile = useUploadFile()

  const handleButtonClick = () => {
    fileInput.current.click()
  }

  const handleDivClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files[0]
    if (file) {
      if (alternativeOnSave) {
        alternativeOnSave(file)
      } else {
        try {
          await uploadFile(file, attachables)
          if (refetchAttachments) {
            refetchAttachments()
          }
        } catch (e) {
          console.log(e)
        }
      }
    }
    // Reset the value of the file input so can upload the same file if deleted right before
    event.target.value = ''
  }

  return (
    <div onClick={handleDivClick}>
      <input
        type="file"
        ref={fileInput}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        accept={accept || '*/*'}
      />
      {button ? (
        React.cloneElement(button, { onClick: handleButtonClick })
      ) : (
        <Button color="primary" variant="contained" onClick={handleButtonClick}>
          Upload File
        </Button>
      )}
    </div>
  )
}

export default FileUploadButton
