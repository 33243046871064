import React, { useEffect, useMemo, useState } from 'react'
import { Popover } from '../Popover/Popover'
import Button from '../MUI/Button'
import Searchbar from '../Searchbar/Searchbar'
import Typography from '@mui/material/Typography'
import usePaginatedMaintenanceItemList from 'src/hooks/requests/usePaginatedMaintenanceItemList'
import { useDebounce } from 'usehooks-ts'
import { useOrgName } from 'src/hooks/useOrgName'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loading from '../Loading'
import useDueStatus from 'src/hooks/useDueStatus'
import { Aircraft, MaintenanceItem } from 'types/graphql'
import { Tooltip } from '@mui/material'

import CORE_AIRCRAFT_USAGE_LOG_FRAGMENT from 'src/fragments/AircraftUsageLog'

import useQuery from 'src/hooks/useQuery'

const GET_AIRCRAFT_BY_ID = gql`
  ${CORE_AIRCRAFT_USAGE_LOG_FRAGMENT}
  query GetAircraftByIdForPopoverPicker($id: String!) {
    aircraft(id: $id) {
      id
      name
      model
      tailNumber
      serialNumber
      orgSlug
      onboardStatus
      createdAt
      updatedAt
      AircraftComponent(isPrimary: true) {
        id
        name
        isPrimary
        cyclesSinceNew
        hoursSinceNew
        serialNumber
        partNumber
      }
      AircraftUsageLog {
        ...CoreAircraftUsageLogFields
      }
      AircraftUsageParameter {
        dailyFlyingTime
        dailyLandings
      }
    }
  }
`

interface PopoverTaskPickerProps {
  aircraftId: string
  onSelect: (maintenanceItemId: string) => void
  trigger?: React.ReactNode
  filterByIds?: string[]
  showInProgress?: boolean
}

const MaintenanceItemRow: React.FC<{
  maintenanceItem: MaintenanceItem
  aircraft: Aircraft
  onClick: () => void
}> = React.memo(({ maintenanceItem, aircraft, onClick }) => {
  const {
    remainingValue: { status },
  } = useDueStatus(maintenanceItem, aircraft)

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'OVERDUE':
        return 'bg-red-500'
      case 'IN_TOLERANCE':
      case 'APPROACHING_DUE':
        return 'bg-yellow-500'
      case 'GOOD':
        return 'bg-green-500'
      default:
        return 'bg-gray-500'
    }
  }

  return (
    <Tooltip title={maintenanceItem.title} placement="right">
      <div
        role="button"
        key={maintenanceItem.id}
        className="cursor-pointer p-1 hover:bg-gray-100"
        onClick={onClick}
      >
        <div className="flex items-center gap-2">
          <div className={`h-1 w-1 rounded-full ${getStatusColor(status)}`} />
          <Typography className="w-full truncate">
            {maintenanceItem.title}
          </Typography>
        </div>
      </div>
    </Tooltip>
  )
})

const PopoverTaskPicker: React.FC<PopoverTaskPickerProps> = ({
  aircraftId,
  onSelect,
  trigger,
  filterByIds,
  showInProgress = true,
}) => {
  const orgSlug = useOrgName()
  const { data } = useQuery(GET_AIRCRAFT_BY_ID, {
    variables: { id: aircraftId },
    skip: !aircraftId,
  })
  const aircraft = useMemo(() => data?.aircraft ?? {}, [data])

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)
  const currentRequest = React.useRef<{
    controller: AbortController
    id: number
  } | null>(null)
  const requestCounter = React.useRef(0)

  const {
    maintenanceItems,
    hasNextPage,
    paginationLoading,
    initialLoading,
    error,
    loadMaintenanceItems,
    fetchNextMaintenancePage,
  } = usePaginatedMaintenanceItemList()

  useEffect(() => {
    if (debouncedSearch === '') return
    const abortController = new AbortController()
    const requestId = ++requestCounter.current

    // Abort previous request if it exists (before starting new one)
    if (currentRequest.current) {
      currentRequest.current.controller.abort()
    }

    // Store new request as current
    currentRequest.current = { controller: abortController, id: requestId }

    const loadData = async () => {
      const updatedQueryVariables = {
        fuzzySearch: search,
        orgSlug,
        aircraftIds: [aircraftId],
        filterByIds,
        showInProgress,
      }

      loadMaintenanceItems(
        updatedQueryVariables,
        true,
        abortController.signal,
        requestId
      )
    }

    Promise.resolve().then(loadData)
  }, [debouncedSearch])

  const handleSearchChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value)
    },
    []
  )

  const renderContent = React.useCallback(
    ({ close }: { close: () => void }) => {
      return (
        <div className="flex flex-col gap-2 p-1">
          <Searchbar
            placeholder={'Search'}
            value={search}
            onChange={handleSearchChange}
          />
          <div id="popover-content" className="h-[300px] overflow-y-auto">
            <InfiniteScroll
              className="w-[475px]"
              dataLength={maintenanceItems.length}
              next={fetchNextMaintenancePage}
              hasMore={hasNextPage}
              loader={paginationLoading && <Loading />}
              scrollableTarget="popover-content"
              scrollThreshold={0.25}
            >
              {error ? (
                <div className="flex h-full items-center justify-center">
                  <Typography variant="caption" className="text-center">
                    Error loading maintenance items
                  </Typography>
                </div>
              ) : initialLoading ? (
                <div className="flex h-full items-center justify-center">
                  <Loading />
                </div>
              ) : maintenanceItems.length > 0 ? (
                maintenanceItems.map((item) => (
                  <MaintenanceItemRow
                    maintenanceItem={item}
                    aircraft={aircraft}
                    key={item.id}
                    onClick={() => {
                      onSelect(item.id)
                      close()
                    }}
                  />
                ))
              ) : (
                <div className="flex h-full w-full flex-col items-center justify-center">
                  <Typography variant="caption" className="text-center">
                    {search ? 'No results found' : 'Start typing to search...'}
                  </Typography>
                </div>
              )}
            </InfiniteScroll>
          </div>
        </div>
      )
    },
    [
      search,
      maintenanceItems,
      hasNextPage,
      paginationLoading,
      initialLoading,
      aircraft,
      onSelect,
      handleSearchChange,
      fetchNextMaintenancePage,
    ]
  )

  return (
    <Popover
      gap={1}
      width={500}
      maxHeight={500}
      anchor={
        trigger || (
          <Button disabled={!aircraftId} disabledTooltip="Aircraft Id not set">
            Open
          </Button>
        )
      }
      content={renderContent}
    />
  )
}

export default React.memo(PopoverTaskPicker)
