import { routes } from '@redwoodjs/router'
import { useBreadcrumbs } from 'src/layouts/components/BreadcrumbsContext'
import WorkOrderCompletedHeader from './components/WorkOrderCompletedHeader'
import useQuery from 'src/hooks/useQuery'
import { Divider } from '@mui/material'
import { GET_WORK_ORDER_BASE_FIELDS } from '../WorkOrderCompliancePageV2/queries'
import WorkOrderCompletedContent from './components/WorkOrderCompletedContent'
import { GET_COMPLIANCE_LEDGER_STATUS } from '../BulkComplianceDetailPage/queries'

const WorkOrderCompletedPage = ({ orgName, id }) => {
  useBreadcrumbs([
    { icon: 'dueList' },
    { label: 'Due List', to: routes.dueList({ orgName }) },
    { label: 'Work Order' },
    { label: 'Review' },
    { label: 'Summary' },
  ])

  const { data } = useQuery(GET_WORK_ORDER_BASE_FIELDS, {
    variables: { id },
    fetchPolicy: 'cache-first',
  })

  const { data: statusData } = useQuery(GET_COMPLIANCE_LEDGER_STATUS, {
    variables: { id: data?.internalWorkOrder?.complianceLedger?.id },
    fetchPolicy: 'cache-first',
  })

  const ledgerStatus = statusData?.complianceLedger?.status

  // status is 'SIGN' or 'COMPLETED_LOGBOOK'
  const createdLogbook = ledgerStatus !== 'COMPLETED'

  return (
    <div className="flex h-full flex-col">
      <WorkOrderCompletedHeader
        id={id}
        createdLogbook={createdLogbook}
        ledgerStatus={ledgerStatus}
      />
      <Divider />
      <WorkOrderCompletedContent
        id={id}
        aircraftId={data?.internalWorkOrder?.aircraft?.id}
        ledgerId={data?.internalWorkOrder?.complianceLedger?.id}
        createdLogbook={createdLogbook}
      />
    </div>
  )
}

export default WorkOrderCompletedPage
