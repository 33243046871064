import React, { useEffect, useMemo } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@mui/material'
import { Permissions } from '@wingwork/common/src/constants/permissions'
import dayjs from 'dayjs'
import { LaborTransaction } from 'types/graphql'
import * as Yup from 'yup'

import { Form, useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'

import ReusableModal from 'src/components/MUI/Modal'
import useGetUsers from 'src/hooks/requests/useGetUsers'
import { useDispatch } from 'src/hooks/useDispatch'
import useHasPermission from 'src/hooks/useHasPermission'
import { useSelector } from 'src/hooks/useSelector'
import { UPSERT_LABOR_TRANSACTION } from 'src/pages/WorkOrderCompliancePage/ComplianceEditor/EditorRoot'
import { closeModal } from 'src/slices/modal'

import PrimSecTextCombo from './common/PrimSecTextCombo'
import HorizontalDivider from './HorizontalDivider'
import DateTimePicker from './MUI/DateTimePicker'
import TextField from './MUI/TextField'
import { UsersDropDownSelector } from './MUI/UsersDropDownSelector'
import Drawer from './MUI/Drawer/Drawer'
import DrawerHeader from './MUI/Drawer/DrawerHeader'
import DrawerActions from './MUI/Drawer/DrawerActions'
import { ApolloCache } from '@apollo/client'

interface SaveLaborTransactionModalProps {
  refetch?: () => void
  upsertUpdateFn?: (
    cache: ApolloCache<any>,
    { data, action }: { data: any; action: 'create' | 'update' }
  ) => void
}

interface SaveLaborTransactionModalMetadata {
  laborTransaction: LaborTransaction
  discriminator: 'InternalWorkItem' | 'CustomInternalWorkItem'
  discriminatorId: string
  variant: 'punchOut' | 'create' | 'edit'
  onClose?: () => void
}

const modalName = 'saveLaborTransactionModal'

const SaveLaborTransactionModal: React.FC<SaveLaborTransactionModalProps> = ({
  refetch,
  upsertUpdateFn,
}) => {
  const [upsertLaborTransaction, { loading }] = useMutation(
    UPSERT_LABOR_TRANSACTION
  )
  const { data: users } = useGetUsers()
  const aircraftId = useSelector((state) => state.auditLog.aircraftId)
  const canCompleteWorkOrder = useHasPermission(
    Permissions.workOrder.complete,
    aircraftId
  )
  const dispatch = useDispatch()
  const open =
    useSelector((state) => state.modal.modals?.[modalName]?.isOpen) ?? false
  const {
    laborTransaction,
    discriminator,
    discriminatorId,
    onClose,
    variant,
  }: SaveLaborTransactionModalMetadata =
    useSelector((state) => state.modal.modals?.[modalName]?.data) ?? {}
  const laborTransactionId = laborTransaction?.id ?? ''

  const handleClose = () => {
    dispatch(closeModal({ name: modalName }))
    formMethods.reset()
    onClose?.()
  }

  const formDefaults = useMemo(() => {
    return {
      workedById: laborTransaction?.workedBy?.id ?? null,
      description: laborTransaction?.description ?? '',
      correctiveAction: laborTransaction?.correctiveAction ?? '',
      notes: laborTransaction?.notes ?? '',
      punchedInAt: laborTransaction?.punchedInAt ?? dayjs().format(),
      punchedOutAt: laborTransaction?.punchedOutAt ?? dayjs().format(),
    }
  }, [variant, laborTransaction])

  const schema = useMemo(() => {
    const schema = Yup.object().shape({
      workedById:
        (variant === 'create' || variant === 'edit') && canCompleteWorkOrder
          ? Yup.string().required('Select mechanic')
          : Yup.string().nullable(),
      description: Yup.string(),
      correctiveAction: Yup.string(),
      notes: Yup.string(),
      punchedInAt:
        variant === 'create' || variant === 'edit'
          ? Yup.date().required('Select punched in date and time')
          : Yup.date(),
      punchedOutAt: Yup.date()
        .required('Select punched out date and time')
        .test(
          'is-after-punch-in',
          'Punch out time must be after punch in time',
          function (value) {
            const { punchedInAt } = this.parent
            if (!value || !punchedInAt) return true // Let required() handle null/undefined
            return dayjs(value).isAfter(dayjs(punchedInAt))
          }
        ),
    })
    return schema
  }, [variant, canCompleteWorkOrder])

  const formMethods = useForm({
    defaultValues: formDefaults,
    resolver: yupResolver(schema),
  })

  const { watch } = formMethods

  const punchedInAt = watch('punchedInAt')
  const punchedOutAt = watch('punchedOutAt')

  useEffect(() => {
    if (open) formMethods.reset(formDefaults)
  }, [open])

  const onSubmit = async (data) => {
    const { workedById, punchedInAt, ...rest } = data
    let inputVars = {
      ...rest,
    }
    if (variant === 'create' || variant === 'edit') {
      inputVars = {
        ...inputVars,
        ...(discriminator === 'InternalWorkItem' && {
          internalWorkItemId: discriminatorId,
        }),
        ...(discriminator === 'CustomInternalWorkItem' && {
          customInternalWorkItemId: discriminatorId,
        }),
        workedById,
        punchedInAt,
      }
    }
    await upsertLaborTransaction({
      variables: {
        id: laborTransactionId,
        input: inputVars,
      },
      update: (cache, { data }) => {
        upsertUpdateFn?.(cache, {
          data,
          action: variant === 'create' ? 'create' : 'update',
        })
      },
    })
    refetch?.()
    handleClose()
  }

  const getTimeWorked = () => {
    // return text in "3h 40mins" format
    const diff = dayjs(punchedOutAt).diff(dayjs(punchedInAt), 'minutes')
    const hours = Math.floor(diff / 60)
    const minutes = diff % 60
    return `${hours}h ${minutes}m`
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => {
        handleClose()
      }}
      title="Save Labor Transaction"
    >
      <DrawerHeader title="Save Labor Transaction" onClose={handleClose} />

      <div className="h-full w-[800px] p-3">
        <Form
          id="saveLaborTransactionForm"
          className="h-full"
          formMethods={formMethods}
          onSubmit={onSubmit}
        >
          <div className="flex flex-col gap-2">
            {(variant === 'create' || variant === 'edit') &&
              canCompleteWorkOrder && (
                <UsersDropDownSelector
                  name="workedById"
                  label="Mechanic"
                  users={users?.users || []}
                  disabled={false}
                  size="small"
                  className="col-start-1 row-start-1"
                />
              )}
            <div className="grid grid-cols-2 gap-2">
              <TextField
                name="description"
                label="Description"
                multiline
                rows={3}
                size="small"
              />
              <TextField
                name="correctiveAction"
                label="Corrective Action"
                multiline
                rows={3}
                size="small"
              />
            </div>
            <TextField
              name="notes"
              label="Task Notes"
              multiline
              rows={3}
              size="small"
            />
            <HorizontalDivider />
            <div className="flex gap-2">
              {(variant === 'create' || variant === 'edit') && (
                <DateTimePicker
                  className="grow"
                  name="punchedInAt"
                  label="Punched In At"
                  timeSteps={{ minutes: 1 }}
                  disableIgnoringDatePartForTimeValidation
                  slotProps={{ textField: { size: 'small' } }}
                />
              )}
              <DateTimePicker
                className="grow"
                name="punchedOutAt"
                label="Punched Out At"
                timeSteps={{ minutes: 1 }}
                minDate={dayjs(punchedInAt)}
                minTime={dayjs(punchedInAt)}
                maxDate={dayjs(punchedInAt).add(100, 'hours')}
                maxTime={dayjs(punchedInAt).add(100, 'hours')}
                disableIgnoringDatePartForTimeValidation
                slotProps={{ textField: { size: 'small' } }}
              />
              <PrimSecTextCombo
                primaryText={getTimeWorked()}
                secondaryText={'Time Worked'}
              />
            </div>
            <div className="mt-2 flex flex-col gap-1"></div>
          </div>
        </Form>
      </div>
      <DrawerActions
        className="gap-1"
        leftActions={
          <Button variant="outlined" color="base" onClick={handleClose}>
            Cancel
          </Button>
        }
        rightActions={
          <Button
            disabled={loading}
            type="submit"
            form="saveLaborTransactionForm"
          >
            Save Time
          </Button>
        }
      />
    </Drawer>
  )
}

export default SaveLaborTransactionModal
