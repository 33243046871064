import {
  Chip,
  Divider,
  Paper,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { useMemo } from 'react'

import ApiError from 'src/components/ApiError'
import Loading from 'src/components/Loading'
import Button from 'src/components/MUI/Button'
import Table from 'src/components/Table'
import { useDispatch } from 'src/hooks/useDispatch'
import useQuery from 'src/hooks/useQuery'
import { openModal } from 'src/slices/modal'
import { centsToDollars } from 'src/utils/helpers'
import { abbreviateFullName } from 'src/utils/stringFormatters'

import { GET_PURCHASE_ORDER } from '../PurchaseOrderDraftPage/queries'
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table'
import { getDefaultMRTOptions } from 'src/utils/getDefaultMRTOptions'
import { PurchaseOrderItem } from 'types/graphql'

interface AddressDisplayProps {
  address?: {
    addressLine1?: string
    addressLine2?: string
    city?: string
    state?: string
    postalCode?: string
    country?: string
  }
}

const AddressDisplay: React.FC<AddressDisplayProps> = ({ address }) => {
  return (
    <div>
      <Typography variant="subtitle2">{address?.addressLine1}</Typography>
      <Typography variant="subtitle2">{address?.addressLine2}</Typography>
      <Typography variant="subtitle2">
        {address?.city}, {address?.state}, {address?.postalCode}
      </Typography>
      <Typography variant="subtitle2">{address?.country}</Typography>
    </div>
  )
}

interface PurchaseOrderViewProps {
  id: string
}

const PurchaseOrderView: React.FC<PurchaseOrderViewProps> = ({ id }) => {
  const { data, loading, hasLoaded, error } = useQuery(GET_PURCHASE_ORDER, {
    variables: { id },
  })

  const {
    purchaseOrder: { purchaseOrderItem: poItems },
  } = data ?? { purchaseOrder: { purchaseOrderItem: [] } }

  const partLineItems = poItems.filter((item) => item.type === 'PART')
  const serviceLineItems = poItems.filter((item) => item.type === 'SERVICE')
  const otherLineItems = poItems.filter((item) => item.type === 'OTHER')

  // Define columns for Parts table
  const partColumns: MRT_ColumnDef<PurchaseOrderItem>[] = [
    {
      accessorKey: 'partNumber',
      header: 'Part Number',
      size: 300,
      Cell: ({ cell }) => (
        <div
          style={{
            whiteSpace: 'normal',
            wordBreak: 'break-word',
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
    },
    {
      accessorKey: 'description',
      header: 'Description',
      size: 600,
      Cell: ({ cell }) => (
        <div
          style={{
            whiteSpace: 'normal',
            wordBreak: 'break-word',
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
    },
    {
      accessorKey: 'condition',
      header: 'Condition',
      size: 200,
      Cell: ({ row }) =>
        t(`purchaseOrderItem.condition.${row.original.condition}`),
    },
    {
      accessorKey: 'quantity',
      header: 'Quantity',
      size: 200,
    },
    {
      accessorKey: 'cost',
      header: 'Cost',
      size: 200,
      Cell: ({ row }) =>
        `${row.original.cost ? '$' : ''}${centsToDollars(row.original.cost)}`,
    },
    {
      accessorKey: 'requestedBy',
      header: 'Requested By',
      size: 200,
      Cell: ({ row }) => abbreviateFullName(row.original.requestedBy),
    },
  ]

  // Define columns for Services table
  const serviceColumns: MRT_ColumnDef<PurchaseOrderItem>[] = [
    {
      accessorKey: 'ataCode',
      header: 'ATA Code',
      size: 300,
      Cell: ({ cell }) => (
        <div
          style={{
            whiteSpace: 'normal',
            wordBreak: 'break-word',
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
    },
    {
      accessorKey: 'description',
      header: 'Description',
      size: 600,
      Cell: ({ cell }) => (
        <div
          style={{
            whiteSpace: 'normal',
            wordBreak: 'break-word',
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
    },
    {
      accessorKey: 'cost',
      header: 'Cost',
      size: 200,
      Cell: ({ row }) =>
        `${row.original.cost ? '$' : ''}${centsToDollars(row.original.cost)}`,
    },
    {
      accessorKey: 'requestedBy',
      header: 'Requested By',
      size: 200,
      Cell: ({ row }) => abbreviateFullName(row.original.requestedBy),
    },
  ]

  // Define columns for Other Items table
  const otherColumns: MRT_ColumnDef<PurchaseOrderItem>[] = [
    {
      accessorKey: 'description',
      header: 'Description',
      size: 600,
      Cell: ({ cell }) => (
        <div
          style={{
            whiteSpace: 'normal',
            wordBreak: 'break-word',
          }}
        >
          {cell.getValue<string>()}
        </div>
      ),
    },
    {
      accessorKey: 'cost',
      header: 'Cost',
      size: 200,
      Cell: ({ row }) =>
        `${row.original.cost ? '$' : ''}${centsToDollars(row.original.cost)}`,
    },
    {
      accessorKey: 'requestedBy',
      header: 'Requested By',
      size: 150,
      Cell: ({ row }) => abbreviateFullName(row.original.requestedBy),
    },
  ]

  // Configure tables
  const partTable = useMaterialReactTable({
    ...getDefaultMRTOptions(),
    columns: partColumns,
    data: partLineItems,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: true,
    enableSorting: false,
    layoutMode: 'grid-no-grow',
  })

  const serviceTable = useMaterialReactTable({
    ...getDefaultMRTOptions(),
    columns: serviceColumns,
    data: serviceLineItems,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: true,
    enableSorting: false,
    layoutMode: 'grid-no-grow',
  })

  const otherTable = useMaterialReactTable({
    ...getDefaultMRTOptions(),
    columns: otherColumns,
    data: otherLineItems,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: true,
    enableSorting: false,
    layoutMode: 'grid-no-grow',
  })

  if (error) {
    return <ApiError />
  }

  if ((loading && !hasLoaded) || !data) {
    return <Loading />
  }

  return (
    <div className="flex h-full gap-4">
      <div className=" h-full pb-4 pl-4 pt-2">
        <div className="flex w-[300px] flex-col gap-3">
          <div className="flex flex-col gap-2">
            <Typography variant="h6">Details</Typography>
            <div className="flex flex-col gap-2">
              <div>
                <Typography variant="body2" color="grey">
                  Vendor
                </Typography>
                <Typography variant="subtitle2">
                  {data.purchaseOrder.vendor.name}
                </Typography>
              </div>
              <div>
                <Typography variant="body2" color="grey">
                  Payment Terms
                </Typography>
                <Typography variant="subtitle2">
                  {t(
                    `purchaseOrder.paymentTerms.${data.purchaseOrder.paymentTerms}`
                  )}
                </Typography>
              </div>

              <div>
                <Typography variant="body2" color="grey">
                  Shipping Address
                </Typography>
                <AddressDisplay address={data.purchaseOrder.shippingAddress} />
              </div>
              <div>
                <Typography variant="body2" color="grey">
                  Billing Address
                </Typography>

                {data.purchaseOrder.billingAddressId !==
                data.purchaseOrder.shippingAddressId ? (
                  <AddressDisplay address={data.purchaseOrder.billingAddress} />
                ) : (
                  <Typography variant="subtitle2">
                    Same as shipping address
                  </Typography>
                )}
              </div>

              <div>
                <Typography variant="body2" color="grey">
                  Requested Delivery Date
                </Typography>
                <Typography variant="subtitle2">
                  {data.purchaseOrder.requestedDeliveryDate
                    ? dayjs(data.purchaseOrder.requestedDeliveryDate).format(
                        'DD MMM, YYYY'
                      )
                    : 'N/A'}
                </Typography>
              </div>

              <div>
                <Typography variant="body2" color="grey">
                  Shipping Method
                </Typography>
                <Typography variant="subtitle2">
                  {data.purchaseOrder.shippingMethod?.name}
                </Typography>
              </div>
              {data.purchaseOrder.shippingMethod?.accountNumber && (
                <div>
                  <Typography variant="body2" color="grey">
                    Shipping Account Number
                  </Typography>
                  <Typography variant="subtitle2">
                    {data.purchaseOrder.shippingMethod?.accountNumber}
                  </Typography>
                </div>
              )}
              <div>
                <Typography variant="body2" color="grey">
                  Delivery Instructions
                </Typography>
                <Typography variant="subtitle2">
                  {data.purchaseOrder.shippingInstructions || 'N/A'}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Divider orientation="vertical" flexItem />
      <div className="flex flex-1 flex-col gap-4 overflow-x-auto pb-4 pr-4 pt-2">
        {partLineItems.length > 0 && (
          <div className="flex flex-col gap-2">
            <Typography variant="h6">Parts</Typography>
            <MaterialReactTable table={partTable} />
          </div>
        )}
        {serviceLineItems.length > 0 && (
          <div className="flex flex-col gap-2">
            <Typography variant="h6">Services</Typography>
            <MaterialReactTable table={serviceTable} />
          </div>
        )}
        {otherLineItems.length > 0 && (
          <div className="flex flex-col gap-2">
            <Typography variant="h6">Other</Typography>
            <MaterialReactTable table={otherTable} />
          </div>
        )}
      </div>
    </div>
  )
}

export default PurchaseOrderView
