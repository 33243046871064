import React, {
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { FileTree, TreeProvider, TreeViewNode, useFileTree } from '../FileTree'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Switch,
  Typography,
} from '@mui/material'
import AssignmentIcon from '@mui/icons-material/Assignment'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ItemForm from './ItemForm'
import Searchbar from 'src/components/Searchbar/Searchbar'
import { useDebounce } from 'usehooks-ts'
import ReviewFormFilterPopover, {
  ReviewFormFilters,
} from './ReviewFormFilterPopover'
import { MassagedComplianceActivity } from './helper'

// Total vertical padding: 2 * p-4 (32px top + 32px bottom) = 64px
const VERTICAL_PADDING = 64

const ReviewForm = ({
  title,
  tasks,
  hasCorrectiveAction = false,
  hasAccounting = false,
  readonly = false,
  aircraftId,
}: {
  title: string
  tasks: MassagedComplianceActivity[]
  hasCorrectiveAction?: boolean
  hasAccounting?: boolean
  readonly?: boolean
  aircraftId: string
}) => {
  const itemRefs = useRef<{ [key: string]: HTMLDivElement }>({})
  const containerRef = useRef<HTMLDivElement>(null)
  const [contentHeight, setContentHeight] = useState(0)

  useEffect(() => {
    if (!containerRef.current) return

    const updateHeight = () => {
      const containerTop =
        containerRef.current?.getBoundingClientRect().top || 0
      const viewportHeight = window.innerHeight
      const availableHeight = viewportHeight - containerTop
      setContentHeight(availableHeight)
    }

    // Initial calculation
    updateHeight()

    // Create ResizeObserver to watch for container position changes
    const resizeObserver = new ResizeObserver(updateHeight)
    resizeObserver.observe(containerRef.current)

    // Watch for window resize
    window.addEventListener('resize', updateHeight)

    return () => {
      resizeObserver.disconnect()
      window.removeEventListener('resize', updateHeight)
    }
  }, [])

  // Convert tasks to tree structure
  const treeItems: TreeViewNode[] = useMemo(() => {
    if (!tasks) return []

    return tasks
      .filter((task) => !task.mtxItem?.parentId) // Only get parent tasks and custom tasks and standalone discrepancies
      .map((task) => ({
        id: task.id,
        name: task.mtxItem
          ? task.mtxItem.ataCode
            ? `${task.mtxItem.ataCode} ${task.mtxItem.manufactureCode}`
            : task.mtxItem.title
          : task.title,
        fileType: 'folder',
        children: task.childItems.map((child) => ({
          id: child.id,
          name: `${child.maintenanceItem.ataCode} ${child.maintenanceItem.manufactureCode}`,
          fileType: 'file',
        })),
      }))
  }, [tasks])

  return (
    <div className="flex h-full" ref={containerRef}>
      <TreeProvider>
        <div className="flex h-full w-full">
          {/* Left sidebar with FileTree */}
          <div className="sticky top-0 p-4">
            <FileTree
              items={treeItems}
              height={contentHeight - VERTICAL_PADDING}
              width={200}
              openByDefault={true}
              type="review"
            />
          </div>

          <Divider orientation="vertical" flexItem />

          {/* Main content area */}
          <div className="flex-1">
            <div
              className="overflow-y-auto"
              style={{ height: `${contentHeight}px` }}
            >
              <div className="p-4">
                <ContentSection
                  title={title}
                  tasks={tasks}
                  itemRefs={itemRefs}
                  hasCorrectiveAction={hasCorrectiveAction}
                  hasAccounting={hasAccounting}
                  readonly={readonly}
                  aircraftId={aircraftId}
                />
              </div>
            </div>
          </div>
        </div>
      </TreeProvider>
    </div>
  )
}

const ContentSection = ({
  title,
  tasks,
  itemRefs,
  hasCorrectiveAction,
  hasAccounting,
  readonly,
  aircraftId,
}: {
  title: string
  tasks: MassagedComplianceActivity[]
  itemRefs: MutableRefObject<{ [key: string]: HTMLDivElement }>
  hasCorrectiveAction: boolean
  hasAccounting: boolean
  readonly: boolean
  aircraftId: string
}) => {
  const { activeNode } = useFileTree()
  const [expandedAccordions, setExpandedAccordions] = useState<string[]>([])
  const [localSearchVal, setLocalSearchVal] = useState('')
  const debouncedSearchValue = useDebounce(localSearchVal, 500)
  const [showNotes, setShowNotes] = useState(true)
  const [showAccounting, setShowAccounting] = useState(true)
  const [filters, setFilters] = useState<ReviewFormFilters>({
    ataCodes: [],
    trackedBy: [],
  })

  // Handle expanding/collapsing accordions
  const handleAccordionChange =
    (parentId: string) => (_, isExpanded: boolean) => {
      setExpandedAccordions((prev) =>
        isExpanded ? [...prev, parentId] : prev.filter((id) => id !== parentId)
      )
    }

  const handleFilterSubmit = (newFilters: ReviewFormFilters) => {
    setFilters(newFilters)
  }

  const handleFilterClear = () => {
    setFilters({
      ataCodes: [],
      trackedBy: [],
    })
  }

  // Calculate applied filters count
  const filtersAppliedCount = useMemo(() => {
    let count = 0
    count += filters.ataCodes.length
    count += filters.trackedBy.length
    return count
  }, [filters])

  // Calculate filtered tasks based on search and filters
  const filteredTasks = useMemo(() => {
    return tasks.filter((task) => {
      // First check ATA code filter
      if (filters.ataCodes.length > 0) {
        const taskAtaCode = task.mtxItem?.ataCode
        const childAtaCodes = task.childItems.map(
          (child) => child.maintenanceItem.ataCode
        )

        const hasMatchingAtaCode = filters.ataCodes.some(
          (code) => taskAtaCode === code || childAtaCodes.includes(code)
        )

        if (!hasMatchingAtaCode) return false
      }

      // Check tracked by filters
      if (filters.trackedBy.length > 0) {
        const taskTrackedBy = task.mtxItem?.trackedByComponentName
        const childTrackedBy = task.childItems.map(
          (child) => child.maintenanceItem.trackedByComponentName
        )
        const hasMatchingTrackedBy = filters.trackedBy.some(
          (trackedBy) =>
            taskTrackedBy === trackedBy || childTrackedBy.includes(trackedBy)
        )

        if (!hasMatchingTrackedBy) return false
      }

      // Then check search text
      if (debouncedSearchValue) {
        const taskTitle = task.mtxItem
          ? `${task.mtxItem.ataCode} ${task.mtxItem.manufactureCode} ${task.mtxItem.title}`
          : task.title

        const matchesTask = taskTitle
          .toLowerCase()
          .includes(debouncedSearchValue.toLowerCase())

        // Also search through child items
        const matchesChildren = task.childItems.some((child) => {
          const childTitle = `${child.maintenanceItem.ataCode} ${child.maintenanceItem.manufactureCode} ${child.maintenanceItem.title}`
          return childTitle
            .toLowerCase()
            .includes(debouncedSearchValue.toLowerCase())
        })

        if (!matchesTask && !matchesChildren) return false
      }

      return true
    })
  }, [tasks, debouncedSearchValue, filters])

  // Auto-expand parent accordion when child is selected
  useEffect(() => {
    if (activeNode?.id) {
      // if is child node, expand the accordion
      if (activeNode.data.fileType === 'file') {
        // Expand the accordion
        setExpandedAccordions((prev) =>
          prev.includes(activeNode.id) ? prev : [...prev, activeNode.id]
        )

        // Scroll after a small delay to allow accordion to expand
        setTimeout(() => {
          const element = itemRefs.current[activeNode.id]
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' })
          }
        }, 100)
      } else {
        // If it's not a child node, scroll immediately
        const element = itemRefs.current[activeNode.id]
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
      }
    }
  }, [activeNode, tasks, itemRefs])

  return (
    <div className="flex flex-1 flex-col gap-2">
      <Typography variant="h6" fontWeight={500}>
        {title}
      </Typography>
      <div className="flex items-center gap-2">
        <Searchbar
          placeholder="Search"
          value={localSearchVal}
          onChange={(e) => setLocalSearchVal(e.target.value)}
          iconPosition="left"
        />
        <ReviewFormFilterPopover
          filtersAppliedCount={filtersAppliedCount}
          onSubmit={handleFilterSubmit}
          onClear={handleFilterClear}
          aircraftId={aircraftId}
        />
        {hasAccounting && (
          <div className="flex items-center">
            <Typography variant="body2">SHOW ACCOUNTING</Typography>
            <Switch
              checked={showAccounting}
              onChange={(e) => setShowAccounting(e.target.checked)}
            />
          </div>
        )}
        <div className="flex items-center">
          <Typography variant="body2">SHOW NOTES</Typography>
          <Switch
            checked={showNotes}
            onChange={(e) => setShowNotes(e.target.checked)}
          />
        </div>
      </div>
      <div className="flex flex-col gap-2">
        {filteredTasks?.map((row) => {
          if (row.mtxItem && row.mtxItem.parentId) {
            if (
              tasks.find(
                (t) => t.mtxItem && t.mtxItem.id === row.mtxItem.parentId
              )
            ) {
              return null
            }
          }
          return (
            <Card
              key={row.id}
              ref={(el) => {
                if (el) itemRefs.current[row.id] = el
              }}
              className={`flex flex-col ${
                activeNode?.id === row.id ? 'ring-2 ring-blue-400' : ''
              }`}
              elevation={0}
              sx={{
                border: '1px solid #E0E0E0',
                borderRadius: 2,
                '& .MuiCardContent-root': {
                  borderTop: '1px solid #E0E0E0',
                },
              }}
            >
              <CardHeader
                title={
                  row.mtxItem
                    ? `${row.mtxItem.ataCode} ${row.mtxItem.manufactureCode}`
                    : row.title
                }
                titleTypographyProps={{
                  variant: 'h6',
                }}
                subheader={row.mtxItem ? row.mtxItem.title : ''}
                subheaderTypographyProps={{
                  variant: 'body2',
                }}
                avatar={<AssignmentIcon style={{ color: 'grey' }} />}
                className="bg-[#FAFAFA]"
              />
              <CardContent className="flex flex-col gap-2">
                <ItemForm
                  row={row}
                  showCorrectiveAction={hasCorrectiveAction}
                  showAccounting={hasAccounting && showAccounting}
                  showParts={row.mtxItem}
                  readonly={readonly}
                  isWorkOrder={row.isWorkOrder}
                  isCustom={row.isCustom}
                  showNotes={showNotes}
                />
                {row.childItems.length > 0 &&
                  row.childItems.map((child) => (
                    <Accordion
                      key={child.id}
                      ref={(el) => {
                        if (el) itemRefs.current[child.id] = el
                      }}
                      expanded={expandedAccordions.includes(child.id)}
                      onChange={handleAccordionChange(child.id)}
                      className={`flex flex-col ${
                        activeNode?.id === child.id
                          ? 'ring-2 ring-blue-400'
                          : ''
                      }`}
                      disableGutters
                      elevation={0}
                      sx={{
                        '&.MuiAccordion-root': {
                          position: 'static',
                          border: '1px solid #E0E0E0',
                          borderRadius: 2,
                        },
                        '& .MuiAccordionDetails-root': {
                          borderTop: '1px solid #E0E0E0', // Add border between header and content
                        },
                        '&::before': {
                          display: 'none',
                        },
                        '& .MuiAccordionSummary-root': {
                          backgroundColor: '#FAFAFA', // Set only header background
                          borderRadius: 2,
                        },
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        id="child-item-header"
                        // className="bg-[#FAFAFA]"
                      >
                        <div className="flex items-center gap-4">
                          <Typography variant="h6">
                            {child.maintenanceItem.ataCode}{' '}
                            {child.maintenanceItem.manufactureCode}
                          </Typography>
                          <Typography variant="body2">
                            {child.maintenanceItem.title}
                          </Typography>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ItemForm
                          row={child}
                          showCorrectiveAction={hasCorrectiveAction}
                          showAccounting={hasAccounting && showAccounting}
                          readonly={readonly}
                          isWorkOrder={row.isWorkOrder}
                          isCustom={row.isCustom}
                          showNotes={showNotes}
                        />
                      </AccordionDetails>
                    </Accordion>
                  ))}
              </CardContent>
            </Card>
          )
        })}
      </div>
    </div>
  )
}

export default ReviewForm
