import { yupResolver } from '@hookform/resolvers/yup'
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded'
import { InputAdornment } from '@mui/material'
import { Form } from '@redwoodjs/forms'
import { useForm } from 'react-hook-form'
import Button from 'src/components/MUI/Button'
import Drawer from 'src/components/MUI/Drawer/Drawer'
import DrawerActions from 'src/components/MUI/Drawer/DrawerActions'
import DrawerHeader from 'src/components/MUI/Drawer/DrawerHeader'
import TextField from 'src/components/MUI/TextField'
import { UsersDropDownSelector } from 'src/components/MUI/UsersDropDownSelector'
import useModal from 'src/hooks/useModal'
import useQuery from 'src/hooks/useQuery'
import * as yup from 'yup'
import { GET_MECHANICS } from './queries'
import { useEffect } from 'react'

// Add type for form data
export type WorkPerformedFormData = {
  description: string
  notes: string
  workedById: string
  workHours: number
}

// Add props interface
interface WorkPerformedDrawerProps {
  id?: string | number
  initialValues?: WorkPerformedFormData
  onSubmit: (
    data: WorkPerformedFormData,
    id?: string | number
  ) => void | Promise<void>
}

const schema = yup.object({
  description: yup.string(),
  notes: yup.string(),
  workedById: yup.string().nullable(),
  workHours: yup
    .number()
    .nullable()
    .transform((value) => (isNaN(value) ? null : value))
    .test(
      'positive',
      'Time must be greater than 0',
      (value) => value === null || value > 0
    ),
})

const defaultValues: WorkPerformedFormData = {
  description: '',
  notes: '',
  workedById: '',
  workHours: null,
}

const WorkPerformedDrawer = ({
  onSubmit,
  initialValues,
  id,
}: WorkPerformedDrawerProps) => {
  const { handleClose, isOpen } = useModal('workPerformedDrawer')

  const { data: mechanicsData, loading: mechanicsLoading } =
    useQuery(GET_MECHANICS)
  const mechanics = mechanicsData?.users ?? []

  // Add React Hook Form setup with yup resolver
  const methods = useForm<WorkPerformedFormData>({
    resolver: yupResolver(schema),
    defaultValues: initialValues || defaultValues,
  })

  // Add useEffect to reset form when drawer closes
  useEffect(() => {
    if (!isOpen) {
      methods.reset(defaultValues)
    } else if (isOpen && initialValues) {
      // Reset to initial values if they exist when drawer opens
      methods.reset(initialValues)
    }
  }, [isOpen, initialValues, methods])

  const handleSubmit = async (data: WorkPerformedFormData) => {
    try {
      await onSubmit(data, id)
      handleClose()
    } catch (error) {
      // If onSubmit throws an error, the drawer will stay open
      console.error('Failed to submit work performed:', error)
    }
  }

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose}>
      <DrawerHeader
        title={id ? 'Edit Work' : 'Add Work'}
        onClose={handleClose}
      />
      <Form
        formMethods={methods}
        onSubmit={handleSubmit}
        className="flex-grow overflow-y-auto px-3 py-2"
        id="workPerformedForm"
      >
        <div className="flex flex-col gap-2">
          <TextField
            label="Description"
            name="description"
            multiline
            rows={4}
          />
          <TextField label="Task Notes" name="notes" multiline rows={4} />
          <div className="grid grid-cols-2 gap-2">
            <UsersDropDownSelector
              label="Mechanic"
              name="workedById"
              users={mechanics}
            />
            <TextField
              label="Time Worked"
              name="workHours"
              type="number"
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <ScheduleRoundedIcon />
                    </InputAdornment>
                  ),
                },
              }}
            />
          </div>
        </div>
      </Form>
      <DrawerActions
        className="gap-1"
        leftActions={
          <Button variant="text" color="black" onClick={handleClose} fullWidth>
            Cancel
          </Button>
        }
        rightActions={
          <Button
            type="submit"
            form="workPerformedForm"
            variant="contained"
            color="primary"
            fullWidth
          >
            {id ? 'Update' : 'Save'}
          </Button>
        }
      />
    </Drawer>
  )
}

export default WorkPerformedDrawer
