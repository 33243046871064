import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { WorkOrderComplianceStateData } from 'src/types'

interface WorkOrderComplianceState {
  data: WorkOrderComplianceStateData
}

const initialState: WorkOrderComplianceState = {
  data: {
    aircraftUsageLogId: '',
    workOrderId: '',
    workOrderType: undefined,
    detailDrawerTaskId: null,
    detailDrawerCustomTaskId: null,
  },
}

const workOrderSlice = createSlice({
  name: 'workOrder',
  initialState,
  reducers: {
    setWorkOrderComplianceStateData: (
      state: WorkOrderComplianceState,
      action: PayloadAction<WorkOrderComplianceStateData>
    ) => {
      state.data = action.payload
    },
  },
})

export const { setWorkOrderComplianceStateData } = workOrderSlice.actions

export default workOrderSlice.reducer
