import { Divider, Typography } from '@mui/material'
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table'
import React, { useMemo } from 'react'
import { abbreviateFullName } from 'src/utils/stringFormatters'
import { InternalWorkItem, LaborTransaction } from 'types/graphql'
import Button from 'src/components/MUI/Button'
import Man2OutlinedIcon from '@mui/icons-material/Man2Outlined'
import dayjs from 'dayjs'
import { centsToDollars, minutesToHoursStr } from 'src/utils/helpers'
import EditLaborDetailsDrawer from './EditLaborDetailsDrawer'
import useModal from 'src/hooks/useModal'
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined'

const LaborDetailsTable = ({ task }: { task: InternalWorkItem }) => {
  if (!task) return null
  const rateType = task?.rateType
  const rateInDollars = task?.rate ? parseFloat(centsToDollars(task.rate)) : 0

  let laborTransactionsGrandTotal = 0
  const laborTransactionsGroupedByMechanic = task?.laborTransactions?.reduce(
    (acc, transaction) => {
      acc[transaction.workedBy.id] = acc[transaction.workedBy.id] || []
      acc[transaction.workedBy.id].push(transaction)
      return acc
    },
    {}
  )

  const tableData: any = Object.entries(laborTransactionsGroupedByMechanic).map(
    ([mechanicId, transactions]: [string, LaborTransaction[]]) => {
      let workMinutes = 0
      let totalCost = 0 // Defaulting to FLAT_RATE
      transactions.forEach((transaction) => {
        if (!transaction.punchedInAt || !transaction.punchedOutAt) return
        const transactionMinutes = dayjs(transaction.punchedOutAt).diff(
          transaction.punchedInAt,
          'minute'
        )
        const transactionHours = transactionMinutes / 60
        let transactionCost = 0
        workMinutes += transactionMinutes
        if (rateType === 'HOURLY_RATE') {
          transactionCost = transactionHours * rateInDollars
          totalCost += transactionCost
        } else {
          totalCost = rateInDollars
        }
        laborTransactionsGrandTotal += transactionCost
      })
      return {
        workedById: mechanicId,
        workedByFullName: abbreviateFullName(transactions[0].workedBy),
        workHours: minutesToHoursStr(workMinutes),
        totalCost: `$${totalCost.toFixed(2)}`,
        rateType,
      }
    }
  )

  const getGrandTotal = () => {
    switch (rateType) {
      case 'FLAT_RATE':
        return rateInDollars
      case 'HOURLY_RATE':
        if (task?.overrideLaborTransactionHours) {
          return (task.workMinutes / 60) * rateInDollars
        }
        return laborTransactionsGrandTotal
      default:
        return laborTransactionsGrandTotal
    }
  }

  const columns = useMemo<MRT_ColumnDef<(typeof tableData)[number]>[]>(
    () => [
      {
        header: 'Mechanic',
        accessorFn: (row) => row.workedByFullName,
        Footer: () => {
          if (task?.overrideLaborTransactionHours) {
            return (
              <div className="flex items-center gap-1">
                <PriorityHighOutlinedIcon color="warning" />
                <Typography
                  variant="body2"
                  color="warning"
                  className="font-semibold"
                >
                  Labor hours overridden
                </Typography>
              </div>
            )
          }
          return null
        },
      },
      {
        header: 'Work Hours',
        accessorFn: (row) => row.workHours,
        Cell: ({ cell }) => {
          return (
            <Typography
              variant="body2"
              color={
                task?.overrideLaborTransactionHours
                  ? 'text.disabled'
                  : undefined
              }
              className={
                task?.overrideLaborTransactionHours ? 'line-through' : undefined
              }
            >
              {cell.getValue()}
            </Typography>
          )
        },
        Footer: () => (
          <>
            Total Work Hours:
            <Typography variant="body1">
              {minutesToHoursStr(task?.workMinutes)}
            </Typography>
          </>
        ),
      },
      {
        header: 'Rate Type',
        accessorFn: (row) => row.rateType,
      },
      {
        header: 'Total Cost',
        accessorFn: (row) => row.totalCost,
        Cell: ({ cell }) => {
          return (
            <Typography
              variant="body2"
              color={
                task?.overrideLaborTransactionHours
                  ? 'text.disabled'
                  : undefined
              }
              className={
                task?.overrideLaborTransactionHours ? 'line-through' : undefined
              }
            >
              {cell.getValue()}
            </Typography>
          )
        },
        Footer: () => (
          <>
            Grand Total Cost:
            <Typography variant="body1">
              {getGrandTotal()?.toLocaleString?.('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </>
        ),
      },
    ],
    [tableData]
  )

  return (
    <MaterialReactTable
      columns={columns}
      data={tableData}
      enableColumnActions={false}
      enableColumnFilters={false}
      enablePagination={false}
      enableSorting={false}
      enableTopToolbar={false}
      enableBottomToolbar={false}
      muiTableProps={{
        sx: {
          tableLayout: 'fixed',
        },
      }}
      muiTablePaperProps={{
        elevation: 0,
        className:
          'border border-solid border-[rgba(224,224,224,1)] rounded-lg w-full',
      }}
      renderEmptyRowsFallback={() => (
        <div className="my-2 flex w-full items-center justify-center">
          <Typography variant="body1">No labor performed</Typography>
        </div>
      )}
    />
  )
}

interface LaborDetailsSectionProps {
  task: InternalWorkItem
  refetch: () => void
}

const LaborDetailsSection: React.FC<LaborDetailsSectionProps> = ({
  task,
  refetch,
}) => {
  const { handleOpen: handleOpenEditLaborDetailsDrawer } = useModal(
    'editLaborDetailsDrawer'
  )
  return (
    <div className="mx-3 mb-3 flex flex-col gap-2">
      <div className="flex justify-between">
        <Typography variant="h6">Labor Rate Details</Typography>
        <Button
          variant="text"
          color="primary"
          startIcon={<Man2OutlinedIcon />}
          onClick={handleOpenEditLaborDetailsDrawer}
        >
          Edit Labor Rate
        </Button>
      </div>
      <LaborDetailsTable task={task} />
      <EditLaborDetailsDrawer task={task} refetch={refetch} />
      <Divider className="border-b-2" />
    </div>
  )
}

export default LaborDetailsSection
