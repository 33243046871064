import * as React from 'react'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Button, { ButtonProps } from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import { Tooltip } from '@mui/material'

interface OptionProps extends ButtonProps {
  label: string
}

interface SplitButtonProps {
  options: OptionProps[]
  initialSelectedIndex?: number
  disabledTooltip?: string
  size?: 'small' | 'medium' | 'large'
}

const SplitButton: React.FC<SplitButtonProps> = ({
  options,
  initialSelectedIndex = 0,
  disabledTooltip,
  size = 'small',
}) => {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)
  const [selectedIndex, setSelectedIndex] = React.useState(initialSelectedIndex)
  const buttonLabel = (selectedOption) => selectedOption.label

  const allOptionsDisabled = options.every((option) => option.disabled)
  let tooltipText: string
  if (allOptionsDisabled) {
    tooltipText = disabledTooltip
  }

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index)
    setOpen(false)
    options[index].onClick(null)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  const getButonProps = (selectedIndex) => {
    const { label: _, ...rest } = options[selectedIndex]
    return rest
  }

  return (
    <React.Fragment>
      <Tooltip enterDelay={100} title={tooltipText}>
        <ButtonGroup
          variant="contained"
          disabled={allOptionsDisabled}
          ref={anchorRef}
          aria-label="split button"
        >
          <Button {...getButonProps(selectedIndex)} size={size}>
            {buttonLabel(options[selectedIndex])}
          </Button>
          <Button
            size={size}
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="menu"
            onClick={handleToggle}
            className="px-1 py-0"
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
      </Tooltip>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={index}
                      disabled={option?.disabled}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  )
}

export default SplitButton
