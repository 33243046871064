import {
  AvailableRoutes,
  matchPath,
  routes,
  useLocation,
  useRoutePaths,
} from '@redwoodjs/router'
import { useOrgName } from 'src/hooks/useOrgName'
import { useAuth } from 'src/auth'
import useQuery from 'src/hooks/useQuery'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { SUPPORT_USER_ID } from '@wingwork/common/src/constants/comments'
import getCommentRoomRoute from 'src/components/Comments/getCommentRoomRoute'
import Sentry from 'src/lib/sentry'
import { IN_PROGRESS_COMPLIANCE_STATUSES } from '@wingwork/common/src/constants'
import { appendTailNumber } from '@wingwork/common/src/helpers'
import { GET_USERS } from 'src/hooks/requests/useGetUsers'
import { createClient } from '@liveblocks/client'
import { createLiveblocksContext } from '@liveblocks/react'
import {
  CommentsProvider,
  DefaultCommentsProvider,
} from 'src/components/Comments/CommentContext'
import { startCase } from 'lodash'

export const GET_ALL_USERS = gql`
  query GetAllUsers {
    allUsers {
      id
      firstName
      lastName
      clerkId
    }
  }
`

const GET_COMPLIANCE_DETAILS = gql`
  query GetComplianceNameById($id: String!) {
    complianceLedger(id: $id) {
      id
      ledgerNumber
      status
      aircraft {
        tailNumber
      }
      MaintenanceLogbook {
        id
      }
    }
  }
`

const GET_COMPLIANCE_LEDGER_ID_FROM_LOGBOOK_ENTRY_ID = gql`
  query GetComplianceLedgerIdFromLogbookEntryId($id: String!) {
    maintenanceLogbook(id: $id) {
      id
      complianceLedgerId
    }
  }
`

const GET_PURCHASE_ORDER_NAME = gql`
  query GetPurchaseOrderName($id: String!) {
    purchaseOrder(id: $id) {
      id
      number
    }
  }
`

const GET_WORK_ORDER_NAME = gql`
  query GetWorkOrderName($id: String!) {
    internalWorkOrder(id: $id) {
      id
      name
      aircraft {
        id
        tailNumber
      }
    }
  }
`

const GET_AIRCRAFT_NAME = gql`
  query GetAircraftNameById($id: String!) {
    aircraft(id: $id) {
      id
      tailNumber
    }
  }
`

const GET_PRODUCT_NAME = gql`
  query GetProductName($id: String!) {
    product(id: $id) {
      id
      name
    }
  }
`

const GET_INVENTORY_ITEM_NAME = gql`
  query GetInventoryItemName($id: String!) {
    inventoryItem(id: $id) {
      id
      serialNumber
      product {
        id
        name
      }
    }
  }
`

const MAX_LIVEBLOCKS_AUTH_FAILURES = 4

type CustomCommentsProviderProps = {
  isAdmin?: boolean
}

export const DISABLED_COMMENT_ROUTES = [
  'forbidden',
  'landing',
  'login',
  'adminSupport',
  'adminAircrafts',
  'featureSettings',
  'administration',
  'administrationId',
  'createTask',
  'inbox',
  'featureNotEnabled',
] as const

type VALID_COMMENT_ROUTES = Exclude<
  keyof AvailableRoutes,
  (typeof DISABLED_COMMENT_ROUTES)[number]
>

/**
 * Sets up and returns a CommentsProvider with the correct auth calls
 * ! This hook should only be called once
 * @param param0
 * @returns
 */
const useComments = ({ isAdmin = false }: CustomCommentsProviderProps) => {
  // TODO: would be nice to filter this in some way
  const { data: users } = useQuery(isAdmin ? GET_ALL_USERS : GET_USERS)

  const orgSlug = useOrgName()
  const { getToken } = useAuth()
  const { pathname } = useLocation()

  const allRoutePaths = useRoutePaths()

  const [roomId, setRoomId] = useState<string>()
  const [liveblocksAuthFailures, setLiveblocksAuthFailures] = useState(0)
  const [getComplianceLedgerDetails] = useLazyQuery(GET_COMPLIANCE_DETAILS)
  const [getComplianceLedgerIdFromLogbookEntryId] = useLazyQuery(
    GET_COMPLIANCE_LEDGER_ID_FROM_LOGBOOK_ENTRY_ID
  )
  const [getPurchaseOrderName] = useLazyQuery(GET_PURCHASE_ORDER_NAME)
  const [getWorkOrderName] = useLazyQuery(GET_WORK_ORDER_NAME)
  const [getAircraftName] = useLazyQuery(GET_AIRCRAFT_NAME)
  const [getProductName] = useLazyQuery(GET_PRODUCT_NAME)
  const [getInventoryItemName] = useLazyQuery(GET_INVENTORY_ITEM_NAME)
  const [provider, setProvider] = useState<CommentsProvider>(
    () => DefaultCommentsProvider // The default provider should still render the children because sometimes, as is the case with the comments drawer, the children are required for structure/styling
  )
  const [providerLoaded, setProviderLoaded] = useState(false)
  const cleanedUsers = useMemo(
    () =>
      ((isAdmin ? users?.allUsers : users?.users) ?? []).map((user) => ({
        id: user.id,
        name: `${user.firstName} ${user.lastName}`,
      })),
    [users]
  )

  const getRoomId = async (pathname: string, orgSlug: string) => {
    const { roomRoute, matchedParams } = getCommentRoomRoute(
      pathname,
      allRoutePaths
    )
    if (!orgSlug || matchedParams.orgName !== orgSlug) {
      return pathname
    }

    // Document hub changes for each aircraft, but the comments should always be in the same room since, from the users perspective, the page doesn't change
    if (roomRoute === 'documentHubByAircraft') {
      return routes.documentHub({ orgName: orgSlug })
    }
    // Carry over comments made when creating a compliance to the created compliance
    if (
      roomRoute === 'ledgerLogbookEntry' &&
      matchedParams.ledgerId &&
      typeof matchedParams.ledgerId === 'string'
    ) {
      return routes.bulkCompliance({
        orgName: orgSlug,
        ledgerId: matchedParams.ledgerId,
      })
    }
    // Comments exist on the compliance ledger rather than the LBE generated from it
    if (
      roomRoute === 'logbookEntry' &&
      matchedParams.entryId &&
      typeof matchedParams.entryId === 'string'
    ) {
      const { data } = await getComplianceLedgerIdFromLogbookEntryId({
        variables: { id: matchedParams.entryId },
      })
      if (!data?.maintenanceLogbook?.complianceLedgerId) {
        return pathname
      }
      return routes.bulkCompliance({
        orgName: orgSlug,
        ledgerId: data?.maintenanceLogbook?.complianceLedgerId,
      })
    }
    // If on the compliance ledger sign page, return the url of the main compliance ledger page
    if (
      roomRoute === 'bulkComplianceSign' &&
      matchedParams.ledgerId &&
      typeof matchedParams.ledgerId === 'string'
    ) {
      return routes.bulkCompliance({
        orgName: orgSlug,
        ledgerId: matchedParams.ledgerId,
      })
    }

    // V2 routes
    switch (roomRoute) {
      case 'fleetDashboardV2':
        return routes.fleetDashboard({ orgName: orgSlug })
      case 'maintenanceItemPageV2':
        if (!matchedParams.id || typeof matchedParams.id !== 'string') {
          console.error(
            'No id found in params for maintenance item page:',
            pathname
          )
          return pathname
        }
        return routes.maintenanceItem({
          orgName: orgSlug,
          id: matchedParams.id,
        })
      case 'bulkComplianceV2':
        if (!matchedParams.id || typeof matchedParams.id !== 'string') {
          console.error(
            'No id found in params for bulk compliance page:',
            pathname
          )
          return pathname
        }
        return routes.bulkCompliance({
          orgName: orgSlug,
          ledgerId: matchedParams.id,
        })
      case 'workOrderCompliancePageV2':
        if (!matchedParams.id || typeof matchedParams.id !== 'string') {
          console.error(
            'No id found in params for work order compliance page:',
            pathname
          )
          return pathname
        }
        return routes.workOrder({ orgName: orgSlug, id: matchedParams.id })
      case 'dueListV2':
        return routes.dueList({ orgName: orgSlug })
      case 'maintenanceItemsV2':
        return routes.maintenanceItems({ orgName: orgSlug })
      case 'purchaseOrdersV2':
        return routes.purchaseOrders({ orgName: orgSlug })
      case 'purchaseOrderV2':
        if (!matchedParams.id || typeof matchedParams.id !== 'string') {
          console.error(
            'No id found in params for purchase order page:',
            pathname
          )
          return pathname
        }
        return routes.purchaseOrder({ orgName: orgSlug, id: matchedParams.id })
    }

    return pathname
  }

  useEffect(() => {
    getRoomId(pathname, orgSlug).then(setRoomId)
  }, [pathname, orgSlug])

  const resolveLedgerRoomName = async (
    roomId: string,
    roomRoute: VALID_COMMENT_ROUTES,
    idParamName: 'ledgerId' | 'entryId' | 'id'
  ) => {
    const matchResult = matchPath(allRoutePaths[roomRoute], roomId)
    let ledgerId
    if (
      matchResult?.match &&
      matchResult.params?.[idParamName] &&
      typeof matchResult.params[idParamName] === 'string'
    ) {
      ledgerId = matchResult.params[idParamName]
    }
    const { data } = await getComplianceLedgerDetails({
      variables: { id: ledgerId },
    })

    if (
      (IN_PROGRESS_COMPLIANCE_STATUSES.includes(
        data?.complianceLedger?.status
      ) ||
        !data?.complianceLedger?.MaintenanceLogbook?.length) &&
      data?.complianceLedger?.ledgerNumber
    ) {
      return `Compliance ${appendTailNumber(
        data?.complianceLedger?.ledgerNumber,
        data?.complianceLedger?.aircraft?.tailNumber
      )}`
    }
    if (
      data?.complianceLedger?.MaintenanceLogbook?.length &&
      data?.complianceLedger?.aircraft?.tailNumber
    ) {
      return `Logbook Entry for ${data?.complianceLedger?.aircraft?.tailNumber}`
    }
    return 'Compliance'
  }

  const resolveWorkOrderRoomName = async (
    roomId: string,
    roomRoute: VALID_COMMENT_ROUTES
  ) => {
    const matchResult = matchPath(allRoutePaths[roomRoute], roomId)
    let workOrderId
    if (
      matchResult?.match &&
      matchResult.params?.id &&
      typeof matchResult.params.id === 'string'
    ) {
      workOrderId = matchResult.params.id
    }
    const { data } = await getWorkOrderName({
      variables: { id: workOrderId },
    })
    return `Work Order ${appendTailNumber(
      data?.internalWorkOrder?.name ?? '',
      data?.internalWorkOrder?.aircraft?.tailNumber ?? ''
    )}`
  }

  const commentRoomNameResolvers: {
    [key in VALID_COMMENT_ROUTES]: (roomId: string) => Promise<string>
  } = {
    orgHomePage: () => Promise.resolve('Fleet Dashboard'),
    dueList: () => Promise.resolve('Due List'),
    maintenanceItems: () => Promise.resolve('Maintenance Items'),
    planeDashboard: async (roomId: string) => {
      const { roomRoute, matchedParams } = getCommentRoomRoute(
        roomId,
        allRoutePaths
      )
      if (roomRoute !== 'planeDashboard') {
        throw new Error('Invalid room route')
      }
      const { data } = await getAircraftName({
        variables: { id: matchedParams.id },
      })
      return `Aircraft ${data?.aircraft?.tailNumber ?? ''} Dashboard`
    },
    aircraftOnboarding: () => Promise.resolve('Aircraft Onboarding'),
    bulkCompliance: async (roomId: string) =>
      await resolveLedgerRoomName(roomId, 'bulkCompliance', 'ledgerId'),
    bulkComplianceSign: async (roomId: string) =>
      await resolveLedgerRoomName(roomId, 'bulkComplianceSign', 'ledgerId'),
    // TODO: probably want to get the name of this mtx item
    maintenanceItem: () => Promise.resolve('Maintenance Item'),
    fleetDashboard: () => Promise.resolve('Fleet Dashboard'),
    logbookEntriesLanding: () => Promise.resolve('Logbook Entries'),
    ledgerLogbookEntry: async (roomId: string) =>
      await resolveLedgerRoomName(roomId, 'ledgerLogbookEntry', 'ledgerId'),
    logbookEntry: async (roomId: string) =>
      await resolveLedgerRoomName(roomId, 'logbookEntry', 'entryId'),
    workCompleted: () => Promise.resolve('Past Compliance'),
    workOrder: async (roomId: string) =>
      await resolveWorkOrderRoomName(roomId, 'workOrder'),
    workOrders: () => Promise.resolve('Work Orders'),
    invoice: () => Promise.resolve('Invoice'),
    activityLog: () => Promise.resolve('Activity Log'),
    purchaseOrders: () => Promise.resolve('Purchase Orders'),
    purchaseOrder: async (roomId: string) => {
      const { roomRoute, matchedParams } = getCommentRoomRoute(
        roomId,
        allRoutePaths
      )
      if (roomRoute !== 'purchaseOrder') {
        throw new Error('Invalid room route')
      }
      const { data } = await getPurchaseOrderName({
        variables: { id: matchedParams.id },
      })
      return `Purchase Order ${data?.purchaseOrder?.number ?? ''}`
    },
    purchaseOrderIngest: () => Promise.resolve('Purchase Order'),
    documentHub: () => Promise.resolve('Document Hub'),
    documentHubByAircraft: () => Promise.resolve('Document Hub'),
    reports: () => Promise.resolve('Reports'),
    settings: () => Promise.resolve('Settings'),

    productsList: () => Promise.resolve('Products'),
    dueProducts: () => Promise.resolve('Due Products'),
    productDetail: async (roomId: string) => {
      const { roomRoute, matchedParams } = getCommentRoomRoute(
        roomId,
        allRoutePaths
      )
      if (roomRoute !== 'productDetail') {
        throw new Error('Invalid room route')
      }
      const { data } = await getProductName({
        variables: { id: matchedParams.id },
      })
      return `Product ${data?.product?.name ?? ''}`
    },
    toolsList: () => Promise.resolve('Tools'),
    vendorsList: () => Promise.resolve('Vendors'),
    inventoryItemDetail: async (roomId: string) => {
      const { roomRoute, matchedParams } = getCommentRoomRoute(
        roomId,
        allRoutePaths
      )
      if (roomRoute !== 'inventoryItemDetail') {
        throw new Error('Invalid room route')
      }
      const { data } = await getInventoryItemName({
        variables: { id: matchedParams.id },
      })
      return `Inventory Item ${data?.inventoryItem?.product?.name ?? ''} ${
        data?.inventoryItem?.serialNumber
          ? `- ${data?.inventoryItem?.serialNumber}`
          : ''
      }`
    },

    orgHomePageV2: () => Promise.resolve('Fleet Dashboard'),
    fleetDashboardV2: () => Promise.resolve('Fleet Dashboard'),
    // TODO: probably want to get the name of this mtx item
    maintenanceItemPageV2: () => Promise.resolve('Maintenance Item'),
    pastCompliance: () => Promise.resolve('Past Compliance'),
    bulkComplianceV2: async (roomId: string) =>
      await resolveLedgerRoomName(roomId, 'bulkComplianceV2', 'id'),
    workOrderCompliancePageV2: async (roomId: string) =>
      await resolveWorkOrderRoomName(roomId, 'workOrderCompliancePageV2'),
    dueListV2: () => Promise.resolve('Due List'),
    maintenanceItemsV2: () => Promise.resolve('Maintenance Items'),
    purchaseOrdersV2: () => Promise.resolve('Purchase Orders'),
    purchaseOrderRequestedItems: () => Promise.resolve('Requested Items'),
    purchaseOrderV2: () => Promise.resolve('Purchase Order'),
  }

  const getAuth = useCallback(async (room: string) => {
    if (liveblocksAuthFailures >= MAX_LIVEBLOCKS_AUTH_FAILURES) {
      return Promise.resolve({
        error: 'forbidden',
        reason: 'Liveblocks is not initialized',
      })
    }
    try {
      const token = await getToken()
      const headers = {
        orgSlug,
        Authorization: `Bearer ${token}`,
        'auth-provider': 'clerk',
      }

      const body = JSON.stringify({ room })

      if (isAdmin) {
        const response = await fetch(
          '/.netlify/functions/liveblocksAdminAuth',
          { method: 'POST', headers, body }
        )
        return await response.json()
      }

      const response = await fetch('/.netlify/functions/liveblocksAuth', {
        method: 'POST',
        headers,
        body,
      })
      return await response.json()
    } catch (err) {
      console.error(err)
      setLiveblocksAuthFailures(
        (prevLiveblocksAuthFailures) => prevLiveblocksAuthFailures + 1
      )
      return Promise.resolve({
        error: 'forbidden',
        reason: 'Liveblocks is not initialized',
      })
    }
  }, [])

  const resolveUsers = useCallback(
    ({ userIds }: { userIds: string[] }) => {
      return userIds.map((userId) =>
        userId === SUPPORT_USER_ID
          ? { id: SUPPORT_USER_ID, name: 'WingWork Support' }
          : cleanedUsers.find((user) => user.id === userId)
      )
    },
    [cleanedUsers]
  )

  const resolveMentionSuggestions = useCallback(
    ({ text }: { text: string }) => {
      const userIds = cleanedUsers
        .filter((user) => user.name.toLowerCase().includes(text.toLowerCase()))
        .map((user) => user.id)
      if (
        'wingwork support'.includes(text.toLowerCase()) ||
        text.toLowerCase() === 'help'
      ) {
        userIds.push(SUPPORT_USER_ID)
      }
      return userIds
    },
    [cleanedUsers]
  )

  const resolveRoomsInfo = useCallback(
    ({ roomIds }: { roomIds: string[] }) => {
      return Promise.all(
        roomIds.map(async (roomId) => {
          const { roomRoute: roomType } = getCommentRoomRoute(
            roomId,
            allRoutePaths
          )
          if (!roomType || !commentRoomNameResolvers[roomType]) {
            const errorMessage = `Unknown comment room type: ${roomId}!!  Ignoring this error will result in missing room/page names in comment notifications`
            Sentry.captureException(errorMessage)
            console.error(errorMessage)
          }
          let roomName: string
          try {
            roomName = await commentRoomNameResolvers[roomType]?.(roomId)
          } catch (err) {
            console.error(err)
          }
          if (!roomName) {
            roomName = startCase(roomType)
          }
          return {
            url: roomId,
            id: roomId,
            name: roomName,
          }
        })
      )
    },
    [orgSlug]
  )

  useEffect(() => {
    const client = createClient({
      authEndpoint: getAuth,
      resolveUsers,
      resolveMentionSuggestions,
      resolveRoomsInfo,
    })
    const { LiveblocksProvider } = createLiveblocksContext(client)
    setProvider(() => LiveblocksProvider)
    setProviderLoaded(true)
  }, [getAuth, resolveUsers, resolveMentionSuggestions, resolveRoomsInfo])

  return {
    CommentsProvider: provider,
    roomId,
    hasLoaded: roomId && providerLoaded,
    hasError: liveblocksAuthFailures > MAX_LIVEBLOCKS_AUTH_FAILURES,
  }
}

export default useComments
