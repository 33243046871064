import { Link } from '@redwoodjs/router'
import { useBreadcrumbs } from './BreadcrumbsContext'
import NavIcon from './NavIcon'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import {
  Typography,
  Link as MUILink,
  Breadcrumbs as MUIBreadcrumbs,
  Skeleton,
} from '@mui/material'
import React from 'react'

const Breadcrumbs = () => {
  const { breadcrumbs } = useBreadcrumbs()

  if (breadcrumbs.length === 0) return null

  return (
    <div className="ml-2 flex items-center gap-0.5">
      <MUIBreadcrumbs separator={<ChevronRightRoundedIcon fontSize="small" />}>
        {breadcrumbs.map((crumb, index) => {
          const defaultIconSize = crumb.label ? 'small' : 'medium'
          const showSkeleton = crumb.loading && !crumb.label

          return crumb.to ? (
            <MUILink
              key={index}
              component={Link}
              to={crumb.to}
              color="inherit"
              underline="hover"
              variant="body2"
              className="flex items-center gap-1"
            >
              {crumb.icon && (
                <NavIcon
                  variety={crumb.icon}
                  fontSize={crumb.iconSize || defaultIconSize}
                />
              )}
              {showSkeleton ? (
                <Skeleton variant="text" width={60} />
              ) : (
                crumb.label
              )}
            </MUILink>
          ) : (
            <Typography
              key={index}
              variant="body2"
              color="text.secondary"
              className="flex items-center gap-1"
              fontWeight={index === breadcrumbs.length - 1 ? 'bold' : 'regular'}
            >
              {crumb.icon && (
                <NavIcon
                  variety={crumb.icon}
                  fontSize={crumb.iconSize || defaultIconSize}
                />
              )}
              {showSkeleton ? (
                <Skeleton variant="text" width={60} />
              ) : (
                crumb.label
              )}
            </Typography>
          )
        })}
      </MUIBreadcrumbs>
    </div>
  )
}

export default Breadcrumbs
