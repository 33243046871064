export const GET_PRODUCTS_LIST = gql`
  query GetProductsList(
    $filters: ProductFilters
    $pagination: PaginationInput
  ) {
    productsPage(filters: $filters, pagination: $pagination) {
      edges {
        id
        name
        partNumber
        quantity
        type
        isRental
        lowStockThreshold
        vendors {
          id
          name
        }
        updatedAt
        createdAt
      }
      pageInfo {
        totalCount
        hasNextPage
      }
    }
  }
`

export const GET_AIRCRAFT_OPTIONS = gql`
  query GetAircraftOptions($orgSlug: String!) {
    aircrafts(orgSlug: $orgSlug) {
      id
      tailNumber
    }
  }
`
