import React from 'react'

import { useQuery } from '@redwoodjs/web'
import WorkflowAttachmentsSection, {
  WorkflowAttachmentType,
} from 'src/components/workflow/WorkflowAttachmentsSection'
import { GET_INTERNAL_WORK_ITEM_ATTACHMENTS } from '../../../queries'
import { useMemo } from 'react'

const WorkItemAttachments = ({
  workItemId,
  flashSaved,
  flashError,
  variant = 'default',
}: {
  workItemId: string
  variant?: 'default' | 'review' | 'readonly'
  flashSaved?: (text?: string) => void
  flashError?: (text?: string) => void
}) => {
  const { data: internalWorkItemData, refetch: refetchInternalWorkItem } =
    useQuery(GET_INTERNAL_WORK_ITEM_ATTACHMENTS, {
      variables: { id: workItemId },
      notifyOnNetworkStatusChange: false,
      fetchPolicy: 'cache-and-network',
      skip: !workItemId,
    })

  const attachables = useMemo(
    () => [
      {
        id: workItemId,
        type: 'InternalWorkItem' as const,
      },
    ],
    [workItemId]
  )

  const attachments: WorkflowAttachmentType[] = useMemo(() => {
    return [
      ...(internalWorkItemData?.internalWorkItem?.attachedFiles?.map((file) =>
        file?.file
          ? {
              ...file.file,
              category: 'InternalWorkItem',
            }
          : null
      ) ?? []),
      ...(internalWorkItemData?.internalWorkItem?.maintenanceItem?.attachedFiles?.map(
        (file) =>
          file?.file
            ? {
                ...file.file,
                category: 'MaintenanceItem',
              }
            : null
      ) ?? []),
      ...(internalWorkItemData?.internalWorkItem?.partsTransaction?.addedPartFiles?.map(
        (file) =>
          file
            ? {
                ...file,
                category: 'AddedPartTransaction',
              }
            : null
      ) ?? []),
      ...(internalWorkItemData?.internalWorkItem?.partsTransaction?.removedPartFiles?.map(
        (file) =>
          file
            ? {
                ...file,
                category: 'RemovedPartTransaction',
              }
            : null
      ) ?? []),
    ].filter(Boolean) as WorkflowAttachmentType[]
  }, [internalWorkItemData])

  return (
    <WorkflowAttachmentsSection
      data={attachments}
      attachables={attachables}
      refetchAttachments={refetchInternalWorkItem}
      flashSaved={flashSaved}
      flashError={flashError}
      variant={variant}
    />
  )
}

export default WorkItemAttachments
