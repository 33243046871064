import React from 'react'

import { Typography } from '@mui/material'
import { Aircraft, MaintenanceItem } from 'types/graphql'
import Drawer from 'src/components/MUI/Drawer/Drawer'
import DrawerActions from 'src/components/MUI/Drawer/DrawerActions'
import DrawerHeader from 'src/components/MUI/Drawer/DrawerHeader'
import Button from 'src/components/MUI/Button'
import ArrowForwardIcon from '@mui/icons-material/KeyboardArrowRight'
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { get } from 'lodash'
import Field from 'src/pages/MaintenanceItemPage/components/Field'
import {
  getIntervals,
  getNextDueString,
  getTolerances,
} from '@wingwork/common/src/maintenanceItem'
import DueStatusChip from 'src/components/DueStatusChip/DueStatusChip'
import PrimSecTextCombo from 'src/components/common/PrimSecTextCombo'
import useDueStatus from 'src/hooks/useDueStatus'
import ReusableTabs, { TabComponent } from 'src/components/MUI/Tabs'
import ChildTasksTab from './ChildTasksTabV2'
import WorkCompletedTab from './WorkCompletedTabV2'
import { routes } from '@redwoodjs/router'
import { navigate } from '@redwoodjs/router'
import { useOrgName } from 'src/hooks/useOrgName'
import SingleComplianceForm from 'src/components/SingleComplianceDrawer/SingleComplianceForm'
import { toast } from '@redwoodjs/web/toast'
import ComplianceTab from './ComplianceTabV2'

interface LineItemDetailsDrawerProps {
  open: boolean
  onClose: () => void
  parentTask: MaintenanceItem
  aircraft: Aircraft
  activeTab?: number
  setActiveTab: (tab: number) => void
  refetch: () => void
}

const LineItemDetailsDrawer: React.FC<LineItemDetailsDrawerProps> = ({
  open,
  onClose,
  parentTask,
  aircraft,
  activeTab = 0,
  setActiveTab,
  refetch,
}) => {
  const orgName = useOrgName()
  const [showMoreDetails, setShowMoreDetails] = React.useState(false)
  const [inProgress, setInProgress] = React.useState(false)
  const { intervals, tolerances } = useDueStatus(parentTask, aircraft)
  const aircraftComponent = get(parentTask, 'aircraftComponent')
  return (
    <Drawer
      anchor="right"
      className="h-full w-[800px]"
      open={open}
      onClose={onClose}
    >
      <DrawerHeader
        title={`${parentTask?.ataCode} ${parentTask?.manufactureCode}`}
        onClose={onClose}
      />
      <div className="h-full w-[800px] overflow-y-auto">
        <div className="flex flex-col gap-1 px-3 pt-3">
          <div className="flex w-full justify-between">
            <Typography variant="caption">DETAILS</Typography>
            <Button
              variant="text"
              endIcon={<ArrowForwardIcon />}
              onClick={() =>
                navigate(
                  routes.maintenanceItemPageV2({ id: parentTask.id, orgName })
                )
              }
            >
              View Task
            </Button>
          </div>
          <div className="flex w-full">
            <div className="flex flex-1 flex-col">
              <Typography variant="caption">Description</Typography>
              <Typography variant="body2">
                {get(parentTask, 'title')}
              </Typography>
            </div>
            <Button
              className="ml-auto whitespace-nowrap"
              variant="text"
              color="base"
              endIcon={showMoreDetails ? <ArrowUpIcon /> : <ArrowDownIcon />}
              onClick={() => setShowMoreDetails(!showMoreDetails)}
            >
              {`Show ${showMoreDetails ? 'Less' : 'More'}`}
            </Button>
          </div>
          {showMoreDetails && (
            <>
              <div className="grid grid-cols-3">
                <Field label="Interval">
                  {intervals.length > 0 ? (
                    intervals.map((item, index) => {
                      return (
                        <div className="flex items-center gap-1" key={index}>
                          <Typography key={item} variant="body2">
                            {item}
                          </Typography>
                          {tolerances[index] && (
                            <Typography variant="caption">
                              + {tolerances[index]}
                            </Typography>
                          )}
                        </div>
                      )
                    })
                  ) : (
                    <Typography> ---- </Typography>
                  )}
                </Field>
                <Field label="Next Due">
                  {getNextDueString(parentTask).length > 0 ? (
                    getNextDueString(parentTask).map((item) => {
                      return (
                        <Typography key={item} variant="body2">
                          {item}
                        </Typography>
                      )
                    })
                  ) : (
                    <Typography> ---- </Typography>
                  )}
                </Field>
                <Field label="Remaining">
                  <DueStatusChip
                    maintenanceItem={parentTask}
                    aircraft={aircraft}
                  />
                </Field>
              </div>
              {aircraftComponent && (
                <div className="grid grid-cols-3">
                  <PrimSecTextCombo
                    primaryText={aircraftComponent?.partNumber}
                    secondaryText="Part Number"
                    variant="inverted"
                  />
                  <PrimSecTextCombo
                    // Todo: Make sure this is the correct value
                    primaryText={aircraftComponent?.description || '--'}
                    secondaryText="Part Description"
                    variant="inverted"
                  />
                  <PrimSecTextCombo
                    // Todo: Make sure this is the correct value
                    primaryText={aircraftComponent?.serialNumber}
                    secondaryText="Serial Number"
                    variant="inverted"
                  />
                </div>
              )}
            </>
          )}
        </div>
        <ReusableTabs
          variant="fullWidth"
          tabsClassName="mb-2 mt-3 normal-case border-t"
          value={activeTab}
          onTabChange={(value) => setActiveTab(value as number)}
        >
          <TabComponent label="COMPLIANCE">
            <ComplianceTab
              id={parentTask?.id}
              isOpen={open}
              onClose={() => {
                onClose()
                refetch()
              }}
              setInProgress={setInProgress}
            />
          </TabComponent>
          <TabComponent label="CHILD TASKS">
            <ChildTasksTab parentTask={parentTask} aircraft={aircraft} />
          </TabComponent>
          <TabComponent label="WORK COMPLETED">
            <WorkCompletedTab parentTask={parentTask} />
          </TabComponent>
        </ReusableTabs>
      </div>
      {!inProgress && activeTab === 0 && (
        <DrawerActions
          leftActions={
            <Button variant="text" color="black" onClick={onClose}>
              CANCEL
            </Button>
          }
          rightActions={
            <Button
              variant="contained"
              color="primary"
              type="submit"
              form="singleComplianceDrawerForm"
            >
              CREATE COMPLIANCE
            </Button>
          }
        />
      )}
    </Drawer>
  )
}

export default LineItemDetailsDrawer
