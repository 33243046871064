import { useState, useCallback, ReactNode, useMemo } from 'react'
import {
  Popover as MuiPopover,
  PopoverProps as MuiPopoverProps,
} from '@mui/material'
import clsx from 'clsx'

interface PopoverProps {
  anchor: ReactNode
  content: ReactNode | ((props: { close: () => void }) => ReactNode)
  open?: boolean
  onOpenChange?: (open: boolean) => void
  anchorOrigin?: MuiPopoverProps['anchorOrigin']
  transformOrigin?: MuiPopoverProps['transformOrigin']
  closeOnClickOutside?: boolean
  width?: number | string
  maxHeight?: number | string
  className?: string
  gap?: number
  gapSide?: 'top' | 'bottom' | 'left' | 'right'
  keepMounted?: boolean
  classes?: {
    popper?: string
    paper?: string
  }
}

export const Popover = ({
  anchor,
  content,
  open: controlledOpen,
  onOpenChange,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin,
  closeOnClickOutside = true,
  width = 'auto',
  maxHeight = 400,
  className,
  gap = 1,
  gapSide = 'top',
  keepMounted = false,
  classes,
}: PopoverProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [uncontrolledOpen, setUncontrolledOpen] = useState(false)

  const isControlled = controlledOpen !== undefined
  const isOpen = isControlled ? controlledOpen : uncontrolledOpen

  const wrappedAnchor = useMemo(() => {
    const handleAnchorClick = (event: React.MouseEvent<HTMLElement>) => {
      if (React.isValidElement(anchor) && anchor.props.onClick) {
        anchor.props.onClick(event)
      }

      setAnchorEl(event.currentTarget)
      if (!isControlled) {
        setUncontrolledOpen(true)
      }
      onOpenChange?.(true)
    }

    if (React.isValidElement(anchor)) {
      return React.cloneElement(anchor, {
        onClick: handleAnchorClick,
      })
    }

    return <div onClick={handleAnchorClick}>{anchor}</div>
  }, [anchor, isControlled, onOpenChange])

  const handleClose = useCallback(() => {
    if (!isControlled) {
      setUncontrolledOpen(false)
    }
    onOpenChange?.(false)
  }, [isControlled, onOpenChange])

  const getMargin = () => {
    const spacing = gap * 8
    const margins = {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    }

    switch (gapSide) {
      case 'top':
        margins.marginTop = spacing
        break
      case 'bottom':
        margins.marginBottom = spacing
        break
      case 'left':
        margins.marginLeft = spacing
        break
      case 'right':
        margins.marginRight = spacing
        break
    }

    return margins
  }

  return (
    <>
      {wrappedAnchor}
      <MuiPopover
        open={isOpen}
        anchorEl={anchorEl}
        keepMounted={keepMounted}
        onClose={closeOnClickOutside ? handleClose : undefined}
        anchorOrigin={anchorOrigin}
        transformOrigin={
          transformOrigin || {
            vertical: anchorOrigin.vertical === 'bottom' ? 'top' : 'bottom',
            horizontal: anchorOrigin.horizontal,
          }
        }
        className={clsx('rounded-md shadow-md', className, classes?.popper)}
        PaperProps={{
          className: clsx(classes?.paper),
          style: {
            width,
            maxHeight,
            ...getMargin(),
          },
        }}
      >
        {typeof content === 'function'
          ? content({ close: handleClose })
          : content}
      </MuiPopover>
    </>
  )
}
