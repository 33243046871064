import Button from 'src/components/MUI/Button'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Stack,
  Paper,
  Divider,
} from '@mui/material'
import useModal from 'src/hooks/useModal'
import WorkPerformedDrawer, {
  WorkPerformedFormData,
} from './WorkPerformedDrawer'
import { useCallback, useState } from 'react'
import { useMutation } from '@redwoodjs/web'
import { SET_WORK_INFORMATION } from './queries'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { abbreviateFullName } from 'src/utils/stringFormatters'
import EditIcon from '@mui/icons-material/Edit'
import { useFragment } from '@apollo/client'
import { minutesToHoursStr } from 'src/utils/helpers'

const WorkRow = ({ workedById, workHours, notes, description, onEdit }) => {
  const [expanded, setExpanded] = useState(false)

  const { data: workedBy } = useFragment({
    fragment: gql`
      fragment WorkedByFragment on User {
        id
        firstName
        lastName
      }
    `,
    fragmentName: 'WorkedByFragment',
    from: {
      __typename: 'User',
      id: workedById,
    },
  })

  return (
    <Paper className="cursor-pointer" onClick={() => setExpanded(!expanded)}>
      <div className="grid grid-cols-[1fr_auto_auto_auto] items-center gap-4 p-2">
        {description ? (
          <Typography variant="body1" noWrap>
            {description}
          </Typography>
        ) : (
          <Typography
            variant="body1"
            className="select-none italic text-gray-500"
          >
            No Description
          </Typography>
        )}

        <div className="flex flex-col">
          <Typography color="text.secondary" variant="body2">
            Time Worked
          </Typography>
          <Typography variant="body2">
            {minutesToHoursStr(workHours)}
          </Typography>
        </div>

        <div className="flex flex-col">
          <Typography color="text.secondary" variant="body2">
            Worked By
          </Typography>
          <Typography variant="body2">
            {workedById ? abbreviateFullName(workedBy) : '-'}
          </Typography>
        </div>

        <div className="flex items-center gap-1">
          <Button
            variant="text"
            color="primary"
            onClick={(e) => {
              e.stopPropagation()
              onEdit()
            }}
          >
            Edit
          </Button>
          <IconButton
            onClick={(e) => {
              e.stopPropagation()
              setExpanded(!expanded)
            }}
          >
            {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </div>
      </div>

      {expanded && (
        <>
          <Divider variant="middle" />
          <div className="p-2">
            <div className="flex flex-col gap-1">
              <div>
                <Typography color="text.secondary" variant="body2">
                  Description
                </Typography>
                <Typography variant="body2">{description || '-'}</Typography>
              </div>
              <div>
                <Typography color="text.secondary" variant="body2">
                  Notes
                </Typography>
                <Typography variant="body2">{notes || '-'}</Typography>
              </div>
            </div>
          </div>
        </>
      )}
    </Paper>
  )
}

const WorkPerformedSection = ({
  description,
  notes,
  workHours,
  workedById,
}) => {
  const { handleOpen: handleOpenWorkPerformed } = useModal(
    'workPerformedDrawer'
  )

  const anyFilled = Boolean(
    description?.trim() || notes?.trim() || workHours || workedById
  )

  return (
    <div className="flex flex-col gap-2 px-3">
      <div className="flex items-center justify-between">
        <Typography variant="h6">Work Performed</Typography>
        {!anyFilled && (
          <Button
            startIcon={<AddCircleOutlineRoundedIcon />}
            variant="text"
            color="primary"
            onClick={handleOpenWorkPerformed}
          >
            Add Work
          </Button>
        )}
      </div>
      {anyFilled ? (
        <WorkRow
          workedById={workedById}
          workHours={workHours}
          notes={notes}
          description={description}
          onEdit={handleOpenWorkPerformed}
        />
      ) : (
        <div className="flex items-center justify-center">
          <Typography variant="body1">No work performed yet</Typography>
        </div>
      )}

      <Divider className="mb-2 border-b-2" />
    </div>
  )
}

export default WorkPerformedSection
