import React from 'react'
import WorkflowHeader from 'src/components/WorkflowHeader'
import PurchaseOrderStatusChip from '../PurchaseOrdersPageV2Page/PurchaseOrderStatusChip'
import { Typography } from '@mui/material'
import { abbreviateFullName } from 'src/utils/stringFormatters'
import Button from 'src/components/MUI/Button'
import { useDispatch } from 'src/hooks/useDispatch'
import { openModal } from 'src/slices/modal'
import { Link, routes } from '@redwoodjs/router'
import useHasPermission from 'src/hooks/useHasPermission'
import { Permissions } from '@wingwork/common/src/constants/permissions'
import { useOrgName } from 'src/hooks/useOrgName'
import PurchaseOrderComplianceModal, {
  AircraftIdToLedgerMap,
  MtxItemsGroupedByAircraftId as MtxItemsGroupedByAircraftId,
} from './PurchaseOrderComplianceModal'
import FileUploadButton from 'src/components/FileUploadButton'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { User } from 'types/graphql'

const PurchaseOrderOpenHeader = ({
  id,
  data,
  completedCompliance,
  serviceItemsWithMtxItemLength,
  invoiceFile,
  invoiceRefetch,
}: {
  id: string
  data: {
    purchaseOrder: {
      status: string
      number: string
      createdAt: string
      authorizedBy: User
    }
  }
  completedCompliance: boolean
  serviceItemsWithMtxItemLength: number
  invoiceFile: any
  invoiceRefetch: () => void
}) => {
  const orgName = useOrgName()
  const po = data?.purchaseOrder ?? {}
  const dispatch = useDispatch()

  return (
    <WorkflowHeader
      title={`PO ${data?.purchaseOrder?.number}`}
      createdAt={data.purchaseOrder.createdAt}
      slots={{
        statusChip: (
          <PurchaseOrderStatusChip
            status={data?.purchaseOrder?.status}
            chipSize="small"
          />
        ),
        inlineFields: (
          <Typography variant="body2" color="text.secondary">
            Authorized by{' '}
            {abbreviateFullName(data?.purchaseOrder?.authorizedBy)}
          </Typography>
        ),
        topRightFields: (
          <>
            {!invoiceFile ? (
              <FileUploadButton
                button={
                  <Button
                    color="base"
                    variant="outlined"
                    startIcon={<AttachFileIcon />}
                  >
                    ATTACH INVOICE
                  </Button>
                }
                accept=".pdf, image/*"
                attachables={[{ id, type: 'PurchaseOrderInvoice' }]}
                refetchAttachments={() => {
                  invoiceRefetch()
                  dispatch(openModal({ name: 'invoiceViewModal' }))
                }}
              />
            ) : (
              <Button
                color="base"
                variant="outlined"
                onClick={() =>
                  dispatch(
                    openModal({
                      name: 'invoiceViewModal',
                    })
                  )
                }
              >
                VIEW INVOICE
              </Button>
            )}
            <Button
              color="base"
              variant="outlined"
              onClick={() =>
                dispatch(openModal({ name: 'purchaseOrderPdfViewModal' }))
              }
            >
              VIEW PDF
            </Button>
            {po.status === 'OPEN' && (
              <div className="flex gap-1">
                <Button
                  variant="outlined"
                  color="base"
                  onClick={() =>
                    dispatch(
                      openModal({
                        name: 'sendPurchaseOrderModal',
                      })
                    )
                  }
                >
                  SEND AGAIN
                </Button>
                <Button
                  // onClick={() =>
                  //   dispatch(
                  //     openModal({
                  //       name: 'confirmationModal',
                  //       data: { discriminator: 'complete-po' },
                  //     })
                  //   )
                  // }
                  component={Link}
                  to={routes.purchaseOrderIngest({ orgName, id })}
                  locked={!useHasPermission(Permissions.purchaseOrder.complete)}
                  lockedTooltip="You do not have permission"
                >
                  DELIVERY CHECK
                </Button>
              </div>
            )}
            {po.status === 'COMPLETED' && serviceItemsWithMtxItemLength > 0 && (
              <Button
                variant={completedCompliance ? 'outlined' : 'contained'}
                onClick={() =>
                  dispatch(
                    openModal({
                      name: 'purchaseOrderComplianceModal',
                    })
                  )
                }
              >
                COMPLETE{completedCompliance ? 'D' : ''} COMPLIANCE
              </Button>
            )}
          </>
        ),
      }}
    />
  )
}

export default PurchaseOrderOpenHeader
