import React, { useRef, useState, useEffect } from 'react'
import { Chip, Typography, Popover, Tooltip } from '@mui/material'
import { HighlightedTypography } from './MUI/StyledComponents'

interface TypeAndDescriptionDisplayProps {
  description: string
  chips?: string[]
  tailTags?: string[]
  maxRows?: number
  highlight?: boolean
}

const TypeAndDescriptionDisplay: React.FC<TypeAndDescriptionDisplayProps> = ({
  description,
  chips,
  tailTags,
  maxRows = 2,
  highlight = false,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const descriptionRef = useRef<HTMLDivElement>(null)

  const [isTruncated, setIsTruncated] = useState(false)

  useEffect(() => {
    if (descriptionRef.current) {
      setIsTruncated(
        descriptionRef.current.clientHeight <
          descriptionRef.current.scrollHeight
      )
    }
  }, [descriptionRef.current])

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const popoverButtonRef = useRef<HTMLDivElement>(null)
  const [popoverButtonWidth, setPopoverButtonWidth] = useState(0)

  useEffect(() => {
    if (popoverButtonRef.current) {
      setPopoverButtonWidth(popoverButtonRef.current.clientWidth)
    }
  }, [popoverButtonRef.current])

  return (
    <div>
      <Tooltip title={isTruncated ? 'Click to expand' : ''}>
        <div
          onClick={isTruncated ? handlePopoverOpen : undefined}
          ref={popoverButtonRef}
          className="flex flex-col"
        >
          <div className="flex flex-row items-center">
            <div className="min-w-0 flex-1">
              <div ref={descriptionRef} className="line-clamp-2">
                {highlight ? (
                  <HighlightedTypography>{description}</HighlightedTypography>
                ) : (
                  <Typography className="subtitle2">{description}</Typography>
                )}
              </div>
            </div>
          </div>

          <div className="flex w-full items-center justify-between">
            {chips && (
              <div>
                {chips?.map((chip) => (
                  <Chip
                    key={chip}
                    label={chip}
                    className="mr-[5px] w-fit"
                    size="small"
                  />
                ))}
              </div>
            )}

            {tailTags && (
              <div className="flex flex-col items-end">
                {tailTags?.map((tag) => (
                  <Typography key={tag} variant="caption" component={'div'}>
                    {tag}
                  </Typography>
                ))}
              </div>
            )}
          </div>
        </div>
      </Tooltip>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <div
          style={{ width: popoverButtonWidth }}
          className=" p-2"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex flex-col">
            {highlight ? (
              <HighlightedTypography>{description}</HighlightedTypography>
            ) : (
              <Typography className="subtitle2">{description}</Typography>
            )}

            <div className="flex w-full items-center justify-between">
              {chips && (
                <div>
                  {chips?.map((chip) => (
                    <Chip
                      key={chip}
                      label={chip}
                      className="mr-[5px] w-fit"
                      size="small"
                    />
                  ))}
                </div>
              )}
              {tailTags && (
                <div className="flex flex-col items-end">
                  {tailTags?.map((tag) => (
                    <Typography key={tag} variant="caption" component={'div'}>
                      {tag}
                    </Typography>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </Popover>
    </div>
  )
}

export default TypeAndDescriptionDisplay
