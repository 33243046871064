import { useMemo } from 'react'

import { ConstructionRounded, ShoppingCartRounded } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Checkbox,
  Modal,
  Paper,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { t } from 'i18next'
import { PurchaseOrderItem } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Table, {
  CheckboxHeader,
  useCheckboxSelection,
} from 'src/components/Table'
import { useDispatch } from 'src/hooks/useDispatch'
import useQuery from 'src/hooks/useQuery'
import { useSelector } from 'src/hooks/useSelector'
import { GET_PURCHASE_ORDER } from './queries'
import { closeModal } from 'src/slices/modal'
import { abbreviateFullName } from 'src/utils/stringFormatters'

import {
  ADD_ITEMS_TO_PURCHASE_ORDER,
  GET_PURCHASE_ORDER_ITEMS,
} from '../PurchaseOrdersPageV2Page/queries'
import Drawer from 'src/components/MUI/Drawer/Drawer'
import DrawerHeader from 'src/components/MUI/Drawer/DrawerHeader'
import DrawerActions from 'src/components/MUI/Drawer/DrawerActions'
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table'
import { getDefaultMRTOptions } from 'src/utils/getDefaultMRTOptions'

interface RequestedLineItemsPickerProps {
  purchaseOrderId: string
  onComplete?: (items: PurchaseOrderItem[]) => void
}

const modalName = 'requestedLineItemsPickerModal'
const RequestedLineItemsPickerModal: React.FC<
  RequestedLineItemsPickerProps
> = ({ purchaseOrderId, onComplete }) => {
  const open =
    useSelector((state) => state.modal.modals?.[modalName]?.isOpen) ?? false

  const dispatch = useDispatch()
  const handleClose = () => {
    setLoading(false)
    dispatch(closeModal({ name: modalName }))
  }
  const [loading, setLoading] = React.useState(false)
  const { data } = useQuery(GET_PURCHASE_ORDER_ITEMS)
  const { purchaseOrderItems: items = [] } = data ?? {}

  const requestedItems = useMemo(
    () => items.filter((item) => !item.rejectedAt && !item.deletedAt),
    [items]
  )

  const [addItemsToPurchaseOrder] = useMutation(ADD_ITEMS_TO_PURCHASE_ORDER, {
    onCompleted: ({ addItemsToPurchaseOrder: items }) => {
      toast.success(
        `${items.length} Item${
          items.length !== 1 ? 's' : ''
        } added to Purchase Order`
      )
      onComplete?.(items)
    },
  })

  const columns = useMemo<MRT_ColumnDef<(typeof requestedItems)[0]>[]>(
    () => [
      {
        accessorKey: 'type',
        header: 'Type',
        size: 50,
        Cell: ({ row }) => (
          <>
            {row.original.type === 'SERVICE' ? (
              <Tooltip title="Service">
                <ConstructionRounded color="disabled" />
              </Tooltip>
            ) : (
              <Tooltip title="Part">
                <ShoppingCartRounded color="disabled" />
              </Tooltip>
            )}
          </>
        ),
      },
      {
        accessorFn: (row) => row.partNumber ?? row.ataCode,
        header: 'Code',
      },
      {
        accessorKey: 'description',
        header: 'Description',
      },
      {
        accessorKey: 'quantity',
        header: 'Quantity',
        size: 100,
      },
      {
        accessorFn: (row) => abbreviateFullName(row.requestedBy),
        header: 'Requested By',
      },
    ],
    []
  )

  const table = useMaterialReactTable({
    ...getDefaultMRTOptions(),
    columns,
    data: requestedItems,
    enableRowSelection: true,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: true,
    enableSorting: true,
    getRowId: (row) => row.id,
    muiTableBodyRowProps: ({ row }) => {
      return {
        onClick: (event) => {
          // Don't toggle if user is dragging to select text
          if (window.getSelection()?.toString()) {
            return
          }
          row.toggleSelected(!row.getIsSelected())
        },
      }
    },
  })

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <DrawerHeader title="Add Requested Items" onClose={handleClose} />
      <div className="h-full w-[800px] p-3">
        <MaterialReactTable table={table} />
      </div>
      <DrawerActions
        className="gap-1"
        leftActions={
          <Button variant="text" color="black" onClick={handleClose}>
            CANCEL
          </Button>
        }
        rightActions={
          <LoadingButton
            onClick={async () => {
              setLoading(true)
              await addItemsToPurchaseOrder({
                variables: {
                  purchaseOrderId,
                  ids: table
                    .getSelectedRowModel()
                    .rows.map((row) => row.original.id),
                },
                update: (
                  cache,
                  { data: { addItemsToPurchaseOrder: items } }
                ) => {
                  const {
                    purchaseOrder: { purchaseOrderItem: poItems },
                  } = cache.readQuery({
                    query: GET_PURCHASE_ORDER,
                    variables: { id: purchaseOrderId },
                  })

                  cache.writeQuery({
                    query: GET_PURCHASE_ORDER,
                    variables: { id: purchaseOrderId },
                    data: {
                      purchaseOrder: {
                        purchaseOrderItem: [...poItems, ...items],
                      },
                    },
                  })

                  // remove added items from the query to get all the Requested PO Items
                  const addedItemIds = new Set(items.map((item) => item.id))
                  const { purchaseOrderItems } = cache.readQuery({
                    query: GET_PURCHASE_ORDER_ITEMS,
                  })

                  cache.writeQuery({
                    query: GET_PURCHASE_ORDER_ITEMS,
                    data: {
                      purchaseOrderItems: purchaseOrderItems.filter(
                        (item) => !addedItemIds.has(item.id)
                      ),
                    },
                  })
                },
              })
              handleClose()
            }}
            variant="contained"
            loading={loading}
            disabled={table.getSelectedRowModel().rows.length === 0}
          >
            ADD SELECTED
          </LoadingButton>
        }
      />
    </Drawer>
  )
}

export default RequestedLineItemsPickerModal
