import React from 'react'

import { TableCell, TableRow, Skeleton } from '@mui/material'

interface TableRowSkeletonProps {
  rows?: number
  cols: number
}

const TableRowSkeleton: React.FC<TableRowSkeletonProps> = ({
  rows = 1,
  cols,
}) => {
  return [...Array(rows)].map((_, idx) => {
    return (
      <TableRow key={idx}>
        {[...Array(cols)].map((_, colIdx) => {
          return (
            <TableCell key={colIdx}>
              <Skeleton animation="wave" />
            </TableCell>
          )
        })}
      </TableRow>
    )
  })
}

export default TableRowSkeleton
