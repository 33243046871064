import useQuery from 'src/hooks/useQuery'
import { useOrgName } from 'src/hooks/useOrgName'
import { toast } from '@redwoodjs/web/toast'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import DropdownButton from 'src/components/MUI/DropdownButton'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import { getDefaultMRTOptions } from '../../../utils/getDefaultMRTOptions'
import {
  WORK_ORDER_AIRCRAFT,
  GET_WORK_ORDER_TASK_LIST,
  GET_WORK_ORDER_CUSTOM_TASK_LIST,
  REMOVE_CUSTOM_TASKS_FROM_WORK_ORDER,
  REMOVE_TASKS_FROM_WORK_ORDER,
  BULK_UPDATE_CUSTOM_WORK_ITEM,
  BULK_UPDATE_WORK_ITEM,
  ADD_TASKS_TO_WORK_ORDER,
  GET_WORK_ORDER_BASE_FIELDS,
  GET_WORK_ORDER_TASK_DETAILS_BY_MTX_ID,
  UPDATE_WORK_ITEM,
  UPDATE_CUSTOM_WORK_ITEM,
  CREATE_WORK_ORDER_PDF,
  DELETE_WORK_ORDER,
} from '../queries'
import TabbedTableLayout from 'src/components/TabbedTableLayout/TabbedTableLayout'
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMaterialReactTable,
} from 'material-react-table'
import React, { useEffect, useMemo, useState } from 'react'
import {
  CustomInternalWorkItem,
  InternalWorkItem,
  MaintenanceItem,
  User,
} from 'types/graphql'
import { abbreviateFullName } from 'src/utils/stringFormatters'
import { RootState } from 'src/store'
import { useSelector } from 'src/hooks/useSelector'
import IconButton from 'src/components/MUI/IconButton'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import SubdirectoryArrowRightRoundedIcon from '@mui/icons-material/SubdirectoryArrowRightRounded'
import { styled } from '@mui/material/styles'
import DueStatusChip from 'src/components/DueStatusChip/DueStatusChip'
import Button from 'src/components/MUI/Button'
import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import useModal from 'src/hooks/useModal'
import AddCustomTaskDrawer from './AddCustomTaskDrawer'
import { useMutation } from '@redwoodjs/web'
import MenuItem from '@mui/material/MenuItem'
import CascadingMenu, { MenuItemType } from 'src/components/CascadingMenu'
import CircularProgress from '@mui/material/CircularProgress'
import useGetUsers from 'src/hooks/requests/useGetUsers'
import WorkflowTasksBulkEditDrawer from 'src/components/WorkflowTasksBulkEditDrawer'
import PopoverTaskPicker from 'src/components/PopoverTaskPicker/PopoverTaskPicker'
import { useApolloClient, useLazyQuery } from '@apollo/client'
import BulkReadyForReviewReassignmentModal from 'src/components/BulkReadyForReviewReassignmentModal.tsx/BulkReadyForReviewReassignmentModal'
import { useAuth } from 'src/auth'
import WorkflowAddDiscrepancyDrawer from 'src/components/WorkflowAddDiscrepancyDrawer'
import dayjs from 'dayjs'
import { get } from 'lodash'
import useHasPermission from 'src/hooks/useHasPermission'
import { Permissions } from '@wingwork/common/src/constants/permissions'
import { InlineTextField } from 'src/components/InlineFields/InlineTextField'
import {
  centsToDollars,
  dollarsToCents,
  hoursToMinutes,
  minutesToHours,
} from 'src/utils/helpers'
import WorkOrderDetailsDrawer from './WorkOrderDetailsDrawer'
import DueListPickerV2 from 'src/components/DueListTable/PickerV2'
import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal'
import { navigate, routes } from '@redwoodjs/router'
import useWorkOrderVitals from 'src/hooks/useWorkOrderVitals'

const defaultTableOptions = getDefaultMRTOptions<InternalWorkItem>()
const statusTabSelector = (state: RootState) => state.compliance.statusTab

interface TableActionsProps {
  workOrderId: string
  selectedIds: string[]
  filteredData: InternalWorkItem[]
  aircraftId: string
  selectedDiscrepancyToUpdate: MaintenanceItem | null
  setSelectedDiscrepancyToUpdate: (discrepancy: MaintenanceItem | null) => void
  resetRowSelection: () => void
  onAddTasks: () => void
}

interface NavigationOptions {
  skipReview?: boolean
}

const TableActions = ({
  workOrderId,
  selectedIds,
  filteredData,
  aircraftId,
  selectedDiscrepancyToUpdate,
  setSelectedDiscrepancyToUpdate,
  resetRowSelection,
  onAddTasks,
}: TableActionsProps) => {
  const { currentUser } = useAuth()
  const { data: users, hasLoaded } = useGetUsers({
    variables: {
      filter: {
        roles: ['ADMIN', 'MECHANIC', 'DIRECTOR_OF_MAINTENANCE'],
      },
    },
  })
  const currentUserFromUser = useMemo(
    () => users?.users.find((user) => user.clerkId === currentUser?.providerId),
    [users, currentUser]
  )
  const client = useApolloClient()
  const canUpdateWorkOrder = useHasPermission(
    Permissions.workOrder.update,
    aircraftId
  )
  const canReadMtxItem = useHasPermission(
    Permissions.maintenanceItem.read,
    aircraftId
  )
  const [getWorkOrderTaskDetailsByMtxId] = useLazyQuery(
    GET_WORK_ORDER_TASK_DETAILS_BY_MTX_ID
  )
  const [removeTasksFromWorkOrder] = useMutation(REMOVE_TASKS_FROM_WORK_ORDER)
  const [removeCustomTasksFromWorkOrder] = useMutation(
    REMOVE_CUSTOM_TASKS_FROM_WORK_ORDER
  )
  const [bulkUpdateCustomWorkItem] = useMutation(BULK_UPDATE_CUSTOM_WORK_ITEM)
  const [bulkUpdateWorkItem] = useMutation(BULK_UPDATE_WORK_ITEM)
  const [createWorkOrderPDF] = useMutation(CREATE_WORK_ORDER_PDF)
  const { data: workOrderBaseFields } = useQuery(GET_WORK_ORDER_BASE_FIELDS, {
    variables: { id: workOrderId },
  })
  const aircraftUsageLogId =
    workOrderBaseFields?.internalWorkOrder?.aircraftUsageLogId

  const { handleOpen: handleOpenAddCustomTask } = useModal(
    'addCustomTaskDrawer'
  )
  const { handleOpen: handleOpenBulkEdit, handleClose: handleCloseBulkEdit } =
    useModal('workflowTasksBulkEditDrawer')
  const { handleOpen: handleOpenBulkReadyForReviewReassignmentModal } =
    useModal('bulkReadyForReviewReassignmentModal')
  const { handleOpen: handleOpenAddDiscrepancy } = useModal(
    'workflowAddDiscrepancyDrawer'
  )

  const workItemsInFilteredData: InternalWorkItem[] = []
  const customWorkItemsInFilteredData: CustomInternalWorkItem[] = []
  filteredData.forEach((task) => {
    if (task.isCustomWorkItem) {
      customWorkItemsInFilteredData.push(task)
    } else if (task.id !== 'DISCREPANCIES_ROOT_ROW') {
      workItemsInFilteredData.push(task)
    }
  })

  const unreviewableSelection = useMemo(() => {
    return selectedIds.filter((taskId) => {
      const task = filteredData.find((task) => {
        // Check if this is the direct task
        if (task.id === taskId) return true
        // Check in child work items
        return task.childWorkItems?.some((child) => child.id === taskId)
      })

      if (!task) return false

      // If this is a child task ID, find the actual child task
      const targetTask =
        task.id === taskId
          ? task
          : task.childWorkItems?.find((child) => child.id === taskId)

      return (
        targetTask &&
        (targetTask.status === 'REVIEW' ||
          targetTask.laborTransactions.some((lt) => lt.punchedOutAt === null))
      )
    })
  }, [selectedIds, filteredData])

  const reviewableSelection = useMemo(() => {
    return selectedIds.filter(
      (taskId) => !unreviewableSelection.includes(taskId)
    )
  }, [selectedIds, filteredData, unreviewableSelection])

  const unassignedSelectedTasks = useMemo(
    () =>
      filteredData.filter(
        (task) => reviewableSelection.includes(task.id) && !task.workedById
      ),
    [filteredData, reviewableSelection]
  )

  const selectedTasksAssignedToOthers = useMemo(
    () =>
      filteredData.filter(
        (task) =>
          task.workedById && task.workedBy?.id !== currentUserFromUser?.id
      ),
    [filteredData, currentUserFromUser]
  )

  const { selectedTaskIds, selectedCustomTaskIds } = useMemo(() => {
    const selectedTaskIds = []
    const selectedCustomTaskIds = []
    selectedIds.forEach((id) => {
      const task = filteredData.find((task) => task.id === id)
      if (task?.isCustomWorkItem) {
        selectedCustomTaskIds.push(id)
      } else {
        selectedTaskIds.push(id)
      }
    })
    return { selectedTaskIds, selectedCustomTaskIds }
  }, [selectedIds, filteredData])

  const onDeleteTasksClick = async () => {
    const selectedTaskIds = []
    const selectedCustomTaskIds = []
    selectedIds.forEach((id) => {
      const task = filteredData.find((task) => task.id === id)
      if (task?.isCustomWorkItem) {
        selectedCustomTaskIds.push(id)
      } else {
        selectedTaskIds.push(id)
      }
    })

    if (selectedTaskIds.length > 0) {
      await removeTasksFromWorkOrder({
        variables: {
          ids: selectedTaskIds,
        },
        update: (cache, data) => {
          for (const task of data.data.bulkDeleteInternalWorkItem) {
            cache.evict({ id: cache.identify(task) })
          }
        },
      })
    }

    if (selectedCustomTaskIds.length > 0) {
      await removeCustomTasksFromWorkOrder({
        variables: {
          ids: selectedCustomTaskIds,
        },
        update: (cache, data) => {
          for (const task of data.data.bulkDeleteCustomInternalWorkItem) {
            cache.evict({ id: cache.identify(task) })
          }
        },
      })
    }
    resetRowSelection()
  }

  const onAssignMechanic = async (id: string) => {
    const currentUserFromUser = users?.users.find((user) => user.id === id)
    const currentUserId = currentUserFromUser?.id || null

    if (selectedCustomTaskIds.length > 0) {
      await bulkUpdateCustomWorkItem({
        variables: {
          ids: selectedCustomTaskIds,
          input: {
            workedById: currentUserId,
          },
        },
      })
    }
    if (selectedTaskIds.length > 0) {
      await bulkUpdateWorkItem({
        variables: {
          ids: selectedTaskIds,
          input: {
            workedById: currentUserId,
          },
        },
      })
    }
    resetRowSelection()
  }

  const onBulkEditSubmit = async ({
    values,
    showOverrideOrAppendToggle,
    overrideOrAppend,
    canApplyToOthers,
    dirtyFields,
  }) => {
    if (selectedTaskIds.length > 0) {
      await bulkUpdateWorkItem({
        variables: {
          ids: selectedTaskIds,
          input: values,
          overrideOrAppend: {
            description: showOverrideOrAppendToggle.description
              ? overrideOrAppend.description
              : true,
            correctiveAction: showOverrideOrAppendToggle.correctiveAction
              ? overrideOrAppend.correctiveAction
              : true,
            taskNotes: showOverrideOrAppendToggle.taskNotes
              ? overrideOrAppend.taskNotes
              : true,
          },
          updateFields: {
            description:
              canApplyToOthers.description || dirtyFields.description,
            correctiveAction:
              canApplyToOthers.correctiveAction || dirtyFields.correctiveAction,
            taskNotes: canApplyToOthers.taskNotes || dirtyFields.taskNotes,
            workedById: canApplyToOthers.workedById || dirtyFields.workedById,
          },
        },
      })
    }
    if (selectedCustomTaskIds.length > 0) {
      await bulkUpdateCustomWorkItem({
        variables: {
          ids: selectedCustomTaskIds,
          input: values,
        },
      })
    }
    resetRowSelection()
    handleCloseBulkEdit()
  }

  const changeTaskStatus = async (
    ids: string[],
    status: 'REVIEW' | 'IN_PROGRESS'
  ) => {
    // Create a map for faster lookups
    const taskMap = new Map(filteredData.map((task) => [task.id, task]))

    // onlyParentsAndSoloChildren returns only the parent tasks and tasks that have no parent
    // Since the backend detects parent status updates and auto updates the children,
    // if the parent is being updated, omit any children from the update to optimize request count
    // if a child is being updated without a parent, we allow that update
    // Get parent maintenance item IDs in one pass
    const selectedParentMaintenanceItemIds = new Set(
      ids
        .map((id) => taskMap.get(id))
        .filter(
          (task) => task && !task.isCustomWorkItem && task.childWorkItems.length
        )
        .map((task) => task.maintenanceItem.id)
    )

    // Categorize IDs in a single pass
    const { selectedWorkItemOPSCIds, selectedCustomWorkItemOPSCIds } =
      ids.reduce(
        (acc, id) => {
          const task = taskMap.get(id)

          if (task?.isCustomWorkItem) {
            acc.selectedCustomWorkItemOPSCIds.push(id)
          } else if (task) {
            const parentId = task.maintenanceItem.parentId
            if (!parentId || !selectedParentMaintenanceItemIds.has(parentId)) {
              acc.selectedWorkItemOPSCIds.push(id)
            }
          }

          return acc
        },
        {
          selectedWorkItemOPSCIds: [] as string[],
          selectedCustomWorkItemOPSCIds: [] as string[],
        }
      )

    const workedByIdPayload =
      status === 'REVIEW'
        ? {
            workedById: currentUserFromUser?.id,
          }
        : {}

    if (selectedWorkItemOPSCIds.length) {
      await bulkUpdateWorkItem({
        variables: {
          ids: selectedWorkItemOPSCIds,
          workOrderId,
          input: {
            status,
            ...workedByIdPayload,
          },
        },
      })
    }
    if (selectedCustomWorkItemOPSCIds.length) {
      await bulkUpdateCustomWorkItem({
        variables: {
          ids: selectedCustomWorkItemOPSCIds,
          input: {
            status,
            ...workedByIdPayload,
          },
        },
      })
    }
    resetRowSelection()
  }

  const markBulkReadyForReview = async () => {
    if (reviewableSelection.length) {
      // This is where you mark items as ready for review
      await changeTaskStatus(reviewableSelection, 'REVIEW')
    } else {
      // This is where you mark items as in progress
      await changeTaskStatus(unreviewableSelection, 'IN_PROGRESS')
    }
  }

  const onMarkAsComplete = () => {
    if (
      selectedTasksAssignedToOthers.length ||
      unassignedSelectedTasks.length
    ) {
      handleOpenBulkReadyForReviewReassignmentModal({
        discriminator: 'bulkReadyForReviewReassignmentModal',
      })
      return
    }
    markBulkReadyForReview()
  }

  const printWorkOrder = async (aircraftId: string, workOrderId: string) => {
    const loadingToastId = toast.loading('Generating PDF...')

    try {
      const pdfResponse = await createWorkOrderPDF({
        variables: {
          aircraftId,
          workOrderId,
        },
      }).catch((e) => {
        throw e
      })

      if (!pdfResponse) {
        throw new Error('Error generating pdf')
      }

      const pdfUrl = pdfResponse.data.createWorkOrderPDF

      toast.loading('Preparing download...', { id: loadingToastId })
      const response = await fetch(pdfUrl)
      const pdfBlob = new Blob([await response.blob()], {
        type: 'application/pdf',
      })

      // Generate URL from downloaded PDF
      const pdfBlobUrl = URL.createObjectURL(pdfBlob)
      const link = document.createElement('a')
      link.href = pdfBlobUrl
      link.setAttribute('download', `WO-${dayjs().format('YYYY-MM-DD')}.pdf`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      setTimeout(() => URL.revokeObjectURL(pdfBlobUrl), 100)
      toast.success('Downloading PDF...', { id: loadingToastId })
    } catch (e) {
      let message = 'Error generating pdf.'
      if (e.message.toLowerCase().includes('Something went wrong')) {
        message += `\n Please contact support.`
      } else {
        message += `\n${e.message}`
      }
      toast.error(message, { id: loadingToastId })
    }
  }

  const moreActionMenuItems: MenuItemType[] = [
    {
      label:
        unreviewableSelection.length && !reviewableSelection.length
          ? 'Mark as In Progress'
          : `Mark as Complete (${selectedIds.length})`,
      onClick: () => {
        onMarkAsComplete()
      },
      disabled: selectedIds.length === 0 || !aircraftUsageLogId,
      disabledTooltip: !aircraftUsageLogId
        ? 'Apply times first'
        : 'No tasks selected',
      locked: !canUpdateWorkOrder,
      lockedTooltip: 'You do not have permission to update this work order',
    },
    {
      label: 'Bulk Edit',
      onClick: () => {
        handleOpenBulkEdit()
      },
      disabled: selectedIds.length <= 1,
      locked: !canUpdateWorkOrder,
      lockedTooltip: 'You do not have permission to update this work order',
    },
    {
      label: 'Assign Mechanic',
      disabled: selectedIds.length === 0,
      locked: !canUpdateWorkOrder,
      lockedTooltip: 'You do not have permission to update this work order',
      renderSubmenu: ({ onClose }) => {
        return !hasLoaded ? (
          <MenuItem disabled>
            <CircularProgress size={20} sx={{ mr: 1 }} />
            Loading mechanics...
          </MenuItem>
        ) : users?.users.length === 0 ? (
          <MenuItem disabled>No mechanics found</MenuItem>
        ) : (
          users?.users.map((user) => (
            <MenuItem
              key={user.id}
              onClick={() => {
                onAssignMechanic(user.id)
                onClose()
              }}
            >
              {user.firstName} {user.lastName}
            </MenuItem>
          ))
        )
      },
    },
    {
      label: 'Delete item',
      onClick: onDeleteTasksClick,
      locked: !canUpdateWorkOrder,
      lockedTooltip: 'You do not have permission to update this work order',
      disabled: selectedIds.length === 0,
    },
    {
      label: 'Print Tasks in Work Order',
      onClick: () => {
        printWorkOrder(aircraftId, workOrderId)
      },
      locked: !canReadMtxItem,
      lockedTooltip: 'You do not have permission to print work orders',
    },
  ]

  return (
    <>
      {/* <Button variant="outlined" color="base">
        Filter By
      </Button> */}
      <CascadingMenu
        buttonText="More Actions"
        buttonProps={{
          variant: 'outlined',
          color: 'base',
        }}
        menuItems={moreActionMenuItems}
      />
      <DropdownButton
        icon={<AddRoundedIcon />}
        variant="outlined"
        options={[
          {
            label: 'Task',
            onClick: () => {
              onAddTasks()
            },
          },
          {
            label: 'Custom Task',
            onClick: () => {
              handleOpenAddCustomTask()
            },
          },
          {
            label: 'Discrepancy',
            onClick: () => {
              handleOpenAddDiscrepancy()
            },
          },
        ]}
      >
        Add
      </DropdownButton>
      <AddCustomTaskDrawer workOrderId={workOrderId} />
      <WorkflowTasksBulkEditDrawer
        isWorkOrder
        selectedTaskIds={selectedTaskIds}
        selectedCustomTaskIds={selectedCustomTaskIds}
        filteredTasks={filteredData}
        onSubmit={onBulkEditSubmit}
      />
      <BulkReadyForReviewReassignmentModal
        onConfirm={markBulkReadyForReview}
        selectedTasksAssignedToOthers={selectedTasksAssignedToOthers as any[]}
        unassignedSelectedTasks={unassignedSelectedTasks as any[]}
      />
      <WorkflowAddDiscrepancyDrawer
        isWorkOrder={true}
        workPackageId={workOrderId}
        aircraftId={aircraftId}
        workPackageTasks={workItemsInFilteredData}
        aircraftUsageLogId={aircraftUsageLogId}
        selectedDiscrepancyToUpdate={selectedDiscrepancyToUpdate}
        setSelectedDiscrepancyToUpdate={setSelectedDiscrepancyToUpdate}
        onCreateSuccess={async (
          discrepancyItem: MaintenanceItem,
          attachedTask: InternalWorkItem,
          isStandaloneDiscrepancy: boolean
        ) => {
          const cacheResult = client.cache.readQuery({
            query: GET_WORK_ORDER_TASK_LIST,
            variables: { id: workOrderId },
          })
          let tasksInCache = JSON.parse(
            JSON.stringify(cacheResult?.internalWorkItems || [])
          )
          if (isStandaloneDiscrepancy) {
            const { data } = await getWorkOrderTaskDetailsByMtxId({
              variables: { maintenanceItemIds: [discrepancyItem.id] },
            })
            tasksInCache = [...data.internalWorkItems, ...tasksInCache]
          } else {
            const targetTaskInCache = tasksInCache.find(
              (task) => task.id === attachedTask.id
            )
            targetTaskInCache.discrepancyItems = [
              ...(targetTaskInCache.discrepancyItems || []),
              discrepancyItem,
            ]
          }
          client.cache.writeQuery({
            query: GET_WORK_ORDER_TASK_LIST,
            variables: { id: workOrderId },
            data: { internalWorkItems: tasksInCache },
          })
        }}
      />
    </>
  )
}

const WorkOrderTaskList = ({ id }) => {
  const {
    data: taskData,
    loading: tasksLoading,
    refetch: refetchWorkOrderTaskList,
  } = useQuery(GET_WORK_ORDER_TASK_LIST, {
    variables: { id },
    fetchPolicy: 'cache-first',
  })

  const { data: customTaskData, loading: customTasksLoading } = useQuery(
    GET_WORK_ORDER_CUSTOM_TASK_LIST,
    {
      variables: { id },
      fetchPolicy: 'cache-first',
    }
  )

  const { data: aircraftData, loading: aircraftLoading } = useQuery(
    WORK_ORDER_AIRCRAFT,
    {
      variables: { id },
      fetchPolicy: 'cache-first',
    }
  )

  const loading = tasksLoading || customTasksLoading || aircraftLoading

  const [deleteWorkOrder] = useMutation(DELETE_WORK_ORDER)

  const [updateWorkItem] = useMutation(UPDATE_WORK_ITEM, {
    refetchQueries: [
      {
        query: GET_WORK_ORDER_BASE_FIELDS,
        variables: { id },
      },
    ],
  })

  const [updateCustomWorkItem] = useMutation(UPDATE_CUSTOM_WORK_ITEM, {
    refetchQueries: [
      {
        query: GET_WORK_ORDER_BASE_FIELDS,
        variables: { id },
      },
    ],
  })
  const [addTasksToWorkOrder] = useMutation(ADD_TASKS_TO_WORK_ORDER)

  const { handleOpen: handleOpenAddDiscrepancy } = useModal(
    'workflowAddDiscrepancyDrawer'
  )

  const { handleOpen: handleOpenWorkOrderDetailDrawer } = useModal(
    'workOrderDetailDrawer'
  )

  const { handleOpen: handleOpenEmptyWorkOrder } = useModal('confirmationModal')
  const orgName = useOrgName()
  const {
    workOrderState: { detailDrawerTaskId, detailDrawerCustomTaskId },
    setWorkOrderDetailDrawerTaskId,
    setWorkOrderDetailDrawerCustomTaskId,
  } = useWorkOrderVitals()
  const aircraft = aircraftData?.internalWorkOrder?.aircraft
  const statusTab = useSelector(statusTabSelector)
  const [selectedComponentId, setSelectedComponentId] = React.useState(null)
  const [showDueListTable, setShowDueListTable] = useState(false)
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({})
  const [searchValue, setSearchValue] = useState('')
  const [selectedDiscrepancyToUpdate, setSelectedDiscrepancyToUpdate] =
    useState<MaintenanceItem | null>(null)
  const selectedIds = useMemo(() => {
    return Object.keys(rowSelection)
  }, [rowSelection])

  useEffect(() => {
    if (
      taskData?.internalWorkItems?.length === 0 &&
      customTaskData?.customInternalWorkItems?.length === 0 &&
      !loading
    ) {
      handleOpenEmptyWorkOrder()
    }
  }, [taskData, loading, customTaskData])

  const { filteredData, allPartRelatedTasks, badgeCounts } = useMemo(() => {
    // Return early if either query hasn't completed yet
    if (
      !taskData?.internalWorkItems ||
      !customTaskData?.customInternalWorkItems
    ) {
      return {
        filteredData: [],
        allPartRelatedTasks: [],
        badgeCounts: {
          All: 0,
          'In Progress': 0,
          Complete: 0,
        },
      }
    }

    const allPartRelatedTasks = taskData.internalWorkItems.filter(
      (task) => task.maintenanceItem.componentID !== null
    )

    // Combine all tasks before any status filtering
    let allData = [
      ...taskData.internalWorkItems,
      ...customTaskData.customInternalWorkItems,
    ]

    // Apply search filter to all data
    if (searchValue) {
      allData = allData.filter((task) => {
        const words = searchValue.split(' ')
        const fuzzySearchFields = [
          'title',
          'ataCode',
          'manufactureCode',
          'ataManufactureCode',
        ]
        const result = words.every((word) => {
          if (task?.isCustomWorkItem) {
            return task.title.toLowerCase().includes(word.toLocaleLowerCase())
          } else {
            return fuzzySearchFields.some((field) => {
              return get(task, ['maintenanceItem', field], '')
                .toLowerCase()
                .includes(word.toLocaleLowerCase())
            })
          }
        })
        if (!result) {
          return false
        }
        return true
      })
    }

    // Calculate badge counts using the full filtered dataset
    const badgeCounts = {
      All: allData.length,
      'In Progress': allData.filter(
        (activity) => activity.status === 'IN_PROGRESS'
      ).length,
      Complete: allData.filter((activity) => activity.status === 'REVIEW')
        .length,
    }

    // Apply status tab filter
    let filteredData = []
    switch (statusTab) {
      case 'In Progress':
        filteredData = allData.filter(
          (activity) => activity.status === 'IN_PROGRESS'
        )
        break
      case 'Complete':
        filteredData = allData.filter(
          (activity) => activity.status === 'REVIEW'
        )
        break
      default:
        filteredData = allData
        break
    }

    const discrepancyItems = []
    for (const task of filteredData) {
      if (!task?.isCustomWorkItem && task?.discrepancyItems?.length > 0) {
        discrepancyItems.push(...task.discrepancyItems)
      }
    }

    let finalFilteredData = []
    if (discrepancyItems.length > 0) {
      // We will add a fake row to the table to indicate that there are discrepancies present
      // under this row, we will show the discrepancies in the UI
      finalFilteredData = [
        {
          id: 'DISCREPANCIES_ROOT_ROW',
          title: 'Task Discrepancies',
          discrepancyItems,
        },
        ...filteredData,
      ]
    } else {
      finalFilteredData = filteredData
    }

    return {
      filteredData: finalFilteredData,
      allPartRelatedTasks,
      badgeCounts,
    }
  }, [
    taskData?.internalWorkItems,
    customTaskData?.customInternalWorkItems,
    statusTab,
    searchValue,
  ])

  const resetRowSelection = () => {
    setRowSelection({})
  }

  const getFlattenedTasks = (tasks: any[]): any[] => {
    return tasks.reduce((flat: any[], task) => {
      if (task.id === 'DISCREPANCIES_ROOT_ROW') return flat

      // Add the parent task
      flat.push(task)

      // Add child tasks if they exist
      if (task.childWorkItems?.length) {
        flat.push(...task.childWorkItems)
      }

      return flat
    }, [])
  }

  const flattenedTasks = useMemo(() => {
    // used to navigate between tasks inside the task details drawer
    return getFlattenedTasks(filteredData)
  }, [filteredData])

  const {
    hasNextTask,
    hasPreviousTask,
    switchToPreviousTask,
    switchToNextTask,
  } = useMemo(() => {
    if (
      (!detailDrawerTaskId && !detailDrawerCustomTaskId) ||
      (detailDrawerTaskId && detailDrawerCustomTaskId)
    ) {
      return {
        hasNextTask: false,
        hasPreviousTask: false,
        switchToPreviousTask: () => {},
        switchToNextTask: () => {},
      }
    }
    const clickedTaskIdx = flattenedTasks.findIndex(
      (task) =>
        task.id === detailDrawerTaskId || task.id === detailDrawerCustomTaskId
    )

    const findPreviousTask = (
      startIdx: number,
      { skipReview = false }: NavigationOptions = {}
    ) => {
      if (!skipReview) {
        return flattenedTasks[startIdx - 1]?.id
      }

      // First try searching backwards from current position
      for (let i = startIdx - 1; i >= 0; i--) {
        const task = flattenedTasks[i]
        if (
          task &&
          task.id !== 'DISCREPANCIES_ROOT_ROW' &&
          task.status !== 'REVIEW'
        ) {
          return task.id
        }
      }

      // If not found and we're allowing wrap-around, search from end
      if (skipReview) {
        for (let i = flattenedTasks.length - 1; i > startIdx; i--) {
          const task = flattenedTasks[i]
          if (
            task &&
            task.id !== 'DISCREPANCIES_ROOT_ROW' &&
            task.status !== 'REVIEW'
          ) {
            return task.id
          }
        }
      }
      return null
    }

    const findNextTask = (
      startIdx: number,
      { skipReview = false }: NavigationOptions = {}
    ) => {
      if (!skipReview) {
        return flattenedTasks[startIdx + 1]?.id
      }

      // First try searching forwards from current position
      for (let i = startIdx + 1; i < flattenedTasks.length; i++) {
        const task = flattenedTasks[i]
        if (
          task &&
          task.id !== 'DISCREPANCIES_ROOT_ROW' &&
          task.status !== 'REVIEW'
        ) {
          return task.id
        }
      }

      // If not found and we're allowing wrap-around, search from beginning
      if (skipReview) {
        for (let i = 0; i < startIdx; i++) {
          const task = flattenedTasks[i]
          if (
            task &&
            task.id !== 'DISCREPANCIES_ROOT_ROW' &&
            task.status !== 'REVIEW'
          ) {
            return task.id
          }
        }
      }
      return null
    }

    const switchToPreviousTask = (options: NavigationOptions = {}) => {
      const previousTaskId = findPreviousTask(clickedTaskIdx, options)
      if (previousTaskId) {
        const targetTask = flattenedTasks.find(
          (task) => task.id === previousTaskId
        )
        if (targetTask?.isCustomWorkItem) {
          setWorkOrderDetailDrawerCustomTaskId(targetTask.id)
        } else {
          setWorkOrderDetailDrawerTaskId(targetTask.id)
        }
      }
    }

    const switchToNextTask = (options: NavigationOptions = {}) => {
      const nextTaskId = findNextTask(clickedTaskIdx, options)
      if (nextTaskId) {
        const targetTask = flattenedTasks.find((task) => task.id === nextTaskId)
        if (targetTask?.isCustomWorkItem) {
          setWorkOrderDetailDrawerCustomTaskId(targetTask.id)
        } else {
          setWorkOrderDetailDrawerTaskId(targetTask.id)
        }
      }
    }

    const hasPreviousTask = !!findPreviousTask(clickedTaskIdx)
    const hasNextTask = !!findNextTask(clickedTaskIdx)

    return {
      switchToPreviousTask,
      switchToNextTask,
      hasNextTask,
      hasPreviousTask,
    }
  }, [detailDrawerTaskId, detailDrawerCustomTaskId, flattenedTasks])

  const onAddTask = async (maintenanceItemIds: string[]) => {
    await addTasksToWorkOrder({
      variables: {
        workOrderId: id,
        ids: maintenanceItemIds,
      },
      onCompleted: () => {
        toast.success('Task added successfully')
      },
      onError: () => {
        toast.error('Failed to add task')
      },
      update: (cache, { data: { addMaintenanceItemsToInternalWorkOrder } }) => {
        // Basically addMaintenanceItemsToInternalWorkOrder is designed
        // to return the entire work order with the new task added
        // So we can just update the cache with the new work order
        try {
          cache.writeQuery({
            query: GET_WORK_ORDER_TASK_LIST,
            variables: { id },
            data: {
              internalWorkItems:
                addMaintenanceItemsToInternalWorkOrder.workItems,
            },
          })
        } catch (_) {
          // Refetch on cache write error
          refetchWorkOrderTaskList({
            id,
          })
        }
      },
    })
  }

  const onAddExistingTaskClick = (componentID = null) => {
    setShowDueListTable(true)
    setSelectedComponentId(componentID)
  }

  const columns = useMemo<
    MRT_ColumnDef<InternalWorkItem | MaintenanceItem | CustomInternalWorkItem>[]
  >(
    () => [
      {
        accessorKey: 'ataCode',
        header: 'ATA Code',
        Cell: ({ row }) => {
          if (row.original.id === 'DISCREPANCIES_ROOT_ROW') return null
          const isDiscrepancyItem = row.original.discrepancyStatus ?? false
          if (isDiscrepancyItem) {
            return <Typography variant="caption">N/A</Typography>
          }
          const isCustomWorkItem = row.original.isCustomWorkItem
          if (isCustomWorkItem) {
            return <Typography variant="caption">N/A</Typography>
          }
          return (
            <span className="font-bold">
              {row.original.maintenanceItem?.ataCode}{' '}
              {row.original.maintenanceItem?.manufactureCode}
            </span>
          )
        },
      },
      {
        id: 'expand',
        header: '',
        size: 50,
        Cell: ({ row }) => {
          if (row.depth > 0) {
            return (
              <IconButton size="small" disabled>
                <SubdirectoryArrowRightRoundedIcon />
              </IconButton>
            )
          }

          if (!row.getCanExpand()) {
            return null
          }

          return (
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                row.getToggleExpandedHandler()()
              }}
              size="small"
              tooltip={
                row.getIsExpanded() ? 'Collapse subtasks' : 'Expand subtasks'
              }
            >
              {row.getIsExpanded() ? (
                <ExpandMoreRoundedIcon />
              ) : (
                <ChevronRightRoundedIcon />
              )}
            </IconButton>
          )
        },
      },
      {
        accessorKey: 'maintenanceItem.title',
        header: 'Task',
        size: 600,
        Cell: ({ cell, row }) => {
          if (row.original.id === 'DISCREPANCIES_ROOT_ROW') {
            return <span>{row.original.title}</span>
          }
          const isDiscrepancyItem = row.original.discrepancyStatus ?? false
          if (isDiscrepancyItem) {
            return (
              <span>
                <span className="italic">{`[Discrepancy]  `}</span>
                {row.original.title}
              </span>
            )
          }
          const isCustomWorkItem = row.original.isCustomWorkItem
          if (isCustomWorkItem) {
            return <span className="font-bold">{row.original.title}</span>
          }
          return (
            <div className="group relative max-w-[600px] overflow-hidden truncate whitespace-nowrap font-bold">
              {cell.getValue()}
              <span className="absolute left-0 top-full mt-1 hidden w-auto rounded bg-gray-800 px-2 py-1 text-white group-hover:block">
                {cell.getValue()}
              </span>
            </div>
          )
        },
      },
      {
        header: 'Cost',
        accessorKey: 'estimatedRate',
        size: 100,
        Cell: ({ row }) => {
          if (
            !row.original.__typename ||
            row.original.__typename === 'MaintenanceItem'
          ) {
            return null
          }

          return (
            <div onClick={(e) => e.stopPropagation()}>
              <InlineTextField
                initialValue={centsToDollars(row.original.estimatedRate)}
                name={`${row.original.id}-estimatedRate`}
                onSubmit={async (value) => {
                  if (row.original.__typename === 'InternalWorkItem') {
                    await updateWorkItem({
                      variables: {
                        id: row.original.id,
                        input: {
                          estimatedRate: dollarsToCents(value),
                          rate: dollarsToCents(value),
                        },
                      },
                    })
                  } else if (
                    row.original.__typename === 'CustomInternalWorkItem'
                  ) {
                    await updateCustomWorkItem({
                      variables: {
                        id: row.original.id,
                        input: {
                          estimatedRate: dollarsToCents(value),
                          rate: dollarsToCents(value),
                        },
                      },
                    })
                  }
                }}
              />
            </div>
          )
        },
      },
      {
        header: 'Est. Hours',
        accessorKey: 'estimatedWorkMinutes',
        size: 100,
        Cell: ({ row }) => {
          if (
            !row.original.__typename ||
            row.original.__typename === 'MaintenanceItem'
          ) {
            return null
          }
          return (
            <div onClick={(e) => e.stopPropagation()}>
              <InlineTextField
                name={`${row.original.id}-estimatedWorkMinutes`}
                initialValue={minutesToHours(row.original.estimatedWorkMinutes)}
                onSubmit={async (value) => {
                  if (row.original.__typename === 'InternalWorkItem') {
                    await updateWorkItem({
                      variables: {
                        id: row.original.id,
                        input: {
                          estimatedWorkMinutes: hoursToMinutes(
                            parseFloat(value)
                          ),
                        },
                      },
                    })
                  } else if (
                    row.original.__typename === 'CustomInternalWorkItem'
                  ) {
                    await updateCustomWorkItem({
                      variables: {
                        id: row.original.id,
                        input: {
                          estimatedWorkMinutes: hoursToMinutes(
                            parseFloat(value)
                          ),
                        },
                      },
                    })
                  }
                }}
              />
            </div>
          )
        },
      },
      {
        accessorKey: 'maintenanceItem.trackedByComponent.name',
        header: 'Tracked By',
        size: 100,
        Cell: ({ row }) => {
          if (row.original.id === 'DISCREPANCIES_ROOT_ROW') return null
          const isDiscrepancyItem = row.original.discrepancyStatus ?? false
          if (isDiscrepancyItem) {
            return (
              <Chip
                label={row.original.trackedByComponent.name}
                color="default"
                variant="outlined"
                size="small"
              />
            )
          }
          const isCustomWorkItem = row.original.isCustomWorkItem
          return (
            <Chip
              label={
                isCustomWorkItem
                  ? 'Custom'
                  : row.original.maintenanceItem.trackedByComponent.name
              }
              color={isCustomWorkItem ? 'info' : 'default'}
              size="small"
            />
          )
        },
      },
      {
        accessorKey: 'calculatedNextDueAt',
        header: 'Remaining',
        size: 120,
        Cell: ({ row }) => {
          if (row.original.id === 'DISCREPANCIES_ROOT_ROW') return null
          const isDiscrepancyItem = row.original.discrepancyStatus ?? false
          if (isDiscrepancyItem) {
            const nextDueValue: any =
              row.original.maintenanceNextDue[0]?.nextDueValue ?? {}
            if (nextDueValue?.date) {
              return (
                <Tooltip title={`Next Due`}>
                  <Typography>
                    {dayjs(nextDueValue.date).format('MM/DD/YYYY')}
                  </Typography>
                </Tooltip>
              )
            }
            return <Typography variant="caption">N/A</Typography>
          }
          const isCustomWorkItem = row.original.isCustomWorkItem
          if (isCustomWorkItem) {
            return <Typography variant="caption">N/A</Typography>
          }
          return (
            <DueStatusChip
              maintenanceItem={row.original.maintenanceItem}
              aircraft={aircraft}
            />
          )
        },
      },
      {
        header: 'Related',
        accessorKey: 'related',
        Cell: ({ row }) => {
          if (row.original.id === 'DISCREPANCIES_ROOT_ROW') return null
          const isDiscrepancyItem = row.original.discrepancyStatus ?? false
          if (isDiscrepancyItem) {
            return <Typography variant="caption">N/A</Typography>
          }
          const isCustomWorkItem = row.original.isCustomWorkItem
          if (isCustomWorkItem) {
            return <Typography variant="caption">N/A</Typography>
          }
          const maintenanceItem = row.original.maintenanceItem
          const relatedIds = maintenanceItem?.relatedMaintenanceItemIds || []

          // Don't show anything if no componentID or no related items
          if (!maintenanceItem?.componentID || relatedIds.length === 0)
            return null

          const totalCount = relatedIds.length + 1 // +1 for current task
          const selectedCount = relatedIds.reduce(
            (count, id) =>
              count +
              (allPartRelatedTasks.some(
                (task) => task.maintenanceItem.id === id
              )
                ? 1
                : 0),
            1 // Start at 1 to include current task
          )

          const isComplete = selectedCount === totalCount

          return (
            <Tooltip
              title={
                isComplete
                  ? 'All related tasks are included in compliance'
                  : 'Some related tasks are missing in compliance'
              }
            >
              <Chip
                label={`Part (${selectedCount}/${totalCount})`}
                variant="outlined"
                color={isComplete ? 'success' : 'warning'}
                size="small"
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  onAddExistingTaskClick(maintenanceItem?.componentID)
                }}
              />
            </Tooltip>
          )
        },
      },
      {
        header: 'Mechanic',
        Cell: ({ row }) => {
          if (row.original.id === 'DISCREPANCIES_ROOT_ROW') return null
          const isDiscrepancyItem = row.original.discrepancyStatus ?? false
          if (isDiscrepancyItem) {
            const targetWorkItem = filteredData.find(
              (item) => item.id === row.original.discrepancyWorkItemId
            )
            return abbreviateFullName(targetWorkItem?.workedBy)
          }
          return abbreviateFullName(row.original.workedBy)
        },
      },
      {
        id: 'status',
        header: '',
        size: 50,
        Cell: ({ row }) => {
          if (row.original.id === 'DISCREPANCIES_ROOT_ROW') return null
          const isDiscrepancyItem = row.original.discrepancyStatus ?? false
          if (isDiscrepancyItem) {
            const discrepancyStatus = row.original.discrepancyStatus
            if (
              discrepancyStatus === 'CLOSED' ||
              discrepancyStatus === 'DEFERRED'
            ) {
              return (
                <Tooltip title={`Discrepancy is ${discrepancyStatus}`}>
                  <CheckCircleOutlineRoundedIcon color="success" />
                </Tooltip>
              )
            }
            return (
              <Tooltip title="Discrepancy is OPEN">
                <ErrorOutlineRoundedIcon color="warning" />
              </Tooltip>
            )
          }
          return row.original.status === 'REVIEW' ? (
            <CheckCircleOutlineRoundedIcon color="success" />
          ) : (
            <ErrorOutlineRoundedIcon color="warning" />
          )
        },
      },
    ],
    [aircraft, allPartRelatedTasks]
  )

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    columns,
    enableStickyHeader: true,
    data: filteredData,
    rowCount: filteredData.length,
    enableTopToolbar: false,
    enableExpanding: true,
    enablePagination: false,
    enableRowSelection: (row) =>
      row.original.id !== 'DISCREPANCIES_ROOT_ROW' &&
      !(row.original?.discrepancyStatus ?? false),
    onRowSelectionChange: setRowSelection,
    getRowId: (originalRow) => originalRow.id,
    enableBottomToolbar: false,
    getSubRows: (row) => {
      if (row.id === 'DISCREPANCIES_ROOT_ROW') {
        return row.discrepancyItems
      }
      return row.childWorkItems
    },
    initialState: {
      showGlobalFilter: true,
      density: 'compact',

      columnVisibility: {
        'mrt-row-expand': false,
      },
    },
    state: {
      isLoading: loading,
      rowSelection,
      columnPinning: {
        right: ['status'],
        left: ['mrt-row-select'],
      },
    },
    muiTableBodyRowProps: ({ row }: { row: any }) => {
      return {
        onClick: (event) => {
          if (row.id === 'DISCREPANCIES_ROOT_ROW') {
            return
          }
          const isDiscrepancyItem = row.original.discrepancyStatus ?? false
          if (isDiscrepancyItem) {
            setSelectedDiscrepancyToUpdate(row.original)
            handleOpenAddDiscrepancy()
            return
          }
          const isCustomWorkItem = row.original.isCustomWorkItem
          if (isCustomWorkItem) {
            setWorkOrderDetailDrawerCustomTaskId(row.original.id)
          } else {
            setWorkOrderDetailDrawerTaskId(row.original.id)
          }
          handleOpenWorkOrderDetailDrawer()
        },
        sx: {
          cursor: 'pointer', //you might want to change the cursor too when adding an onClick
          ...(row.getParentRow() && {
            backgroundColor: '#f4f1eb',
          }),
          ...(row.getIsExpanded() && { backgroundColor: '#dce7f0' }),
        },
      }
    },
  })

  return (
    <TabbedTableLayout
      sections={['All', 'In Progress', 'Complete']}
      badgeCounts={badgeCounts}
      tabSelectorFn={statusTabSelector}
      onSearchChange={setSearchValue}
      searchValue={searchValue}
      showSearchbar={!showDueListTable}
      actions={
        showDueListTable ? (
          <></>
        ) : (
          <TableActions
            workOrderId={id}
            selectedIds={selectedIds}
            filteredData={filteredData}
            aircraftId={aircraft?.id}
            resetRowSelection={resetRowSelection}
            selectedDiscrepancyToUpdate={selectedDiscrepancyToUpdate}
            setSelectedDiscrepancyToUpdate={setSelectedDiscrepancyToUpdate}
            onAddTasks={() => onAddExistingTaskClick()}
          />
        )
      }
    >
      {showDueListTable ? (
        <div className="mt-[-32px]">
          <DueListPickerV2
            onlyDueItems={false}
            tableHeightSx="calc(100vh - 460px)"
            aircraftIds={[aircraft?.id]}
            componentID={selectedComponentId}
            onDismiss={() => setShowDueListTable(false)}
            onAdd={(tasks) => {
              if (tasks.length === 0) {
                setShowDueListTable(false)
                setSelectedComponentId(null)
                return
              }
              setShowDueListTable(false)
              setSelectedComponentId(null)
              onAddTask(tasks)
            }}
          />
        </div>
      ) : (
        <MaterialReactTable table={table} />
      )}
      <WorkOrderDetailsDrawer
        workOrderId={id}
        switchToPreviousTask={switchToPreviousTask}
        switchToNextTask={switchToNextTask}
        hasNextTask={hasNextTask}
        hasPreviousTask={hasPreviousTask}
      />
      <ConfirmationModal
        title="Empty Work Order"
        richMessage={`Your work order is currently empty. Would you like to keep it or discard it?`}
        confirmText="Discard"
        confirmNote="Your work order will be discarded and you will be redirected to the due list."
        denyText="Keep It"
        onConfirm={() => {
          deleteWorkOrder({
            variables: {
              id,
            },
            onCompleted: () => {
              navigate(routes.dueList({ orgName }))
            },
            onError: () => {
              toast.error('Failed to delete work order: ')
            },
          })
        }}
      />
    </TabbedTableLayout>
  )
}

export default WorkOrderTaskList
