import { useMemo, useEffect } from 'react'

import { useApolloClient } from '@apollo/client'

import useMaintenanceItemTable, {
  TableVariant,
} from './useMaintenanceItemTable'
import useAircraftList from './requests/useAircraftRecords'
import { useOrgName } from './useOrgName'

const useGetDueListSelectedAircraft = (storeType: TableVariant): string => {
  const client = useApolloClient()
  const orgName = useOrgName()
  const {
    loadAircraftList,
    loading: isAircraftListLoading,
    hasLoaded: hasAircraftListLoaded,
    aircrafts,
  } = useAircraftList()

  const { duelistFiltersData, selectedItemIds } =
    useMaintenanceItemTable(storeType)

  useEffect(() => {
    if (!hasAircraftListLoaded) {
      loadAircraftList({ orgSlug: orgName })
    }
  }, [])

  return useMemo(() => {
    const aircraftIds = Object.keys(duelistFiltersData.aircrafts)
    if (aircraftIds.length === 1) return aircraftIds[0]
    if (Object.keys(aircrafts).length === 1) return Object.keys(aircrafts)[0]

    if (!selectedItemIds.length) return ''
    const fragments = selectedItemIds.map((id) =>
      client.readFragment({
        id: `MaintenanceItem:${id}`,
        fragment: gql`
          fragment aircraftOnMaintenanceItemFragmentForHook on MaintenanceItem {
            id
            aircraftId
          }
        `,
      })
    )

    const aircraftIdsFromMaintenanceItems = Array.from(
      new Set(fragments.flatMap((fragment) => fragment['aircraftId']))
    )
    if (aircraftIdsFromMaintenanceItems.length === 1)
      return aircraftIdsFromMaintenanceItems[0]
    return ''
  }, [client, selectedItemIds, duelistFiltersData, aircrafts])
}

export default useGetDueListSelectedAircraft
