import React, { useState, useRef, useEffect } from 'react'
import {
  InlineFieldWrapper,
  InlineFieldWrapperProps,
} from '../InlineFieldWrapper'
import clsx from 'clsx'
import { Popover, MenuItem } from '@mui/material'

interface InlineSelectInputProps
  extends Omit<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    'onChange' | 'onBlur'
  > {
  /** Current value */
  value: string
  /** Available options */
  options: Array<{ value: string; label: string }>
  /** Change handler */
  onChange: (value: string) => void
  /** Blur handler */
  onBlur?: (value: string) => void
  /** Whether the field has an error */
  error?: string | null
  /** Whether the field is valid */
  isValid?: boolean
  /** Whether the field is currently submitting */
  isSubmitting?: boolean
  /** Whether the field is currently validating */
  isValidating?: boolean
  /** Optional callback when dropdown opens */
  onOpen?: () => void
  /** Placeholder text */
  placeholder?: string
}

export const InlineSelectInput: React.FC<InlineSelectInputProps> = ({
  value,
  options,
  onChange,
  onBlur,
  error,
  isValid,
  isSubmitting,
  isValidating,
  placeholder,
  className = '',
  disabled = false,
  onOpen,
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const handleClick = () => {
    if (!disabled && !isSubmitting) {
      setAnchorEl(containerRef.current)
      onOpen?.()
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
    setIsFocused(false)
    onBlur?.(value)
  }

  const handleOptionSelect = (optionValue: string) => {
    onChange(optionValue)
    handleClose()
  }

  const selectedOption = options.find((option) => option.value === value)

  return (
    <>
      <div
        ref={containerRef}
        className={clsx(
          'flex h-[28px] cursor-pointer items-center rounded-md border border-solid border-transparent px-1 transition-colors duration-0',
          {
            'bg-sys-lightgrey': !error && !isValid && !disabled,
            'hover:border-black':
              !disabled && !error && !isFocused && !isSubmitting,
            'border-red-500 bg-red-50': error,
            'cursor-pointer': !disabled && !isSubmitting,
            'cursor-not-allowed': disabled || isSubmitting,
          },
          className
        )}
        onClick={handleClick}
      >
        <span
          className={clsx('truncate', {
            'opacity-50': disabled || isSubmitting,
          })}
        >
          {selectedOption?.label || placeholder || ''}
        </span>
      </div>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {placeholder && (
          <MenuItem
            disabled
            value=""
            sx={{ fontSize: '14px', minHeight: '32px' }}
          >
            {placeholder}
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem
            key={option.value}
            onClick={() => handleOptionSelect(option.value)}
            selected={option.value === value}
            sx={{ fontSize: '14px', minHeight: '32px' }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Popover>
    </>
  )
}

interface InlineSelectFieldProps
  extends Omit<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    'name' | 'onSubmit' | 'onError' | 'onSuccess'
  > {
  /** Field name/identifier */
  name: string
  /** Initial value */
  initialValue: string
  /** Available options */
  options: Array<{ value: string; label: string }>
  /** Optional validation schema */
  validation?: InlineFieldWrapperProps['validation']
  /** Submit handler */
  onSubmit?: InlineFieldWrapperProps['onSubmit']
  /** Optional error handler */
  onError?: InlineFieldWrapperProps['onError']
  /** Optional success handler */
  onSuccess?: InlineFieldWrapperProps['onSuccess']
  /** When to trigger validation/submission */
  triggerOn?: InlineFieldWrapperProps['triggerOn']
  /** Placeholder text */
  placeholder?: string
  /** Whether the field is disabled */
  disabled?: boolean
}

export const InlineSelectField: React.FC<InlineSelectFieldProps> = ({
  name,
  initialValue,
  options,
  validation,
  onSubmit,
  onError,
  onSuccess,
  className,
  triggerOn = { change: true, typing: false, blur: false, enter: false },
  placeholder,
  disabled,
}) => {
  return (
    <InlineFieldWrapper
      name={name}
      initialValue={initialValue}
      validation={validation}
      onSubmit={onSubmit}
      onError={onError}
      onSuccess={onSuccess}
      triggerOn={triggerOn}
    >
      {({
        error,
        isValid,
        isSubmitting,
        isValidating,
        value,
        onChange,
        onBlur,
        setters,
      }) => (
        <InlineSelectInput
          value={value}
          options={options}
          placeholder={placeholder}
          onChange={onChange}
          onOpen={() => {
            setters.setTouched(true)
          }}
          onBlur={onBlur}
          error={error}
          isValid={isValid}
          isSubmitting={isSubmitting}
          isValidating={isValidating}
          className={className}
          disabled={disabled}
        />
      )}
    </InlineFieldWrapper>
  )
}
