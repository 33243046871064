import { FieldErrors } from '@redwoodjs/forms'

export default function getFormErrorArray(
  errors: FieldErrors<unknown> | undefined
) {
  const messages: string[] = []

  function traverse(obj: FieldErrors<unknown> | undefined) {
    Object.values(obj || {}).forEach((value) => {
      if (value && 'message' in value && value.message) {
        messages.push(value.message.toString())
      } else {
        traverse(value)
      }
    })
  }

  traverse(errors)
  return messages
}
