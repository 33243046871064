export const GET_VENDORS_LIST = gql`
  query getVendorsList {
    vendors {
      id
      name
      email
    }
  }
`

export const CREATE_VENDOR = gql`
  mutation CreateVendor($input: CreateVendorInput!) {
    createVendor(input: $input) {
      id
      name
      email
    }
  }
`

export const GET_VENDOR_BY_ID = gql`
  query GetVendorById($id: String!) {
    vendor(id: $id) {
      id
      orgSlug
      name
      email
      phoneNumber
      website
      type
    }
  }
`

export const UPDATE_VENDOR = gql`
  mutation UpdateVendor($id: String!, $input: UpdateVendorInput!) {
    updateVendor(id: $id, input: $input) {
      id
      name
      email
    }
  }
`
