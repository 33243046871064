import { useThreads } from '@liveblocks/react/suspense'
import useHasFeature from './useHasFeature'
import { useOrgName } from './useOrgName'

// Wrapper hook to conditionally use useThreads
const useConditionalThreads = (commentsEnabled: boolean) => {
  if (!commentsEnabled) {
    return { threads: [], error: null, isLoading: false }
  }
  return useThreads({ query: { resolved: false } })
}

const useUnresolvedComments = () => {
  const orgName = useOrgName()
  const { hasFeature: commentsEnabled, loading: featureLoading } =
    useHasFeature('Comments', orgName)

  const {
    threads: unresolvedCommentThreads,
    error,
    isLoading: threadsLoading,
  } = useConditionalThreads(commentsEnabled)

  // Early return if comments are not enabled
  if (!commentsEnabled) {
    return {
      hasUnresolvedComments: false,
      unresolvedCommentThreads: [],
      isLoading: featureLoading,
      error: null,
    }
  }

  return {
    // Returns true if there are unresolved comments, but only when:
    // - Feature flag check is complete (!featureLoading)
    // - Thread data has finished loading (!threadsLoading)
    // - No errors occurred (!error)
    // - And there is at least one unresolved thread
    hasUnresolvedComments:
      !featureLoading &&
      !threadsLoading &&
      !error &&
      unresolvedCommentThreads.length > 0,
    unresolvedCommentThreads,
    isLoading: featureLoading || threadsLoading,
    error,
  }
}

export default useUnresolvedComments
