import React, { useEffect, useMemo } from 'react'

import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import CheckIcon from '@mui/icons-material/Check'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import SdCardAlertIcon from '@mui/icons-material/SdCardAlert'
import { Typography } from '@mui/material'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import _ from 'lodash'
import { InternalWorkItem } from 'types/graphql'

import { Form, useForm } from '@redwoodjs/forms'

import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal'
import FileUploadButton from 'src/components/FileUploadButton'
import Loading from 'src/components/Loading'
import ReusableTabs, { TabComponent } from 'src/components/MUI/Tabs'
import TextField from 'src/components/MUI/TextField'
import ReusableTimeline, {
  ReusableTimelineItem,
} from 'src/components/MUI/Timeline'
import UploadedFileDetails from 'src/components/UploadedFileDetails/UploadedFileDetails'
import useQuery from 'src/hooks/useQuery'
import { FormSubmitRef } from 'src/types'
import useHasPermission from 'src/hooks/useHasPermission'
import { Permissions } from '@wingwork/common/src/constants/permissions'
import Button from 'src/components/MUI/Button'
import { useSelector } from 'src/hooks/useSelector'

dayjs.extend(relativeTime)

const FILE_FRAGMENT = gql`
  fragment FileFragmentForWorkOrderCompliancePage on FileEntry {
    id
    title
    description
    originalFilename
    uploadedBy {
      id
      firstName
      lastName
    }
  }
`

const FETCH_ATTACHMENTS = gql`
  ${FILE_FRAGMENT}
  query FetchWorkOrderComplianceAttachments($id: String!) {
    internalWorkItem(id: $id) {
      id
      attachedFiles {
        id
        createdAt
        file {
          ...FileFragmentForWorkOrderCompliancePage
        }
      }
      maintenanceItem {
        id
        attachedFiles {
          id
          createdAt
          file {
            ...FileFragmentForWorkOrderCompliancePage
          }
        }
      }
    }
  }
`

interface AdditionalTabProps {
  workItem: InternalWorkItem
  handleSubmit: (values: any) => Promise<void>
  onError: (formName: string, errors: any) => void
}

const AdditionalTab = React.forwardRef<FormSubmitRef, AdditionalTabProps>(
  ({ workItem, handleSubmit, onError }, ref) => {
    const { data, refetch, loading, hasLoaded } = useQuery(FETCH_ATTACHMENTS, {
      variables: { id: workItem.id },
    })

    const formMethods = useForm({
      defaultValues: {
        workItem: {
          additionalNotes: workItem.additionalNotes,
        },
      },
    })

    React.useImperativeHandle(ref, () => ({
      handleSubmit: async () => {
        let hasError = false
        await formMethods.handleSubmit(handleSubmit, (errs) => {
          hasError = true
          onError('additional', errs)
        })()
        return !hasError
      },
      isDirty: Object.keys(formMethods.formState.dirtyFields).length > 0,
    }))

    useEffect(() => {
      formMethods.reset({
        workItem: {
          additionalNotes: workItem.additionalNotes,
        },
      })
    }, [workItem, formMethods])

    const workItemFiles = useMemo(
      () =>
        _.chain(
          data?.internalWorkItem?.attachedFiles.map((attachedFile) => ({
            attachedFileId: attachedFile.id,
            ...attachedFile.file,
            createdAt: attachedFile.createdAt,
          })) ?? []
        )
          .sortBy('createdAt')
          .reverse()
          .value(),
      [data]
    )
    const maintenanceFiles = useMemo(
      () =>
        _.chain(
          data?.internalWorkItem?.maintenanceItem?.attachedFiles.map(
            (attachedFile) => ({
              attachedFileId: attachedFile.id,
              ...attachedFile.file,
              createdAt: attachedFile.createdAt,
            })
          ) ?? []
        )
          .sortBy('createdAt')
          .reverse()
          .value(),
      [data]
    )

    const aircraftId = useSelector((state) => state.auditLog.aircraftId)
    const canUpdateWorkOrder = useHasPermission(
      Permissions.workOrder.update,
      aircraftId
    )

    return (
      <div className="p-3">
        {loading && !hasLoaded ? (
          <Loading />
        ) : (
          <div>
            <div>
              <Typography
                variant="subtitle1"
                fontWeight="bold"
                className="mb-2"
              >
                Additional
              </Typography>
            </div>
            <Form formMethods={formMethods} onSubmit={handleSubmit}>
              <TextField
                name="workItem.additionalNotes"
                size="small"
                label="Additional Notes"
                multiline
                rows={4}
                variant="outlined"
                disabled={!canUpdateWorkOrder}
              />
            </Form>
            <Typography variant="subtitle1" fontWeight="bold" className="mt-2">
              Attachments
            </Typography>
            <ReusableTabs>
              <TabComponent
                label="Work Completed"
                tooltip="Attachments related to the work performed for this maintenance item."
                icon={<InfoOutlinedIcon fontSize="small" />}
                iconPosition="end"
              >
                <div className="flex flex-col p-2">
                  <div className="flex items-center justify-between">
                    <Typography variant="subtitle2" fontWeight={'bold'}>
                      Work Completed
                    </Typography>
                    <div></div>
                    <FileUploadButton
                      key={workItem.id}
                      attachables={[
                        { id: workItem.id, type: 'InternalWorkItem' },
                      ]}
                      refetchAttachments={() => refetch()}
                      button={
                        <Button
                          size="small"
                          variant="outlined"
                          color="base"
                          startIcon={<AddOutlinedIcon fontSize="small" />}
                          locked={!canUpdateWorkOrder}
                          lockedTooltip="You do not have permission to update this work order"
                        >
                          Upload File
                        </Button>
                      }
                    />
                  </div>
                  <div className="mt-2 h-50 overflow-y-auto">
                    {workItemFiles?.length ? (
                      <ReusableTimeline>
                        {workItemFiles?.map((file) => (
                          <ReusableTimelineItem
                            key={file.id}
                            icon={<CheckIcon fontSize="small" />}
                            dotColor="#7FABFF"
                            title={dayjs(file.createdAt).fromNow()}
                            description={dayjs(file.createdAt).format(
                              'MMMM DD, YYYY'
                            )}
                          >
                            <div className="flex flex-col gap-2">
                              <div className="flex flex-col">
                                <Typography
                                  variant="subtitle1"
                                  fontWeight={500}
                                >
                                  {file.title}
                                </Typography>
                                <Typography variant="caption">
                                  {file.description}
                                </Typography>
                              </div>
                              <UploadedFileDetails
                                file={{
                                  fileId: file.id,
                                  uploadedBy: `${file.uploadedBy?.firstName} ${file.uploadedBy?.lastName}`,
                                  fileName: file.originalFilename,
                                  // fileSize: '3 MB',
                                }}
                                attachedFileId={file.attachedFileId}
                              />
                            </div>
                          </ReusableTimelineItem>
                        ))}
                      </ReusableTimeline>
                    ) : (
                      <div className="flex h-full items-center justify-center gap-1">
                        <SdCardAlertIcon fontSize="large" color="action" />
                        <Typography variant="body1" color="textSecondary">
                          No files found
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
              </TabComponent>
              <TabComponent
                label="Task Attachments"
                tooltip="Attachments that are directly associated with this maintenance item."
                icon={<InfoOutlinedIcon fontSize="small" />}
                iconPosition="end"
              >
                <div className="flex flex-col p-2">
                  <div className="flex items-center justify-between">
                    <Typography variant="subtitle2" fontWeight={'bold'}>
                      Task Attachments
                    </Typography>
                    <FileUploadButton
                      attachables={[
                        {
                          id: workItem.maintenanceItem?.id,
                          type: 'MaintenanceItem',
                        },
                      ]}
                      refetchAttachments={() => refetch()}
                      button={
                        <Button
                          size="small"
                          variant="outlined"
                          color="base"
                          startIcon={<AddOutlinedIcon fontSize="small" />}
                          locked={!canUpdateWorkOrder}
                          lockedTooltip="You do not have permission to update this work order"
                        >
                          Upload File
                        </Button>
                      }
                    />
                  </div>
                  <div className="mt-2 h-50 overflow-y-auto">
                    {maintenanceFiles?.length ? (
                      <ReusableTimeline>
                        {maintenanceFiles?.map((file) => (
                          <ReusableTimelineItem
                            key={file.id}
                            icon={<CheckIcon fontSize="small" />}
                            dotColor="#7FABFF"
                            title={dayjs(file.createdAt).fromNow()}
                            description={dayjs(file.createdAt).format(
                              'MMMM DD, YYYY'
                            )}
                          >
                            <div className="flex flex-col gap-2">
                              <div className="flex flex-col">
                                <Typography
                                  variant="subtitle1"
                                  fontWeight={500}
                                >
                                  {file.title}
                                </Typography>
                                <Typography variant="caption">
                                  {file.description}
                                </Typography>
                              </div>
                              <UploadedFileDetails
                                file={{
                                  fileId: file.id,
                                  uploadedBy: `${file.uploadedBy?.firstName} ${file.uploadedBy?.lastName}`,
                                  fileName: file.originalFilename,
                                  // fileSize: '3 MB',
                                }}
                                attachedFileId={file.attachedFileId}
                              />
                            </div>
                          </ReusableTimelineItem>
                        ))}
                      </ReusableTimeline>
                    ) : (
                      <div className="flex h-full items-center justify-center gap-1">
                        <SdCardAlertIcon fontSize="large" color="action" />
                        <Typography variant="body1" color="textSecondary">
                          No files found
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
              </TabComponent>
            </ReusableTabs>
          </div>
        )}
        <ConfirmationModal
          discriminator="removeFile"
          title="Remove File"
          confirmText="Remove"
          denyText="Cancel"
          onConfirm={() => refetch()}
        />
      </div>
    )
  }
)
export default AdditionalTab
