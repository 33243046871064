import { FC } from 'react'

import clsx from 'clsx'

type ModalActionsProps = {
  children: React.ReactNode
  className?: string
  classes?: {
    root?: string
  }
}

const ModalActions: FC<ModalActionsProps> = ({
  children,
  classes = {},
  className,
}) => {
  return (
    <div
      className={clsx(
        'flex items-center justify-end gap-1 rounded-b-xl bg-[#FAFAFA] px-3 py-2',
        classes?.root,
        className
      )}
    >
      {children}
    </div>
  )
}

export default ModalActions
