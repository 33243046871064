import { OperationVariables } from '@apollo/client/core'
import { ComplianceLedger, UpdateComplianceLedgerInput } from 'types/graphql'

import CORE_AIRCRAFT_USAGE_LOG_FRAGMENT from 'src/fragments/AircraftUsageLog'
import CORE_MAINTENANCE_NEXT_DUE_FRAGMENT from 'src/fragments/MaintenanceNextDue'

import useMutationUpdate from './useMutationUpdate'

const UPDATE_COMPLIANCE_LEDGER_MUTATION = gql`
  ${CORE_MAINTENANCE_NEXT_DUE_FRAGMENT}
  ${CORE_AIRCRAFT_USAGE_LOG_FRAGMENT}
  mutation UpdateComplianceLedger(
    $id: String!
    $input: UpdateComplianceLedgerInput!
  ) {
    updateComplianceLedger(id: $id, input: $input) {
      id
      description
      timestamp
      aircraftId
      aircraftUsageLogId
      aircraftUsageLog {
        ...CoreAircraftUsageLogFields
      }
      complianceActivity(filters: { omitChildren: false }) {
        id
        nextDue {
          ...CoreMaintenanceNextDueFields
        }
      }
      createdAt
      status
      workedById
      inspectedById
      requestedInspectorId
      requestedWorkerId
    }
  }
`

const useUpdateComplianceLedger = (options?: OperationVariables) => {
  return useMutationUpdate<ComplianceLedger, UpdateComplianceLedgerInput>(
    UPDATE_COMPLIANCE_LEDGER_MUTATION,
    options
  )
}

export default useUpdateComplianceLedger
