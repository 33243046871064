import { Controller } from '@redwoodjs/forms'
import { useFormContext } from '@redwoodjs/forms'
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps,
  Chip,
  TextField,
} from '@mui/material'
import theme, { wwAppColors } from 'src/theme'
import React from 'react'

type CustomAutocompleteProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
> = AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>

export const Autocomplete = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(
  props: CustomAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>
) => {
  const { renderInput, renderTags, ...otherProps } = props
  const [open, setOpen] = React.useState(false)

  return (
    <MuiAutocomplete
      {...otherProps}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      renderInput={renderInput || ((params) => <TextField {...params} />)}
      renderTags={
        renderTags ||
        ((value: T[], getTagProps) =>
          value.map((option, index) => {
            const chipProps = getTagProps({ index })
            return (
              <Chip
                {...chipProps}
                variant="soft"
                label={props.getOptionLabel?.(option) || String(option)}
                key={`chip-${
                  props.getOptionLabel?.(option) || String(option)
                }-${index}`}
                className="mb-0.5 mr-1 rounded-full"
                onDelete={(e) => {
                  setOpen(false)
                  chipProps.onDelete?.(e)
                }}
                sx={{
                  backgroundColor: wwAppColors.coolBlue[100],
                  color: theme.palette.info.dark,
                  padding: '12px 8px',
                  '& .MuiChip-deleteIcon': {
                    color: theme.palette.info.dark,
                    marginLeft: '3px',
                    marginRight: '-3px',
                    height: '18px',
                  },
                }}
              />
            )
          }))
      }
    />
  )
}

export interface AutocompleteOption {
  label: string
  value: string | number
}

interface AutocompleteFieldProps
  extends Omit<React.ComponentProps<typeof Autocomplete>, 'renderInput'> {
  name: string
  label: string
  helperText?: string
  size?: 'small' | 'medium'
  options: AutocompleteOption[]
  className?: string
  growHeight?: boolean
  required?: boolean
}

export const AutocompleteField: React.FC<AutocompleteFieldProps> = ({
  name,
  options,
  size = 'small',
  multiple = false,
  ...props
}) => {
  const {
    formState: { errors },
    control,
    watch,
  } = useFormContext()
  const value = watch(name)

  const fieldError = errors[name]?.[0]
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }) => (
        <Autocomplete
          multiple={multiple}
          options={options}
          getOptionLabel={(option: AutocompleteOption) =>
            option.label as string
          }
          size={size}
          onChange={(e, value: AutocompleteOption[]) => {
            onChange(value.map((v) => v.value))
          }}
          value={options.filter((option) => {
            return value?.includes(option.value)
          })}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={props.label}
              helperText={fieldError}
              placeholder="Search"
            />
          )}
          {...props}
        />
      )}
    />
  )
}

export default Autocomplete
