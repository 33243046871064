import { Tooltip } from 'react-tooltip'
import {
  OrganizationSwitcher,
  UserButton,
  useOrganizationList,
} from '@clerk/clerk-react'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import {
  AppBar,
  CssBaseline,
  IconButton,
  Paper,
  styled,
  Toolbar,
  Typography,
} from '@mui/material'

import { routes, useLocation, useRouteName } from '@redwoodjs/router'
import { Toaster } from '@redwoodjs/web/toast'

import Toast from 'src/components/Toast'
import useHistoryCount from 'src/hooks/useHistoryCount'
import useUploadEventListeners from 'src/hooks/useUploadEventListeners'

import Drawer from './Drawer'
import NotificationPopover from '../../components/Notifications/NotificationPopover'
import { useDispatch } from 'src/hooks/useDispatch'
import {
  navDrawerExpandedToggled,
  selectCommentsDrawerExpanded,
  selectNavDrawerExpanded,
} from 'src/slices/appLayoutSlice'
import { useSelector } from 'src/hooks/useSelector'
import SupportIcon from 'src/components/Comments/SupportIcon'
import { commentsDrawerWidth } from 'src/components/Comments/CommentsDrawerScaffold'
import CommentContext from 'src/components/Comments/CommentContext'
import CommentsDrawer from 'src/components/Comments/CommentsDrawer'
import useComments from 'src/components/Comments/useComments'
import CommentIcon from 'src/components/Comments/CommentIcon'
import useIsAdmin from 'src/hooks/useIsAdmin'
import { RoomProvider } from '@liveblocks/react/suspense'
import Loading from 'src/components/Loading'
import AdminCommentContext from 'src/components/Comments/AdminCommentContext'
import { BreadcrumbsProvider } from '../components/BreadcrumbsContext'
import Breadcrumbs from '../components/Breadcrumbs'
import clsx from 'clsx'

const AppContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  height: '100vh',
  width: '100vw',
  '#appBar': {
    height: '64px',
  },
  '#appContentContainer': {
    height: 'calc(100vh - 64px)',
    display: 'flex',
    flexDirection: 'row',
    width: '100vw',
  },
})

export const transitionOutClasses = 'transition-all ease-out duration-300'
export const transitionInClasses = 'transition-all ease-in duration-300'

const MainContent = styled('div', {
  shouldForwardProp: (prop) => prop !== 'commentsDrawerExpanded',
})<{
  commentsDrawerExpanded?: boolean
}>(({ commentsDrawerExpanded }) => ({
  flexGrow: 1,
  width: `calc(100vw - ${commentsDrawerWidth}px)`,
  overflow: 'auto',
  marginRight: commentsDrawerExpanded ? 0 : -commentsDrawerWidth,
}))

type AppLayoutProps = {
  children?: React.ReactNode
}

const AppLayout = ({ children }: AppLayoutProps) => {
  const { organizationList, isLoaded } = useOrganizationList()
  const dispatch = useDispatch()
  const {
    CommentsProvider,
    roomId,
    hasLoaded: commentsProviderHasLoaded,
  } = useComments({
    isAdmin: false,
  })
  const {
    CommentsProvider: AdminCommentsProvider,
    hasLoaded: adminCommentsProviderHasLoaded,
  } = useComments({
    isAdmin: true,
  })

  const navDrawerExpanded = useSelector(selectNavDrawerExpanded)
  const commentsDrawerExpanded = useSelector(selectCommentsDrawerExpanded)
  const showOrganizationSwitcher = React.useMemo(
    () => isLoaded && organizationList.length > 1,
    [isLoaded, organizationList]
  )
  const userIsAdmin = useIsAdmin()
  const { search, pathname } = useLocation()
  const queryParams = new URLSearchParams(search)
  const isDocHubPreview = queryParams.get('view') === 'docHubPreview'
  const routeName = useRouteName()
  const addContrastToBulkCompliance = React.useMemo(() => {
    return routeName === 'bulkCompliance' || routeName === 'workOrder'
  }, [routeName])
  useHistoryCount()
  useUploadEventListeners()

  if (isDocHubPreview) {
    return (
      <BreadcrumbsProvider>
        <div className="flex">
          <CssBaseline />
          <main className="flex-grow">{children}</main>
        </div>
      </BreadcrumbsProvider>
    )
  }

  const isOnAdminRoute = pathname.match(/^\/admin/)
  const MainContentCommentProvider = isOnAdminRoute
    ? adminCommentsProviderHasLoaded
      ? AdminCommentsProvider
      : Loading
    : commentsProviderHasLoaded
    ? CommentsProvider
    : Loading

  return (
    <CommentContext.Provider
      value={{
        CommentsProvider,
        roomId,
        hasLoaded: commentsProviderHasLoaded,
      }}
    >
      <AdminCommentContext.Provider
        value={{
          AdminCommentsProvider,
          hasLoaded: adminCommentsProviderHasLoaded,
        }}
      >
        <BreadcrumbsProvider>
          <AppContainer>
            <CssBaseline />
            <AppBar
              position="relative"
              id="appBar"
              className="bg-transparent shadow-none"
              color="inherit"
              elevation={1}
            >
              <Toolbar className="pl-1">
                <div
                  className={`
                  rounded-md transition-all duration-300 ease-in-out
                  ${navDrawerExpanded ? 'w-[200px]' : 'w-[50px]'}
                  h-[40px]
                `}
                >
                  <div className="relative flex h-5 w-[180px] items-center">
                    <img
                      src="/WingWorkFullLogo.png"
                      alt="Wingwork Logo"
                      width={'180px'}
                      className={`
                      absolute h-5 transition-all duration-300 ease-in-out
                      ${
                        navDrawerExpanded
                          ? 'translate-x-0 opacity-100'
                          : '-translate-x-4 opacity-0'
                      }
                    `}
                    />
                    <IconButton
                      aria-label="open drawer"
                      onClick={() => dispatch(navDrawerExpandedToggled())}
                      className={`
                      rounded-md p-0 transition-all duration-300 ease-in-out hover:bg-black/[0.04]
                      ${
                        navDrawerExpanded
                          ? 'w-[180px] min-w-[180px]'
                          : 'w-[44px] min-w-[44px]'
                      }
                      h-[44px]
                    `}
                    >
                      <img
                        src="/WingWorkIcon.png"
                        alt="Wingwork Logo"
                        width={'32px'}
                        className={`
                        absolute transition-all duration-300 ease-in-out
                        ${
                          navDrawerExpanded
                            ? 'translate-x-4 opacity-0'
                            : 'translate-x-0 opacity-100'
                        }
                      `}
                      />
                    </IconButton>
                  </div>
                </div>
                <div className="z-[1] pl-2">
                  <Breadcrumbs />
                </div>

                <div className="flex-grow" />

                <div className="flex items-center gap-1">
                  <CommentIcon />
                  <IconButton
                    size="large"
                    aria-label="help area"
                    color="inherit"
                  >
                    <HelpOutlineIcon />
                  </IconButton>
                  <CommentsProvider>
                    <NotificationPopover />
                  </CommentsProvider>
                  {userIsAdmin && <SupportIcon />}
                  {showOrganizationSwitcher && (
                    <div className="pl-2">
                      <OrganizationSwitcher
                        hidePersonal
                        afterSelectOrganizationUrl={routes.landing()}
                      />
                    </div>
                  )}
                  <div className="pl-1">
                    <UserButton showName={false} />
                  </div>
                </div>
              </Toolbar>
            </AppBar>
            <div id="appContentContainer">
              <MainContentCommentProvider>
                <RoomProvider id={roomId}>
                  <Drawer expanded={navDrawerExpanded} />
                  <MainContent
                    className={`${
                      navDrawerExpanded
                        ? transitionInClasses
                        : transitionOutClasses
                    }`}
                    commentsDrawerExpanded={commentsDrawerExpanded}
                  >
                    <Paper
                      id="main-content" // ! Most InfiniteScroll components in the app reference this ID.  Be very wary of changing it.
                      className={`scrollbar-mt-4 h-full overflow-y-auto overflow-x-hidden rounded-t-2xl ${
                        addContrastToBulkCompliance
                          ? 'bg-[#f2f7fc]'
                          : 'bg-[#fafafa]'
                      }`}
                    >
                      {children}

                      <Tooltip
                        id="global-tooltip"
                        className={clsx(
                          'z-50', // stay on top of other elements
                          'max-w-md', // max width to keep the wrap under control
                          'rounded-[4px] bg-[#616161] px-1 py-0.5' // emulate mui tooltips close enough
                        )}
                        render={({ content }) => (
                          <Typography
                            variant="body2"
                            className="font-medium leading-5" // these are both just to mimic mui tooltips
                          >
                            {content}
                          </Typography>
                        )}
                      />
                    </Paper>
                  </MainContent>
                  <CommentsDrawer />
                </RoomProvider>
              </MainContentCommentProvider>
            </div>
            <Toaster position="top-right" containerClassName="mt-7 z-51">
              {(t) => <Toast t={t} message={t.message} />}
            </Toaster>
          </AppContainer>
        </BreadcrumbsProvider>
      </AdminCommentContext.Provider>
    </CommentContext.Provider>
  )
}

export default AppLayout
