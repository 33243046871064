import {
  Switch as MUISwitch,
  FormControlLabel,
  Tooltip,
  TooltipProps,
} from '@mui/material'

import { Controller, useFormContext } from '@redwoodjs/forms'

interface SwitchProps {
  className?: string
  name: string
  label?: string
  required?: boolean
  switchProps?: React.ComponentProps<typeof MUISwitch>
  tooltip?: string
  disabledTooltip?: string
  tooltipProps?: TooltipProps
}

const Switch: React.FC<SwitchProps> = ({
  name,
  label,
  tooltip,
  disabledTooltip,
  required = false,
  switchProps,
  tooltipProps,
  ...otherProps
}) => {
  // We use the Redwood form context to get form methods
  const { register, control } = useFormContext()

  let tooltipText: string
  if (switchProps?.disabled) {
    tooltipText = disabledTooltip
  } else {
    tooltipText = tooltip
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value: isChecked, ...rest } }) => {
        return (
          <Tooltip title={tooltipText} placement="top" {...tooltipProps}>
            <span>
              <FormControlLabel
                control={
                  <MUISwitch
                    {...register(name, { required })}
                    {...rest}
                    {...switchProps}
                    checked={isChecked ?? false}
                  />
                }
                label={label}
                {...otherProps}
              />
            </span>
          </Tooltip>
        )
      }}
    />
  )
}

export default Switch
