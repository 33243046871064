import React, { useEffect } from 'react'

import { ClerkProvider, useSession, useUser } from '@clerk/clerk-react'

import { createAuth } from '@redwoodjs/auth-clerk-web'
import { navigate } from '@redwoodjs/router'

export const { AuthProvider: ClerkRwAuthProvider, useAuth } = createAuth()

const ClerkStatusUpdater = () => {
  const { isSignedIn, user, isLoaded } = useUser()
  const { session } = useSession()
  const { reauthenticate, isAuthenticated } = useAuth()

  useEffect(() => {
    // calculate if the session is expired
    const sessionExpired =
      session?.expireAt && new Date() > new Date(session.expireAt)

    // if we have loaded and the session is expired or not authenticated, reauthenticate
    if (isLoaded && (!isAuthenticated || sessionExpired)) {
      reauthenticate()
    }
  }, [isSignedIn, user, reauthenticate, isLoaded, session?.expireAt])

  return null
}

type ClerkOptions =
  | { publishableKey: string; frontendApi?: never; touchSession?: boolean }
  | { publishableKey?: never; frontendApi: string; touchSession?: boolean }

interface Props {
  children: React.ReactNode
}

const ClerkProviderWrapper = ({
  children,
  clerkOptions,
}: Props & { clerkOptions: ClerkOptions }) => {
  const { reauthenticate } = useAuth()

  return (
    <ClerkProvider
      {...clerkOptions}
      navigate={(to) => reauthenticate().then(() => navigate(to))}
    >
      {children}
      <ClerkStatusUpdater />
    </ClerkProvider>
  )
}

export const AuthProvider = ({ children }: Props) => {
  const publishableKey = process.env.CLERK_PUBLISHABLE_KEY
  const frontendApi =
    process.env.CLERK_FRONTEND_API_URL || process.env.CLERK_FRONTEND_API

  const clerkOptions: ClerkOptions = publishableKey
    ? { publishableKey, touchSession: false }
    : { frontendApi, touchSession: false }

  return (
    <ClerkRwAuthProvider>
      <ClerkProviderWrapper clerkOptions={clerkOptions}>
        {children}
      </ClerkProviderWrapper>
    </ClerkRwAuthProvider>
  )
}
