import React, { useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@mui/material'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Form } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'

import TextField from 'src/components/MUI/TextField'
import { useOrgName } from 'src/hooks/useOrgName'
import useQuery from 'src/hooks/useQuery'

import {
  CREATE_ADDRESS,
  GET_ADDRESS_BY_ID,
  GET_ALL_ADDRESSES,
  UPDATE_ADDRESS,
} from '../../queries'

const schema = yup.object().shape({
  vendorId: yup.string().nullable(),
  addressLine1: yup.string().required(),
  addressLine2: yup.string().nullable(),
  city: yup.string().required(),
  state: yup.string().required(),
  postalCode: yup.string().required(),
  country: yup.string().required(),
})

interface AddressFormProps {
  id: string
  onClose: () => void
  onComplete?: (data: any) => void
  discriminator?: 'shippingAddress' | 'billingAddress'
}

const AddressForm: React.FC<AddressFormProps> = ({
  id,
  onClose,
  onComplete,
  discriminator,
}) => {
  const orgSlug = useOrgName()
  const formMethods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  })
  const {
    formState: { errors },
  } = formMethods

  const [createAddress] = useMutation(CREATE_ADDRESS, {
    update: (cache, { data: { createAddress: address } }) => {
      // Add the new address to the cache for the GET_ALL_ADDRESSES query
      // This will cause the UI to update and add the new address to the list
      const { addresses } = cache.readQuery({ query: GET_ALL_ADDRESSES })

      cache.writeQuery({
        query: GET_ALL_ADDRESSES,
        data: { addresses: [...addresses, address] },
      })

      // automatically set the shipping/billing address in the PO form if created from there
      onComplete?.({
        data: {
          id: address.id,
          addressLine1: address.addressLine1,
          addressLine2: address.addressLine2,
          city: address.city,
          state: address.state,
          postalCode: address.postalCode,
          country: address.country,
        },
        discriminator,
      })
    },
  })
  const [updateAddress] = useMutation(UPDATE_ADDRESS)

  const { data, hasLoaded } = useQuery(GET_ADDRESS_BY_ID, {
    variables: { id },
    skip: !id,
  })

  useEffect(() => {
    // if we load data because we're in update mode, reset the form with the new data
    if (data) {
      const { __typename, id: _id, ...address } = data.address

      formMethods.reset(address)
    }
  }, [data, formMethods])

  const handleSubmit = async (values) => {
    if (id) {
      const updatePayload = {
        ...values,
        orgSlug,
        id: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        vendorId: undefined,
      }

      await updateAddress({
        variables: {
          id,
          input: updatePayload,
        },
      })
    } else {
      await createAddress({
        variables: {
          input: { ...values, orgSlug },
        },
      })
    }
    // return to the list view and unset id
    onClose()
  }

  return (
    <Form
      onSubmit={handleSubmit}
      formMethods={formMethods}
      className="flex flex-col gap-2"
    >
      <TextField
        name="addressLine1"
        required
        label="Address Line 1"
        error={!!errors.addressLine1}
        helperText={errors.addressLine1 ? 'Address Line 1 is required' : ''}
        fullWidth
      />
      <TextField name="addressLine2" label="Address Line 2" fullWidth />
      <TextField
        name="city"
        required
        label="City"
        error={!!errors.city}
        helperText={errors.city ? 'City is required' : ''}
        fullWidth
      />
      <TextField
        name="state"
        required
        label="State"
        error={!!errors.state}
        helperText={errors.state ? 'State is required' : ''}
        fullWidth
      />
      <TextField
        name="postalCode"
        required
        label="Postal Code"
        error={!!errors.postalCode}
        helperText={errors.postalCode ? 'Postal Code is required' : ''}
        fullWidth
      />
      <TextField
        name="country"
        required
        label="Country"
        error={!!errors.country}
        helperText={errors.country ? 'Country is required' : ''}
        fullWidth
      />
      <div className="flex justify-end gap-2">
        <Button size="large" onClick={onClose} color="base" variant="outlined">
          Cancel
        </Button>
        <Button size="large" type="submit" variant="contained" color="primary">
          {id ? 'Edit Address' : 'Create Address'}
        </Button>
      </div>
    </Form>
  )
}

export default AddressForm
