import { Metadata } from '@redwoodjs/web'
import { useBreadcrumbs } from 'src/layouts/components/BreadcrumbsContext'
import MaintenanceItemList from 'web/src/pages/MaintenanceItemsPage/MaintenanceItemListV2'

const MaintenanceItemsPage = ({ orgName }: { orgName: string }) => {
  useBreadcrumbs([{ icon: 'maintenanceItems' }, { label: 'Maintenance Items' }])
  const DEFAULT_QUERY_VARIABLES = {
    isChildItem: false,
    onlyDueItems: false,
    parentId: null,
    showInProgress: true,
  }
  return (
    <div className="flex h-full flex-col">
      <Metadata
        title="Maintenance Items"
        description="Maintenance Items page"
      />
      <MaintenanceItemList
        title="Maintenance Items"
        orgName={orgName}
        variant="maintenanceItems"
        showSummaryCards={false}
        queryVariables={DEFAULT_QUERY_VARIABLES}
      />
    </div>
  )
}

export default MaintenanceItemsPage
