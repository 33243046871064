import { Divider, Typography, Skeleton } from '@mui/material'
import {
  minutesToHours,
  minutesToHoursStr,
  centsToDollars,
  titleCase,
} from 'src/utils/helpers'
import { abbreviateFullName } from 'src/utils/stringFormatters'
import PrimSecTextCombo from 'src/components/common/PrimSecTextCombo'
import type {
  ComplianceActivity,
  InternalWorkItem,
  MaintenanceItem,
} from 'types/graphql'
import { useMemo } from 'react'

interface DetailsSectionProps {
  task: ComplianceActivity | InternalWorkItem | CustomInternalWorkItem
  loading?: boolean
}

const DetailsSection: React.FC<DetailsSectionProps> = ({
  task,
  loading = false,
}) => {
  const maintenanceItem: MaintenanceItem = task?.maintenanceItem

  const getEstimatedCostStr = () => {
    const estWorkHours = task?.estimatedWorkMinutes
      ? parseFloat(minutesToHours(task?.estimatedWorkMinutes))
      : 0
    const rate = task?.estimatedRate
      ? parseFloat(centsToDollars(task?.estimatedRate))
      : 0
    const rateType = task?.estimatedRateType

    if (!rate || !rateType) return '-'

    switch (rateType) {
      case 'FLAT_RATE':
        return `$${rate}`
      case 'HOURLY_RATE':
        return `$${(estWorkHours * rate).toFixed(2)}`
      default:
        return '-'
    }
  }

  const getActualCostStr = () => {
    const actualWorkHours = task?.workMinutes
      ? parseFloat(minutesToHours(task?.workMinutes))
      : 0
    const rate = task?.rate ? parseFloat(centsToDollars(task?.rate)) : 0
    const rateType = task?.rateType
    const partCost = task?.partCost
      ? parseFloat(centsToDollars(task?.partCost))
      : 0

    if (!rate || !rateType) return '-'

    switch (rateType) {
      case 'FLAT_RATE':
        return `$${(rate + partCost).toFixed(2)}`
      case 'HOURLY_RATE':
        return `$${(actualWorkHours * rate + partCost).toFixed(2)}`
      default:
        return '-'
    }
  }

  const taskFields = [
    {
      label: 'REGISTRATION NUMBER',
      value: maintenanceItem?.registrationNumber,
    },
    {
      label: 'ITEM TYPE',
      value: titleCase(maintenanceItem?.maintenanceType ?? ''),
    },
    { label: 'ATA CODE', value: maintenanceItem?.ataCode },
    { label: 'REFERENCE', value: null },
    { label: 'TRACKED BY', value: maintenanceItem?.trackedByComponent?.name },
    { label: 'DISPOSITION', value: maintenanceItem?.disposition },
    { label: 'MFG CODE', value: maintenanceItem?.manufactureCode },
    { label: 'REQUIREMENT TYPE', value: null },
  ]

  const laborDetailFields = useMemo(
    () => [
      { label: 'ESTIMATED COST', value: getEstimatedCostStr() },
      {
        label: 'ACTUAL COST (PART & ADDITIONAL)',
        value: getActualCostStr(),
      },
      {
        label: 'MECHANIC',
        value: task?.workedById
          ? `${task?.workedBy?.firstName} ${task?.workedBy?.lastName}`
          : '-',
      },
      {
        label: 'TOTAL WORK HOURS',
        value: minutesToHoursStr(task?.workMinutes),
      },
    ],
    [task]
  )

  const detailFields = task?.isCustomWorkItem
    ? laborDetailFields
    : [...taskFields, ...laborDetailFields]

  if (!task) return null

  return (
    <div>
      <div className="flex flex-col gap-2 px-3 py-2">
        <Typography variant="caption">DETAILS</Typography>

        <div className="grid grid-cols-4 gap-x-3 gap-y-2">
          {detailFields.map((field, index) => (
            <div key={index}>
              <Typography variant="caption">{field.label}</Typography>
              <Typography variant="body2">
                {loading ? <Skeleton width="80%" /> : field.value || '-'}
              </Typography>
            </div>
          ))}
        </div>
      </div>
      <Divider variant="fullWidth" className="border-b-2" />
    </div>
  )
}

export default DetailsSection
