import Button from 'src/components/MUI/Button'
import Drawer from 'src/components/MUI/Drawer/Drawer'
import DrawerActions from 'src/components/MUI/Drawer/DrawerActions'
import DrawerHeader from 'src/components/MUI/Drawer/DrawerHeader'
import TextField from 'src/components/MUI/TextField'
import useModal from 'src/hooks/useModal'
import { useForm } from 'react-hook-form'
import { Form } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { REQUEST_PART_FROM_DRAWER } from './queries'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const schema = yup.object().shape({
  partNumber: yup.string().required('Part number is required'),
  quantity: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required('Quantity is required')
    .positive('Quantity must be positive')
    .integer('Quantity must be a whole number'),
  description: yup.string().required('Description is required'),
})

type RequestPartDrawerProps = {
  complianceActivityId?: string
  workItemId?: string
  flashSaved?: () => void
  flashError?: () => void
}

const RequestPartDrawer = ({
  complianceActivityId,
  workItemId,
  flashSaved,
  flashError,
}: RequestPartDrawerProps) => {
  const { handleClose, isOpen } = useModal('requestPartDrawer')
  const [requestPart, { loading }] = useMutation(REQUEST_PART_FROM_DRAWER)
  const formMethods = useForm({
    resolver: yupResolver(schema),
  })

  const handleSubmit = async (data: any) => {
    const input = {
      ...data,
      ...(complianceActivityId && { complianceActivityId }),
      ...(workItemId && { workItemId }),
    }

    await requestPart({
      variables: { input },
      onCompleted: () => {
        flashSaved?.()
      },
      onError: () => {
        flashError?.()
      },
      update: (cache, { data: mutationData }) => {
        if (complianceActivityId) {
          cache.modify({
            id: cache.identify({
              __typename: 'ComplianceActivity',
              id: complianceActivityId,
            }),
            fields: {
              purchaseOrderItem: () => ({
                __ref: cache.identify(mutationData.requestPurchaseOrderPart),
              }),
            },
          })
        }
        // TODO: Add cache update for workItem if needed
      },
    })

    handleClose()
  }

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose}>
      <DrawerHeader title="Request Part" onClose={handleClose} />
      <div className="flex-grow overflow-y-auto px-3 py-2">
        <Form
          formMethods={formMethods}
          onSubmit={handleSubmit}
          className="flex flex-col gap-2"
          id="requestPartDrawerForm"
        >
          <div className="grid grid-cols-2 gap-2">
            <TextField
              fullWidth
              label="Part Number"
              name="partNumber"
              size="small"
            />
            <TextField
              fullWidth
              size="small"
              label="Quantity"
              name="quantity"
              type="number"
            />
          </div>
          <TextField
            fullWidth
            size="small"
            label="Description"
            name="description"
            multiline
            minRows={2}
            maxRows={4}
          />
        </Form>
      </div>
      <DrawerActions
        leftActions={
          <Button variant="text" color="black" onClick={handleClose}>
            Cancel
          </Button>
        }
        rightActions={
          <Button
            type="submit"
            form="requestPartDrawerForm"
            loading={loading}
            disabled={loading}
          >
            {loading ? 'Requesting...' : 'Request'}
          </Button>
        }
      />
    </Drawer>
  )
}

export default RequestPartDrawer
