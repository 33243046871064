import LockPersonIcon from '@mui/icons-material/LockPerson'
import {
  LoadingButton as MUILoadingButton,
  LoadingButtonProps as MUILoadingButtonProps,
} from '@mui/lab'
import { Tooltip, TooltipProps } from '@mui/material'

export type LoadingButtonProps = MUILoadingButtonProps & {
  tooltip?: string
  disabledTooltip?: string
  lockedTooltip?: string
  TooltipProps?: TooltipProps
  locked?: boolean
}

const LoadingButton: React.FC<LoadingButtonProps> = ({
  tooltip,
  disabledTooltip,
  lockedTooltip,
  TooltipProps,
  disabled,
  locked,
  ...buttonProps
}) => {
  const isDisabled = disabled || locked

  let tooltipText: string
  if (locked) {
    tooltipText = lockedTooltip
  } else if (disabled) {
    tooltipText = disabledTooltip
  } else {
    tooltipText = tooltip
  }

  return (
    <Tooltip
      enterDelay={isDisabled ? 100 : 700} // fast tooltips for disabled or locked clarity, long delay for regular buttons
      {...TooltipProps}
      title={tooltipText}
    >
      <span>
        <MUILoadingButton
          disabled={isDisabled}
          endIcon={locked && <LockPersonIcon />}
          {...buttonProps}
        />
      </span>
    </Tooltip>
  )
}

export default LoadingButton
