import React, { useEffect } from 'react'

import AttachFileIcon from '@mui/icons-material/AttachFile'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import { Typography, IconButton } from '@mui/material'
import {
  AircraftComponent,
  ComplianceActivity,
  PartsTransaction,
} from 'types/graphql'

import { useFormContext, useWatch } from '@redwoodjs/forms'

import FileUploadFieldMulti from 'src/components/FileUploadFieldMulti'
import SelectDropdown from 'src/components/MUI/SelectDropdown'
import TextField from 'src/components/MUI/TextField'
import PartsTimeInputTextField from 'src/components/PartsTimeInputTextField'
import useQuery from 'src/hooks/useQuery'
import useHasPermission from 'src/hooks/useHasPermission'
import { Permissions } from '@wingwork/common/src/constants/permissions'
import Button from 'src/components/MUI/Button'
import { useSelector } from 'src/hooks/useSelector'

// Fragment for aircraftComponent
export const AIRCRAFT_COMPONENT_FRAGMENT = gql`
  fragment AircraftComponentFragment on AircraftComponent {
    id
    aircraftId
    isPrimary
    isIntegral
    partNumber
    serialNumber
    monthsSinceNew
    monthsSinceOverhaul
    monthsSinceRepair
    hoursSinceNew
    hoursSinceOverhaul
    hoursSinceRepair
    cyclesSinceNew
    cyclesSinceOverhaul
    cyclesSinceRepair
  }
`

// Fragment for added parts
const ADDED_PART_FRAGMENT = gql`
  fragment AddedPartFragment on AircraftComponent {
    id
    isPrimary
    isIntegral
    partNumber
    serialNumber
    monthsSinceNew
    monthsSinceOverhaul
    monthsSinceRepair
    hoursSinceNew
    hoursSinceOverhaul
    hoursSinceRepair
    cyclesSinceNew
    cyclesSinceOverhaul
    cyclesSinceRepair
    files {
      id
      url
      originalFilename
    }
  }
`

// Fragment for removed parts
const REMOVED_PART_FRAGMENT = gql`
  fragment RemovedPartFragment on AircraftComponent {
    id
    aircraftId
    isPrimary
    isIntegral
    partNumber
    serialNumber
    files {
      id
      url
      originalFilename
    }
  }
`

// Fragment for partsTransaction
export const PARTS_TRANSACTION_FRAGMENT = gql`
  fragment PartsTransactionFragment on PartsTransaction {
    id
    removalReason
    installedStatus
    addedPartNumber
    addedSerialNumber
    removedPartNumber
    removedSerialNumber

    monthsSinceNew
    monthsSinceOverhaul
    monthsSinceRepair
    hoursSinceNew
    hoursSinceOverhaul
    hoursSinceRepair
    cyclesSinceNew
    cyclesSinceOverhaul
    cyclesSinceRepair

    addedPartFiles {
      id
      url
      originalFilename
    }

    removedPartFiles {
      id
      url
      originalFilename
    }

    added {
      ...AddedPartFragment
    }
    removed {
      ...RemovedPartFragment
    }
  }
  ${ADDED_PART_FRAGMENT}
  ${REMOVED_PART_FRAGMENT}
`

// Main query using the fragments
export const GET_PARTS = gql`
  query getParts($id: String!) {
    complianceActivity(id: $id) {
      id
      maintenanceItem {
        id
        aircraftComponent {
          ...AircraftComponentFragment
        }
      }
      partsTransaction {
        ...PartsTransactionFragment
      }
    }
  }
  ${AIRCRAFT_COMPONENT_FRAGMENT}
  ${PARTS_TRANSACTION_FRAGMENT}
`

const FileValue = (value, clear) => (
  <div className="flex items-center justify-between px-2">
    <Typography
      variant="body2"
      className="overflow-hidden text-ellipsis whitespace-nowrap"
    >
      {value?.name}
    </Typography>
    <IconButton onClick={clear} size="small">
      <ClearRoundedIcon fontSize="small" />
    </IconButton>
  </div>
)

type PartsFormProps = {
  complianceActivity: ComplianceActivity
  part: AircraftComponent
  transaction?: PartsTransaction
}

const PartsForm: React.FC<PartsFormProps> = ({
  complianceActivity,
  part,
  transaction,
}) => {
  const aircraftId = useSelector((state) => state.compliance.aircraftId)
  const canUpdateCompliance = useHasPermission(
    Permissions.compliance.update,
    aircraftId
  )
  const { data, loading, hasLoaded } = useQuery(GET_PARTS, {
    variables: { id: complianceActivity.id },
  })
  const context = useFormContext()
  const removalReason = useWatch({ name: 'parts.removalReason' })
  const installedStatus = useWatch({ name: 'parts.installedStatus' })

  useEffect(() => {
    const values = context.getValues()

    context.reset({
      ...values,
      parts: {
        removed: {
          partNumber:
            data?.complianceActivity?.partsTransaction?.removedPartNumber ??
            data?.complianceActivity?.maintenanceItem?.aircraftComponent
              ?.partNumber ??
            '',
          serialNumber:
            data?.complianceActivity?.partsTransaction?.removedSerialNumber ??
            data?.complianceActivity?.maintenanceItem?.aircraftComponent
              ?.serialNumber ??
            '',
          files: undefined,
        },
        added: {
          partNumber:
            data?.complianceActivity?.partsTransaction?.addedPartNumber ?? '',
          serialNumber:
            data?.complianceActivity?.partsTransaction?.addedSerialNumber ?? '',
          files: undefined,
        },
        removalReason:
          data?.complianceActivity?.partsTransaction?.removalReason?.startsWith(
            'Other'
          )
            ? data?.complianceActivity?.partsTransaction?.removalReason.split(
                ' - '
              )[0]
            : data?.complianceActivity?.partsTransaction?.removalReason ?? '',
        otherRemovalReason:
          data?.complianceActivity?.partsTransaction?.removalReason?.startsWith(
            'Other'
          )
            ? data?.complianceActivity?.partsTransaction?.removalReason.split(
                ' - '
              )[1]
            : '',
        installedStatus:
          data?.complianceActivity?.partsTransaction?.installedStatus?.startsWith(
            'Other'
          )
            ? data?.complianceActivity?.partsTransaction?.installedStatus.split(
                ' - '
              )[0]
            : data?.complianceActivity?.partsTransaction?.installedStatus ?? '',
        otherInstalledStatus:
          data?.complianceActivity?.partsTransaction?.installedStatus?.startsWith(
            'Other'
          )
            ? data?.complianceActivity?.partsTransaction?.installedStatus.split(
                ' - '
              )[1]
            : '',
        monthsSinceNew:
          data?.complianceActivity?.partsTransaction?.monthsSinceNew ?? -1,
        monthsSinceOverhaul:
          data?.complianceActivity?.partsTransaction?.monthsSinceOverhaul ?? -1,
        monthsSinceRepair:
          data?.complianceActivity?.partsTransaction?.monthsSinceRepair ?? -1,
        hoursSinceNew:
          data?.complianceActivity?.partsTransaction?.hoursSinceNew ?? -1,
        hoursSinceOverhaul:
          data?.complianceActivity?.partsTransaction?.hoursSinceOverhaul ?? -1,
        hoursSinceRepair:
          data?.complianceActivity?.partsTransaction?.hoursSinceRepair ?? -1,
        cyclesSinceNew:
          data?.complianceActivity?.partsTransaction?.cyclesSinceNew ?? -1,
        cyclesSinceOverhaul:
          data?.complianceActivity?.partsTransaction?.cyclesSinceOverhaul ?? -1,
        cyclesSinceRepair:
          data?.complianceActivity?.partsTransaction?.cyclesSinceRepair ?? -1,
      },
    })
  }, [loading, complianceActivity.id])

  const oldFiles =
    data?.complianceActivity?.partsTransaction?.removedPartFiles ?? []
  const newFiles =
    data?.complianceActivity?.partsTransaction?.addedPartFiles ?? []

  const disableRemovalReason =
    !data?.complianceActivity?.maintenanceItem?.aircraftComponent?.partNumber &&
    !data?.complianceActivity?.maintenanceItem?.aircraftComponent?.serialNumber
  return (
    <div className="p-3">
      <div>
        <Typography variant="subtitle1" fontWeight="bold">
          Parts Information
        </Typography>
      </div>
      <div className="mt-2 grid grid-cols-2 gap-2">
        <div className="flex flex-col gap-2 ">
          <Typography variant="subtitle2" fontWeight={'bold'}>
            Part Removed
          </Typography>
          <TextField
            name="parts.removed.partNumber"
            size="small"
            label="Number"
            variant="outlined"
            disabled
          />
          <TextField
            name="parts.removed.serialNumber"
            size="small"
            label="Serial"
            variant="outlined"
            disabled
          />
          <SelectDropdown
            name="parts.removalReason"
            disabled={disableRemovalReason || !canUpdateCompliance}
            options={[
              'Scheduled',
              'Premature Removal',
              'Maintenance Issue',
              'Life Limit Item',
              'Timed Item',
              'Failure',
              'Worn',
              'Other',
            ].map((status) => ({ label: status, value: status }))}
            label="Removal Reason"
          />
          {removalReason === 'Other' && (
            <TextField
              name="parts.otherRemovalReason"
              size="small"
              label="Other Reason"
              variant="outlined"
              disabled={!canUpdateCompliance}
            />
          )}
          <FileUploadFieldMulti
            name="parts.removedPartFile"
            renderValue={FileValue}
            button={
              <Button
                className="w-fit"
                variant="outlined"
                color="base"
                size="small"
                startIcon={<AttachFileIcon />}
                locked={!canUpdateCompliance}
                lockedTooltip="You do not have permission"
              >
                Attach File
              </Button>
            }
          />
          {oldFiles.length > 0 && (
            <div>
              <Typography variant="subtitle2" fontWeight="bold">
                Uploaded files:
              </Typography>
              {oldFiles.map((file) => (
                <div
                  key={file.id}
                  className="my-0 flex items-center justify-between px-2"
                >
                  <Typography variant="body2">
                    {file.originalFilename}
                  </Typography>
                  <IconButton onClick={() => window.open(file.url, '_blank')}>
                    <CloudDownloadOutlinedIcon fontSize="small" />
                  </IconButton>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="flex flex-col gap-2 ">
          <div className="flex items-center justify-between">
            <Typography variant="subtitle2" fontWeight={'bold'}>
              Part Installed
            </Typography>
          </div>
          <TextField
            name="parts.added.partNumber"
            size="small"
            label="Number"
            variant="outlined"
            disabled={!canUpdateCompliance}
          />
          <TextField
            name="parts.added.serialNumber"
            size="small"
            label="Serial"
            variant="outlined"
            disabled={!canUpdateCompliance}
          />
          <SelectDropdown
            name="parts.installedStatus"
            options={[
              'Altered',
              'Inspected',
              'Modified',
              'New',
              'Not Specified',
              'Overhauled',
              'Other',
              'Repaired',
              'Serviceable',
            ].map((status) => ({ label: status, value: status }))}
            label="Installed Status"
            onChange={() => {}}
            disabled={!canUpdateCompliance}
          />
          {installedStatus === 'Other' && (
            <TextField
              name="parts.otherInstalledStatus"
              size="small"
              label="Other Installed Status"
              variant="outlined"
              disabled={!canUpdateCompliance}
            />
          )}
          <FileUploadFieldMulti
            name="parts.addedPartFile"
            renderValue={FileValue}
            button={
              <Button
                className="w-fit"
                variant="outlined"
                color="base"
                size="small"
                startIcon={<AttachFileIcon />}
                locked={!canUpdateCompliance}
                lockedTooltip="You do not have permission"
              >
                Attach File
              </Button>
            }
          />
          {newFiles.length > 0 && (
            <div>
              <Typography variant="subtitle2" fontWeight="bold">
                Uploaded files:
              </Typography>
              {newFiles.map((file) => (
                <div
                  key={file.id}
                  className="flex items-center justify-between px-2"
                >
                  <Typography variant="body2">
                    {file.originalFilename}
                  </Typography>
                  <IconButton onClick={() => window.open(file.url, '_blank')}>
                    <CloudDownloadOutlinedIcon fontSize="small" />
                  </IconButton>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="mt-3">
        <Typography variant="subtitle1" fontWeight="bold">
          Times Since Installed
        </Typography>
        <div className="mt-2 grid grid-cols-4 gap-2">
          <div className="flex flex-col gap-2 ">
            <div className="flex items-center justify-between">
              <Typography variant="subtitle2" fontWeight={'bold'}>
                Months
              </Typography>
            </div>
            <PartsTimeInputTextField
              name="parts.monthsSinceNew"
              label="New"
              disabled={!canUpdateCompliance}
            />
            <PartsTimeInputTextField
              name="parts.monthsSinceOverhaul"
              label="Overhaul"
              disabled={!canUpdateCompliance}
            />
            <PartsTimeInputTextField
              name="parts.monthsSinceRepair"
              label="Repair"
              disabled={!canUpdateCompliance}
            />
          </div>
          <div className="flex flex-col gap-2 ">
            <div className="flex items-center justify-between">
              <Typography variant="subtitle2" fontWeight={'bold'}>
                Hours
              </Typography>
            </div>
            <PartsTimeInputTextField
              name="parts.hoursSinceNew"
              label="New"
              disabled={!canUpdateCompliance}
            />
            <PartsTimeInputTextField
              name="parts.hoursSinceOverhaul"
              label="Overhaul"
              disabled={!canUpdateCompliance}
            />
            <PartsTimeInputTextField
              name="parts.hoursSinceRepair"
              label="Repair"
              disabled={!canUpdateCompliance}
            />
          </div>
          <div className="flex flex-col gap-2 ">
            <div className="flex items-center justify-between">
              <Typography variant="subtitle2" fontWeight={'bold'}>
                Landings
              </Typography>
            </div>
            <PartsTimeInputTextField
              name="parts.cyclesSinceNew"
              label="New"
              disabled={!canUpdateCompliance}
            />
            <PartsTimeInputTextField
              name="parts.cyclesSinceOverhaul"
              label="Overhaul"
              disabled={!canUpdateCompliance}
            />
            <PartsTimeInputTextField
              name="parts.cyclesSinceRepair"
              label="Repair"
              disabled={!canUpdateCompliance}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PartsForm
