import { useEffect } from 'react'

import { LoadingButton } from '@mui/lab'
import { Button, Typography } from '@mui/material'

import DueListTableFilters from '../DueListTableFilters/DueListTableFiltersV2'

import TableWithData from './TableWithData/indexV2'
import useMaintenanceItemTable from 'src/hooks/useMaintenanceItemTable'

interface DueListPickerV2Props {
  componentID?: string
  aircraftIds?: string[]
  onlyDueItems?: boolean
  aircraftFilterEnabled?: boolean
  tableHeightSx?: string
  onAdd: (selectedTasks: string[]) => void
  onDismiss: () => void
  onSelectItem?: (id: string | string[], isSelect?: boolean) => void
}

const DueListPickerV2: React.FC<DueListPickerV2Props> = ({
  componentID,
  onlyDueItems = true,
  aircraftFilterEnabled = false,
  aircraftIds = [],
  tableHeightSx,
  onAdd,
  onDismiss,
  onSelectItem,
}) => {
  const tableVariant = 'maintenanceItems' // The due list picker should only appear in screens not on the actual due list or maintenance item tables, so just use maintenanceItems as the default
  const [loading, setLoading] = React.useState(false)
  const { selectedItemIds, unselectAll } = useMaintenanceItemTable(tableVariant)

  useEffect(() => {
    // Fire once to clean due list selected state on mount.
    // e.g. user that selected items in due list page does not reset when navigating to this page.
    unselectAll()
  }, [])

  return (
    <div className="mt-2 overflow-x-scroll rounded-2xl p-2">
      <div className="flex items-center justify-between">
        <Typography variant="h6" component="h6" className="mb-2">
          {selectedItemIds.length} Selected Tasks
        </Typography>
        <div className="flex items-center">
          <div className="flex items-center gap-2">
            <Button
              onClick={() => onDismiss()}
              size="small"
              variant="outlined"
              color="base"
            >
              Back
            </Button>
            <LoadingButton
              onClick={async () => {
                setLoading(true)
                try {
                  await onAdd(selectedItemIds)
                } finally {
                  setLoading(false)
                }
              }}
              size="small"
              loading={loading}
            >
              Add
            </LoadingButton>
          </div>
        </div>
      </div>

      <DueListTableFilters
        aircraftFilterEnabled={aircraftFilterEnabled}
        tableVariant={tableVariant}
      />
      <TableWithData
        variant={tableVariant}
        onSelectItem={onSelectItem}
        queryVariables={{
          isChildItem: false,
          parentId: null,
          showInProgress: false,
          componentID,
          onlyDueItems,
          aircraftIds,
        }}
        discriminator="dueListPicker"
        enableRowVirtualization={true}
        tableHeightSx={tableHeightSx}
      />
    </div>
  )
}

export default DueListPickerV2
