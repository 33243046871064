import React from 'react'
import WorkflowHeader from 'src/components/WorkflowHeader'
import PurchaseOrderStatusChip from '../PurchaseOrdersPageV2Page/PurchaseOrderStatusChip'
import Button from 'src/components/MUI/Button'
import { useDispatch } from 'src/hooks/useDispatch'
import { openModal } from 'src/slices/modal'
import useHasPermission from 'src/hooks/useHasPermission'
import { Permissions } from '@wingwork/common/src/constants/permissions'
import { UseFormReturn } from '@redwoodjs/forms'
import { FieldValues } from '@redwoodjs/forms'
import { t } from 'i18next'
import dayjs from 'dayjs'
import { abbreviateFullName } from 'src/utils/stringFormatters'
import { centsToDollars, dollarsToCents } from 'src/utils/helpers'
import { toast } from '@redwoodjs/web/toast'
import { Typography } from '@mui/material'
import { User } from 'types/graphql'

interface PurchaseOrderDraftHeaderProps {
  data: {
    purchaseOrder: {
      status: string
      number: string
      createdAt: string
      authorizedBy: User
    }
  }
  formMethods: UseFormReturn<FieldValues, any, undefined>
  handlePOSubmit: (values: any) => Promise<void>
}

const PurchaseOrderDraftHeader: React.FC<PurchaseOrderDraftHeaderProps> = ({
  data,
  formMethods: poFormMethods,
  handlePOSubmit,
}) => {
  const dispatch = useDispatch()

  const handleSaveSubmit = async () => {
    poFormMethods.clearErrors()
    await handlePOSubmit(poFormMethods.getValues())
  }

  const handleReviewPOSubmit = async () => {
    // save first
    await handleSaveSubmit()

    // trigger error validation before generating PDF
    const isFormValid = await poFormMethods.trigger()

    if (!isFormValid) {
      toast.error('Please fix the errors to Review PO')
      return
    }
    // generate PDF
    const {
      requestedDeliveryDate,
      paymentTerms,
      parts,
      services,
      other,
      ...restPOFormValues
    } = poFormMethods.getValues()
    const partsWithTotalCost = Object.values(parts).map((part: any) => ({
      ...part,
      condition: t(`purchaseOrderItem.condition.${part.condition}`),
      cost: parseFloat(part.cost ?? 0),
      totalCost: parseFloat(
        centsToDollars(dollarsToCents(part.cost ?? 0) * (part.quantity ?? 0))
      ),
    }))
    // calculate the totalcost of the parts and services
    const servicesArray = Object.values(services).map((service: any) => ({
      ...service,
      cost: parseFloat(service.cost ?? 0),
    }))
    const otherLineItemsArray = Object.values(other).map((item: any) => ({
      ...item,
      cost: parseFloat(item.cost ?? 0),
    }))

    const totalCost =
      partsWithTotalCost.reduce(
        (acc, part) => acc + dollarsToCents(part.totalCost),
        0
      ) +
      servicesArray.reduce(
        (acc, service: any) => acc + dollarsToCents(service.cost),
        0
      ) +
      otherLineItemsArray.reduce(
        (acc, lineItem: any) => acc + dollarsToCents(lineItem.cost),
        0
      )

    const pdfData = {
      ...restPOFormValues,
      requestedDeliveryDate: dayjs(requestedDeliveryDate).format(
        'DD MMM, YYYY'
      ),
      paymentTerms: t(
        `purchaseOrder.paymentTerms.${paymentTerms}`,
        paymentTerms
      ),
      parts: partsWithTotalCost,
      services: servicesArray,
      other: otherLineItemsArray,
      authorizedBy: abbreviateFullName(data.purchaseOrder.authorizedBy),
      totalCost: parseFloat(centsToDollars(totalCost)),
      dateCreated: dayjs().format('DD MMM, YYYY hh:mm A'),
    }

    dispatch(openModal({ name: 'purchaseOrderReviewModal', data: { pdfData } }))
  }

  return (
    <WorkflowHeader
      title="New Purchase Order"
      createdAt={data?.purchaseOrder?.createdAt}
      // tailNumber={data?.purchaseOrder?.aircraft?.tailNumber}
      slots={{
        statusChip: (
          <PurchaseOrderStatusChip
            status={data?.purchaseOrder?.status}
            chipSize="small"
          />
        ),
        inlineFields: (
          <Typography variant="body2" color="text.secondary">
            Authorized by{' '}
            {abbreviateFullName(data?.purchaseOrder?.authorizedBy)}
          </Typography>
        ),
        topRightFields: (
          <>
            <Button
              color="pastelRed"
              variant="text"
              size="large"
              onClick={() =>
                dispatch(
                  openModal({
                    name: 'confirmationModal',
                    data: { discriminator: 'cancelPO' },
                  })
                )
              }
              locked={!useHasPermission(Permissions.purchaseOrder.cancel)}
              lockedTooltip="You do not have permission"
            >
              CANCEL
            </Button>
            <Button
              color="base"
              variant="outlined"
              size="large"
              onClick={() =>
                dispatch(openModal({ name: 'requestedLineItemsPickerModal' }))
              }
              locked={!useHasPermission(Permissions.purchaseOrder.addItems)}
              lockedTooltip="You do not have permission"
            >
              REQUESTED ITEMS
            </Button>
            <Button
              color="base"
              variant="outlined"
              size="large"
              onClick={handleSaveSubmit}
              locked={!useHasPermission(Permissions.purchaseOrder.update)}
              lockedTooltip="You do not have permission"
            >
              SAVE
            </Button>
            <Button
              color="primary"
              variant="outlined"
              size="large"
              onClick={handleReviewPOSubmit}
              locked={
                !(
                  useHasPermission(Permissions.purchaseOrder.update) &&
                  useHasPermission(Permissions.purchaseOrder.complete)
                )
              }
              lockedTooltip="You do not have permission"
            >
              REVIEW PURCHASE ORDER
            </Button>
          </>
        ),
      }}
    />
  )
}

export default PurchaseOrderDraftHeader
