import {
  ComplianceActivity,
  ComplianceActivityStatus,
  CustomInternalWorkItem,
  InternalWorkItem,
  LaborTransaction,
  PartsTransaction,
  User,
} from 'types/graphql'

export interface MassagedMtxItem {
  id: string
  title: string
  trackedByComponentName: string
  ataCode: string
  manufactureCode: string
  ataManufactureCode: string
  parentId?: string
  relatedMaintenanceItemIds: string[]
  componentID?: string
  childItems: MassagedMtxItem[]
  discrepancyStatus?: string
}

export interface MassagedComplianceActivity {
  id: string
  status: ComplianceActivityStatus
  mtxItem?: MassagedMtxItem
  discrepancyItems?: MassagedMtxItem[]
  childItems: MassagedComplianceActivity[]
  title?: string
  description?: string
  workedById?: string
  correctiveAction?: string
  notes?: string
  workedBy?: User
  laborTransactions?: LaborTransaction[]
  partsTransaction?: PartsTransaction
  rate?: number
  rateType?: string
  partCost?: number
  workMinutes?: number
  isWorkOrder: boolean
  isCustom: boolean
}

export const massageTasks = (
  tasks: ComplianceActivity[] | InternalWorkItem[] | CustomInternalWorkItem[],
  isWorkOrder: boolean,
  areCustomTasks = false
): MassagedComplianceActivity[] => {
  const massagedData = tasks.map((activity) => {
    const getMassagedMtxItem = () => {
      return {
        id: activity.maintenanceItem.id,
        title: activity.maintenanceItem.title,
        trackedByComponentName:
          activity.maintenanceItem.trackedByComponent.name,
        ataCode: activity.maintenanceItem.ataCode,
        manufactureCode: activity.maintenanceItem.manufactureCode,
        ataManufactureCode: activity.maintenanceItem.ataManufactureCode,
        parentId: activity.maintenanceItem.parentId,
        relatedMaintenanceItemIds:
          activity.maintenanceItem.relatedMaintenanceItemIds,
        componentID: activity.maintenanceItem.componentID,
        childItems: [],
      }
    }

    const getMassagedTask = () => {
      const massagedMtxItem = getMassagedMtxItem()
      return {
        id: activity.id,
        status: activity.status,
        mtxItem: massagedMtxItem,
        discrepancyItems: activity?.discrepancyItems || [],
        childItems:
          (!isWorkOrder
            ? activity?.childActivities
            : activity?.childWorkItems) || [],
        title: null, //passing null because it's not a custom work item
        description: activity?.description,
        workedById: activity?.workedById,
        correctiveAction: activity?.correctiveAction,
        notes: activity?.notes || null,
        workedBy: activity?.workedBy,
        laborTransactions: activity?.laborTransactions || [],
        partsTransaction: activity?.partsTransaction || null,
        rate: activity?.rate,
        rateType: activity?.rateType,
        partCost: activity?.partCost,
        workMinutes: activity?.workMinutes,
        isWorkOrder,
        isCustom: false,
      }
    }

    const getMassagedCustomTask = () => {
      return {
        id: activity.id,
        status: activity.status,
        mtxItem: null,
        discrepancyItems: [],
        childItems: [],
        title: activity.title,
        description: activity?.description,
        workedById: activity?.workedById,
        correctiveAction: activity?.correctiveAction,
        notes: activity?.notes || null,
        workedBy: activity?.workedBy,
        laborTransactions: activity?.laborTransactions || [],
        rate: activity?.rate,
        rateType: activity?.rateType,
        partCost: activity?.partCost,
        workMinutes: activity?.workMinutes,
        isWorkOrder,
        isCustom: true,
      }
    }

    return areCustomTasks ? getMassagedCustomTask() : getMassagedTask()
  })
  return massagedData
}
