import { WorkOrderComplianceStateData } from 'src/types'

import { RootState } from '../reducers'
import { setWorkOrderComplianceStateData } from '../slices/workOrderSlice'

import { useDispatch } from './useDispatch'
import { useSelector } from './useSelector'

interface WorkCompletedTableFiltersHook {
  workOrderState: WorkOrderComplianceStateData
  setWorkOrderComplianceState: (data: WorkOrderComplianceStateData) => void
  setWorkOrderType: (workOrderType: 'INTERNAL' | 'EXTERNAL') => void
  setWorkOrderAircraftUsageLogId: (id: string) => void
  setWorkOrderDetailDrawerTaskId: (id: string) => void
  setWorkOrderDetailDrawerCustomTaskId: (id: string) => void
}

const useWorkOrderVitals = (): WorkCompletedTableFiltersHook => {
  const dispatch = useDispatch()
  const workOrderState: WorkOrderComplianceStateData = useSelector(
    (state: RootState) => state.workOrder.data
  )

  const setWorkOrderComplianceState = (
    data: WorkOrderComplianceStateData
  ): void => {
    dispatch(setWorkOrderComplianceStateData(data))
  }

  const setWorkOrderType = (workOrderType: 'INTERNAL' | 'EXTERNAL'): void => {
    setWorkOrderComplianceState({
      ...workOrderState,
      workOrderType,
    })
  }

  const setWorkOrderAircraftUsageLogId = (id: string): void => {
    setWorkOrderComplianceState({
      ...workOrderState,
      aircraftUsageLogId: id,
    })
  }

  const setWorkOrderDetailDrawerTaskId = (id: string): void => {
    setWorkOrderComplianceState({
      ...workOrderState,
      detailDrawerTaskId: id,
      detailDrawerCustomTaskId: null,
    })
  }

  const setWorkOrderDetailDrawerCustomTaskId = (id: string): void => {
    setWorkOrderComplianceState({
      ...workOrderState,
      detailDrawerCustomTaskId: id,
      detailDrawerTaskId: null,
    })
  }

  return {
    workOrderState,
    setWorkOrderComplianceState,
    setWorkOrderType,
    setWorkOrderAircraftUsageLogId,
    setWorkOrderDetailDrawerTaskId,
    setWorkOrderDetailDrawerCustomTaskId,
  }
}

export default useWorkOrderVitals
