import dayjs, { Dayjs } from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { isEmpty } from 'lodash'
import { object, number, date, InferType, string } from 'yup'

import { MaintenanceNextDue, ComponentUsageLog } from '../graphql'
import { roundToPrecision } from '../helpers'

import { CadenceValue } from './cadenceValue'

dayjs.extend(timezone)
dayjs.extend(utc)

const remainingValueUnitSchema = object()
  .shape({
    nextDueValue: number().positive().required(),
    value: number().positive().required(),
    toleranceValue: number().positive().required(),
    toleranceString: string().optional().default(undefined),
    valString: string().optional().default(undefined),
    status: string().required(),
    note: string().optional().default(undefined),
  })
  .optional()
  .default(undefined)

const dateValueUnitSchema = object()
  .shape({
    nextDueValue: date().required(),
    value: number().positive().required(),
    remMonths: number().positive().required(),
    remDays: number().positive().required(),
    toleranceValue: number().positive().required(),
    status: string().required(),
    toleranceString: string().optional().default(undefined),
    valString: string().optional().default(undefined),
    note: string().optional().default(undefined),
  })
  .optional()
  .default(undefined)

export const remainingValueScheme = object({
  flying_hours: remainingValueUnitSchema,
  cycles: remainingValueUnitSchema,
  landings: remainingValueUnitSchema,
  date: dateValueUnitSchema,
  status: string()
    .required()
    .oneOf(['APPROACHING_DUE', 'GOOD', 'IN_TOLERANCE', 'NOT_DUE', 'OVERDUE'])
    .default(undefined),
  note: string().optional().default(undefined),
})

const DEFAULT_APPROACHING_DUE_CONFIG = {
  flying_hours: 10,
  landings: 4,
  cycles: 4,
  date: 7,
}

export const UNIT_MAP = {
  flying_hours: 'H',
  landings: 'L',
  cycles: 'C',
  days: 'D',
  date: 'M', // Since tolerance for date is in months
  months: 'M',
}

export type RemainingValue = InferType<typeof remainingValueScheme>

export const getRemainingDaysInMonthsDays = (
  startDate: Dayjs,
  endDate: Dayjs
) => {
  const remainingMonths = endDate.diff(startDate, 'months')
  const addMonthsToDate = startDate.add(remainingMonths, 'M')
  const remainingDays = endDate.diff(addMonthsToDate, 'days')
  return {
    months: remainingMonths,
    days: remainingDays,
    asString: `${remainingMonths}M ${remainingDays}D`,
  }
}

export const getRemainingValueFromNextDueValue = (
  mtxNextDue: Pick<MaintenanceNextDue, 'nextDueValue' | 'nextDueOverride'>,
  mtxCadenceValue: CadenceValue,
  latestUsageLog: Pick<ComponentUsageLog, 'totalTimeSinceNew' | 'cycleSinceNew'>
): RemainingValue => {
  const isOverrideProvided = !isEmpty(mtxNextDue.nextDueOverride)
  const nextDueValue = isOverrideProvided
    ? mtxNextDue.nextDueOverride
    : mtxNextDue.nextDueValue
  const remainingValue: RemainingValue = {}
  // NOTE: We make an assumption that override value will be complete and that we don't need
  // merge it with the calculated nextDueValue
  const fields = ['date', 'flying_hours', 'cycles', 'landings']
  fields
    .filter(
      (key) => nextDueValue?.[key] !== undefined && nextDueValue?.[key] !== null
    )
    .forEach((key) => {
      if (key === 'date') {
        const currentDate = dayjs.utc()
        const nextDueValueDate = dayjs.utc(nextDueValue[key]).endOf('day')
        const remValue = dayjs.utc(nextDueValue[key]).diff(currentDate, 'days')
        const toleranceValueMonths = mtxCadenceValue.tolerance?.months
        const toleranceValueDays = mtxCadenceValue.tolerance?.days
        remainingValue[key] = {
          nextDueValue: nextDueValue[key],
          value: remValue,
          toleranceValue: toleranceValueMonths || toleranceValueDays,
        }
        if (remValue < 0) {
          if (toleranceValueMonths || toleranceValueDays) {
            // ASSUMPTION: Tolerance wont be defined in both months and days, it will be either one
            const dueDateWithTolerance = toleranceValueMonths
              ? nextDueValueDate
                  .add(toleranceValueMonths, 'months')
                  .endOf('day')
              : nextDueValueDate.add(toleranceValueDays, 'days').endOf('day')
            const remainingValueWithTolerance = dueDateWithTolerance.diff(
              currentDate,
              'days'
            )
            if (remainingValueWithTolerance > 0) {
              remainingValue[key]['status'] = 'IN_TOLERANCE'
              remainingValue[key]['toleranceValue'] =
                remainingValueWithTolerance
              const { months, days, asString } = getRemainingDaysInMonthsDays(
                currentDate,
                dueDateWithTolerance
              )
              remainingValue[key]['valString'] = asString
              remainingValue[key]['remMonths'] = months
              remainingValue[key]['remDays'] = days
            } else {
              remainingValue[key]['status'] = 'OVERDUE'
              remainingValue[key]['toleranceValue'] = 0
              remainingValue[key]['valString'] = `Overdue ${UNIT_MAP[key]}`
            }
          } else {
            remainingValue[key]['status'] = 'OVERDUE'
            remainingValue[key]['valString'] = `Overdue ${UNIT_MAP[key]}`
          }
        } else if (remValue <= DEFAULT_APPROACHING_DUE_CONFIG[key]) {
          remainingValue[key]['status'] = 'APPROACHING_DUE'
          const { months, days, asString } = getRemainingDaysInMonthsDays(
            currentDate,
            nextDueValueDate
          )
          remainingValue[key]['valString'] = asString
          remainingValue[key]['remMonths'] = months
          remainingValue[key]['remDays'] = days
          if (toleranceValueMonths) {
            remainingValue[key]['toleranceString'] = `${toleranceValueMonths} M`
          } else if (toleranceValueDays) {
            remainingValue[key]['toleranceString'] = `${toleranceValueDays} D`
          }
        } else {
          remainingValue[key]['status'] = 'GOOD'
          const { months, days, asString } = getRemainingDaysInMonthsDays(
            currentDate,
            nextDueValueDate
          )
          remainingValue[key]['valString'] = asString
          remainingValue[key]['remMonths'] = months
          remainingValue[key]['remDays'] = days
          if (toleranceValueMonths) {
            remainingValue[key]['toleranceString'] = `${toleranceValueMonths} M`
          } else if (toleranceValueDays) {
            remainingValue[key]['toleranceString'] = `${toleranceValueDays} D`
          }
        }
      } else {
        const remValue = roundToPrecision(
          nextDueValue[key] -
            latestUsageLog[
              key === 'flying_hours' ? 'totalTimeSinceNew' : 'cycleSinceNew'
            ]
        )
        const toleranceValue = mtxCadenceValue.tolerance?.[key]
        remainingValue[key] = {
          nextDueValue: nextDueValue[key],
          value: remValue,
          toleranceValue: toleranceValue,
        }
        if (remValue < 0) {
          if (toleranceValue) {
            const remainingValueInTolerance = roundToPrecision(
              toleranceValue - Math.abs(remValue)
            )
            if (remainingValueInTolerance > 0) {
              remainingValue[key]['status'] = 'IN_TOLERANCE'
              remainingValue[key]['toleranceValue'] = remainingValueInTolerance
              remainingValue[key][
                'valString'
              ] = `${remainingValueInTolerance} ${UNIT_MAP[key]}`
            } else {
              remainingValue[key]['status'] = 'OVERDUE'
              remainingValue[key]['toleranceValue'] = 0
              remainingValue[key]['valString'] = `Overdue ${UNIT_MAP[key]}`
            }
          } else {
            remainingValue[key]['status'] = 'OVERDUE'
            remainingValue[key]['valString'] = `Overdue ${UNIT_MAP[key]}`
          }
        } else if (remValue <= DEFAULT_APPROACHING_DUE_CONFIG[key]) {
          remainingValue[key]['status'] = 'APPROACHING_DUE'
          remainingValue[key]['valString'] = `${roundToPrecision(remValue)} ${
            UNIT_MAP[key]
          }`
          if (toleranceValue) {
            remainingValue[key]['toleranceString'] = `${roundToPrecision(
              toleranceValue
            )} ${UNIT_MAP[key]}`
          }
        } else {
          remainingValue[key]['status'] = 'GOOD'
          remainingValue[key]['valString'] = `${roundToPrecision(remValue)} ${
            UNIT_MAP[key]
          }`
          if (toleranceValue) {
            remainingValue[key]['toleranceString'] = `${roundToPrecision(
              toleranceValue
            )} ${UNIT_MAP[key]}`
          }
        }
      }
    })
  const status = Object.keys(remainingValue).reduce(
    (acc, key) => {
      if (acc.status === 'OVERDUE') return acc
      if (acc.status === 'IN_TOLERANCE') {
        if (remainingValue[key].status === 'OVERDUE') {
          return { status: remainingValue[key].status }
        }
      }
      if (acc.status === 'APPROACHING_DUE') {
        if (
          remainingValue[key].status === 'OVERDUE' ||
          remainingValue[key].status === 'IN_TOLERANCE'
        ) {
          return { status: remainingValue[key].status }
        }
      }
      if (acc.status === 'GOOD' || acc.status === 'NOT_DUE') {
        return { status: remainingValue[key].status }
      }
      return acc
    },
    { status: 'NOT_DUE' } // Defaulting to NOT_DUE because if there is no remaining value, it is not due
  )

  return { ...remainingValue, ...status }
}

export const getRemainingValueAsStringArray = (
  remainingValue: RemainingValue
) => {
  const remainingValues = []
  const fields = ['date', 'flying_hours', 'cycles', 'landings']
  fields.forEach((field) => {
    if (remainingValue[field]) {
      remainingValues.push(remainingValue[field].valString)
    }
  })
  return remainingValues
}

export const getCalculatedNextDueDate = (
  remainingValue: RemainingValue,
  dailyFlyingTime: number,
  dailyLandings: number
): Date => {
  const { flying_hours, cycles, landings, date } = remainingValue
  const dates: Date[] = []
  const currentDate = dayjs()
  if (date) {
    dates.push(dayjs.utc(date.nextDueValue).toDate())
  }
  if (flying_hours) {
    const hoursToNextDue = Math.floor(flying_hours.value / dailyFlyingTime)
    dates.push(currentDate.add(hoursToNextDue, 'days').toDate())
  }
  if (cycles) {
    const cyclesToNextDue = Math.floor(cycles.value / dailyLandings)
    dates.push(currentDate.add(cyclesToNextDue, 'days').toDate())
  }
  if (landings) {
    const landingsToNextDue = Math.floor(landings.value / dailyLandings)
    dates.push(currentDate.add(landingsToNextDue, 'days').toDate())
  }
  return dates.reduce((acc, date) => {
    if (acc === undefined) return date
    if (date < acc) return date
    return acc
  }, undefined)
}

export const getCalculatedNextDueDateForSelectedUsageLogByCadence = (
  usageAsOf: string,
  cadenceValue: CadenceValue,
  dailyFlyingTime: number,
  dailyLandings: number
): Date => {
  const { flying_hours, cycles, landings, days, months } = cadenceValue
  const dates: Date[] = []
  const usageAsOfDate = dayjs(usageAsOf)
  if (days) {
    dates.push(usageAsOfDate.add(days, 'days').toDate())
  }
  if (months) {
    dates.push(usageAsOfDate.add(months, 'months').toDate())
  }
  if (flying_hours) {
    const hoursToNextDue = Math.floor(flying_hours / dailyFlyingTime)
    dates.push(usageAsOfDate.add(hoursToNextDue, 'days').toDate())
  }
  if (cycles) {
    const cyclesToNextDue = Math.floor(cycles / dailyLandings)
    dates.push(usageAsOfDate.add(cyclesToNextDue, 'days').toDate())
  }
  if (landings) {
    const landingsToNextDue = Math.floor(landings / dailyLandings)
    dates.push(usageAsOfDate.add(landingsToNextDue, 'days').toDate())
  }
  return dates.reduce((acc, date) => {
    if (acc === undefined) return date
    if (date < acc) return date
    return acc
  }, undefined)
}
