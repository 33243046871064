import { useSelector } from 'react-redux'
import { RootState } from '../store'

interface InlineFieldState {
  /** Whether any field has unsaved changes */
  isDirty: boolean

  /** Whether any field is currently being submitted */
  isSubmitting: boolean

  /** Whether any field has a server/submission error */
  hasError: boolean

  /** Whether any field has a validation error */
  hasValidationError: boolean

  /** Whether any field has been touched by user interaction */
  isTouched: boolean

  /**
   * Whether all fields are in a valid state
   * Returns undefined if no fields have been validated
   */
  isValid: boolean | undefined

  /**
   * Whether all fields are in a settled state:
   * - No fields are submitting
   * - No fields are validating
   * - No unsaved changes (not dirty)
   * - No errors (validation or submission)
   *
   * This is a high-level "everything is good" state that can be used to:
   * - Determine if it's safe to navigate away
   * - Allow other server actions to proceed
   * - Enable/disable non-field-related operations
   * - Trigger side effects that depend on stable field states
   *
   * Think of this as a "green light" for any operations that shouldn't
   * happen while fields are in flux or have pending changes.
   */
  isSettled: boolean
}

export const useInlineFieldState = (): InlineFieldState => {
  const inlineState = useSelector((state: RootState) => state.inline)

  return {
    // Basic states
    isDirty: inlineState.dirty,
    isSubmitting: inlineState.submitting,
    hasError: inlineState.error,
    hasValidationError: inlineState.validationError,
    isTouched: inlineState.touched,

    // Computed states
    isValid: Object.values(inlineState.fields).some((f) => f.touched)
      ? Object.values(inlineState.fields)
          .filter((f) => f.touched)
          .every((f) => f.valid === true)
      : undefined,

    isSettled:
      !inlineState.submitting &&
      !inlineState.dirty &&
      !inlineState.error &&
      !inlineState.validationError &&
      !Object.values(inlineState.fields).some((f) => f.validating),
  }
}
