import * as Yup from 'yup'

export const optionalNumberSchema = Yup.number()
  .nullable()
  .transform((value, originalValue) =>
    typeof originalValue === 'string' && originalValue.trim() === ''
      ? null
      : value
  )

export const optionalDateSchema = Yup.string()
  .nullable()
  .test('is-valid-date', 'Enter Valid Date', (value) => {
    if (!value) return true
    return !isNaN(Date.parse(value))
  })
