import { Button, Typography, IconButton } from '@mui/material'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'

import { useFormContext, useWatch } from '@redwoodjs/forms'

type UploadFieldProps = {
  name: string
  accept?: string
  button?: React.ReactNode
  multiple?: boolean
  renderValue?: (value: any, clearValue: () => void) => React.ReactNode
}
const FileUploadField: React.FC<UploadFieldProps> = ({
  name,
  accept,
  button,
  renderValue,
  multiple = false,
}) => {
  const { register, setValue } = useFormContext()
  const value = useWatch({ name })

  const handleButtonClick = () => {
    const input = document.getElementById(name) as HTMLInputElement
    input.click()
  }

  const handleClearFile = () => {
    setValue(name, null)
  }
  const handleChange = (event) => {
    // Clear old value if not multiple
    if (!multiple) {
      setValue(name, Array.from(event.target.files))
    } else {
      setValue(name, [...(value ?? []), ...Array.from(event.target.files)])
    }
  }

  const renderButton = !(value && renderValue)
  const ButtonToRender = button ? (
    React.cloneElement(button as React.ReactElement, {
      onClick: handleButtonClick,
    })
  ) : (
    <Button onClick={handleButtonClick}>Upload</Button>
  )

  return (
    <div>
      <input
        type="file"
        id={name}
        name={name}
        accept={accept}
        style={{ display: 'none' }}
        multiple={multiple}
        {...register(name)}
        onChange={handleChange}
      />
      {/* renders provided button or default button */}
      {/* unless value is populated AND renderValue is provided */}
      {renderButton && ButtonToRender}

      {/* renders the output of renderValue if value is populated */}
      {value && renderValue && renderValue(value, handleClearFile)}
    </div>
  )
}

export const RenderFileValue = (value, clear) => (
  <div className="flex items-center justify-between">
    <Typography className="overflow-hidden text-ellipsis whitespace-nowrap">
      {value?.[0]?.name}
    </Typography>
    <IconButton onClick={clear} size="small">
      <ClearRoundedIcon fontSize="small" />
    </IconButton>
  </div>
)

export default FileUploadField
