export const SET_WORK_INFORMATION = gql`
  mutation SetWorkInformation(
    $input: UpdateComplianceActivityInput!
    $id: String!
  ) {
    updateComplianceActivity(input: $input, id: $id) {
      id
      workedBy {
        id
        firstName
        lastName
      }
      workHours
      notes
      description
    }
  }
`

export const GET_MECHANICS = gql`
  query GetMechanics {
    users {
      id
      firstName
      lastName
    }
  }
`
