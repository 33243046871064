import React, { useMemo } from 'react'
import { useEffect, useRef } from 'react'

import isEqual from 'lodash/isEqual'

import { routes } from '@redwoodjs/router'

import { useDispatch } from 'src/hooks/useDispatch'
import { useSelector } from 'src/hooks/useSelector'
import { RootState } from 'src/reducers'
import {
  initialFilterData as initialDueListFilterData,
  setDuelistFiltersData,
  initialDuelistPrevDeps,
  getDuelistSearchParams,
  areDuelistPrevDepValuesReset,
  getDueListDepsState,
  getDueListFiltersFromUrl,
  DueListSearchParams,
} from 'src/slices/dueListTableSlice'
import {
  ELogbookSearchParams,
  areELogbookPrevDepValuesReset,
  createELogbookFiltersObject,
  getELogbookSearchParams,
  initialFilterData as initialELogbookFilterData,
  setELogbookFiltersData,
  updateELogbookPrevDeps,
} from 'src/slices/eLogbooksFiltersSlice'
import { incrementHistoryCountBy } from 'src/slices/historyCountSlice'
import {
  areWorkCompletedPrevDepValuesReset,
  getWorkCompletedSearchParams,
  createWorkCompletedFiltersObject,
  initialFilterData as initialWorkCompletedFilterData,
  setWorkCompletedFiltersData,
  updateWorkCompletedPrevDeps,
  WorkCompletedSearchParams,
} from 'src/slices/workCompletedFiltersSlice'
import {
  WorkOrdersSearchParams,
  setWorkOrdersFiltersData,
  initialFilterData as initialWorkOrdersFilterData,
  getWorkOrdersSearchParams,
  updateWorkOrdersPrevDeps,
  areWorkOrdersPrevDepValuesReset,
  createWorkOrdersFiltersObject,
} from 'src/slices/workOrdersFiltersSlice'
import {
  DueListTableFilterData,
  DueListPrevDeps,
  WorkCompletedFiltersData,
  ELogbookTableFiltersData,
  WorkOrdersFiltersData,
} from 'src/types'
import { setMaintenanceItemFiltersData } from 'src/slices/maintenanceItemTableSlice'

interface SearchComponentProps {
  variant:
    | 'duelist'
    | 'maintenanceItems'
    | 'eLogbook'
    | 'workCompleted'
    | 'pastCompliance'
    | 'workOrders-scheduled'
    | 'workOrders-completed'
    | 'workOrders-myorders'
  orgName: string
}

type FiltersData =
  | DueListTableFilterData
  | WorkCompletedFiltersData
  | ELogbookTableFiltersData
  | WorkOrdersFiltersData

type SearchParams =
  | DueListSearchParams
  | WorkCompletedSearchParams
  | ELogbookSearchParams
  | WorkOrdersSearchParams

type PrevDeps =
  | DueListPrevDeps
  | WorkCompletedFiltersData
  | ELogbookTableFiltersData
  | WorkOrdersFiltersData

type RouteParams = {
  orgName: string
} & SearchParams

interface ConfigurationInterface {
  filtersData: FiltersData
  setFiltersData: (filtersData: FiltersData) => void
  sessionKey: string
  initialFilterData: FiltersData
  initialPrevDeps: PrevDeps
  searchParamFunc: (filtersData: FiltersData) => SearchParams
  route: (routeParams: RouteParams) => string
  updatePrevDepsFunc: (filtersData: FiltersData) => PrevDeps
  arePrevDepValuesReset: (prevDeps: PrevDeps) => boolean
  createFiltersObject: (searchParams: URLSearchParams) => FiltersData
}

const SearchComponent: React.FC<SearchComponentProps> = ({
  variant,
  orgName,
}) => {
  const workOrdersConfig = {
    initialFilterData: initialWorkOrdersFilterData,
    initialPrevDeps: initialWorkOrdersFilterData,
    searchParamFunc: getWorkOrdersSearchParams,
    route: routes.workOrders,
    updatePrevDepsFunc: updateWorkOrdersPrevDeps,
    arePrevDepValuesReset: areWorkOrdersPrevDepValuesReset,
    createFiltersObject: createWorkOrdersFiltersObject,
  }
  const configuration: Record<string, ConfigurationInterface> = {
    duelist: {
      filtersData: useSelector(
        (state: RootState) => state.dueListTable.filters
      ),
      setFiltersData: (data: DueListTableFilterData) =>
        dispatch(setDuelistFiltersData(data)),
      sessionKey: 'duelistFiltersJSON',
      initialFilterData: initialDueListFilterData,
      initialPrevDeps: initialDuelistPrevDeps,
      searchParamFunc: getDuelistSearchParams,
      route: routes.dueList,
      updatePrevDepsFunc: getDueListDepsState,
      arePrevDepValuesReset: areDuelistPrevDepValuesReset,
      createFiltersObject: getDueListFiltersFromUrl,
    },
    maintenanceItems: {
      filtersData: useSelector(
        (state: RootState) => state.maintenanceItemTable.filters
      ),
      setFiltersData: (data: DueListTableFilterData) =>
        dispatch(setMaintenanceItemFiltersData(data)),
      sessionKey: 'maintenanceItemFiltersJSON',
      initialFilterData: initialDueListFilterData,
      initialPrevDeps: initialDuelistPrevDeps,
      searchParamFunc: getDuelistSearchParams,
      route: routes.maintenanceItems,
      updatePrevDepsFunc: getDueListDepsState,
      arePrevDepValuesReset: areDuelistPrevDepValuesReset,
      createFiltersObject: getDueListFiltersFromUrl,
    },
    eLogbook: {
      filtersData: useSelector((state) => state.eLogbookFilters.data),
      setFiltersData: (data) => dispatch(setELogbookFiltersData(data)),
      sessionKey: 'eLogbookFiltersJSON',
      initialFilterData: initialELogbookFilterData,
      initialPrevDeps: initialELogbookFilterData,
      searchParamFunc: getELogbookSearchParams,
      route: routes.logbookEntriesLanding,
      updatePrevDepsFunc: updateELogbookPrevDeps,
      arePrevDepValuesReset: areELogbookPrevDepValuesReset,
      createFiltersObject: createELogbookFiltersObject,
    },
    workCompleted: {
      filtersData: useSelector((state) => state.workCompletedFilters.data),
      setFiltersData: (data) => dispatch(setWorkCompletedFiltersData(data)),
      sessionKey: 'workCompletedFiltersJSON',
      initialFilterData: initialWorkCompletedFilterData,
      initialPrevDeps: initialWorkCompletedFilterData,
      searchParamFunc: getWorkCompletedSearchParams,
      route: routes.workCompleted,
      updatePrevDepsFunc: updateWorkCompletedPrevDeps,
      arePrevDepValuesReset: areWorkCompletedPrevDepValuesReset,
      createFiltersObject: createWorkCompletedFiltersObject,
    },
    pastCompliance: {
      // v2 of workCompleted page
      filtersData: useSelector((state) => state.workCompletedFilters.data),
      setFiltersData: (data) => dispatch(setWorkCompletedFiltersData(data)),
      sessionKey: 'workCompletedFiltersJSON',
      initialFilterData: initialWorkCompletedFilterData,
      initialPrevDeps: initialWorkCompletedFilterData,
      searchParamFunc: getWorkCompletedSearchParams,
      route: routes.pastCompliance,
      updatePrevDepsFunc: updateWorkCompletedPrevDeps,
      arePrevDepValuesReset: areWorkCompletedPrevDepValuesReset,
      createFiltersObject: createWorkCompletedFiltersObject,
    },
    ['workOrders-myorders']: {
      filtersData: useSelector(
        (state) => state.workOrdersFilters.pages.myorders.data
      ),
      setFiltersData: (data) =>
        dispatch(setWorkOrdersFiltersData({ name: 'myorders', data })),
      sessionKey: 'workOrdersFiltersJSON-myorders',
      ...workOrdersConfig,
    },
    ['workOrders-scheduled']: {
      filtersData: useSelector(
        (state) => state.workOrdersFilters.pages.scheduled.data
      ),
      setFiltersData: (data) =>
        dispatch(setWorkOrdersFiltersData({ name: 'scheduled', data })),
      sessionKey: 'workOrdersFiltersJSON-scheduled',
      ...workOrdersConfig,
    },
    ['workOrders-completed']: {
      filtersData: useSelector(
        (state) => state.workOrdersFilters.pages.completed.data
      ),
      setFiltersData: (data) =>
        dispatch(setWorkOrdersFiltersData({ name: 'completed', data })),
      sessionKey: 'workOrdersFiltersJSON-completed',
      ...workOrdersConfig,
    },
  }

  const variantConfig = configuration[variant]

  const sessionKey = useMemo(
    () => orgName + variantConfig.sessionKey,
    [orgName, variantConfig]
  )

  const dispatch = useDispatch()

  // Then use this function when initializing prevDepsForUrlUpdate
  const prevDepsForUrlUpdate = useRef(variantConfig.initialPrevDeps)

  const getTargetUrl = React.useCallback(
    (withFilters: boolean) => {
      const defaultRouteParams = {
        orgName: orgName,
        ...(variant.slice(0, 10) === 'workOrders' && {
          ordersDiscriminator: variant.slice(11),
        }),
      }
      if (!withFilters) {
        return variantConfig.route({
          ...defaultRouteParams,
        })
      }
      return variantConfig.route({
        ...defaultRouteParams,
        ...variantConfig.searchParamFunc(variantConfig.filtersData),
      })
    },
    [variantConfig.filtersData]
  )

  useEffect(() => {
    // update the session when the filters change
    if (!isEqual(variantConfig.filtersData, variantConfig.initialFilterData)) {
      sessionStorage.setItem(
        sessionKey,
        JSON.stringify(variantConfig.filtersData)
      )
    }

    // Update the URL when the filters change
    const currentUrl = window.location.pathname + window.location.search
    const targetUrl = !isEqual(
      variantConfig.filtersData,
      variantConfig.initialFilterData
    )
      ? getTargetUrl(true)
      : getTargetUrl(false)

    // Update the ref at this specific point in the code
    const updatePrevDeps = () => {
      prevDepsForUrlUpdate.current = variantConfig.updatePrevDepsFunc(
        variantConfig.filtersData
      )
    }

    // If all filters are deselected, navigate to the clean URL
    if (
      isEqual(variantConfig.filtersData, variantConfig.initialFilterData) &&
      currentUrl.includes('?')
    ) {
      if (variantConfig.arePrevDepValuesReset(prevDepsForUrlUpdate.current)) {
        sessionStorage.removeItem(sessionKey)
        window.history.pushState(
          {},
          '',
          window.location.origin + window.location.pathname
        )
      }
      updatePrevDeps()
      return
    }

    updatePrevDeps()

    if (currentUrl !== targetUrl) {
      window.history.pushState({}, '', targetUrl)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantConfig.filtersData])

  // historyCount represents the number of times the user has navigated since the last hard refresh / manual URL change
  const historyCount = useSelector(
    (state: RootState) => state.historyCount.data
  )
  useEffect(() => {
    // hard refresh / user manually changed the URL when historyCount is -1, 0, 1 so we hydrate the filters from the URL
    if (historyCount <= 1) {
      dispatch(incrementHistoryCountBy(2))
      const searchParams = new URLSearchParams(window.location.search)

      let updatedFilters = variantConfig.createFiltersObject(searchParams)
      // if the variant is duelist then we need to add the projectionFilterData/generalFilterData
      // from session to the updatedFilters because they're not part of the searchParams
      if (variant === 'duelist') {
        const filtersJSON = sessionStorage.getItem(sessionKey)
        if (filtersJSON) {
          const parsedFilters = JSON.parse(filtersJSON)
          const projectionFilterData = parsedFilters.projectionFilterData
          const generalFilterData = parsedFilters.generalFilterData
          updatedFilters = {
            ...updatedFilters,
            projectionFilterData:
              // projectionFilterData is stale if it has more than 2 keys because we moved the
              // rest to the generalFilterData
              Object.keys(projectionFilterData).length > 2
                ? initialDueListFilterData.projectionFilterData
                : projectionFilterData,
            generalFilterData:
              generalFilterData ?? initialDueListFilterData.generalFilterData,
          }
        }
      }
      // update the session when the url changes
      sessionStorage.setItem(sessionKey, JSON.stringify(updatedFilters))
      variantConfig.setFiltersData(updatedFilters)
    } else if (historyCount > 1) {
      // else if historyCount > 1 then the user navigated from another page so we should hydrate the filters from the session
      const filtersJSON = sessionStorage.getItem(sessionKey)
      if (filtersJSON) {
        const parsedFilters = JSON.parse(filtersJSON)
        if (parsedFilters && Object.keys(parsedFilters).length > 0)
          variantConfig.setFiltersData(parsedFilters)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyCount])

  return <></>
}

export default SearchComponent
