import CORE_AIRCRAFT_USAGE_LOG_FRAGMENT from './AircraftUsageLog'

export const MaintenanceItemFragment = gql`
  fragment MaintenanceItemFragment on MaintenanceItem {
    id
    title
    ataCode
    manufactureCode
    aircraftId
  }
`

export const AircraftFragment = gql`
  ${CORE_AIRCRAFT_USAGE_LOG_FRAGMENT}
  fragment AircraftFragment on Aircraft {
    id
    tailNumber
    serialNumber
    model
    tenant {
      id
      name
    }
    AircraftUsageLog {
      ...CoreAircraftUsageLogFields
    }
    AircraftComponent(isPrimary: true) {
      id
      name
      partNumber
      serialNumber
      ComponentUsageLog {
        id
        totalTimeSinceNew
        cycleSinceNew
        usageAsOf
      }
    }
  }
`
