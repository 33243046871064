import { useQuery } from '@redwoodjs/web'
import { useCallback, useState } from 'react'
import { FileUploadButtonProps } from 'src/components/FileUploadButton'
import WorkflowAttachmentsSection, {
  WorkflowAttachmentType,
} from 'src/components/workflow/WorkflowAttachmentsSection'

type Attachment = {
  file: File
  id: number
  originalFilename: string
}

const AttachmentsSection = ({
  onChange,
}: {
  onChange: (files: File[]) => void
}) => {
  const [attachments, setAttachments] = useState<Attachment[]>([])

  const onDeleteFile = useCallback((id: number) => {
    setAttachments((prevAttachments) => {
      return prevAttachments.filter((a) => a.id !== id)
    })
    onChange(attachments.map((a) => a.file))
  }, [])

  const handleSaveFile = (file: File) => {
    const newAttachments = [
      ...attachments,
      {
        originalFilename: file.name,
        createdAt: new Date(),
        id: attachments.length,
        file,
      },
    ]

    setAttachments(newAttachments)
    onChange(newAttachments.map((a) => a.file))
  }

  return (
    <WorkflowAttachmentsSection
      data={attachments}
      onDeleteFile={onDeleteFile}
      onSaveFile={handleSaveFile}
    />
  )
}

export default AttachmentsSection
