import { PartsTransaction, UpsertPartsTransactionInput } from 'types/graphql'
import { GET_PARTS_INFORMATION } from './queries'
import useQuery from 'src/hooks/useQuery'
import WorkflowPartsTransactionDrawer from 'src/components/workflow/WorkflowPartsTransactionSection/WorkflowPartsTransactionDrawer'

interface PartTransactionDrawerProps {
  maintenanceItemId: string
  onSubmit: (data: {
    input: Omit<UpsertPartsTransactionInput, 'id'>
    files: { removed: File[]; added: File[] }
  }) => void
  partsTransaction: Omit<PartsTransaction, 'id'>
}

const PartTransactionDrawer = ({
  maintenanceItemId,
  onSubmit,
  partsTransaction,
}: PartTransactionDrawerProps) => {
  const { data, loading } = useQuery(GET_PARTS_INFORMATION, {
    variables: { id: maintenanceItemId },
    skip: !maintenanceItemId,
  })

  const currentPart = data?.maintenanceItem?.aircraftComponent

  return (
    <WorkflowPartsTransactionDrawer
      currentPart={currentPart}
      onSubmit={onSubmit}
      doMutation={false}
      partsTransaction={partsTransaction}
    />
  )
}

export default PartTransactionDrawer
