import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react'
import { Chip, Typography, Popover, Tooltip } from '@mui/material'
import { HighlightedTypography } from './MUI/StyledComponents'

export interface ChipData {
  label: string
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
}

interface TypeAndDescriptionDisplayProps {
  description: string
  chips?: ChipData[]
  tailTags?: string[]
  maxRows?: number
  highlight?: boolean
}

const ChipsDisplay = React.memo(({ chips }: { chips?: ChipData[] }) => (
  <div>
    {chips?.map((chip) => (
      <Chip
        key={chip.label}
        label={chip.label}
        className="mr-[5px] w-fit"
        size="small"
        onClick={chip.onClick}
        {...(chip.onClick && { style: { cursor: 'pointer' } })}
      />
    ))}
  </div>
))

ChipsDisplay.displayName = 'ChipsDisplay'

const TailTagsDisplay = React.memo(({ tailTags }: { tailTags?: string[] }) => (
  <div className="flex flex-col items-end">
    {tailTags?.map((tag) => (
      <Typography key={tag} variant="caption" component={'div'}>
        {tag}
      </Typography>
    ))}
  </div>
))

TailTagsDisplay.displayName = 'TailTagsDisplay'

// Main component with optimizations for virtualization
const TypeAndDescriptionDisplay = React.memo<TypeAndDescriptionDisplayProps>(
  ({ description, chips, tailTags, maxRows = 2, highlight = false }) => {
    const descriptionRef = useRef<HTMLDivElement>(null)
    const [isTruncated, setIsTruncated] = useState(false)

    // Single useEffect for measurements
    useEffect(() => {
      const currentDescRef = descriptionRef.current

      if (currentDescRef) {
        setIsTruncated(
          currentDescRef.clientHeight < currentDescRef.scrollHeight
        )
      }
    }, [description]) // Only re-run when description changes

    const DescriptionContent = useMemo(
      () =>
        highlight ? (
          <HighlightedTypography variant="body2">
            {description}
          </HighlightedTypography>
        ) : (
          <Typography variant="subtitle2">{description}</Typography>
        ),
      [description, highlight]
    )

    const contentDisplay = useMemo(
      () => (
        <div className="flex flex-col">
          <div className="flex flex-row items-center">
            <div className="min-w-0 flex-1">
              <div ref={descriptionRef} className="line-clamp-2">
                {DescriptionContent}
              </div>
            </div>
          </div>

          <div className="flex w-full items-center justify-between">
            {chips && <ChipsDisplay chips={chips} />}
            {tailTags && <TailTagsDisplay tailTags={tailTags} />}
          </div>
        </div>
      ),
      [DescriptionContent, chips, tailTags]
    )

    return isTruncated ? (
      <div data-tooltip-id="global-tooltip" data-tooltip-content={description}>
        {contentDisplay}
      </div>
    ) : (
      contentDisplay
    )
  }
)

TypeAndDescriptionDisplay.displayName = 'TypeAndDescriptionDisplay'

export default TypeAndDescriptionDisplay
