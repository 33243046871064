import { useForm, useFormContext, useFieldArray } from 'react-hook-form'
import TextField from '../MUI/TextField'
import FilterPopover from './FilterPopover'
import {
  Checkbox as MUICheckbox,
  FormControlLabel,
  TextField as MUITextField,
} from '@mui/material'
import DatePicker from '../MUI/DatePicker'
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table'
import { Controller } from '@redwoodjs/forms'
import useAircraftList from 'src/hooks/requests/useAircraftRecords'
import useMaintenanceItemTable, {
  TableVariant,
} from 'src/hooks/useMaintenanceItemTable'
import { useEffect, useMemo } from 'react'
import { roundToPrecision } from 'src/utils/helpers'
import dayjs from 'dayjs'
import SelectDropdown, { SelectOption } from '../MUI/SelectDropdown'
import Checkbox from 'src/components/MUI/Checkbox'
import { Divider } from '@mui/material' // Add this import
import { MaintenanceNextDueStatus, MaintenanceCadenceType } from 'types/graphql'
import { ataCodeOptions } from 'src/constants'
import {
  AutocompleteField,
  AutocompleteOption,
} from '../MUI/Autocomplete/Autocomplete'
import useQuery from 'src/hooks/useQuery'
import { useOrgName } from 'src/hooks/useOrgName'

export const GET_TRACKED_BY_COMPONENTS = gql`
  query GetTrackedByComponents(
    $aircraftComponentFilters: AircraftComponentFilters
  ) {
    aircraftComponents(aircraftComponentFilters: $aircraftComponentFilters) {
      id
      name
    }
  }
`

interface FilterFormValues {
  projections: {
    date: string
    projectionGrid: {
      id: number
      metric: string
      dailyUse: number
      days: number
      override: boolean
      projection: number
    }[]
  }
  generalFilters: {
    cadenceType: MaintenanceCadenceType
    isOptional: boolean
    statuses: MaintenanceNextDueStatus[]
    isPartBased: boolean
    isAdSb: boolean | null
  }
  ataCodes: string[]
  trackedBy: string[]
}

const sections = ['Projections', 'General Filters', 'ATA Code', 'Tracked By']

const cadenceTypeOptions: SelectOption[] = [
  {
    label: 'None',
    value: '',
  },
  {
    label: 'Days',
    value: 'CALENDAR_DAYS',
  },
  {
    label: 'Months',
    value: 'CALENDAR_MONTHS',
  },
  {
    label: 'Hours',
    value: 'FLYING_HOURS',
  },
  {
    label: 'Landings',
    value: 'LANDINGS',
  },
  {
    label: 'Cycles',
    value: 'CYCLES',
  },
]

const dueStatusesOptions: AutocompleteOption[] = [
  {
    label: 'Overdue',
    value: 'OVERDUE',
  },
  {
    label: 'In Tolerance',
    value: 'IN_TOLERANCE',
  },
  {
    label: 'Approaching Due',
    value: 'APPROACHING_DUE',
  },
  {
    label: 'Good',
    value: 'GOOD',
  },
  {
    label: 'Not Due',
    value: 'NOT_DUE',
  },
]

const DueListFilterPopover = ({
  filtersAppliedCount,
  tableVariant,
}: {
  filtersAppliedCount: number
  tableVariant: TableVariant
}) => {
  const {
    loadAircraftList,
    loading: isAircraftListLoading,
    aircrafts,
  } = useAircraftList()

  const {
    duelistFiltersData: {
      projectionFilterData,
      generalFilterData,
      ataCodes,
      trackedBy,
      aircrafts: aircraftFilter,
    },
    setDueListFilterPopoverFilters,
    clearProjectionsAndFilters,
  } = useMaintenanceItemTable(tableVariant)

  const selectedAircraftIds = Object.keys(aircraftFilter).filter(
    (aircraftId) => aircraftFilter[aircraftId]
  )

  const defaultValues = useMemo(() => {
    const aircraftIds = Object.keys(aircrafts)
    const gridRows = projectionFilterData.projectionGrid.map((row, index) => {
      if (aircraftIds.length > 0) {
        if (index === 0 || index === 2) {
          return {
            ...row,
            dailyUse:
              aircrafts[aircraftIds[0]].AircraftUsageParameter?.dailyLandings,
          }
        }
        return {
          ...row,
          dailyUse:
            aircrafts[aircraftIds[0]].AircraftUsageParameter?.dailyFlyingTime,
        }
      }
      return row
    })

    const projectionsDefaultValues = {
      date: projectionFilterData.date,
      projectionGrid: gridRows,
    }
    return {
      projections: projectionsDefaultValues,
      generalFilters: generalFilterData,
      ataCodes,
      trackedBy,
    }
  }, [
    projectionFilterData,
    aircrafts,
    isAircraftListLoading,
    generalFilterData,
    ataCodes,
    trackedBy,
  ])

  const formMethods = useForm<FilterFormValues>({
    defaultValues,
  })

  useEffect(() => {
    formMethods.reset(defaultValues)
  }, [defaultValues, formMethods])

  const handleSubmit = (data: FilterFormValues) => {
    console.log(data)
    setDueListFilterPopoverFilters({
      projections: {
        ...data.projections,
        date: data.projections.date ? data.projections.date : undefined,
      },
      generalFilters: data.generalFilters,
      ataCodes: data.ataCodes,
      trackedBy: data.trackedBy,
    })
  }

  let buttonText = `PROJECTIONS & FILTERS`
  if (filtersAppliedCount > 0) {
    buttonText += ` (${filtersAppliedCount})`
  }

  const clearAllFilters = () => {
    clearProjectionsAndFilters()
  }

  return (
    <FilterPopover<FilterFormValues>
      sections={sections}
      initialFilterSection={sections[0]}
      buttonText={buttonText}
      formMethods={formMethods}
      onSubmit={handleSubmit}
      buttonColor={filtersAppliedCount > 0 ? 'primary' : 'base'}
      clearAllFilters={clearAllFilters}
    >
      {(currentSection) => {
        switch (currentSection) {
          case 'Projections':
            return <ProjectionsForm />
          case 'General Filters':
            return <GeneralFiltersForm />
          case 'ATA Code':
            return <ATACodeForm />
          case 'Tracked By':
            return <TrackedByForm aircraftIds={selectedAircraftIds} />
          default:
            return null
        }
      }}
    </FilterPopover>
  )
}

const ProjectionsForm = () => {
  const { watch, setValue } = useFormContext<FilterFormValues>()

  const gridValues = watch('projections.projectionGrid')
  const date = watch('projections.date')

  // Define columns for Material React Table
  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: 'metric',
        header: 'Metric',
        size: 100,
        enableEditing: false,
      },
      {
        accessorKey: 'dailyUse',
        header: 'Daily Use',
        size: 100,
        Cell: ({ row }) => (
          <MUITextField
            size="small"
            value={row.original.dailyUse}
            onChange={(event) => {
              const newValue = roundToPrecision(parseFloat(event.target.value))
              setValue(
                'projections.projectionGrid',
                gridValues.map((r) =>
                  r.id === row.original.id ? { ...r, dailyUse: newValue } : r
                )
              )
            }}
            type="number"
          />
        ),
      },
      {
        accessorKey: 'days',
        header: 'Days',
        size: 100,
        enableEditing: false,
      },
      {
        accessorKey: 'override',
        header: 'Override',
        size: 100,
        Cell: ({ row }) => (
          <MUICheckbox
            checked={row.original.override}
            onChange={() => {
              let overrideProjectionVal = 1
              if (row.original.override) {
                overrideProjectionVal = Math.ceil(
                  row.original.dailyUse * row.original.days
                )
              } else {
                overrideProjectionVal = row.original.projection
              }
              setValue(
                'projections.projectionGrid',
                gridValues.map((r) =>
                  r.id === row.original.id
                    ? {
                        ...r,
                        override: !r.override,
                        projection: overrideProjectionVal,
                      }
                    : r
                )
              )
            }}
          />
        ),
      },
      {
        accessorKey: 'projection',
        header: 'Projection',
        size: 100,
        Cell: ({ row }) => {
          const value = row.original.override
            ? row.original.projection
            : Math.ceil(row.original.dailyUse * row.original.days)
          return (
            <MUITextField
              size="small"
              value={value}
              disabled={!row.original.override}
              onChange={(event) => {
                setValue(
                  'projections.projectionGrid',
                  gridValues.map((r) =>
                    r.id === row.original.id
                      ? { ...r, projection: parseInt(event.target.value) }
                      : r
                  )
                )
              }}
              type="number"
            />
          )
        },
      },
    ],
    [gridValues, setValue]
  )

  const table = useMaterialReactTable({
    columns,
    data: gridValues,
    enablePagination: false,
    enableColumnFilters: false,
    enableColumnActions: false,
    enableSorting: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,

    muiTableHeadCellProps: {
      sx: {
        backgroundColor: '#fafafa',
        color: 'grey',
      },
    },

    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: '8px',
        border: '1px solid rgba(224, 224, 224, 1)',
      },
    },
    muiTableBodyRowProps: {
      sx: {
        '& td': {
          paddingY: '5px',
        },
      },
    },
  })

  useEffect(() => {
    if (date) {
      const today = dayjs()
      const selectedDate = dayjs(date)
      let days = Math.ceil(selectedDate.diff(today, 'day', true))
      if (days <= 0) {
        days = 0
      }
      setValue(
        'projections.projectionGrid',
        gridValues.map((row) => ({
          ...row,
          days,
          projection: !row.override
            ? Math.ceil(row.dailyUse * days)
            : row.projection,
        }))
      )
    }
  }, [date, gridValues])

  return (
    <div className="m-2 flex flex-col gap-2">
      <DatePicker
        name="projections.date"
        label="Date"
        disablePast
        closeOnSelect
        className="w-1/2"
        slotProps={{ textField: { size: 'small', fullWidth: false } }}
      />
      <MaterialReactTable table={table} />
    </div>
  )
}

const ATACodeForm = () => {
  const codes = ataCodeOptions

  return (
    <div className="m-2 flex flex-col gap-1">
      <AutocompleteField
        name="ataCodes"
        options={codes as AutocompleteOption[]}
        multiple
        className="w-full"
        label=""
      />
    </div>
  )
}

const GeneralFiltersForm = () => {
  // TIP: all forms of this nature should use m-2 className
  // it is the only way to get on hover and focused styles to not get cut off
  return (
    <div className="m-2 flex flex-col gap-1 ">
      {/* <TextField
        size="small"
        name="generalFilters.intervalType"
        label="Interval Type"
      /> */}
      <SelectDropdown
        name="generalFilters.cadenceType"
        label="Interval Type"
        options={cadenceTypeOptions}
        fullWidth
        className="w-full"
      />
      <Checkbox name="generalFilters.isOptional" label="Is Optional" />
      <AutocompleteField
        name="generalFilters.statuses"
        label="Due Status"
        options={dueStatusesOptions}
        multiple
        className="w-full"
      />
      <Checkbox name="generalFilters.isPartBased" label="Is Part Associated" />
      <Checkbox name="generalFilters.isAdSb" label="ADs & SBs" />
    </div>
  )
}

const TrackedByForm = ({ aircraftIds }: { aircraftIds: string[] }) => {
  const orgSlug = useOrgName()
  const { data: trackedByData } = useQuery(GET_TRACKED_BY_COMPONENTS, {
    variables: {
      aircraftComponentFilters: {
        orgSlug,
        isPrimary: true,
        aircraftIds,
      },
    },
  })

  const trackedByOptions = [
    ...new Set(
      trackedByData?.aircraftComponents.map((component) => component.name)
    ),
  ].map((name) => ({
    label: name,
    value: name,
  }))

  return (
    <div className="m-2 flex flex-col gap-1">
      <AutocompleteField
        name="trackedBy"
        options={trackedByOptions}
        multiple
        className="w-full"
        label=""
      />
    </div>
  )
}

export default DueListFilterPopover
