import { useState } from 'react'

import { useLazyQuery, ApolloError } from '@apollo/client'
import { ComplianceActivity, ComplianceActivityFilter } from 'types/graphql'

import CORE_AIRCRAFT_USAGE_LOG_FRAGMENT from 'src/fragments/AircraftUsageLog'

const GET_COMPLIANCE_ACTIVITIES_LIST = gql`
  ${CORE_AIRCRAFT_USAGE_LOG_FRAGMENT}
  query getComplianceActivitiesList($filters: ComplianceActivityFilter) {
    complianceActivities(filters: $filters) {
      correctiveAction
      id
      status
      complianceLedger {
        id
        timestamp
        createdAt
        aircraftUsageLog {
          ...CoreAircraftUsageLogFields
        }
        inspectedBy {
          firstName
          lastName
        }
      }
      MaintenanceLogbookActivity {
        Logbook {
          id
        }
      }
      maintenanceItemId
    }
  }
`
interface AircraftRecordsHook {
  complianceActivities: ComplianceActivity[]
  loading: boolean
  error: ApolloError
  loadComplianceActivities: (params?: ComplianceActivityFilter) => void
}

const useComplianceActivitiesRecords = (): AircraftRecordsHook => {
  const [complianceActivities, setComplianceActivities] = useState<
    ComplianceActivity[]
  >([])
  const [error, setError] = useState<ApolloError>(undefined)

  const [fetchComplianceActivities, { loading: isAircraftListLoading }] =
    useLazyQuery(GET_COMPLIANCE_ACTIVITIES_LIST, {
      onCompleted: (data) => {
        setComplianceActivities(data.complianceActivities)
      },
      onError: (error) => {
        setError(error)
      },
    })

  const loading = isAircraftListLoading

  const loadComplianceActivities = (params?: ComplianceActivityFilter) => {
    fetchComplianceActivities({ variables: { filters: params } })
  }

  return {
    complianceActivities,
    loading,
    error,
    loadComplianceActivities,
  }
}

export default useComplianceActivitiesRecords
