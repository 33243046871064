import { navigate, routes } from '@redwoodjs/router'
import { Typography } from '@mui/material'
import { toast } from '@redwoodjs/web/toast'
import React, { useEffect, useMemo } from 'react'
import SingleComplianceForm from 'src/components/SingleComplianceDrawer/SingleComplianceForm'
import useQuery from 'src/hooks/useQuery'
import { inProgressValues } from 'src/utils/maintenanceItem'
import IconButton from 'src/components/MUI/IconButton'
import { useOrgName } from 'src/hooks/useOrgName'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import DifferenceOutlinedIcon from '@mui/icons-material/DifferenceOutlined'
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'
import useHasPermission from 'src/hooks/useHasPermission'
import { Permissions } from '@wingwork/common/src/constants/permissions'
import Loading from 'src/components/Loading'

const GET_MAINTENANCE_ITEM = gql`
  query GetMaintenanceItemForSidebarCompliance($id: String!) {
    maintenanceItem(id: $id) {
      id
      workOrderIdInProgress
      complianceLedger {
        id
        status
        isRevision
        MaintenanceLogbook {
          id
          status
        }
      }
      aircraftId
    }
  }
`

interface ComplianceTabProps {
  id: string
  isOpen: boolean
  onClose: () => void
  setInProgress: (value: boolean) => void
}

const ComplianceTab: React.FC<ComplianceTabProps> = ({
  id,
  isOpen,
  onClose,
  setInProgress,
}) => {
  const orgSlug = useOrgName()
  const { data, hasLoaded, loading } = useQuery(GET_MAINTENANCE_ITEM, {
    variables: { id: id },
    skip: !id || !isOpen,
  })

  const maintenanceItem = data?.maintenanceItem ?? {}

  const {
    isLedgerInProgress,
    isRevisionInProgress,
    isLogbookInProgress,
    isLogbookAwaitingSignature,
    isWorkOrderInProgress,
    isMaintenanceItemInProgress,
  } = loading
    ? {
        isLedgerInProgress: false,
        isRevisionInProgress: false,
        isLogbookInProgress: false,
        isLogbookAwaitingSignature: false,
        isWorkOrderInProgress: false,
        isMaintenanceItemInProgress: false,
      }
    : inProgressValues(maintenanceItem)

  useEffect(() => {
    setInProgress(isMaintenanceItemInProgress)
  }, [isMaintenanceItemInProgress])

  const canReadCompliance = useMemo(() => {
    if (!data?.maintenanceItem?.aircraftId) return false
    return useHasPermission(
      Permissions.compliance.read,
      data?.maintenanceItem?.aircraftId
    )
  }, [data?.maintenanceItem?.aircraftId])

  if (!id) {
    return null
  }

  if (loading) {
    return <Loading />
  }

  if (isMaintenanceItemInProgress) {
    return (
      <div className="flex w-full items-center justify-center p-5">
        {isLedgerInProgress && (
          <>
            <Typography variant="body2">
              This task is currently in progress on an existing compliance
            </Typography>
            <IconButton
              tooltip="Open compliance in progress"
              disabled={!canReadCompliance}
              disabledTooltip="You do not have permission to view compliance"
              onClick={() =>
                navigate(
                  routes.bulkCompliance({
                    orgName: orgSlug,
                    ledgerId: maintenanceItem.complianceLedger?.id,
                  })
                )
              }
            >
              <FolderOutlinedIcon />
            </IconButton>
          </>
        )}
        {isRevisionInProgress && (
          <>
            <Typography variant="body2">
              This task is currently in progress on a compliance revision
            </Typography>
            <IconButton
              tooltip="Continue revision"
              disabled={!canReadCompliance}
              disabledTooltip="You do not have permission to continue revision"
              onClick={() =>
                navigate(
                  routes.bulkCompliance({
                    orgName: orgSlug,
                    ledgerId: maintenanceItem.complianceLedger?.id,
                  })
                )
              }
            >
              <DifferenceOutlinedIcon />
            </IconButton>
          </>
        )}
        {isLogbookInProgress && (
          <>
            <Typography variant="body2">
              This task is waiting for the logbook to be published
            </Typography>
          </>
        )}
        {isLogbookAwaitingSignature && (
          <>
            <Typography variant="body2">
              This task is currently waiting for logbook signatures
            </Typography>
          </>
        )}
        {isWorkOrderInProgress && (
          <>
            <Typography variant="body2">
              This task is currently in progress on an existing work order
            </Typography>
            <IconButton
              tooltip="Open work order in progress"
              onClick={() =>
                navigate(
                  routes.workOrder({
                    orgName: orgSlug,
                    id: maintenanceItem.workOrderIdInProgress,
                  })
                )
              }
            >
              <WorkOutlineOutlinedIcon />
            </IconButton>
          </>
        )}
      </div>
    )
  }

  return (
    <SingleComplianceForm
      id={id}
      isOpen={isOpen}
      onCompleted={() => {
        toast.success('Compliance completed')
        onClose()
      }}
    />
  )
}

export default ComplianceTab
