import React, { useEffect, useMemo, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import AddAlarmIcon from '@mui/icons-material/AddAlarm'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import {
  IconButton,
  Modal,
  Paper,
  TableCell,
  TableRow,
  Typography,
  TableHead as MuiTableHead,
  TableBody,
  Table,
  TableContainer,
} from '@mui/material'
import dayjs from 'dayjs'
import { LaborTransaction } from 'types/graphql'

import useGetUsers from 'src/hooks/requests/useGetUsers'
import { useDispatch } from 'src/hooks/useDispatch'
import { useSelector } from 'src/hooks/useSelector'
import { closeModal, openModal, setModalData } from 'src/slices/modal'
import { minutesToHoursStr } from 'src/utils/helpers'

import MechanicFilter from './MechanicsFilter'
import TableRowSkeleton from './TableRowsLoading'
import useHasPermission from 'src/hooks/useHasPermission'
import { Permissions } from '@wingwork/common/src/constants/permissions'
import Button from './MUI/Button'
import Drawer from './MUI/Drawer'
import DrawerHeader from './MUI/Drawer/DrawerHeader'

const GET_LABOR_TRANSACTIONS = gql`
  query GetLaborTransactionsForModal(
    $filters: LaborTransactionsFilter
    $sorting: SortingInput
  ) {
    laborTransactions(filters: $filters, sorting: $sorting) {
      id
      punchedInAt
      punchedOutAt
      description
      correctiveAction
      notes
      workedBy {
        id
        firstName
        lastName
      }
      lastUpdatedBy {
        id
        firstName
        lastName
      }
    }
  }
`

interface LaborTransactionsModalMetadata {
  targetId: string
  discriminator: 'InternalWorkItem' | 'CustomInternalWorkItem'
}

interface LaborTransactionsModalProps {
  onBail?: () => void
}

const LaborTransactionsModal: React.FC<LaborTransactionsModalProps> = ({
  onBail,
}) => {
  const dispatch = useDispatch()
  const [mechanicFilterData, setMechanicFilterData] = React.useState({})
  const [laborTransactionFilters, setLaborTransactionFilters] =
    useState(undefined)

  const [getLaborTransactions, { data: laborTransactionsData, loading }] =
    useLazyQuery(GET_LABOR_TRANSACTIONS)

  const { data: users } = useGetUsers({
    variables: {
      filter: {
        roles: ['ADMIN', 'MECHANIC', 'DIRECTOR_OF_MAINTENANCE'],
      },
    },
  })

  const mechanics = useMemo(() => {
    return users?.users ?? []
  }, [users])

  const open =
    useSelector(
      (state) => state.modal.modals?.laborTransactionsModal?.isOpen
    ) ?? false

  const { targetId, discriminator }: LaborTransactionsModalMetadata =
    useSelector((state) => state.modal.modals?.laborTransactionsModal?.data) ??
    {}

  useEffect(() => {
    if (open) {
      dispatch(
        setModalData({
          name: 'laborTransactionsModal',
          data: { loading: true },
        })
      )
      const identifier = {}
      if (discriminator === 'InternalWorkItem') {
        identifier['internalWorkItemId'] = targetId
      }
      if (discriminator === 'CustomInternalWorkItem') {
        identifier['customInternalWorkItemId'] = targetId
      }
      getLaborTransactions({
        variables: {
          filters: {
            workedByIds: laborTransactionFilters?.workedByIds,
            ...identifier,
          },
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, laborTransactionFilters])

  const handleClose = () => {
    dispatch(closeModal({ name: 'laborTransactionsModal' }))
  }

  const handleBail = () => {
    onBail?.()
    handleClose()
  }

  useEffect(() => {
    setMechanicFilterData(
      mechanics.reduce((acc, user) => {
        return {
          ...acc,
          [user.id]: false,
        }
      }, {})
    )
  }, [mechanics])

  const setMechanicFilter = (id: string, value: boolean) => {
    setMechanicFilterData({
      ...mechanicFilterData,
      [id]: value,
    })
  }

  useEffect(() => {
    setLaborTransactionFilters({
      ...laborTransactionFilters,
      workedByIds: Object.keys(mechanicFilterData).filter(
        (key) => mechanicFilterData[key]
      ),
    })
  }, [mechanicFilterData])

  const aircraftId = useSelector((state) => state.auditLog.aircraftId)
  const canPerformWorkItem = useHasPermission(
    Permissions.workOrder.performWorkItem,
    aircraftId
  )

  return (
    <Drawer
      open={open}
      anchor="right"
      onClose={handleBail}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disablePortal
    >
      <DrawerHeader title="Labor Transactions" onClose={handleBail} />
      <div>
        <div className="p-3">
          <div className="flex flex-row-reverse gap-2">
            <MechanicFilter
              dropdownStyles={{ maxHeight: 500, overflowY: 'scroll' }}
              userIdType="id"
              mechanics={mechanics}
              mechanicFilterData={mechanicFilterData}
              setMechanicFilter={setMechanicFilter}
              resetMechanicFilter={() =>
                setMechanicFilterData(
                  mechanics.reduce((acc, user) => {
                    return {
                      ...acc,
                      [user.id]: false,
                    }
                  }, {})
                )
              }
            />
            <Button
              startIcon={<AddAlarmIcon />}
              variant="outlined"
              color="base"
              onClick={() => {
                handleClose()
                dispatch(
                  openModal({
                    name: 'saveLaborTransactionModal',
                    data: {
                      variant: 'create',
                      discriminator,
                      laborTransaction: undefined,
                      discriminatorId: targetId,
                      onClose: () => {
                        dispatch(
                          openModal({
                            name: 'laborTransactionsModal',
                            data: {
                              targetId,
                              discriminator,
                            },
                          })
                        )
                      },
                    },
                  })
                )
              }}
              locked={!canPerformWorkItem}
              lockedTooltip="You do not have permission"
            >
              Add Time
            </Button>
          </div>
          <TableContainer className="mt-2 max-h-60 overflow-y-auto">
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={'small'}
            >
              <TableHead />
              <TableBody>
                {loading ? (
                  <TableRowSkeleton cols={headCells.length} rows={5} />
                ) : (
                  <>
                    {laborTransactionsData?.laborTransactions.map(
                      (row: LaborTransaction) => {
                        return (
                          <React.Fragment key={row.id}>
                            <TableRow hover tabIndex={-1}>
                              <TableCell>
                                {dayjs(row.punchedInAt).format(
                                  'MM/DD/YYYY hh:mm A'
                                )}
                              </TableCell>
                              <TableCell>
                                {row.punchedOutAt
                                  ? dayjs(row.punchedOutAt).format(
                                      'MM/DD/YYYY hh:mm A'
                                    )
                                  : '...'}
                              </TableCell>
                              <TableCell>
                                <div className="flex items-center gap-1">
                                  <AccountCircleIcon
                                    color="primary"
                                    fontSize="small"
                                  />
                                  <Typography>
                                    {`${
                                      row.workedBy?.firstName
                                    } ${row.workedBy?.lastName
                                      .charAt(0)
                                      .toUpperCase()}.`}
                                  </Typography>
                                </div>
                              </TableCell>
                              <TableCell>{row.description}</TableCell>
                              <TableCell>{row.correctiveAction}</TableCell>
                              <TableCell>{row.notes}</TableCell>
                              <TableCell>
                                {row.punchedOutAt
                                  ? minutesToHoursStr(
                                      dayjs(row.punchedOutAt).diff(
                                        dayjs(row.punchedInAt),
                                        'minutes'
                                      )
                                    )
                                  : '...'}
                              </TableCell>
                              <TableCell>
                                {row.lastUpdatedBy ? (
                                  <div className="flex items-center gap-1">
                                    <AccountCircleIcon
                                      color="primary"
                                      fontSize="small"
                                    />
                                    <Typography>
                                      {`${
                                        row.lastUpdatedBy?.firstName
                                      } ${row.lastUpdatedBy?.lastName
                                        .charAt(0)
                                        .toUpperCase()}.`}
                                    </Typography>
                                  </div>
                                ) : (
                                  '...'
                                )}
                              </TableCell>
                              <TableCell align="right">
                                <IconButton
                                  onClick={() => {
                                    handleClose()
                                    dispatch(
                                      openModal({
                                        name: 'saveLaborTransactionModal',
                                        data: {
                                          variant: 'edit',
                                          discriminator,
                                          laborTransaction: row,
                                          discriminatorId: targetId,
                                          onClose: () => {
                                            dispatch(
                                              openModal({
                                                name: 'laborTransactionsModal',
                                                data: {
                                                  targetId,
                                                  discriminator,
                                                },
                                              })
                                            )
                                          },
                                        },
                                      })
                                    )
                                  }}
                                  disabled={!canPerformWorkItem}
                                >
                                  <EditIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        )
                      }
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </Drawer>
  )
}

interface HeadCell {
  disablePadding: boolean
  id: string
  label: string
}

const headCells: readonly HeadCell[] = [
  {
    id: 'punchedInAt',
    disablePadding: false,
    label: 'Punch In',
  },
  {
    id: 'punchedOutAt',
    disablePadding: false,
    label: 'Punch Out',
  },
  {
    id: 'user',
    disablePadding: false,
    label: 'Worked By',
  },
  {
    id: 'description',
    disablePadding: false,
    label: 'Description',
  },
  {
    id: 'correctiveAction',
    disablePadding: false,
    label: 'Corrective Action',
  },
  {
    id: 'notes',
    disablePadding: false,
    label: 'Notes',
  },
  {
    id: 'timeWorked',
    disablePadding: false,
    label: 'Time Worked',
  },
  {
    id: 'lastUpdatedBy',
    disablePadding: false,
    label: 'Last Updated',
  },
  {
    id: 'actions',
    disablePadding: false,
    label: 'Actions',
  },
]

const TableHead: React.FC = () => {
  return (
    <MuiTableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.id === 'actions' ? 'right' : 'left'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  )
}

export default LaborTransactionsModal
