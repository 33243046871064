import Loading from 'src/components/Loading'
import useQuery from 'src/hooks/useQuery'
import WorkOrderCompliancePageV2 from '../WorkOrderCompliancePageV2/WorkOrderCompliancePageV2'
import WorkOrderReviewPage from '../WorkOrderReviewPage/WorkOrderReviewPage'
import WorkOrderCompletedPage from '../WorkOrderCompletedPage/WorkOrderCompletedPage'
import { GET_WORK_ORDER_STATUS } from './queries'

const WorkOrderDetailPage = (props) => {
  const { id } = props

  const { data } = useQuery(GET_WORK_ORDER_STATUS, {
    variables: {
      id,
    },
  })

  const { internalWorkOrder } = data ?? {}

  if (internalWorkOrder === undefined) {
    return <Loading />
  } else if (['DRAFT', 'IN_PROGRESS'].includes(internalWorkOrder.status)) {
    return <WorkOrderCompliancePageV2 {...props} />
  } else if (internalWorkOrder.status === 'IN_REVIEW') {
    return <WorkOrderReviewPage {...props} />
  } else if (internalWorkOrder.status === 'COMPLETED') {
    return <WorkOrderCompletedPage {...props} />
  }
}

export default WorkOrderDetailPage
