import {
  TableCell,
  TableCellProps,
  TableRow,
  Typography,
  styled,
  Link as MuiLink,
  LinkProps as MuiLinkProps,
} from '@mui/material'

import { tabletMediaQuery, wwAppColors } from 'src/theme'

export interface DuelistTableCellProps {
  cellSize: 'small' | 'medium' | 'large'
}

export const cellSizeWidthMap = {
  small: 80,
  medium: 140,
  large: 320,
}

export const DuelistTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'cellSize',
})<DuelistTableCellProps>(({ cellSize }) => ({
  minWidth: cellSizeWidthMap?.[cellSize] || 0,
}))

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:hover': {
    background: `linear-gradient(to right, ${theme.palette.primary.light}, ${theme.palette.background.default})`,
  },
}))

export const StyledTableCell = styled(DuelistTableCell)(({ theme }) => ({
  verticalAlign: 'top',
  padding: theme.spacing(1, 2),
  borderBottom: `1px solid ${theme.palette.divider}`,
}))

export const HighlightedTypography = styled(Typography)(() => ({
  fontWeight: 'bold',
  color: wwAppColors.sys.black,
  [tabletMediaQuery]: {
    // Tablet adjustments
    fontWeight: 'bold',
  },
}))

export interface HoverableLinkProps extends MuiLinkProps {
  hoverContent?: React.ReactNode
}

export const HoverableLink = styled(
  ({ hoverContent, children, ...props }: HoverableLinkProps) => (
    <MuiLink {...props}>
      {children}
      {hoverContent && <span className="hover-content">{hoverContent}</span>}
    </MuiLink>
  )
)(({ theme }) => ({
  position: 'relative',
  display: 'block',
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
  '& .hover-content': {
    display: 'none',
    position: 'absolute',
    top: '100%',
    left: 0,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    padding: theme.spacing(1),
    zIndex: 1,
  },
  '&:hover .hover-content': {
    display: 'block',
  },
}))

export const MultiLineTypography = styled(Typography)(({ theme }) => ({
  '& > *:not(:last-child)': {
    marginBottom: theme.spacing(0.75),
  },
}))

export interface StickyCellProps extends TableCellProps {
  stickyPosition?: 'left' | 'right'
}

export const StickyTableCell = styled(DuelistTableCell, {
  shouldForwardProp: (prop) => prop !== 'stickyPosition',
})<StickyCellProps>(({ theme, stickyPosition }) => ({
  position: 'sticky',
  ...(stickyPosition && {
    [stickyPosition]: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 2,
  }),
}))
