import usePaginatedMaintenanceItemList from './usePaginatedMaintenanceItemList'
import usePaginatedWorkOrderMaintenanceItemList from './usePaginatedWorkOrderMaintenanceItemList'

const useDiscrepanciesTabDataForWorkOrder = (isInternalAircraft: boolean) => {
  const {
    maintenanceItems,
    hasNextPage: hasNextMaintenancePage,
    initialLoading: maintenanceItemsInitialLoading,
    error: maintenanceItemsError,
    loadMaintenanceItems,
    fetchNextMaintenancePage,
  } = usePaginatedMaintenanceItemList()

  const {
    workOrderMaintenanceItems,
    hasNextPage: hasNextWorkOrderMaintenancePage,
    initialLoading: workOrderMaintenanceItemsInitialLoading,
    error: workOrderMaintenanceItemsError,
    loadWorkOrderMaintenanceItems,
    fetchNextWorkOrderMaintenancePage,
  } = usePaginatedWorkOrderMaintenanceItemList()

  if (isInternalAircraft) {
    return {
      items: maintenanceItems,
      hasNextPage: hasNextMaintenancePage,
      initialLoading: maintenanceItemsInitialLoading,
      error: maintenanceItemsError,
      loadItems: loadMaintenanceItems,
      fetchNextPage: fetchNextMaintenancePage,
    }
  } else {
    return {
      items: workOrderMaintenanceItems,
      hasNextPage: hasNextWorkOrderMaintenancePage,
      initialLoading: workOrderMaintenanceItemsInitialLoading,
      error: workOrderMaintenanceItemsError,
      loadItems: loadWorkOrderMaintenanceItems,
      fetchNextPage: fetchNextWorkOrderMaintenancePage,
    }
  }
}

export default useDiscrepanciesTabDataForWorkOrder
