import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Sentry from 'src/lib/sentry'

const CREATE_MTX_LOGBOOK_PDF = gql`
  mutation CreateMtxLogbookPdf(
    $complianceLedgerId: String!
    $options: LogbookPdfOptions
    $logbookId: String
  ) {
    createMaintenanceLogbookPdf(
      complianceLedgerId: $complianceLedgerId
      options: $options
      logbookId: $logbookId
    )
  }
`

interface CreateLogbookPDFResult {
  pdfUrl: string
}

const useCreateLogbookPdf = () => {
  const [createMtxLogbookPdf] = useMutation(CREATE_MTX_LOGBOOK_PDF)

  const handleCreateLogbookPDF = async (
    complianceLedgerId: string,
    pdfOptions: object,
    logbookId?: string,
    options?: any
  ): Promise<CreateLogbookPDFResult> => {
    try {
      const variables = {
        complianceLedgerId: complianceLedgerId,
        options: pdfOptions,
      }
      if (logbookId) variables['logbookId'] = logbookId
      const pdfResponse = await createMtxLogbookPdf({
        variables,
        ...options,
      })

      return { pdfUrl: pdfResponse.data.createMaintenanceLogbookPdf }
    } catch (error) {
      console.error(error)
      Sentry.captureException(error)
      toast.error('Error creating logbook PDF')
    }
  }

  return {
    createLogbookPdf: handleCreateLogbookPDF,
  }
}

export default useCreateLogbookPdf
