import { type MRT_RowData, type MRT_TableOptions } from 'material-react-table'

// reusable function that should output our default options for a table @ wingwork
// naturally, you can override any of these options in your specific instance

// see official documentation here for more information about reusable table options
// https://www.material-react-table.com/docs/guides/best-practices#re-usable-default-options

export const getDefaultMRTOptions = <TData extends MRT_RowData>(): Partial<
  MRT_TableOptions<TData>
> => ({
  // disable column actions and sorting
  enableColumnActions: false,
  enableSorting: false,

  // remove default toolbar, doesnt match our design
  enableTopToolbar: false,

  // custom paper container styles to match our design
  muiTablePaperProps: {
    className: 'rounded-lg',
  },

  // custom table head row styles to match our design
  muiTableHeadRowProps: {
    className: 'bg-gray-50',
  },
})
