import React, { useMemo } from 'react'
import useQuery from 'src/hooks/useQuery'
import useHasPermission from 'src/hooks/useHasPermission'
import { Permissions } from '@wingwork/common/src/constants'
import ReviewForm from 'src/components/ReviewForm'
import WorkOrderCompletedModal from './WorkOrderCompletedModal'
import {
  GET_WORK_ORDER_CUSTOM_TASK_LIST,
  GET_WORK_ORDER_TASK_LIST,
} from 'src/pages/WorkOrderCompliancePageV2/queries'
import Loading from 'src/components/Loading'
import CreateLogbookEntryModal from 'src/components/CreateLogbookEntryModal'
import ViewLogbookEntryModal from 'src/components/ViewLogbookEntryModal/ViewLogbookEntryModal'
import {
  MassagedComplianceActivity,
  massageTasks,
} from 'src/components/ReviewForm/helper'

interface WorkOrderCompletedContentProps {
  id: string
  aircraftId: string
  ledgerId: string
  onCancel?: () => void
  createdLogbook: boolean
}

const WorkOrderCompletedContent: React.FC<WorkOrderCompletedContentProps> = ({
  id,
  aircraftId,
  ledgerId,
  onCancel,
  createdLogbook,
}) => {
  const canCompleteWorkOrder = useHasPermission(
    Permissions.workOrder.complete,
    aircraftId
  )

  const {
    data: taskData,
    loading: taskDataLoading,
    error: taskDataError,
  } = useQuery(GET_WORK_ORDER_TASK_LIST, {
    variables: { id },
    fetchPolicy: 'cache-first',
  })

  const {
    data: customTaskData,
    loading: customTaskDataLoading,
    error: customTaskDataError,
  } = useQuery(GET_WORK_ORDER_CUSTOM_TASK_LIST, {
    variables: { id },
    fetchPolicy: 'cache-first',
  })

  const { workItems, customWorkItems } = useMemo(() => {
    return {
      workItems: taskData?.internalWorkItems,
      customWorkItems: customTaskData?.customInternalWorkItems,
    }
  }, [taskData, customTaskData])

  const tasks: MassagedComplianceActivity[] = useMemo(() => {
    if (!workItems) return []
    return [
      ...massageTasks(workItems, true, false),
      ...massageTasks(customWorkItems, true, true),
    ]
  }, [workItems, customWorkItems])

  if (taskDataLoading || customTaskDataLoading) {
    return <Loading />
  }

  return (
    <div className="flex h-full flex-col">
      <ReviewForm
        title="Information Summary"
        tasks={tasks}
        hasCorrectiveAction
        hasAccounting
        readonly
        aircraftId={aircraftId}
      />
      <WorkOrderCompletedModal workOrderId={id} ledgerId={ledgerId} />
      {createdLogbook ? (
        <ViewLogbookEntryModal ledgerId={ledgerId} workOrderId={id} />
      ) : (
        <CreateLogbookEntryModal propLedgerId={ledgerId} />
      )}
    </div>
  )
}

export default WorkOrderCompletedContent
