const fetchFileFromWeb = async (url: string) => {
  const response = await fetch(url)
  const pdfBlob = new Blob([await response.blob()], {
    type: 'application/pdf',
  })

  // Generate URL from downloaded PDF
  return URL.createObjectURL(pdfBlob)
}

export default async function fileDownload(
  url: string,
  filename: string,
  download = false
) {
  const link = document.createElement('a')
  if (download) {
    link.href = await fetchFileFromWeb(url)
  } else {
    link.href = url
  }

  link.download = filename
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
