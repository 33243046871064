import LockPersonIcon from '@mui/icons-material/LockPerson'
import {
  Button as MUIButton,
  ButtonProps as MUIButtonProps,
  Tooltip,
  TooltipProps,
} from '@mui/material'
import clsx from 'clsx'

export type ButtonProps = MUIButtonProps & {
  tooltip?: string
  disabledTooltip?: string
  lockedTooltip?: string
  TooltipProps?: Partial<TooltipProps>
  locked?: boolean
  to?: string
}

const Button: React.FC<ButtonProps> = ({
  tooltip,
  disabledTooltip,
  lockedTooltip,
  TooltipProps,
  disabled,
  locked,
  fullWidth,
  endIcon,
  ...buttonProps
}) => {
  const isDisabled = disabled || locked

  let tooltipText: string
  if (locked) {
    tooltipText = lockedTooltip
  } else if (disabled) {
    tooltipText = disabledTooltip
  } else {
    tooltipText = tooltip
  }

  return (
    <Tooltip
      enterDelay={isDisabled ? 100 : 700} // fast tooltips for disabled or locked clarity, long delay for regular buttons
      {...TooltipProps}
      title={tooltipText}
    >
      <span className={clsx(fullWidth && 'w-full')}>
        <MUIButton
          disabled={isDisabled}
          endIcon={locked ? <LockPersonIcon /> : endIcon}
          fullWidth={fullWidth}
          {...buttonProps}
        />
      </span>
    </Tooltip>
  )
}

export default Button
