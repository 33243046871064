import {
  MonetizationOnOutlined,
  ShoppingBagOutlined,
  StoreOutlined,
} from '@mui/icons-material'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import { Divider, TextField as MUITextField } from '@mui/material'
import { StaticDatePicker } from '@mui/x-date-pickers'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'
import dayjs from 'dayjs'
import React from 'react'
import InlinePopoverField from 'src/components/InlinePopoverField'
import WorkflowHeader from 'src/components/WorkflowHeader'
import { centsToDollars, formatDateForDisplay } from 'src/utils/helpers'
import { GET_WORK_ORDER_BASE_FIELDS, UPDATE_WORK_ORDER } from '../queries'
import useQuery from 'src/hooks/useQuery'
import useModal from 'src/hooks/useModal'
import AircraftTimeModal from 'src/components/AircraftTimeModal/AircraftTimeModal'
import WorkflowAircraftDetailsDrawer from 'src/components/WorkflowAircraftDetailsDrawer'
import { Chip } from '@mui/material'
import Button from 'src/components/MUI/Button'
import Sentry from 'src/lib/sentry'

interface WorkOrderHeaderProps {
  id: string
}

export const WorkOrderHeader: React.FC<WorkOrderHeaderProps> = ({ id }) => {
  const { data } = useQuery(GET_WORK_ORDER_BASE_FIELDS, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
  })
  const [updateWorkOrder] = useMutation(UPDATE_WORK_ORDER)

  const { handleOpen: openAircraftDetailsDrawer } = useModal(
    'workflowAircraftDetailsDrawer'
  )

  const { handleOpen: openAircraftTimeModal } = useModal('aircraftTimeModal')

  const onApplyTimes = (usageLogId: string) => {
    updateWorkOrder({
      variables: {
        id: data.internalWorkOrder.id,
        input: {
          aircraftUsageLogId: usageLogId,
        },
      },
      onCompleted: (data) => {
        const dateDisplay = formatDateForDisplay(
          data.updateInternalWorkOrder.aircraftUsageLog.usageAsOf
        )
        toast.success(`Applied latest times from ${dateDisplay} to compliance`)
      },
      update: (cache, { data: { updateInternalWorkOrder } }) => {
        cache.modify({
          id: cache.identify(updateInternalWorkOrder),
          fields: {
            aircraftUsageLog: () => updateInternalWorkOrder.aircraftUsageLog,
            aircraftUsageLogId: () =>
              updateInternalWorkOrder.aircraftUsageLogId,
          },
        })
      },
    })
  }

  const noWorkItems =
    data?.internalWorkOrder?.workItems?.length === 0 &&
    data?.internalWorkOrder?.customWorkItems?.length === 0

  const allTasksReadyForReview =
    !noWorkItems &&
    data?.internalWorkOrder?.workItems?.length ===
      data?.internalWorkOrder?.workItems?.filter(
        (workItem) => workItem.status === 'REVIEW'
      ).length &&
    data?.internalWorkOrder?.customWorkItems?.length ===
      data?.internalWorkOrder?.customWorkItems?.filter(
        (customWorkItem) => customWorkItem.status === 'REVIEW'
      ).length

  const aircraftUsageLogId = data?.internalWorkOrder?.aircraftUsageLog?.id
  return (
    <>
      <WorkflowHeader
        title={data?.internalWorkOrder?.workOrderNumber}
        createdAt={data?.internalWorkOrder?.createdAt}
        tailNumber={data?.internalWorkOrder?.aircraft?.tailNumber}
        slots={{
          statusChip: (
            <Chip
              className="px-1"
              size="small"
              variant="filled"
              label={
                data?.internalWorkOrder?.status === 'IN_REVIEW'
                  ? 'In Review'
                  : 'In Progress'
              }
              color="warning"
            />
          ),
          topRightFields: (
            <>
              <Button
                variant="outlined"
                color="base"
                onClick={openAircraftDetailsDrawer}
              >
                AIRCRAFT DETAILS
              </Button>
              <Button
                variant={aircraftUsageLogId ? 'outlined' : 'contained'}
                color={aircraftUsageLogId ? 'base' : 'primary'}
                disabled={noWorkItems}
                onClick={() =>
                  openAircraftTimeModal({
                    aircraftId: data?.internalWorkOrder?.aircraft?.id,
                  })
                }
              >
                {aircraftUsageLogId ? 'CHANGE TIMES' : 'APPLY TIMES'}
              </Button>
              <Button
                onClick={async () => {
                  await updateWorkOrder({
                    variables: {
                      id,
                      input: {
                        status: 'IN_REVIEW',
                      },
                    },
                    onError: (error) => {
                      toast.error('Failed to update work order status')
                      Sentry.captureException(error)
                    },
                    update: (cache, { data: { updateInternalWorkOrder } }) => {
                      cache.modify({
                        id: cache.identify(updateInternalWorkOrder),
                        fields: {
                          status: () => updateInternalWorkOrder.status,
                        },
                      })
                    },
                  })
                }}
                disabled={!allTasksReadyForReview}
                disabledTooltip="Please mark all tasks as complete first"
              >
                REVIEW
              </Button>
            </>
          ),
          inlineFields: (
            <>
              <Divider orientation="vertical" flexItem variant="middle" />
              <InlinePopoverField
                label="Start Date"
                value={formatDateForDisplay(data?.internalWorkOrder?.startedAt)}
                icon={CalendarTodayOutlinedIcon}
                name="startedAt"
                triggerOn={{ change: false, onClose: false }}
                onSubmit={(val) => {
                  return updateWorkOrder({
                    variables: {
                      id: data.internalWorkOrder.id,
                      input: {
                        startedAt: val,
                      },
                    },
                  })
                }}
              >
                {({ submit, handleClose, value }) => (
                  <StaticDatePicker
                    value={dayjs(value)}
                    disableHighlightToday
                    onClose={handleClose}
                    onAccept={(date) => submit(date.toISOString())}
                  />
                )}
              </InlinePopoverField>

              <Divider orientation="vertical" flexItem variant="middle" />

              <InlinePopoverField
                label="End Date"
                value={formatDateForDisplay(data?.internalWorkOrder?.endedAt)}
                icon={CalendarTodayOutlinedIcon}
                name="endedAt"
                triggerOn={{ change: false, onClose: false }}
                onSubmit={(val) => {
                  return updateWorkOrder({
                    variables: {
                      id: data.internalWorkOrder.id,
                      input: {
                        endedAt: val,
                      },
                    },
                  })
                }}
              >
                {({ handleClose, submit, value }) => (
                  <>
                    <StaticDatePicker
                      value={dayjs(value)}
                      disableHighlightToday
                      onClose={handleClose}
                      onAccept={(date) => submit(date.toISOString())}
                    />
                  </>
                )}
              </InlinePopoverField>

              <Divider orientation="vertical" flexItem variant="middle" />

              <InlinePopoverField
                label="Vendor"
                value={data?.internalWorkOrder?.vendor}
                icon={StoreOutlined}
                name="vendor"
                triggerOn={{ enter: true, blur: true, onClose: true }}
                onSubmit={(val) => {
                  return updateWorkOrder({
                    variables: {
                      id: data.internalWorkOrder.id,
                      input: {
                        vendor: val,
                      },
                    },
                  })
                }}
              >
                {({
                  onChange,
                  value,
                  onKeyDown,
                  handleClose,
                  handleCancel,
                }) => (
                  <MUITextField
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    className="p-2"
                    size="small"
                    onKeyDown={(e) => {
                      onKeyDown?.(e)
                      if (e.key === 'Enter') {
                        handleClose()
                      }
                      if (e.key === 'Escape') {
                        handleCancel()
                      }
                    }}
                  />
                )}
              </InlinePopoverField>

              <Divider orientation="vertical" flexItem variant="middle" />

              <InlinePopoverField
                label="Shop"
                value={data?.internalWorkOrder?.shop}
                icon={ShoppingBagOutlined}
                name="shop"
                triggerOn={{ enter: true, blur: true, onClose: true }}
                onSubmit={(val) => {
                  return updateWorkOrder({
                    variables: {
                      id: data.internalWorkOrder.id,
                      input: {
                        shop: val,
                      },
                    },
                  })
                }}
              >
                {({
                  onChange,
                  value,
                  onKeyDown,
                  handleClose,
                  handleCancel,
                }) => (
                  <MUITextField
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    size="small"
                    className="p-2"
                    onKeyDown={(e) => {
                      onKeyDown?.(e)
                      if (e.key === 'Enter') {
                        handleClose()
                      }
                      if (e.key === 'Escape') {
                        handleCancel()
                      }
                    }}
                  />
                )}
              </InlinePopoverField>

              <Divider orientation="vertical" flexItem variant="middle" />

              <InlinePopoverField
                label="Est. Cost"
                value={`$${centsToDollars(
                  data?.internalWorkOrder?.estimatedLaborCost
                )}`}
                icon={MonetizationOnOutlined}
                name="estimatedLaborCost"
              />
            </>
          ),
        }}
      />

      <WorkflowAircraftDetailsDrawer
        aircraftId={data?.internalWorkOrder?.aircraft?.id}
      />

      <AircraftTimeModal
        onSuccess={(id) => {
          onApplyTimes(id)
        }}
      />
    </>
  )
}

export default WorkOrderHeader
