import React from 'react'

import { useLazyQuery } from '@apollo/client'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import { IconButton, Tooltip } from '@mui/material'

import { toast } from '@redwoodjs/web/toast'

const FETCH_FILE_DOWNLOAD_URL = gql`
  query FetchFileDownloadUrl($id: String!) {
    file(id: $id) {
      url
    }
  }
`

type FileDownloadButtonProps = {
  fileId: string
  downloadUrl?: string
  className?: string
}

const FileDownloadButton: React.FC<FileDownloadButtonProps> = ({
  fileId,
  downloadUrl, // optional prop if there is a custom download url instead of from backend (e.g. LogbookEntryPage/Attachments)
  className,
}) => {
  const [getFileDownloadUrl] = useLazyQuery(FETCH_FILE_DOWNLOAD_URL)

  const handleButtonClick = async () => {
    if (downloadUrl) {
      window.open(downloadUrl, '_blank')
      return
    }
    await getFileDownloadUrl({
      variables: {
        id: fileId,
      },
      onError: (error) => {
        toast.error('Error downloading file')
        console.error('Error fetching file download url:', error)
      },
      onCompleted: (data) => {
        window.open(data.file.url, '_blank')
      },
    })
  }

  return (
    <Tooltip title={'Download File'}>
      <IconButton onClick={() => handleButtonClick()} className={className}>
        <CloudDownloadOutlinedIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  )
}

export default FileDownloadButton
