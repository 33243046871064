import { useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@mui/material'
import { Dialog, DialogTitle, DialogContent } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import * as yup from 'yup'

import { Form, useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'

import TextField from 'src/components/MUI/TextField'
import { useOrgName } from 'src/hooks/useOrgName'
import useQuery from 'src/hooks/useQuery'
import { closeModal } from 'src/slices/modal'

import {
  CREATE_SHIPPING_METHOD,
  GET_ALL_SHIPPING_METHODS,
  GET_SHIPPING_METHOD_BY_ID,
  UPDATE_SHIPPING_METHOD,
} from '../../queries'

const modalName = 'shippingMethodEditorModal'

const schema = yup.object().shape({ name: yup.string().required() })

type ShippingMethodEditorProps = {
  onComplete?: (value: any) => void
}

const ShippingMethodEditor: React.FC<ShippingMethodEditorProps> = ({
  onComplete,
}) => {
  const orgSlug = useOrgName()
  const dispatch = useDispatch()

  const isOpen =
    useSelector((state: any) => state.modal.modals[modalName]?.isOpen) ?? false

  const id = useSelector(
    (state: any) => state.modal.modals[modalName]?.data?.shippingMethodId
  )

  const formMethods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  })
  const {
    formState: { errors },
  } = formMethods

  const [createShippingMethod] = useMutation(CREATE_SHIPPING_METHOD, {
    update: (cache, { data: { createShippingMethod: method } }) => {
      // Add the new address to the cache for the GET_ALL_ADDRESSES query
      // This will cause the UI to update and add the new address to the list
      const { shippingMethods } = cache.readQuery({
        query: GET_ALL_SHIPPING_METHODS,
      })

      cache.writeQuery({
        query: GET_ALL_SHIPPING_METHODS,
        data: { shippingMethods: [...shippingMethods, method] },
      })

      // automatically set the shipping method in the PO form if created from there
      onComplete?.({
        id: method.id,
        name: method.name,
        accountNumber: method.accountNumber,
      })
    },
  })
  const [updateShippingMethod] = useMutation(UPDATE_SHIPPING_METHOD)

  const { data, hasLoaded } = useQuery(GET_SHIPPING_METHOD_BY_ID, {
    variables: { id },
    skip: !id,
  })

  useEffect(() => {
    // if we load data because we're in update mode, reset the form with the new data
    if (data) {
      const { __typename, id: _id, ...shippingMethod } = data.shippingMethod

      formMethods.reset(shippingMethod)
    }
  }, [data, formMethods])

  const handleClose = () => {
    dispatch(closeModal({ name: modalName }))
    formMethods.reset({ name: '', accountNumber: '' })
  }

  const handleSubmit = async (values) => {
    if (id) {
      const updatePayload = {
        ...values,
        orgSlug,
        id: undefined,
      }

      await updateShippingMethod({
        variables: {
          id,
          input: updatePayload,
        },
      })
    } else {
      await createShippingMethod({
        variables: {
          input: { ...values, orgSlug },
        },
      })
    }
    // return to the list view and unset id
    handleClose()
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} className="min-w-xl">
      <DialogTitle>
        {id ? 'Edit Shipping Method' : 'Add Shipping Method'}
      </DialogTitle>
      <DialogContent className="pt-1">
        <Form
          onSubmit={handleSubmit}
          formMethods={formMethods}
          className="flex flex-col gap-2"
        >
          <TextField
            name="name"
            required
            label="Name"
            error={!!errors.name}
            helperText={errors.name ? 'Name is required' : ''}
            fullWidth
          />
          <TextField name="accountNumber" label="Account Number" fullWidth />
          <div className="flex gap-2">
            <Button
              color="base"
              size="large"
              onClick={handleClose}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              size="large"
              type="submit"
              variant="contained"
              color="primary"
            >
              {id ? 'Edit Shipping Method' : 'Add Shipping Method'}
            </Button>
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

export default ShippingMethodEditor
