import { useEffect, useMemo } from 'react'

import { useLazyQuery } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormHelperText, Typography } from '@mui/material'
import { defaultLogbookPrintOptions } from '@wingwork/common/src/constants/printLogbookSettings'
import { LogbookPdfOptions } from 'types/graphql'
import * as yup from 'yup'

import { Form, useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import useModal from 'src/hooks/useModal'
import fileDownload from 'src/utils/fileDownload'

import Modal from '../Modal/Modal'
import ModalActions from '../Modal/ModalActions'
import ModalContent from '../Modal/ModalContent'
import ModalHeader from '../Modal/ModalHeader'
import Button from '../MUI/Button'
import Checkbox from '../MUI/Checkbox'
import SelectDropdown from '../MUI/SelectDropdown'

import { CREATE_WORK_CARD_PDF, GET_WORK_CARD_USERS } from './queries'

type printWorkCardModalProps = {
  onBail?: () => void
  onCompleted?: (values: LogbookPdfOptions) => void
  variety: 'due_list' | 'work_order' | 'compliance'
}

const modalName = 'printWorkCardModal'

const schema = yup.object().shape({
  renderCurrentTimesOnHeader: yup.boolean(),
  populateComplianceWithCurrentTimes: yup.boolean(),
  suppressNotes: yup.boolean(),
  includeReturnToServiceStatement: yup.boolean(),
  technicianId: yup
    .string()
    .nullable()
    .transform((value) => (value === '' ? null : value)),
  includeInspector: yup.boolean(),
  inspectorId: yup
    .string()
    .nullable()
    .transform((value) => (value === '' ? null : value)),
})

const PrintWorkCardModal: React.FC<printWorkCardModalProps> = ({
  onCompleted,
  onBail,
  variety,
}) => {
  const defaultValues = {
    renderCurrentTimesOnHeader: variety !== 'due_list',
    populateComplianceWithCurrentTimes: false,
    suppressNotes: false,
    includeReturnToServiceStatement: false,
    technicianId: '',
    includeInspector: false,
    inspectorId: '',
  }

  const {
    isOpen,
    handleClose,
    data: { ids } = { ids: [] },
  } = useModal(modalName)
  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  })
  const [fetchUsers, { data }] = useLazyQuery(GET_WORK_CARD_USERS)
  const [createPdf, { loading: submitLoading }] = useMutation(
    CREATE_WORK_CARD_PDF,
    {}
  )

  const inspectorId = formMethods.watch('inspectorId')
  const includeInspector = formMethods.watch('includeInspector')

  // Set the includeInspector checkbox based on the inspectorId value
  useEffect(() => {
    formMethods.setValue('includeInspector', !!inspectorId)
  }, [inspectorId, formMethods])

  // Set the inspectorId based on the includeInspector checkbox value
  useEffect(() => {
    if (!includeInspector) {
      formMethods.setValue('inspectorId', '')
    }
  }, [includeInspector, formMethods])

  useEffect(() => {
    // reset on the closure, so you don't see the label animation on the next open
    if (!isOpen) {
      formMethods.reset(defaultValues)
    } else {
      fetchUsers()
    }
  }, [isOpen, formMethods])

  const userOptions = useMemo(() => {
    const options =
      data?.users.map((user) => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user.id,
      })) || []

    return [{ label: <em>(None)</em>, value: '' }, ...options]
  }, [data?.users])

  const handleSubmit = async (values) => {
    console.log(values)

    const id = toast.loading('Generating PDF...')
    try {
      await createPdf({
        variables: { ids, variety, options: values },

        onCompleted: async (data) => {
          await fileDownload(data.createWorkCardPdf, 'work-card.pdf', true)
          toast.success('PDF Generated', { id })
        },
      })
    } catch (e) {
      toast.error('Error generating PDF', { id })
    }

    // handleClose()
    // onCompleted?.(values)
  }

  if (!ids?.length) {
    return null
  }

  return (
    <Modal modalName={modalName} onBail={onBail} classes={{ paper: 'w-50' }}>
      <ModalHeader title="Print Work Cards" />
      <ModalContent>
        <Form
          id="printWorkCardModalForm"
          formMethods={formMethods}
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col px-3">
            <div>
              <Checkbox
                name="renderCurrentTimesOnHeader"
                label="Print Current Times on Header"
                disabled={variety !== 'due_list'}
              />
              {variety !== 'due_list' && (
                <FormHelperText className="mt-0">
                  Applied times will be printed on the header
                </FormHelperText>
              )}
            </div>
            {/* <Checkbox
              name="includeReturnToServiceStatement"
              label="Include Return To Service Statement"
            /> */}
            <Checkbox name="suppressNotes" label="Suppress Notes" />
          </div>
          <div className="mt-2 flex flex-col gap-2 px-3">
            <Typography variant="h6" className="mb-2">
              Signature Options
            </Typography>

            <div className="w-full">
              <SelectDropdown
                label="Technician"
                name="technicianId"
                options={userOptions}
                fullWidth
                disabled={variety !== 'due_list'}
              />
              {variety !== 'due_list' && (
                <FormHelperText>
                  Technician field prepopulated with task's Mechanic field
                </FormHelperText>
              )}
            </div>
            <Checkbox
              name="includeInspector"
              label="Include Inspector Signature"
            />
            <SelectDropdown
              label="Inspector"
              name="inspectorId"
              options={userOptions}
            />
          </div>
        </Form>
      </ModalContent>

      <ModalActions className="mt-3">
        <Button onClick={handleClose} variant="outlined" color="base">
          Cancel
        </Button>
        <Button
          disabled={submitLoading}
          fullWidth
          form="printWorkCardModalForm"
          type="submit"
        >
          Print
        </Button>
      </ModalActions>
    </Modal>
  )
}

export default PrintWorkCardModal
