import React, { useEffect } from 'react'

import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import CloseFullscreenRoundedIcon from '@mui/icons-material/CloseFullscreenRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import { TextField, IconButton, Popover, Typography } from '@mui/material'
import { MaintenanceItem } from 'types/graphql'
import dayjs from 'dayjs'

interface EditableDescriptionCellProps {
  timestamp?: string
  description?: string
  maintenanceItems?: MaintenanceItem[]
  onSave: (description: string) => void
  editEnabled?: boolean
}

const EMPTY_DESCRIPTION_VALUE = 'No description provided, edit to set'

const EditableDescriptionCell: React.FC<EditableDescriptionCellProps> = ({
  description,
  timestamp,
  maintenanceItems,
  editEnabled = true,
  onSave,
}) => {
  const [displayDescription, setDisplayDescription] = React.useState(
    description ? description : EMPTY_DESCRIPTION_VALUE
  )
  const ref = React.useRef<HTMLDivElement>(null)
  const [expanded, setExpanded] = React.useState(false)
  const [editing, setEditing] = React.useState(false)
  const [value, setValue] = React.useState(description)
  useEffect(() => {
    if (description && description.length > 0) {
      setDisplayDescription(description)
    } else {
      setDisplayDescription(EMPTY_DESCRIPTION_VALUE)
    }
  }, [description])

  const handleSave = (e) => {
    e.stopPropagation()
    setEditing(false)
    onSave(value)
    value
      ? setDisplayDescription(value)
      : setDisplayDescription(EMPTY_DESCRIPTION_VALUE)
  }
  const handleEdit = (e) => {
    e.stopPropagation()
    displayDescription !== EMPTY_DESCRIPTION_VALUE
      ? setValue(displayDescription)
      : setValue('')
    setEditing(true)
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSave(event)
    } else if (event.key === 'Escape') {
      setEditing(false)
    }
  }

  useEffect(() => {
    setValue(description)
  }, [description])

  const getDescription = () => {
    if (displayDescription !== EMPTY_DESCRIPTION_VALUE) {
      return (
        <Typography
          variant="body2"
          className={displayDescription ? 'font-bold' : ''}
        >
          {displayDescription.length > 60
            ? `${displayDescription.slice(0, 60)}...`
            : displayDescription}
        </Typography>
      )
    }
    if (!maintenanceItems) {
      return <Typography variant="body2">{displayDescription}</Typography>
    }
    return (
      <Typography variant="body2">
        {`Completed: ${dayjs(timestamp).format('MMM DD, YYYY')} `}
        <span className="bg-yellow-200">{` (${maintenanceItems?.length} tasks)`}</span>
      </Typography>
    )
  }

  const descriptionDOM = editing ? (
    <>
      <TextField
        label="Description"
        size="small"
        fullWidth
        value={value}
        autoFocus
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={handleKeyDown}
        onClick={(e) => {
          e.stopPropagation()
        }}
      />
      <IconButton
        onClick={(e) => {
          e.stopPropagation()
          setEditing(false)
        }}
      >
        <CloseRoundedIcon />
      </IconButton>
    </>
  ) : (
    <>
      {getDescription()}
      {(maintenanceItems?.length > 0 || description?.length > 60) && (
        <IconButton
          onClick={(e) => {
            e.stopPropagation()
            setExpanded(true)
          }}
        >
          <OpenInFullIcon />
        </IconButton>
      )}
    </>
  )
  return (
    <div className="flex items-center justify-start gap-1">
      <div className="flex items-center gap-1" ref={ref}>
        {descriptionDOM}
      </div>
      {editEnabled ? (
        editing ? (
          <IconButton onClick={handleSave}>
            <CheckRoundedIcon />
          </IconButton>
        ) : (
          <IconButton onClick={handleEdit}>
            <EditRoundedIcon />
          </IconButton>
        )
      ) : (
        <></>
      )}

      <Popover
        open={expanded}
        anchorEl={ref.current}
        className="max-h-[500px] max-w-[1000px]"
        onClose={() => setExpanded(false)}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <div
          className="flex w-full items-start justify-between"
          style={{ width: ref?.current?.offsetWidth }}
        >
          <Typography variant="body2" className="p-2 pr-0 font-bold">
            {getDescription()}
          </Typography>
          <IconButton
            onClick={(e) => {
              e.stopPropagation()
              setExpanded(false)
            }}
            className="m-1"
          >
            <CloseFullscreenRoundedIcon />
          </IconButton>
        </div>
        {maintenanceItems?.length > 0 && (
          <div className="flex flex-col px-2 pb-2">
            <div className="grid grid-cols-12">
              <Typography
                variant="body2"
                className="col-span-2 border-b border-l border-t border-solid border-gray-200 p-0.5 font-bold"
              >
                ATA Code
              </Typography>
              <Typography
                variant="body2"
                className="col-span-10 border-b border-l border-t border-solid border-gray-200 p-0.5 font-bold"
              >
                Title
              </Typography>
            </div>
            {maintenanceItems?.map((item) => (
              <div key={item?.id}>
                <div className="grid grid-cols-12">
                  <Typography
                    variant="body2"
                    className="col-span-2 border-b border-l border-t-0 border-solid border-gray-200 p-0.5"
                  >
                    {`${item?.ataCode} ${item?.manufactureCode}`}
                  </Typography>
                  <Typography
                    variant="body2"
                    className="col-span-10 border-b border-l-0 border-t-0 border-solid border-gray-200 p-0.5"
                  >
                    {item?.title}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        )}
      </Popover>
    </div>
  )
}

export default EditableDescriptionCell
