import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ComplianceState {
  aircraftUsageLogId: string
  complianceLedgerId: string
  aircraftId: string
  statusTab: string
  detailDrawerTaskId: string | null
}

const initialState: ComplianceState = {
  aircraftUsageLogId: '',
  complianceLedgerId: '',
  aircraftId: '',
  statusTab: 'All',
  detailDrawerTaskId: null,
}

const complianceSlice = createSlice({
  name: 'compliance',
  initialState,
  reducers: {
    setAircraftUsageLogId: (
      state: ComplianceState,
      action: PayloadAction<string>
    ) => {
      state.aircraftUsageLogId = action.payload
    },
    setComplianceLedgerId: (
      state: ComplianceState,
      action: PayloadAction<string>
    ) => {
      state.complianceLedgerId = action.payload
    },
    setAircraftId: (state: ComplianceState, action: PayloadAction<string>) => {
      state.aircraftId = action.payload
    },
    setStatusTab: (state: ComplianceState, action: PayloadAction<string>) => {
      state.statusTab = action.payload
    },
    setDetailDrawerTaskId: (
      state: ComplianceState,
      action?: PayloadAction<string | null>
    ) => {
      state.detailDrawerTaskId = action?.payload ?? null
    },
  },
})

export const {
  setAircraftUsageLogId,
  setComplianceLedgerId,
  setAircraftId,
  setStatusTab,
  setDetailDrawerTaskId,
} = complianceSlice.actions

export default complianceSlice.reducer
