import React from 'react'

import { Divider, Typography } from '@mui/material'

import PrimSecTextCombo from 'src/components/common/PrimSecTextCombo'
import RadioGroup, { RadioGroupOption } from 'src/components/MUI/RadioGroup'
import SelectDropdown, { SelectOption } from 'src/components/MUI/SelectDropdown'
import TextField from 'src/components/MUI/TextField'

import { TaskType } from '.'

interface GeneralInformationProps {
  onTaskChange: (task: TaskType) => void
  selectedTask: TaskType
}

const GeneralInformation: React.FC<GeneralInformationProps> = ({
  onTaskChange,
  selectedTask,
}) => {
  const aircraftOptions: SelectOption[] = [
    {
      label: 'N12345',
      value: 'N12345',
    },
    {
      label: 'N54321',
      value: 'N54321',
    },
  ]

  const taskTypeOptions: RadioGroupOption[] = [
    {
      label: 'Scheduled',
      value: 'scheduled',
    },
    {
      label: 'Squawk',
      value: 'squawk',
    },
    {
      label: 'Discrepancy',
      value: 'discrepancy',
    },
    {
      label: 'MEL',
      value: 'mel',
    },
  ]
  return (
    <div className="rounded border border-solid border-gray-300 p-1">
      <Typography variant="subtitle1" fontWeight="bold">
        General Information
      </Typography>
      <Typography variant="caption">
        Fill in the aircraft and task information to be able to track it
      </Typography>
      <div className="my-2">
        <Typography variant="subtitle2" fontWeight="bold">
          Select type of task
        </Typography>
        <div className="p-2">
          <RadioGroup
            name="taskType"
            onChange={(e) => onTaskChange(e.target.value as TaskType)}
            value={selectedTask}
            options={taskTypeOptions}
          />
        </div>
        <Divider variant="fullWidth" />
      </div>
      {selectedTask && (
        <>
          <div className="my-2">
            <Typography variant="subtitle2" fontWeight="bold">
              Aircraft
            </Typography>
            <SelectDropdown
              label="Assign to Aircraft"
              options={aircraftOptions}
              onChange={() => {}}
              value=""
              className="my-1"
            />
            <div className="grid grid-cols-2">
              <PrimSecTextCombo
                primaryText="----"
                secondaryText="Tail Number"
                variant="inverted"
              />
              <PrimSecTextCombo
                primaryText="----"
                secondaryText="Manufacturer Code"
                variant="inverted"
              />
            </div>
            <SelectDropdown
              label="Task Tracked By"
              options={aircraftOptions}
              onChange={() => {}}
              value=""
              className="my-1"
            />
          </div>
          <Divider variant="fullWidth" />
          <div className="my-2">
            <Typography variant="subtitle2" fontWeight="bold">
              Task
            </Typography>
            <div className="flex flex-col gap-2">
              <TextField
                // className="col-span-2"
                // name={`${prefix}Hours`}
                name="ata"
                label="ATA"
                size="small"
                // onChange={handleChange}
                // onBlur={handleBlur}
                // onFocus={onFocus}
                autoComplete="off"
                // error={!!errors[`${prefix}Hours`]}
                // helperText={errors[`${prefix}Hours`]?.message}
              />
              <TextField
                // className="col-span-2"
                // name={`${prefix}Hours`}
                name="itemName"
                size="small"
                label="Item Name"
                // onChange={handleChange}
                // onBlur={handleBlur}
                // onFocus={onFocus}
                autoComplete="off"
                // error={!!errors[`${prefix}Hours`]}
                // helperText={errors[`${prefix}Hours`]?.message}
              />
              <div className="flex items-center gap-1">
                <TextField
                  // className="col-span-2"
                  // name={`${prefix}Hours`}
                  name="itemName"
                  size="small"
                  label="Interval"
                  // onChange={handleChange}
                  // onBlur={handleBlur}
                  // onFocus={onFocus}
                  autoComplete="off"
                  // error={!!errors[`${prefix}Hours`]}
                  // helperText={errors[`${prefix}Hours`]?.message}
                />
                <SelectDropdown
                  label="Untis"
                  options={aircraftOptions}
                  onChange={() => {}}
                  value=""
                />
              </div>
              <div className="flex items-center gap-1">
                <TextField
                  // className="col-span-2"
                  // name={`${prefix}Hours`}
                  name="ata"
                  label="Average Labor Hours"
                  size="small"
                  // onChange={handleChange}
                  // onBlur={handleBlur}
                  // onFocus={onFocus}
                  autoComplete="off"
                  // error={!!errors[`${prefix}Hours`]}
                  // helperText={errors[`${prefix}Hours`]?.message}
                />
                <TextField
                  // className="col-span-2"
                  // name={`${prefix}Hours`}
                  name="itemName"
                  size="small"
                  label="Labor Cost"
                  // onChange={handleChange}
                  // onBlur={handleBlur}
                  // onFocus={onFocus}
                  autoComplete="off"
                  // error={!!errors[`${prefix}Hours`]}
                  // helperText={errors[`${prefix}Hours`]?.message}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default GeneralInformation
