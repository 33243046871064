import { useLazyQuery } from '@apollo/client'
import { Stack, Typography } from '@mui/material'
import clsx from 'clsx'

import { useDispatch } from 'src/hooks/useDispatch'
import { useSelector } from 'src/hooks/useSelector'
import { setModalData } from 'src/slices/modal'
import { formatDateForDisplay } from 'src/utils/helpers'

import Loading from '../Loading'
import AndroidSwitch from '../MUI/AndroidSwitch'

import CreateTimeForm from './CreateTimeForm'

interface AircraftTimeModalProps {
  onSuccess?: (aircraftUsageLogId: string) => void
  disablePick?: boolean
  aircraftUsageLogId?: string
  aircraftUsageAsOf?: string
  trackedByComponentName?: string
}

const GET_USAGE_LOGS = gql`
  query internalUsageLogsV2($id: String!) {
    aircraft(id: $id) {
      id
      tailNumber
      AircraftUsageLog {
        id
        aircraftId
        createdAt
        usageAsOf
      }
    }
  }
`

export const GET_COMPONENT_DATA = gql`
  query primaryAircraftComponentList(
    $aircraftId: String!
    $usageAsOf: String
    $after: Boolean
  ) {
    aircraftComponents(
      aircraftComponentFilters: { aircraftId: $aircraftId, isPrimary: true }
    ) {
      id
      isPrimary
      name
      isIntegral
      description
      partNumber
      serialNumber
      ComponentUsageLog(usageAsOf: $usageAsOf, after: $after) {
        id
        aircraftUsageLog {
          id
          usageAsOf
        }
        usageAsOf
        componentId
        totalTimeSinceNew
        timeSinceOverhaul
        timesSinceRepair
        cycleSinceNew
        cycleSinceOverhaul
        cyclesSinceRepair
        createdAt
        updatedAt
      }
    }
  }
`

const CreateTime: React.FC<AircraftTimeModalProps> = ({
  onSuccess,
  aircraftUsageLogId,
  aircraftUsageAsOf,
  disablePick,
  trackedByComponentName,
}) => {
  const dispatch = useDispatch()
  const [isAccruedMode, setIsAccruedMode] = React.useState(false)
  const { aircraftId, loading: isAircraftSelectorModalLoading } = useSelector(
    (state) => state.modal.modals?.aircraftTimeModal?.data
  )
  const [tailNumber, setTailNumber] = React.useState('')
  const [mostRecentUpdate, setMostRecentUpdate] = React.useState(undefined)
  const [componentData, setComponentData] = React.useState(undefined)
  const [loadComponentData, { loading: isComponentDataLoading }] = useLazyQuery(
    GET_COMPONENT_DATA,
    {
      onCompleted: (data) => {
        setComponentData(data.aircraftComponents)
      },
    }
  )
  const [loadAircraftData, { loading: isAircraftDataLoading }] = useLazyQuery(
    GET_USAGE_LOGS,
    {
      onCompleted: (resp) => {
        dispatch(
          setModalData({
            name: 'aircraftTimeModal',
            data: { loading: false },
          })
        )
        setTailNumber(resp.aircraft.tailNumber)
        setMostRecentUpdate(resp.aircraft.AircraftUsageLog[0].usageAsOf)
        loadComponentData({
          variables: {
            aircraftId: resp.aircraft.id,
          },
        })
      },
    }
  )

  const loading = React.useMemo(() => {
    return [
      isAircraftSelectorModalLoading,
      isAircraftDataLoading,
      isComponentDataLoading,
    ].some((data) => data)
  }, [
    isAircraftSelectorModalLoading,
    isAircraftDataLoading,
    isComponentDataLoading,
  ])

  React.useEffect(() => {
    if (aircraftId) {
      loadAircraftData({ variables: { id: aircraftId } })
    }
  }, [aircraftId, isAircraftSelectorModalLoading, loadAircraftData])

  return (
    <>
      <div
        className={clsx({
          'flex h-75 items-center justify-center': true,
          hidden: !loading,
        })}
      >
        <Loading />
      </div>
      <div className={!loading ? `mb-10 h-full overflow-auto pt-2` : 'hidden'}>
        <div className="mx-3 mb-3 flex items-start justify-between">
          <div className="flex flex-col">
            <Typography variant="subtitle2" id="modal-modal-description">
              Update the usage data for the aircraft {tailNumber}
            </Typography>
            <Typography
              variant="caption"
              id="modal-modal-date"
              className="mt-1"
            >
              Last updated provided on{' '}
              <span style={{ backgroundColor: 'yellow' }}>
                {formatDateForDisplay(mostRecentUpdate)}
              </span>
            </Typography>
          </div>
          <div className="flex flex-col items-end">
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="subtitle2" className="text-[#9E9E9E]">
                Total
              </Typography>
              <AndroidSwitch
                onChange={() => setIsAccruedMode(!isAccruedMode)}
                checked={isAccruedMode}
              />
              <Typography variant="subtitle2" className="text-[#9E9E9E]">
                Accrued
              </Typography>
            </Stack>
          </div>
        </div>
        <CreateTimeForm
          isAccruedMode={isAccruedMode}
          mostRecentUpdateDate={mostRecentUpdate}
          aircraftId={aircraftId}
          componentData={componentData}
          aircraftUsageLogId={aircraftUsageLogId}
          aircraftUsageAsOf={aircraftUsageAsOf}
          tailNumber={tailNumber}
          onSuccess={onSuccess}
          disablePick={disablePick}
          trackedByComponentName={trackedByComponentName}
        />
      </div>
    </>
  )
}

export default CreateTime
