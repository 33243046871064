import { useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Modal, Paper, Typography } from '@mui/material'
import { PurchaseOrderItem } from 'types/graphql'
import * as yup from 'yup'

import { Form, useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { useDispatch } from 'src/hooks/useDispatch'
import { useSelector } from 'src/hooks/useSelector'
import { closeModal } from 'src/slices/modal'

import Button from './MUI/Button'
import IconButton from './MUI/IconButton'
import TextField from './MUI/TextField'
import VendorSelector from './VendorSelector'

const REQUEST_PURCHASE_ORDER_PART = gql`
  mutation RequestPurchaseOrderPart($input: RequestPurchaseOrderPartInput!) {
    requestPurchaseOrderPart(input: $input) {
      id
      rejectedAt
      purchaseOrder {
        id
        status
      }
    }
  }
`

type RequestPartModalProps = {
  partNumber?: string
  quantity?: number
  quantityHelperText?: string
  onRequest?: ({ description, quantity, partNumber }) => void
  /** Optionally define what happens when the user clicks backdrop, the close icon, or hits "escape" */
  onBail?: () => void
  onCompleted?: (item: PurchaseOrderItem) => void
}

const modalName = 'requestPartsModal'

const schema = yup.object().shape({
  partNumber: yup.string().required('Part Number is required'),
  quantity: yup
    .number()
    .typeError('Quantity must be a number')
    .required('Quantity is required'),
  description: yup.string(),
})

const defaultValues = {
  partNumber: '',
  quantity: '',
  description: '',
  vendorId: null,
}

const RequestPartModal: React.FC<RequestPartModalProps> = ({
  partNumber: propPartNumber,
  quantity: propQuantity,
  quantityHelperText = '',
  onRequest,
  onCompleted,
  onBail,
}) => {
  const [requestPurchaseOrderPart] = useMutation(REQUEST_PURCHASE_ORDER_PART)
  const dispatch = useDispatch()
  const formMethods = useForm({ resolver: yupResolver(schema) })

  const open =
    useSelector((state) => state.modal.modals?.[modalName]?.isOpen) ?? false
  const data = useSelector((state) => state.modal.modals?.[modalName]?.data)

  const partNumber = propPartNumber ?? data?.partNumber
  const quantity = propQuantity ?? data?.quantity

  useEffect(() => {
    if (open) {
      formMethods.reset({
        ...defaultValues,
        partNumber,
        description: data?.description ?? '',
        quantity: quantity ?? '',
      })
    }
  }, [open, partNumber, quantity, data?.description, formMethods])

  const handleClose = () => {
    dispatch(closeModal({ name: modalName }))
  }

  const handleBail = () => {
    handleClose()
    onBail?.()
  }

  const handleSubmit = async (values) => {
    await requestPurchaseOrderPart({
      variables: { input: { ...values, workItemId: data?.workItemId } },
      onCompleted: ({ requestPurchaseOrderPart }) => {
        onCompleted?.(requestPurchaseOrderPart)
      },
    })
    handleClose()
    toast.success('Part requested')
    onRequest?.(values)
  }

  return (
    <Modal
      open={open}
      onClose={handleBail}
      aria-labelledby="confirmationModal-title"
      aria-describedby="confirmationModal-description"
      disablePortal
    >
      <Paper className="absolute left-1/2 top-1/2 min-w-[500px] -translate-x-1/2 -translate-y-1/2 transform rounded-xl">
        <Form formMethods={formMethods} onSubmit={handleSubmit}>
          <div className="flex flex-col gap-2 p-3">
            <div className="flex items-center">
              <Typography
                variant="h6"
                component="h3"
                id="confirmationModal-title"
              >
                Request Part
              </Typography>

              <div className="flex-grow" />

              <IconButton onClick={handleBail}>
                <CloseRoundedIcon />
              </IconButton>
            </div>
            <div className="flex gap-2">
              <TextField
                label="Part Number"
                required
                name="partNumber"
                disabled={!!partNumber}
              />
              <TextField
                label="Quantity"
                required
                name="quantity"
                helperText={quantityHelperText}
              />
            </div>
            <VendorSelector name="vendorId" control={formMethods.control} />
            <TextField
              label="Description"
              name="description"
              multiline
              fullWidth
              rows={4}
            />
            <div className="flex justify-end gap-2">
              <Button
                onClick={handleBail}
                size="large"
                variant="outlined"
                color="base"
              >
                Cancel
              </Button>

              <Button type="submit" size="large">
                Request
              </Button>
            </div>
          </div>
        </Form>
      </Paper>
    </Modal>
  )
}

export default RequestPartModal
