import { IN_PROGRESS_COMPLIANCE_STATUSES } from '@wingwork/common/src/constants'
import { LazyQueryExecFunction, QueryResult } from '@apollo/client'

import { ComplianceLedger } from 'types/graphql'
import { ThreadData } from '@liveblocks/client'
import { AvailableRoutes, matchPath, routes } from '@redwoodjs/router'

export const GET_COMPLIANCE_LEDGER_STATUS = gql`
  query GetComplianceLedgerStatus($id: String!) {
    complianceLedger(id: $id) {
      id
      status
    }
  }
`

/**
 * Get the link for a thread
 * @param thread - The thread to get the link for
 * @param orgSlug - The slug of the organization
 * @param getComplianceLedgerStatus - Function to get the status of a compliance ledger, used to determine where to navigate to
 * @param routePaths - The paths of the routes
 * @returns The link for the thread
 */
const getThreadLink = async (
  thread: ThreadData,
  orgSlug: string,
  getComplianceLedgerStatus: LazyQueryExecFunction<
    ComplianceLedger,
    {
      id: string
    }
  >,
  routePaths: Record<keyof AvailableRoutes, string>
) => {
  // For compliance, we want comments to carry over from in progress compliance to completed compliance, so the link for the notification depends on the status of the compliance
  const complianceMatch = matchPath(routePaths.bulkCompliance, thread.roomId)
  if (
    complianceMatch?.match &&
    complianceMatch.params?.ledgerId &&
    typeof complianceMatch.params.ledgerId === 'string'
  ) {
    let complianceLedgerStatusResult: QueryResult<
      ComplianceLedger,
      {
        id: string
      }
    >
    try {
      complianceLedgerStatusResult = await getComplianceLedgerStatus({
        variables: { id: complianceMatch.params.ledgerId },
      })
    } catch (error) {
      console.error(error)
    }
    if (
      complianceLedgerStatusResult &&
      !IN_PROGRESS_COMPLIANCE_STATUSES.includes(
        complianceLedgerStatusResult?.data?.complianceLedger?.status
      )
    ) {
      return routes.ledgerLogbookEntry({
        orgName: orgSlug,
        ledgerId: complianceMatch.params.ledgerId,
      })
    }
  }
  return (thread?.metadata.urlOverride as string | undefined) || thread.roomId
}

export default getThreadLink
