const CORE_MAINTENANCE_NEXT_DUE_FRAGMENT = gql`
  fragment CoreMaintenanceNextDueFields on MaintenanceNextDue {
    id
    maintenanceItemId
    isCompleted
    nextDueValue
    nextDueType
    nextDueOverride
    nextDueOverrideType
    nextDueOverrideBy
    createdAt
    updatedAt
  }
`

export default CORE_MAINTENANCE_NEXT_DUE_FRAGMENT
