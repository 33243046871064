export const GET_PURCHASE_ORDER_ITEMS = gql`
  query GetPurchaseOrderItems {
    purchaseOrderItems(
      filter: {
        withoutPurchaseOrder: true
        includeRejected: true
        includeDeleted: true
      }
    ) {
      id
      description
      requestedBy {
        id
        firstName
        lastName
      }
      quantity
      cost
      partNumber
      ataCode
      type
      rejectionNotes
      createdAt
      rejectedAt
      deletedAt
    }
  }
`

export const GET_PURCHASE_ORDER_ITEM_IDS = gql`
  query GetPurchaseOrderItemIds {
    purchaseOrderItems(filter: { withoutPurchaseOrder: true }) {
      id
    }
  }
`

export const GET_PO_NUMBERS = gql`
  query GetPONumbers {
    purchaseOrders {
      id
      status
      number
    }
  }
`

export const GET_PO_LIST = gql`
  query GetPOList($filters: PurchaseOrderFilters) {
    purchaseOrders(purchaseOrderFilters: $filters) {
      id
      number
      status
      paymentTerms
      vendor {
        id
        name
      }
      purchaseOrderItem {
        id
      }
      authorizedBy {
        id
        firstName
        lastName
      }
      shippingAddress {
        id
        addressLine1
        addressLine2
        city
        state
      }
      createdAt
    }
  }
`

export const CREATE_PURCHASE_ORDER = gql`
  mutation CreatePurchaseOrder {
    createPurchaseOrder {
      id
      number
      status
    }
  }
`

export const ADD_ITEMS_TO_PURCHASE_ORDER = gql`
  mutation AddItemsToPurchaseOrder(
    $purchaseOrderId: String!
    $ids: [String!]!
  ) {
    addItemsToPurchaseOrder(purchaseOrderId: $purchaseOrderId, ids: $ids) {
      id
      type
      ataCode
      partNumber
      description
      condition
      quantity
      purchaseOrderId
      cost
      requestedBy {
        id
        firstName
        lastName
      }
    }
  }
`

export const BULK_REJECT_PURCHASE_ORDER_ITEMS = gql`
  mutation BulkRejectPurchaseOrderItems($ids: [String!]!, $note: String) {
    bulkRejectPurchaseOrderItems(ids: $ids, note: $note) {
      id
      rejectionNotes
      rejectedAt
    }
  }
`

export const DELETE_PURCHASE_ORDER_ITEM = gql`
  mutation DeletePurchaseOrderItem($id: String!) {
    deletePurchaseOrderItem(id: $id) {
      id
      rejectedAt
      deletedAt
    }
  }
`

export const UPDATE_PURCHASE_ORDER_ITEM = gql`
  mutation UpdatePurchaseOrderItem(
    $id: String!
    $input: UpdatePurchaseOrderItemInput!
  ) {
    updatePurchaseOrderItem(id: $id, input: $input) {
      id
      rejectedAt
      deletedAt
    }
  }
`

export const BULK_CANCEL_PURCHASE_ORDERS = gql`
  mutation BulkCancelPurchaseOrders($ids: [String!]!) {
    bulkCancelPurchaseOrders(ids: $ids) {
      id
      status
    }
  }
`
