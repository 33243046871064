import { t } from 'i18next'
import { Chip } from '@mui/material'
const statusMap = {
  DRAFT: {
    bgColor: 'rgba(0, 0, 0, 0.08)', // light gray
    textColor: 'rgba(0, 0, 0, 0.87)', // dark text for better contrast
  },
  // IN PROGRESS
  OPEN: {
    bgColor: 'rgba(239, 108, 0, 0.08)', // light orange
    textColor: 'rgba(230, 81, 0, 1)', // dark orange
  },
  COMPLETED: {
    textColor: 'rgba(27, 94, 32, 1)', // light green
    bgColor: 'rgb(237, 247, 237)', // light green
  },
  CANCELLED: {
    bgColor: 'rgba(211, 47, 47, 0.08)', // light red
    textColor: 'rgba(198, 40, 40, 1)', // dark red
  },
}

interface PurchaseOrderStatusChipProps {
  status: 'DRAFT' | 'OPEN' | 'COMPLETED' | 'CANCELLED'
  chipSize: 'small' | 'medium'
}

const PurchaseOrderStatusChip: React.FC<PurchaseOrderStatusChipProps> = ({
  status,
  chipSize,
}) => {
  return (
    <Chip
      className="px-1"
      size={chipSize}
      variant="filled"
      label={t(`purchaseOrder.status.${status}`, { defaultValue: status })}
      sx={{
        backgroundColor: statusMap[status].bgColor,
        '& .MuiChip-label': {
          color: statusMap[status].textColor,
        },
      }}
    />
  )
}

export default PurchaseOrderStatusChip
