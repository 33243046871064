import useTrackingComponents from 'src/hooks/requests/useTrackingComponentOptions'

import SelectDropdown from '../MUI/SelectDropdown'

interface TrackedByComponentsSelectorProp {
  aircraftId: string
  name?: string
  disabled?: boolean
}

const TrackedByComponentsSelector: React.FC<
  TrackedByComponentsSelectorProp
> = ({ aircraftId, name, disabled }) => {
  const { trackingComponentOptions, loading, hasLoaded } =
    useTrackingComponents(aircraftId)
  const renderValue = (selectedValue: any) => {
    if (typeof selectedValue === 'object' && selectedValue !== null) {
      return selectedValue.name // Assuming the object has a 'name' property
    }
    return selectedValue
  }
  return (
    <SelectDropdown
      name={name || 'trackedBy'}
      label="Tracked By"
      options={trackingComponentOptions}
      loading={loading}
      disabled={disabled || !hasLoaded}
      fullWidth
      renderValue={renderValue}
    />
  )
}

export default TrackedByComponentsSelector
