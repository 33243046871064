import { Button, Typography } from '@mui/material'
import { Form } from '@redwoodjs/forms'
import { ReactNode, useState } from 'react'
import { FormProvider, UseFormReturn } from 'react-hook-form'
import { Popover } from 'src/components/Popover/Popover'
import FilterSectionPicker from './FilterSectionPicker'
import FilterList from '@mui/icons-material/FilterList'

interface FilterPopoverProps<TFormValues extends Record<string, any>> {
  initialFilterSection?: string
  sections: string[]
  buttonText?: string
  children: (currentSection: string) => ReactNode
  formMethods: UseFormReturn<TFormValues>
  onSubmit: (data: TFormValues) => void
  buttonColor?: 'base' | 'primary'
  clearAllFilters: () => void
  keepMounted?: boolean
}

const FilterPopover = <TFormValues extends Record<string, any>>({
  initialFilterSection,
  sections,
  buttonText = 'Filters',
  formMethods,
  onSubmit,
  children,
  keepMounted = true,
  buttonColor = 'base',
  clearAllFilters,
}: FilterPopoverProps<TFormValues>) => {
  const [selectedFilterSection, setSelectedFilterSection] = useState<string>(
    initialFilterSection || sections[0]
  )

  return (
    <Popover
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      gap={1}
      gapSide="top"
      keepMounted={keepMounted}
      classes={{
        paper: 'rounded-lg',
      }}
      anchor={
        <Button
          variant="outlined"
          color={buttonColor}
          startIcon={<FilterList />}
          className="h-[32px]"
        >
          {buttonText}
        </Button>
      }
      content={
        <FormProvider {...formMethods}>
          <Form formMethods={formMethods} onSubmit={onSubmit}>
            <div className="flex h-50 w-120 overflow-y-clip">
              <FilterSectionPicker<TFormValues>
                filterSections={sections}
                selectedFilterSection={selectedFilterSection}
                setSelectedFilterSection={setSelectedFilterSection}
                formMethods={formMethods}
                clearAllFilters={clearAllFilters}
              />

              <div className="shrink-0 basis-3/4">
                <div className="ml-2 mt-2">
                  <Typography variant="caption">
                    {selectedFilterSection}
                  </Typography>
                </div>
                <div className="h-[calc(100%-36px)] overflow-y-auto">
                  {sections.map((section) => (
                    <div
                      key={section}
                      style={{
                        display:
                          selectedFilterSection === section ? 'block' : 'none',
                      }}
                    >
                      {children(section)}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Form>
        </FormProvider>
      }
    />
  )
}

export default FilterPopover
