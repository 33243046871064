import { useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Card, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Form } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'

import { useOrgName } from 'src/hooks/useOrgName'
import useQuery from 'src/hooks/useQuery'
import { useSelector } from 'src/hooks/useSelector'

import Loading from '../Loading'
import Button from '../MUI/Button'
import SelectDropdown from '../MUI/SelectDropdown'
import TextField from '../MUI/TextField'

import { CREATE_VENDOR, GET_VENDOR_BY_ID, UPDATE_VENDOR } from './queries'

export const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  phoneNumber: yup.string().nullable(),
  website: yup.string().nullable(),
  type: yup.string().required('Type is required'),
})

const AddVendor = ({ onCancel }) => {
  const formMethods = useForm({ mode: 'onBlur', resolver: yupResolver(schema) })
  const orgSlug = useOrgName()
  const id = useSelector((state) => state.modal.modals.vendorDrawer?.data?.id)

  const [createVendor] = useMutation(CREATE_VENDOR)
  const [updateVendor] = useMutation(UPDATE_VENDOR)
  const { data, hasLoaded } = useQuery(GET_VENDOR_BY_ID, {
    variables: { id },
    skip: !id,
  })

  useEffect(() => {
    if (data) {
      const { __typename, id: _id, ...vendor } = data.vendor

      formMethods.reset(vendor)
    }
  }, [data, formMethods])

  const handleSubmit = async (values) => {
    if (id) {
      await updateVendor({
        variables: {
          id,
          input: { ...values, orgSlug: undefined },
        },
      })
    } else {
      await createVendor({
        variables: {
          input: { ...values, orgSlug },
        },
      })
    }
    // return to the list view and unset id
    onCancel()
  }

  if (id && !hasLoaded) {
    return <Loading />
  }

  return (
    <Card className="">
      <div className="p-2">
        <div className="mb-4 flex items-center justify-between">
          <Typography variant="h6">
            {id ? 'Edit Vendor' : 'Add New Vendor'}
          </Typography>
        </div>
        <Form formMethods={formMethods} onSubmit={handleSubmit}>
          <div className="flex flex-col gap-2">
            <TextField name="name" label="Name" required />
            <TextField name="email" label="Email" required />
            <TextField name="phoneNumber" label="Phone Number" />
            <TextField name="website" label="Website" />
            <SelectDropdown
              required
              size="medium"
              name="type"
              label="Type"
              options={[
                { label: 'Customer', value: 'CUSTOMER' },
                { label: 'Supplier', value: 'SUPPLIER' },
                { label: 'Manufacturer', value: 'MANUFACTURER' },
              ]}
            />
            <div className="flex justify-end gap-2">
              <Button onClick={onCancel} color="base" variant="outlined">
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </div>
          </div>
        </Form>
      </div>
    </Card>
  )
}

export default AddVendor
