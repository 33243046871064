import React, { createContext, useContext } from 'react'

import { ModalName } from 'src/slices/modal'

// Create a context for the modal
const ModalContext = createContext<ModalName | null>(null)

export const ModalProvider = ({ modalName, children }) => {
  return (
    <ModalContext.Provider value={modalName}>{children}</ModalContext.Provider>
  )
}

export const useModalName = () => useContext(ModalContext)
