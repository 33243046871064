import CloseIcon from '@mui/icons-material/Close'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import { IconButton, Modal, Paper, Typography } from '@mui/material'

import Button from 'src/components/MUI/Button'
import { useDispatch } from 'src/hooks/useDispatch'
import { useSelector } from 'src/hooks/useSelector'
import { closeModal, openModal } from 'src/slices/modal'
import { downloadPdfFromUrl } from 'src/utils/helpers'
import useUserIdWithSignature from 'src/hooks/useUserIdWithSignature'
import { ComplianceLedger, LogbookPdfOptions } from 'types/graphql'
import useCreateLogbookPdf from 'src/hooks/requests/useCreateLogbookPdf'
import useQuery from 'src/hooks/useQuery'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'
import LogbookEntrySignForm from 'src/components/ViewLogbookEntryModal/LogbookEntrySignForm'
import { useOrgName } from 'src/hooks/useOrgName'
import Loading from 'src/components/Loading'
import dayjs from 'dayjs'
import useModal from 'src/hooks/useModal'
import { GET_COMPLIANCE_LEDGER_STATUS } from 'src/pages/BulkComplianceDetailPage/queries'
import { GET_WORK_ORDER_BASE_FIELDS } from 'src/pages/WorkOrderCompliancePageV2/queries'
import { GET_COMPLIANCE_LEDGER_BASE_FIELDS } from 'src/pages/BulkCompliancePageV2/queries'

const COMPLIANCE_LEDGER_TO_SIGN = gql`
  query ComplianceLedgerToSign($id: String!) {
    complianceLedger(id: $id) {
      id
      status
      workedBy {
        id
        firstName
        lastName
      }
      inspectedBy {
        id
        firstName
        lastName
      }
      requestedWorker {
        id
        firstName
        lastName
      }
      requestedInspector {
        id
        firstName
        lastName
      }
      signatures {
        id
        signedAs
        signer {
          id
          firstName
          lastName
        }
      }
    }
  }
`

const SIGN_COMPLIANCE_LEDGER = gql`
  mutation SignComplianceLedger(
    $id: String!
    $workedByPin: String
    $inspectedByPin: String
  ) {
    signComplianceLedger(
      id: $id
      workedByPin: $workedByPin
      inspectedByPin: $inspectedByPin
    ) {
      id
      status
      workedBy {
        id
        firstName
        lastName
      }
      inspectedBy {
        id
        firstName
        lastName
      }
      signatures {
        id
        signedAs
        signer {
          id
          firstName
          lastName
        }
      }
    }
  }
`

type ViewLogbookEntryModalProps = {
  // urlForPdfPreview: string
  // urlForPdfDownload: string
  // fileName: string
  ledgerId: string
  workOrderId?: string
}

const modalName = 'viewLogbookEntryModal'

const ViewLogbookEntryModal: React.FC<ViewLogbookEntryModalProps> = ({
  // urlForPdfPreview,
  // urlForPdfDownload,
  // fileName,
  ledgerId,
  workOrderId,
}) => {
  const dispatch = useDispatch()
  const orgName = useOrgName()

  const handleClose = () => {
    dispatch(closeModal({ name: modalName }))
  }
  const open =
    useSelector((state) => state.modal.modals?.[modalName]?.isOpen) ?? false

  const {
    id: userId,
    signatureFile: signatureFile,
    hasLoaded: userLoaded,
  } = useUserIdWithSignature()
  const { data, hasLoaded } = useQuery(COMPLIANCE_LEDGER_TO_SIGN, {
    variables: { id: ledgerId },
  })
  const { complianceLedger: ledger }: { complianceLedger: ComplianceLedger } =
    data ?? {}

  const [signComplianceLedger] = useMutation(SIGN_COMPLIANCE_LEDGER)
  const { createLogbookPdf } = useCreateLogbookPdf()

  const [pdfLoaded, setPdfLoaded] = React.useState(false)
  const [pdfUrl, setPdfUrl] = React.useState('' as string)
  const [pdfFailed, setPdfFailed] = React.useState(false)

  React.useEffect(() => {
    try {
      if (!open || !hasLoaded) return

      // the pdfOptions are stored in the BE when we first create the logbook
      createLogbookPdf(ledgerId, null, undefined, {
        onCompleted: (data) => {
          setPdfUrl(data.createMaintenanceLogbookPdf)
          setPdfLoaded(true)
        },
        onError: (error) => {
          console.error(error)
          toast.error(
            `Something went wrong while loading the document.\nPlease contact support.`,
            {
              duration: 3000,
            }
          )
          setPdfFailed(true)
        },
      })
    } catch (error) {
      console.error(error)
      toast.error(
        `Something went wrong while loading the document.\nPlease contact support.`,
        {
          duration: 3000,
        }
      )
      setPdfFailed(true)
    }
  }, [open, hasLoaded])

  const handleSubmit = async (values) => {
    // this prevents empty form submits
    if (!values.workedByPin && !values.inspectedByPin) {
      toast.error('Please enter a PIN')
      return
    }

    // this exists if the user is both the technician and the inspector
    if (
      ledger?.requestedWorker?.id === ledger?.requestedInspector?.id &&
      (!values.workedByPin || !values.inspectedByPin)
    ) {
      toast.error(
        'If Technician and Inspector are the same user, both pins must be entered at the same time.',
        { duration: 10000 }
      )
      return
    }
    await signComplianceLedger({
      variables: {
        id: ledgerId,
        workedByPin: values.workedByPin,
        inspectedByPin: values.inspectedByPin,
      },
      onError: (error) => {
        if (error.message === 'PIN_DOES_NOT_MATCH') {
          toast.error('PIN does not match')
        } else if (error.message === 'PIN_EMPTY') {
          toast.error(
            'If Technician or Inspector is selected, their pins must be entered as well',
            { duration: 10000 }
          )
        } else if (error.message === 'INSPECTOR_CANNOT_SIGN_BEFORE_WORKER') {
          toast.error('Inspector cannot sign before worker')
        } else {
          toast.error('Something went wrong')
        }
      },
      onCompleted: async (data) => {
        if (data.signComplianceLedger.status === 'COMPLETED_LOGBOOK') {
          toast.success('Logbook signed and completed')
        } else {
          toast.success('Logbook signed')
        }
        // Reset PDF states
        setPdfLoaded(false)
        setPdfUrl('')

        // Regenerate the PDF
        createLogbookPdf(ledgerId, null, undefined, {
          onCompleted: (data) => {
            setPdfUrl(data.createMaintenanceLogbookPdf)
            setPdfLoaded(true)
          },
          onError: (error) => {
            console.error(error)
            toast.error(
              `Something went wrong while loading the document.\nPlease contact support.`,
              {
                duration: 3000,
              }
            )
            setPdfFailed(true)
          },
        })
      },
      update: (cache, { data: { signComplianceLedger } }) => {
        // updates the LogbookEntrySignForm UI
        const ledgerToSignData = cache.readQuery({
          query: COMPLIANCE_LEDGER_TO_SIGN,
          variables: { id: ledgerId },
        })

        cache.writeQuery({
          query: COMPLIANCE_LEDGER_TO_SIGN,
          variables: { id: ledgerId },
          data: {
            complianceLedger: {
              ...ledgerToSignData.complianceLedger,
              status: signComplianceLedger.status,
              inspectedBy: signComplianceLedger.inspectedBy,
              workedBy: signComplianceLedger.workedBy,
              signatures: signComplianceLedger.signatures,
            },
          },
        })

        // update the workedById OR inspectedById so the UI that is conditionally rendered based on isUserRequestedAsNextSigner is updated
        if (workOrderId) {
          const workOrderOldData = cache.readQuery({
            query: GET_WORK_ORDER_BASE_FIELDS,
            variables: { id: workOrderId },
          })
          cache.writeQuery({
            query: GET_WORK_ORDER_BASE_FIELDS,
            variables: { id: workOrderId },
            data: {
              internalWorkOrder: {
                ...workOrderOldData.internalWorkOrder,
                inspectedById: signComplianceLedger.inspectedBy?.id,
                workedById: signComplianceLedger.workedBy?.id,
              },
            },
          })
        } else {
          const ledgerOldData = cache.readQuery({
            query: GET_COMPLIANCE_LEDGER_BASE_FIELDS,
            variables: { id: ledgerId },
          })
          cache.writeQuery({
            query: GET_COMPLIANCE_LEDGER_BASE_FIELDS,
            variables: { id: ledgerId },
            data: {
              complianceLedger: {
                ...ledgerOldData.complianceLedger,
                inspectedById: signComplianceLedger.inspectedBy?.id,
                workedById: signComplianceLedger.workedBy?.id,
              },
            },
          })
        }

        // if completed, update the status so the status chip in the header is updated
        if (signComplianceLedger.status === 'COMPLETED_LOGBOOK') {
          cache.writeQuery({
            query: GET_COMPLIANCE_LEDGER_STATUS,
            variables: { id: ledgerId },
            data: {
              complianceLedger: {
                id: ledgerId,
                status: 'COMPLETED_LOGBOOK',
              },
            },
          })
        }
      },
    })
  }

  const handlePrintLogbookEntry = async () => {
    const loadingToastId = toast.loading('Preparing download...')
    const response = await fetch(pdfUrl)
    const pdfBlob = new Blob([await response.blob()], {
      type: 'application/pdf',
    })

    // Generate URL from downloaded PDF
    const pdfBlobUrl = URL.createObjectURL(pdfBlob)
    const link = document.createElement('a')
    link.href = pdfBlobUrl
    link.setAttribute(
      'download',
      `logbook-${dayjs(ledger.timestamp).format('YYYY-MM-DD')}.pdf`
    )
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    toast.success('Downloading PDF...', { id: loadingToastId })
  }

  if (pdfFailed) {
    return (
      <Typography variant="h6">
        Something went wrong while loading the document. Please try again later.
      </Typography>
    )
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper className="absolute left-1/2 top-1/2 max-h-[90vh] w-140 -translate-x-1/2 -translate-y-1/2 transform overflow-y-auto rounded-xl p-3">
        {!hasLoaded || !pdfLoaded || !userLoaded ? (
          <Loading />
        ) : (
          <>
            <div className="mb-2 flex items-center justify-between">
              <Typography variant="h6" component="h2">
                Logbook Entry
              </Typography>
              <div className="flex items-center gap-2">
                {ledger.status === 'COMPLETED_LOGBOOK' && (
                  <Button
                    variant="outlined"
                    color="base"
                    onClick={() => {
                      handlePrintLogbookEntry()
                    }}
                  >
                    Print
                  </Button>
                )}
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <iframe
              title="logbookToSign"
              src={pdfUrl}
              className="mb-4 h-[calc(80vh-180px)] w-full"
            />

            {ledger.status === 'SIGN' && (
              <LogbookEntrySignForm
                orgName={orgName}
                userId={userId}
                ledger={ledger}
                signatureFile={signatureFile}
                handleSubmit={handleSubmit}
              />
            )}
          </>
        )}
      </Paper>
    </Modal>
  )
}

export default ViewLogbookEntryModal
