import { useLazyQuery } from '@apollo/client'
import { Chip, Paper, Typography } from '@mui/material'
import { Permissions } from '@wingwork/common/src/constants/permissions'
import dayjs from 'dayjs'
import {
  Aircraft,
  MaintenanceItem,
  MaintenanceItemFilters,
  DueListReportType,
} from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { toast } from '@redwoodjs/web/toast'

import AircraftTimeModal from 'src/components/AircraftTimeModal/AircraftTimeModal'
import CORE_AIRCRAFT_USAGE_LOG_FRAGMENT from 'src/fragments/AircraftUsageLog'
import { AircraftFragment } from 'src/fragments/workCardPdfFragment'
import useAppLayoutState from 'src/hooks/useAppLayoutSlice'
import { useDispatch } from 'src/hooks/useDispatch'
import useMaintenanceItemTable, {
  TableVariant,
} from 'src/hooks/useMaintenanceItemTable'
import useHasPermission from 'src/hooks/useHasPermission'
import { useOrgName } from 'src/hooks/useOrgName'
import Sentry from 'src/lib/sentry'
import { openModal } from 'src/slices/modal'

import AddMtxItemsToPurchaseOrderModal from '../AddMtxItemsToPurchaseOrderModal/AddMtxItemsToPurchaseOrderModal'
import AircraftSelectorModal from '../AircraftSelectorModal'
import CreateNonRoutineTaskSlider from '../CreateNonRoutineTaskSlider'
import DueListTableFilters from '../DueListTableFilters/DueListTableFiltersV2'
import PrintWorkCardModal from '../PrintWorkCardModal/PrintWorkCardModal'

import CreateNewTaskDrawer from './CreateNewTaskDrawer'
import TableWithData from './TableWithData/indexV2'
import { manageColumnsEnabled } from './TableWithData/TableHead'
import { useMutation } from '@redwoodjs/web'
import { useSelector } from 'src/hooks/useSelector'
import DropdownButton, { MenuOption } from '../MUI/DropdownButton'
import Button from '../MUI/Button'
import { useEffect } from 'react'

const WORK_CARD_PDF_DATA = gql`
  ${AircraftFragment}
  ${CORE_AIRCRAFT_USAGE_LOG_FRAGMENT}
  query workCardPdfData($orgSlug: String!, $maintenanceItemIds: [String!]!) {
    aircrafts(orgSlug: $orgSlug) {
      ...AircraftFragment
    }
    maintenanceItems(ids: $maintenanceItemIds, maintenanceItemFilters: {}) {
      id
      aircraftId
      ataCode
      manufactureCode
      title
      cadenceType
      cadenceValue
      trackedByComponentId
      trackedByComponent {
        id
        name
      }
      lastComplianceDate
      lastComplianceStamp {
        ...CoreAircraftUsageLogFields
      }
      aircraftComponent {
        id
        partNumber
        serialNumber
      }
      maintenanceNextDue {
        id
        nextDueValue
      }
    }
  }
`
const DUE_LIST_AIRCRAFT_PDF_DATA = gql`
  ${AircraftFragment}
  query DueListPdfAircraftData($aircraftId: String!) {
    aircraft(id: $aircraftId) {
      ...AircraftFragment
      AircraftUsageParameter {
        id
        dailyFlyingTime
        dailyLandings
      }
    }
  }
`
const DUE_LIST_MAINTENANCE_ITEM_PDF_DATA = gql`
  ${CORE_AIRCRAFT_USAGE_LOG_FRAGMENT}
  query DueListPdfMaintenanceItems(
    $filters: MaintenanceItemFilters
    $maintenanceItemIds: [String!]
    $sortField: String
    $sortOrder: String
  ) {
    maintenanceItems(
      maintenanceItemFilters: $filters
      sorting: { sortField: $sortField, sortOrder: $sortOrder }
      ids: $maintenanceItemIds
    ) {
      id
      ataCode
      manufactureCode
      title
      cadenceType
      cadenceValue
      nextDueStatus
      adSbStatus
      trackedByComponentId
      trackedByComponent {
        id
        name
      }
      lastComplianceDate
      lastComplianceStamp {
        ...CoreAircraftUsageLogFields
      }
      importedDataCompliance
      aircraftComponent {
        id
        partNumber
        serialNumber
      }
      maintenanceNextDue(isCompleted: false) {
        id
        nextDueValue
        nextDueOverride
      }
    }
  }
`

const CREATE_DUE_LIST_PDF = gql`
  mutation CreateDueListPDF(
    $aircraftId: String!
    $maintenanceItemFilters: MaintenanceItemFilters
    $sorting: SortingInput
    $ids: [String!]
  ) {
    createDueListPDF(
      aircraftId: $aircraftId
      maintenanceItemFilters: $maintenanceItemFilters
      sorting: $sorting
      ids: $ids
      type: DUE_LIST
    )
  }
`

interface DueListTableProps {
  onSelectItem?: (id: string | string[], isSelected?: boolean) => void
  queryVariables?: MaintenanceItemFilters
  variant: TableVariant
  disableApplyTimes?: boolean
  taskViewMode?: 'simple' | 'advanced'
  selectedAircraft?: string
  actions?: React.ReactElement[]
  filterProps?: {
    searchEnabled?: boolean
    aircraftFilterEnabled?: boolean
    projectionFilterEnabled?: boolean
    usageFilterEnabled?: boolean
  }
  discriminator?:
    | ''
    | 'associatedTasks'
    | 'componentRelatedMaintenanceItems'
    | 'unscheduledType'
  onChildTaskSliderClick?: (row: MaintenanceItem) => void
  enableRowVirtualization?: boolean
}

const handleError = (toastId: string) => (e: Error) => {
  let message = 'Error generating pdf.'
  if (e.message.toLowerCase().includes('Something went wrong')) {
    message += `\n Please contact support.`
  } else {
    message += `\n${e.message}`
  }
  toast.error(message, { id: toastId })
}

const DueListTable: React.FC<DueListTableProps> = ({
  onSelectItem,
  variant,
  selectedAircraft,
  queryVariables,
  disableApplyTimes = false,
  taskViewMode = 'advanced',
  actions = [],
  filterProps = {
    searchEnabled: true,
    aircraftFilterEnabled: true,
    projectionFilterEnabled: true,
    usageFilterEnabled: true,
    manageColumnsEnabled: manageColumnsEnabled,
  },
  discriminator = '',
  onChildTaskSliderClick,
  enableRowVirtualization = false,
}) => {
  const selectedAircraftIds = useSelector(
    (state) => state.dueListTable.selectedAircrafts
  )
  const { navDrawerExpanded } = useAppLayoutState()
  const [tableTitle, setTableTitle] = React.useState('')

  useEffect(() => {
    if (variant === 'duelist') {
      setTableTitle('Fleet Due List')
    } else {
      setTableTitle('Tasks List')
    }
  }, [variant])

  const [massagedQueryVariables, setMassagedQueryVariables] = React.useState({})
  const [createNewTask, setCreateNewTask] = React.useState(false)
  const [showCreateNonRoutineTaskModal, setShowCreateNonRoutineTaskModal] =
    React.useState(false)
  const {
    setFilterDataBy,
    removeProjectionFilters,
    areProjectionsApplied,
    setTableRefreshKey,
    duelistFiltersData: {
      tableRefreshKey,
      filterDataBy,
      aircrafts: aircraftFilter,
    },
    resetAircraftFilter,
    selectedItemIds,
  } = useMaintenanceItemTable(variant)
  const selectedAircrafts = Object.keys(aircraftFilter).filter(
    (key) => aircraftFilter[key]
  )

  const orgName = useOrgName()
  const dispatch = useDispatch()
  const [fetchDueListPdfAircraftData] = useLazyQuery(DUE_LIST_AIRCRAFT_PDF_DATA)
  const [fetchDueListPdfMaintenanceItemData] = useLazyQuery(
    DUE_LIST_MAINTENANCE_ITEM_PDF_DATA
  )
  const [fetchWorkCardPdfData] = useLazyQuery(WORK_CARD_PDF_DATA)

  const handlePrintDueList = () => {
    if (selectedAircraft) {
      printDueList(selectedAircraft)
    } else {
      dispatch(openModal({ name: 'aircraftSelectorModal' }))
    }
  }

  const [createDueListPdf] = useMutation(CREATE_DUE_LIST_PDF)

  const printDueList = async (aircraftId) => {
    const loadingToastId = toast.loading('Generating PDF...')

    try {
      const { sortField, sortOrder, pageSize, ...filters } =
        massagedQueryVariables

      fetchDueListPdfAircraftData({
        variables: { aircraftId },
        onError: handleError(loadingToastId),
        onCompleted: async ({ aircraft }: { aircraft: Aircraft }) => {
          const variables = {
            sortOrder,
            sortField,
          }
          if (selectedItemIds.length > 0) {
            variables['maintenanceItemIds'] = selectedItemIds
            variables['filters'] = { aircraftIds: [aircraftId] }
          } else {
            const showDueInNextNDays = filters.showDueInNextNDays ?? 60
            const defaultedFilters = {
              ...filters,
              aircraftIds: [aircraftId],
              remainingValueFilters: filters?.remainingValueFilters ?? [
                {
                  type: 'LANDINGS',
                  value:
                    showDueInNextNDays *
                    aircraft.AircraftUsageParameter?.dailyLandings,
                },
                {
                  type: 'FLYING_HOURS',
                  value:
                    showDueInNextNDays *
                    aircraft?.AircraftUsageParameter?.dailyFlyingTime,
                },
                {
                  type: 'CYCLES',
                  value:
                    showDueInNextNDays *
                    aircraft?.AircraftUsageParameter?.dailyLandings,
                },
              ],
              showDueInNextNDays,
            }
            variables['filters'] = defaultedFilters
          }

          const pdfResponse = await createDueListPdf({
            variables: {
              aircraftId,
              maintenanceItemFilters: variables.filters,
              sorting: { sortField, sortOrder },
              ids: selectedItemIds,
            },
          }).catch((e) => {
            handleError(loadingToastId)(e)
          })

          const pdfUrl = pdfResponse.data.createDueListPDF

          toast.loading('Preparing download...', { id: loadingToastId })
          const response = await fetch(pdfUrl)
          const pdfBlob = new Blob([await response.blob()], {
            type: 'application/pdf',
          })

          // Generate URL from downloaded PDF
          const pdfBlobUrl = URL.createObjectURL(pdfBlob)
          const link = document.createElement('a')
          link.href = pdfBlobUrl
          link.setAttribute(
            'download',
            `${aircraft.tailNumber}-duelist-${dayjs().format('YYYY-MM-DD')}.pdf`
          )
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          toast.success('Downloading PDF...', { id: loadingToastId })
        },
      })
    } catch (e) {
      handleError(loadingToastId)(e)
    }
  }

  const onCreateNewTaskMenuClick = () => {
    // setCreateNewTask(true)
    navigate(routes.createTask({ orgName }))
  }

  const onCreateNonRoutineTaskMenuClick = () => {
    setShowCreateNonRoutineTaskModal(true)
  }

  const onCreateTaskClick = () => {
    setCreateNewTask(false)
  }

  const onResetClick = () => {
    setCreateNewTask(false)
  }

  const canAddToPurchaseOrder = useHasPermission(
    Permissions.purchaseOrder.addItems
  )

  const tableMenuOptions: MenuOption[] = [
    {
      label: 'Create New Task',
      onClick: () => onCreateNewTaskMenuClick(),
    },
    {
      label: 'Create Non Routine Task',
      onClick: () => onCreateNonRoutineTaskMenuClick(),
    },
    {
      label: 'Print Work Cards',
      onClick: () =>
        dispatch(
          openModal({
            name: 'printWorkCardModal',
            data: { ids: selectedItemIds },
          })
        ),
      disabled: !(selectedItemIds?.length ?? false),
    },
    {
      label: 'Print List',
      onClick: handlePrintDueList,
      disabled: selectedAircraftIds.length > 1,
      disabledTooltip: 'All selected items must belong to a single aircraft',
    },
    {
      label: 'Add to Purchase Order',
      onClick: () =>
        dispatch(openModal({ name: 'addMtxItemsToPurchaseOrderModal' })),
      disabled: selectedItemIds.length === 0,
      disabledTooltip: 'Select some tasks to add to a Purchase Order',
      locked: !canAddToPurchaseOrder,
      lockedTooltip: 'You do not have permission',
    },
    // TODO: Support this functionality
    /* {
      label: 'Save Duelist as...',
      onClick: () => console.log('Edit'),
      disabled: true,
    }, */
  ]

  const filterChipLabelMap = {
    pastDueAndAog: 'Overdue',
    inToleranceAndComingDue: 'Coming Due',
    discrepanciesAndMel: 'Discrepancies & MELs',
    ads: 'ADs',
    sbs: 'SBs',
    adsb: 'ADs & SBs',
    adhocOnlyNotDue: 'Adhoc Only - Not Due',
    adhocAndDuelist: 'Adhoc & Duelist',
  }

  const onFilterRemove = (idx) => {
    setFilterDataBy(filterDataBy.filter((_, index) => index !== idx))
  }

  const onApplyTimesSuccess = async () => {
    setTableRefreshKey()
    fetch('/.netlify/functions/statusRefresher-background')
      .then(async (resp) => {
        if (resp.status !== 200) {
          Sentry.captureException(resp.statusText)
        }
      })
      .catch((err) => {
        Sentry.captureException(err)
      })
  }

  const itemActionsDropdown = (
    <DropdownButton
      variant="outlined"
      color="base"
      options={tableMenuOptions}
      className="h-[32px]"
    >
      ITEM ACTIONS
    </DropdownButton>
  )

  const [openAircraftFilter, setOpenAircraftFilter] = React.useState(false)

  const onToggleAircraftFilter = () => {
    setOpenAircraftFilter(!openAircraftFilter)
  }

  return (
    <div
      className="flex h-full flex-col gap-4 overflow-hidden"
      id="duelist-container"
    >
      {discriminator !== 'associatedTasks' && (
        <div className="grid gap-2">
          {/* Top row with title and actions */}
          <div className="grid grid-cols-[1fr_auto] items-center gap-4">
            {/* Left side with title and aircraft selection */}
            <div className="grid grid-cols-[auto_1fr] items-baseline gap-1 lg:grid-cols-[auto_auto_1fr]">
              <Typography
                variant="h5"
                component="h5"
                className="whitespace-nowrap"
              >
                {tableTitle}
              </Typography>

              {selectedAircrafts.length > 0 && (
                <div className="col-span-2 flex gap-2 lg:col-span-1">
                  <Button
                    variant="text"
                    onClick={() => onToggleAircraftFilter()}
                    className="whitespace-nowrap px-0 text-blue-500 lg:px-1"
                  >
                    {selectedAircrafts.length} AIRCRAFT SELECTED
                  </Button>
                  <Button
                    variant="text"
                    onClick={() => resetAircraftFilter()}
                    className="px-0 text-blue-500 lg:px-1"
                  >
                    CLEAR
                  </Button>
                </div>
              )}
            </div>

            {/* Right side actions */}
            <div className="flex items-center justify-end gap-1">
              {actions?.map((action) => action)}
            </div>
          </div>
        </div>
      )}
      <div className="flex min-h-0 flex-col gap-2">
        <DueListTableFilters
          {...filterProps}
          tableVariant={variant}
          disableActions={
            discriminator === 'associatedTasks' ? false : disableApplyTimes
          }
          actions={
            discriminator === 'associatedTasks'
              ? actions
              : [itemActionsDropdown]
          }
          openAircraftFilter={openAircraftFilter}
          onToggleAircraftFilter={onToggleAircraftFilter}
        />
        <TableWithData
          key={tableRefreshKey}
          variant={variant}
          setQueryVariables={setMassagedQueryVariables}
          onSelectItem={onSelectItem}
          selectedAircraft={selectedAircraft}
          queryVariables={queryVariables}
          disableTaskSelection={disableApplyTimes}
          taskViewMode={taskViewMode}
          setTableTitle={setTableTitle}
          discriminator={discriminator}
          onChildTaskSliderClick={onChildTaskSliderClick}
          enableRowVirtualization={enableRowVirtualization}
        />
      </div>
      <CreateNewTaskDrawer
        open={createNewTask}
        onClose={() => setCreateNewTask(false)}
        onCreateTaskClick={onCreateTaskClick}
        onResetClick={onResetClick}
      />
      <CreateNonRoutineTaskSlider
        open={showCreateNonRoutineTaskModal}
        onClose={(refreshTable) => {
          refreshTable && setTableRefreshKey()
          setShowCreateNonRoutineTaskModal(false)
        }}
      />
      <AircraftTimeModal onSuccess={(_) => onApplyTimesSuccess()} />
      <AircraftSelectorModal onSuccess={printDueList} />
      <PrintWorkCardModal variety="due_list" />
      <AddMtxItemsToPurchaseOrderModal
        tableVariant={variant}
        selected={selectedItemIds}
        discriminator="dueList"
      />
    </div>
  )
}

export default DueListTable
