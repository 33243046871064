import React from 'react'

import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import {
  Button,
  Paper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'

import useBulkComplianceEditModalFileUtils from 'src/hooks/useBulkComplianceEditModalFileUtils'
import useGetUsers from 'src/hooks/requests/useGetUsers'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import FileDownloadButton from '../FileDownloadButton'
import FileRemoveButton from '../FileRemoveButton'
import FileUploadButton from '../FileUploadButton'
import { UsersDropDownSelector } from '../MUI/UsersDropDownSelector'

interface FormFieldsProps {
  isWorkOrder: boolean
  formMethods: any
  selectedTaskIds: readonly string[]
  selectedCustomTaskIds?: readonly string[]
  open: boolean
  conflict: {
    description: boolean
    correctiveAction: boolean
    taskNotes: boolean
    workedById: boolean
  }
  overrideOrAppend: {
    description: boolean
    correctiveAction: boolean
    taskNotes: boolean
    workedById: boolean
  }
  updateOverrideOrAppend: (field: string, value: boolean) => void
  dirtyFields: {
    description: boolean
    correctiveAction: boolean
    taskNotes: boolean
    workedById: boolean
  }
  setDirtyFields: React.Dispatch<
    React.SetStateAction<{
      description: boolean
      correctiveAction: boolean
      taskNotes: boolean
      workedById: boolean
    }>
  >
  canApplyToOthers: {
    description: boolean
    correctiveAction: boolean
    taskNotes: boolean
    workedById: boolean
  }
}

const FormFields: React.FC<FormFieldsProps> = ({
  isWorkOrder,
  formMethods,
  selectedTaskIds,
  selectedCustomTaskIds,
  open,
  conflict,
  overrideOrAppend,
  updateOverrideOrAppend,
  dirtyFields,
  setDirtyFields,
  canApplyToOthers,
}) => {
  const { formState, watch } = formMethods
  const { data: users } = useGetUsers()
  const mechanics =
    users?.users.filter((user) =>
      user.membership.some((member) => member.userRole === 'ADMIN')
    ) || []
  const descriptionValue = watch('task.description')
  const correctiveActionValue = watch('task.correctiveAction')
  const taskNotesValue = watch('task.notes')
  const workedByIdValue = watch('task.workedById')
  React.useEffect(() => {
    setDirtyFields({
      description: !!formState.dirtyFields?.task?.description,
      correctiveAction: !!formState.dirtyFields?.task?.correctiveAction,
      taskNotes: !!formState.dirtyFields?.task?.notes,
      workedById: !!formState.dirtyFields?.task?.workedById,
    })
  }, [
    setDirtyFields,
    descriptionValue,
    correctiveActionValue,
    taskNotesValue,
    workedByIdValue,
    formState.dirtyFields,
  ])

  const { overlappingWorkCompletedFiles, fileIdToAttachedFileIds, refetch } =
    useBulkComplianceEditModalFileUtils({
      fetchFrom: isWorkOrder ? 'internalWorkItem' : 'compliance',
      open,
      selectedTasks: selectedTaskIds,
    })

  return (
    <div className="flex w-1/2 flex-col pl-3">
      <Typography
        variant="subtitle2"
        id="information-to-update-title"
        className="mb-3"
      >
        Work information to update
      </Typography>
      <div className="mb-3 flex flex-col overflow-y-auto pt-1">
        <div className="flex flex-col gap-y-3">
          <div className="flex flex-col gap-y-1">
            {dirtyFields.description && conflict.description && (
              <div className="flex items-center gap-2">
                <Typography variant="body2" color="error">
                  Selected tasks have different work descriptions.
                </Typography>
                <ToggleButtonGroup
                  value={overrideOrAppend.description}
                  onChange={(_, newAlignment) => {
                    if (newAlignment !== null)
                      updateOverrideOrAppend('description', newAlignment)
                  }}
                  exclusive
                  color="error"
                  size="small"
                >
                  <ToggleButton value={true}>Override</ToggleButton>
                  <ToggleButton value={false}>Add</ToggleButton>
                </ToggleButtonGroup>
              </div>
            )}
            {!dirtyFields.description && canApplyToOthers.description && (
              <Typography variant="body2" color="primary">
                Will apply same work description to other selected tasks without
                one.
              </Typography>
            )}

            <TextField
              {...formMethods.register('task.description')}
              name="task.description"
              label="Description"
              multiline
              rows={4}
              className="basis-1/2"
              error={dirtyFields.description && conflict.description}
              slotProps={{
                inputLabel: {
                  shrink: !!descriptionValue,
                },
              }}
            />
          </div>

          {isWorkOrder && (
            <div className="flex flex-col gap-y-1">
              {dirtyFields.correctiveAction && conflict.correctiveAction && (
                <div className="flex items-center gap-2">
                  <Typography variant="body2" color="error">
                    Selected tasks have different corrective actions.
                  </Typography>
                  <ToggleButtonGroup
                    value={overrideOrAppend.correctiveAction}
                    onChange={(_, newAlignment) => {
                      if (newAlignment !== null)
                        updateOverrideOrAppend('correctiveAction', newAlignment)
                    }}
                    exclusive
                    color="error"
                    size="small"
                  >
                    <ToggleButton value={true}>Override</ToggleButton>
                    <ToggleButton value={false}>Add</ToggleButton>
                  </ToggleButtonGroup>
                </div>
              )}
              {!dirtyFields.correctiveAction &&
                canApplyToOthers.correctiveAction && (
                  <Typography variant="body2" color="primary">
                    Will apply same corrective action to other selected tasks
                    without one.
                  </Typography>
                )}
              <TextField
                {...formMethods.register('task.correctiveAction')}
                name="task.correctiveAction"
                label="Corrective Action"
                multiline
                rows={4}
                className="basis-1/2"
                error={
                  dirtyFields.correctiveAction && conflict.correctiveAction
                }
                slotProps={{
                  inputLabel: {
                    shrink: !!correctiveActionValue,
                  },
                }}
              />
            </div>
          )}

          <div className="flex flex-col gap-y-1">
            {dirtyFields.taskNotes && conflict.taskNotes && (
              <div className="flex items-center gap-2">
                <Typography variant="body2" color="error">
                  Selected tasks have different task notes.
                </Typography>
                <ToggleButtonGroup
                  value={overrideOrAppend.taskNotes}
                  onChange={(_, newAlignment) => {
                    if (newAlignment !== null)
                      updateOverrideOrAppend('taskNotes', newAlignment)
                  }}
                  exclusive
                  color="error"
                  size="small"
                >
                  <ToggleButton value={true}>Override</ToggleButton>
                  <ToggleButton value={false}>Add</ToggleButton>
                </ToggleButtonGroup>
              </div>
            )}
            {!dirtyFields.taskNotes && canApplyToOthers.taskNotes && (
              <Typography variant="body2" color="primary">
                Will apply same task notes to other selected tasks without one.
              </Typography>
            )}
            <TextField
              {...formMethods.register('task.notes')}
              name="task.notes"
              label="Task Notes"
              multiline
              rows={4}
              className="basis-1/2"
              error={dirtyFields.taskNotes && conflict.taskNotes}
              slotProps={{
                inputLabel: {
                  shrink: !!taskNotesValue,
                },
              }}
            />
          </div>

          <div className="flex flex-col gap-y-1">
            {dirtyFields.workedById && conflict.workedById && (
              <Typography variant="body2" color="error">
                Selected tasks have different mechanics. Will override.
              </Typography>
            )}
            {!dirtyFields.workedById && canApplyToOthers.workedById && (
              <Typography variant="body2" color="primary">
                Will apply same mechanic to other selected tasks without one.
              </Typography>
            )}
            <UsersDropDownSelector
              name="task.workedById"
              label="Mechanic"
              users={mechanics}
              // fullWidth
              error={dirtyFields.workedById && conflict.workedById}
            />
          </div>
        </div>
      </div>
      <FileUploadButton
        attachables={[
          ...(selectedTaskIds || []).map((taskId) => ({
            id: taskId,
            type: isWorkOrder ? 'InternalWorkItem' : 'ComplianceActivity',
          })),
          ...(selectedCustomTaskIds || []).map((taskId) => ({
            id: taskId,
            type: 'CustomInternalWorkItem',
          })),
        ]}
        refetchAttachments={() => refetch()}
        button={
          <Button
            variant="text"
            color="primary"
            startIcon={<FileUploadOutlinedIcon />}
          >
            Upload
          </Button>
        }
      />
      <Paper className="mt-2 flex flex-col px-2 py-1">
        {overlappingWorkCompletedFiles.length === 0 ? (
          <Typography variant="body2" color="textSecondary">
            No shared attachments found
          </Typography>
        ) : (
          overlappingWorkCompletedFiles.map((file) => (
            <div key={file.id} className="flex flex-col gap-2">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <div className="flex h-5 w-5 items-center justify-center rounded rounded-full bg-[#E4E4E4] p-2">
                    <InsertDriveFileIcon
                      fontSize="small"
                      style={{ color: '#9D9997' }}
                    />
                  </div>
                  <div className="flex flex-col">
                    <Typography variant="body2">
                      {file.originalFilename}
                    </Typography>
                  </div>
                </div>
                <div>
                  <FileDownloadButton fileId={file.id} />
                  <FileRemoveButton
                    fileId={file.id}
                    filename={file.originalFilename}
                    attachedFileIds={fileIdToAttachedFileIds[file.id]}
                  />
                </div>
              </div>
            </div>
          ))
        )}
      </Paper>
      <ConfirmationModal
        discriminator="removeFile"
        title="Remove File"
        confirmText="Remove"
        denyText="Cancel"
        onConfirm={() => refetch()}
      />
    </div>
  )
}

export default FormFields
