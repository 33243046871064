import { WorkOrderCustomTaskTableDataFragment } from "src/pages/WorkOrderCompliancePageV2/queries"

export const UPDATE_COMPLIANCE_ACTIVITY_MUTATION = gql`
  mutation UpdateComplianceActivity(
    $id: String!
    $input: UpdateComplianceActivityInput!
  ) {
    updateComplianceActivity(id: $id, input: $input) {
      id
      updatedAt
      complianceLedgerId

      # used to update cache in review form
      description
      notes
    }
  }
`

export const UPDATE_INTERNAL_WORK_ITEM_MUTATION = gql`
  mutation UpdateInternalWorkItem($id: String!, $input: UpdateInternalWorkItemInput!) {
    updateInternalWorkItem(id: $id, input: $input) {
      id
      updatedAt
      workOrderId

      # used to update cache in review form
      description
      correctiveAction
      notes
      workedBy {
        id
        firstName
        lastName
      }
      rate
      rateType
      partCost
      workMinutes
    }
  }
`

export const UPDATE_CUSTOM_INTERNAL_WORK_ITEM_MUTATION = gql`
  mutation UpdateCustomInternalWorkItem(
    $id: String!
    $input: UpdateCustomInternalWorkItemInput!
  ) {
    updateCustomInternalWorkItem(id: $id, input: $input) {
      id
      updatedAt
      workOrderId

      # used to update cache in review form
      description
      correctiveAction
      notes
      workedBy {
        id
        firstName
        lastName
      }
      rate
      rateType
      workMinutes
    }
  }
`
