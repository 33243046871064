import CloseIcon from '@mui/icons-material/Close'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import { IconButton, Modal, Paper, Typography } from '@mui/material'

import Button from 'src/components/MUI/Button'
import { useDispatch } from 'src/hooks/useDispatch'
import { useSelector } from 'src/hooks/useSelector'
import { closeModal } from 'src/slices/modal'
import { downloadPdfFromUrl } from 'src/utils/helpers'

type PurchaseOrderPdfViewModalProps = {
  urlForPdfPreview: string
  urlForPdfDownload: string
  fileName: string
}

const modalName = 'purchaseOrderPdfViewModal'

const PurchaseOrderPdfViewModal: React.FC<PurchaseOrderPdfViewModalProps> = ({
  urlForPdfPreview,
  urlForPdfDownload,
  fileName,
}) => {
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(closeModal({ name: modalName }))
  }
  const open =
    useSelector((state) => state.modal.modals?.[modalName]?.isOpen) ?? false
  return (
    <Modal open={open} onClose={handleClose}>
      <Paper className="absolute left-1/2 top-1/2 w-140 -translate-x-1/2 -translate-y-1/2 transform rounded-xl p-3">
        <div className="mb-2 flex items-center justify-between">
          <Typography variant="h6" component="h2">
            Purchase Order PDF
          </Typography>
          <div className="flex items-center gap-2">
            <Button
              color="base"
              variant="outlined"
              onClick={() => downloadPdfFromUrl(urlForPdfDownload, fileName)}
              startIcon={<CloudDownloadOutlinedIcon fontSize="small" />}
            >
              DOWNLOAD
            </Button>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="flex h-[8.5in] items-center justify-center">
          <object data={urlForPdfPreview} width="100%" height="100%">
            Link
            <a href={urlForPdfPreview}>to the PDF!</a>
          </object>
        </div>
      </Paper>
    </Modal>
  )
}

export default PurchaseOrderPdfViewModal
