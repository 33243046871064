import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import supabase from 'src/lib/supabase'

import { useMutation } from '@redwoodjs/web'
import { useLazyQuery } from '@apollo/client'

import useQuery from 'src/hooks/useQuery'

import useUserId from 'src/hooks/useUserId'
import { toast } from '@redwoodjs/web/toast'
import {
  useClient,
  useInboxNotifications,
  useMarkInboxNotificationAsRead,
} from '@liveblocks/react'
import { InboxNotificationData } from '@liveblocks/client'
import { ThreadData } from '@liveblocks/client'
import { ComplianceLedger, Notification } from 'types/graphql'
import isThreadNotification from 'src/components/Notifications/helpers/isThreadNotification'
import getThreadLink, {
  GET_COMPLIANCE_LEDGER_STATUS,
} from 'src/components/Comments/getThreadLink'
import { CommentNotificationData } from 'src/components/Notifications/NotificationsList'
import getNewNotifications from 'src/components/Notifications/helpers/getNewNotifications'
import useHasFeature from 'src/hooks/useHasFeature'
import addNotificationToast from 'src/components/Notifications/addNotificationToast'
import { useRoutePaths } from '@redwoodjs/router'

const GET_NOTIFICATIONS = gql`
  query GetNotifications {
    notifications {
      id
      message
      read
      createdAt
      actionName
      actionPerformed
      link
    }
  }
`

const MARK_ALL_NOTIFICATIONS_AS_READ = gql`
  mutation MarkAllNotificationsAsRead {
    markAllNotificationsAsRead {
      id
      read
    }
  }
`

const useNotifications = ({ orgSlug, currentOrgOnly = false }) => {
  const { id: userId, hasLoaded: userIdHasLoaded } = useUserId()
  const { hasFeature: orgHasComments } = useHasFeature('Comments', orgSlug)
  const routePaths = useRoutePaths()
  const [inboxNotifications, setInboxNotifications] = useState<
    CommentNotificationData[] | undefined
  >()

  let client: ReturnType<typeof useClient> | undefined
  let markInboxNotificationAsRead:
    | ReturnType<typeof useMarkInboxNotificationAsRead>
    | undefined
  let allInboxNotifications:
    | (InboxNotificationData & { link?: string })[]
    | undefined

  try {
    client = useClient()
    markInboxNotificationAsRead = useMarkInboxNotificationAsRead()
    const useInboxNotificationsResponse = useInboxNotifications()
    allInboxNotifications = useInboxNotificationsResponse.inboxNotifications
  } catch (err) {
    // Expected error as the page loads
    if (err.message !== 'LiveblocksProvider is missing from the React tree.') {
      console.error(err)
    }
  }

  useEffect(() => {
    setInboxNotifications((prevInboxNotifications) => {
      if (!orgHasComments) return undefined

      return allInboxNotifications
        ?.filter(isThreadNotification)
        ?.filter((notification) =>
          notification.roomId?.startsWith(`/${orgSlug}`)
        )
        .map((notification) => {
          // Carry the link over from the previous notification so that we don't have to re-fetch it
          return {
            ...notification,
            link: prevInboxNotifications?.find((n) => n.id === notification.id)
              ?.link,
          }
        })
    })
  }, [orgHasComments, allInboxNotifications])

  const unreadInboxNotificationsCount = useMemo(() => {
    if (!inboxNotifications) return 0
    return inboxNotifications.filter(
      (n) => !n.readAt || n.notifiedAt > n.readAt
    ).length
  }, [inboxNotifications])

  const previousInboxNotifications = useRef(inboxNotifications)

  const [getComplianceLedgerStatus] = useLazyQuery<
    ComplianceLedger,
    { id: string }
  >(GET_COMPLIANCE_LEDGER_STATUS)

  const addLinksToCommentThreads = useCallback(async () => {
    if (!client || !inboxNotifications) return

    const newInboxNotifications = await Promise.all(
      inboxNotifications.map(async (notification) => {
        // Assign the correct link for each thread, since links might change depending on the status of their attached entity (e.g. a compliance that is now a logbook entry)
        const { room, leave } = client.enterRoom(notification.roomId)
        let thread: ThreadData | undefined
        try {
          const threadResponse = await room.getThread(notification.threadId)
          thread = threadResponse.thread
        } finally {
          leave()
        }

        return {
          ...notification,
          link: await getThreadLink(
            thread,
            orgSlug,
            getComplianceLedgerStatus,
            routePaths
          ),
        }
      })
    )
    setInboxNotifications(newInboxNotifications)
  }, [client, getComplianceLedgerStatus, routePaths, inboxNotifications])

  useEffect(() => {
    if (
      !previousInboxNotifications.current ||
      previousInboxNotifications.current.length !== inboxNotifications.length ||
      previousInboxNotifications.current.some(
        (n, i) => n.link !== inboxNotifications[i].link
      )
    ) {
      addLinksToCommentThreads()
    }
  }, [inboxNotifications, addLinksToCommentThreads])

  useEffect(() => {
    if (!inboxNotifications) return

    // For any new notifications, show the toast
    if (previousInboxNotifications.current) {
      const newNotifications = getNewNotifications(
        previousInboxNotifications.current,
        inboxNotifications
      )
      for (const notification of newNotifications) {
        addNotificationToast(notification)
      }
    }

    previousInboxNotifications.current = inboxNotifications
  }, [inboxNotifications])

  useEffect(() => {
    if (!userIdHasLoaded || !userId) return
    const channel = supabase
      .channel('realtime notifications')
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'Notification',
          filter: `userId=eq.${userId}`,
        },
        (payload) => {
          toast(payload.new.message)
          refetch()
        }
      )
      .subscribe()
    return () => {
      supabase.removeChannel(channel)
    }
  }, [supabase, userIdHasLoaded])

  const [markDBNotificationsAsRead] = useMutation(
    MARK_ALL_NOTIFICATIONS_AS_READ
  )

  const { data, hasLoaded, refetch } = useQuery<{
    notifications: Notification[]
  }>(GET_NOTIFICATIONS)
  const notifications = [
    ...(data?.notifications ?? []),
    ...(inboxNotifications ?? []),
  ].sort((a, b) => {
    const notificationTimeA = new Date(
      'createdAt' in a ? a.createdAt : a.notifiedAt
    )
    const notificationTimeB = new Date(
      'createdAt' in b ? b.createdAt : b.notifiedAt
    )
    return notificationTimeB.getTime() - notificationTimeA.getTime()
  })

  const unreadSystemNotificationsCount =
    data?.notifications?.filter((n) => !n.read).length ?? 0
  const unreadNotificationsCount =
    (unreadInboxNotificationsCount ?? 0) + unreadSystemNotificationsCount

  const markAllNotificationsAsRead = async () => {
    await Promise.all([
      markDBNotificationsAsRead(),
      ...(inboxNotifications ?? []).map((n) =>
        markInboxNotificationAsRead?.(n.id)
      ),
    ])
    refetch()
  }

  return {
    notifications: currentOrgOnly
      ? notifications.filter((n) => n.link?.startsWith(`/${orgSlug}`))
      : notifications,
    hasLoaded,
    refetch,
    unreadNotificationsCount,
    markAllNotificationsAsRead,
  }
}

export default useNotifications
