import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material'
import { get } from 'lodash'

import DropdownFilter from './DropdownFilter/DropdownFilter'

export const ComplianceEventTypes = [
  'APPLY_TIMES',
  'MARK_AS_READY_FOR_REVIEW',
  'MARK_AS_NOT_READY_FOR_REVIEW',
  'ADD_TASKS',
  'REMOVE_TASKS',
  'ADD_DISCREPANCY',
  'UPDATE_RATE',
  'UPDATE_RATE_TYPE',
  'UPDATE_NOTES',
  'UPDATE_WORK_DESCRIPTION',
  'UPDATE_CORRECTIVE_ACTION',
  'UPDATE_WORK_ORDER_NOTES',
  'MULTIPLE_EVENTS',
  'OTHER',
] as const

export type ComplianceEventType = (typeof ComplianceEventTypes)[number]

interface ComplianceEventTypeFilterProps {
  dropdownStyles?: React.CSSProperties
  resetEventTypeFilter: () => void
  eventTypeFilterData: any
  setEventTypeFilter: (eventType: string, checked: boolean) => void
}

const ComplianceEventTypeFilter: React.FC<ComplianceEventTypeFilterProps> = ({
  dropdownStyles,
  resetEventTypeFilter,
  eventTypeFilterData,
  setEventTypeFilter,
}) => {
  return (
    <div>
      <DropdownFilter label="Event Types" dropdownStyles={dropdownStyles}>
        <div className="flex w-full items-center justify-between">
          <Typography variant="body1">Event Types</Typography>
          <Button
            variant="text"
            onClick={() => resetEventTypeFilter()}
            sx={{ textTransform: 'none' }}
          >
            Clear all
          </Button>
        </div>
        <FormGroup>
          {ComplianceEventTypes.map((eventType) => {
            return (
              <FormControlLabel
                key={eventType}
                control={
                  <Checkbox
                    key={eventTypeFilterData[eventType] ?? eventType}
                    checked={get(eventTypeFilterData, eventType)}
                    onChange={(e) =>
                      setEventTypeFilter(eventType, e.target.checked)
                    }
                  />
                }
                label={eventType.toLowerCase().replace(/_/g, ' ')}
              />
            )
          })}
        </FormGroup>
      </DropdownFilter>
    </div>
  )
}

export default ComplianceEventTypeFilter
