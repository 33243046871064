import { Button, Tab, Tabs } from '@mui/material'
import { UseFormReturn } from 'react-hook-form'
import clsx from 'clsx'

export type FilterSection = string

interface FilterSectionPickerProps<TFormValues extends Record<string, any>> {
  filterSections: FilterSection[]
  selectedFilterSection: FilterSection | null
  setSelectedFilterSection: (section: FilterSection) => void
  formMethods: UseFormReturn<TFormValues>
  clearAllFilters: () => void
}

const FilterSectionPicker = <TFormValues extends Record<string, any>>({
  filterSections,
  selectedFilterSection,
  setSelectedFilterSection,
  formMethods,
  clearAllFilters,
}: FilterSectionPickerProps<TFormValues>) => {
  return (
    <div className="flex h-full shrink-0 basis-1/4 flex-col rounded-l-md border-y-0 border-l-0 border-r border-solid border-[#00000026]">
      <div className="flex-1 overflow-y-auto">
        <Tabs
          orientation="vertical"
          value={selectedFilterSection ?? false}
          onChange={(_, newValue) => setSelectedFilterSection(newValue)}
        >
          {filterSections.map((section) => (
            <Tab
              key={section}
              label={section}
              value={section}
              disableRipple
              className="items-start"
            />
          ))}
        </Tabs>
      </div>

      <div className="flex items-center gap-2 p-2">
        <Button
          variant="text"
          fullWidth
          type="reset"
          onClick={() => clearAllFilters()}
        >
          Clear All
        </Button>
        <Button variant="contained" fullWidth type="submit">
          Apply
        </Button>
      </div>
    </div>
  )
}

export default FilterSectionPicker
