import React, { useEffect, useMemo } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Typography } from '@mui/material'
import * as Yup from 'yup'

import { Form, useForm, useWatch } from '@redwoodjs/forms'
import { navigate, routes } from '@redwoodjs/router'
import { Metadata, useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import AddPartLineItemModal from 'src/components/AddPartLineItemModal'
import AddServiceLineItemModal from 'src/components/AddServiceLineItemModal'
import ApiError from 'src/components/ApiError'
import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal'
import Loading from 'src/components/Loading'
import DatePicker from 'src/components/MUI/DatePicker'
import SelectDropdown from 'src/components/MUI/SelectDropdown'
import TextField from 'src/components/MUI/TextField'
import VendorEditorModal from 'src/components/VendorDrawer/VendorEditorModal'
import { useDispatch } from 'src/hooks/useDispatch'
import useQuery from 'src/hooks/useQuery'
import { openModal } from 'src/slices/modal'
import {
  centsToDollars,
  dollarsToCents,
  isDecimalWithTwoOrLessPlacesNullable,
} from 'src/utils/helpers'
import RequestedLineItemsPickerModal from './RequestedLineItemsPickerModal'
import {
  GET_ALL_ADDRESSES,
  GET_ALL_SHIPPING_METHODS,
} from '../SettingsPage/queries'
import AddressEditorModal from '../SettingsPage/sections/ShippingSettings/AddressEditorModal'
import ShippingMethodEditor from '../SettingsPage/sections/ShippingSettings/ShippingMethodEditor'

import PartLineItemsTable from './PartLineItemsTable'
import PurchaseOrderReviewModal from './PurchaseOrderReviewModal'
import {
  CANCEL_PURCHASE_ORDER,
  GET_PURCHASE_ORDER,
  GET_VENDORS,
  MULTI_UPDATE_PURCHASE_ORDER_ITEM,
  UPDATE_PURCHASE_ORDER_MUTATION,
} from './queries'
import ServiceLineItemsTable from './ServiceLineItemsTable'
import OtherLineItemsTable from './OtherLineItemsTable'
import AddLineItemModal from 'src/components/AddLineItemModal'
import { useBreadcrumbs } from 'src/layouts/components/BreadcrumbsContext'
import { Divider } from '@mui/material'
import PurchaseOrderDraftHeader from './PurchaseOrderDraftHeader'
import LowStockPickerDrawer from './LowStockPickerDrawer'

const PurchaseOrderPage = ({ orgName, id }) => {
  const dispatch = useDispatch()
  useBreadcrumbs([
    { icon: 'purchaseOrders' },
    { label: 'Purchase Orders', to: routes.purchaseOrders({ orgName }) },
    { label: 'New Purchase Order' },
  ])
  const [formInitialized, setFormInitialized] = React.useState(false)
  const { data, loading, hasLoaded, error } = useQuery(GET_PURCHASE_ORDER, {
    variables: { id },
  })

  const [updatePurchaseOrder] = useMutation(UPDATE_PURCHASE_ORDER_MUTATION)
  const [cancelPurchaseOrder] = useMutation(CANCEL_PURCHASE_ORDER)

  const [multiUpdatePurchaseOrderItem] = useMutation(
    MULTI_UPDATE_PURCHASE_ORDER_ITEM
  )

  const {
    data: vendorsData,
    loading: vendorsLoading,
    hasLoaded: vendorsHasLoaded,
  } = useQuery(GET_VENDORS)

  const {
    data: addressesData,
    loading: addressesLoading,
    hasLoaded: addressesHasLoaded,
  } = useQuery(GET_ALL_ADDRESSES)

  const {
    data: shippingMethodsData,
    loading: shippingMethodsLoading,
    hasLoaded: shippingMethodsHasLoaded,
  } = useQuery(GET_ALL_SHIPPING_METHODS)

  const {
    purchaseOrder: { purchaseOrderItem: poItems },
  } = data ?? { purchaseOrder: { purchaseOrderItem: [] } }

  const purchaseOrderItems = poItems.map((item) => {
    return {
      ...item,
      cost: centsToDollars(item.cost),
    }
  })
  const partLineItems = purchaseOrderItems.filter(
    (item) => item.type === 'PART'
  )
  const serviceLineItems = purchaseOrderItems.filter(
    (item) => item.type === 'SERVICE'
  )
  const otherLineItems = purchaseOrderItems.filter(
    (item) => item.type !== 'SERVICE' && item.type !== 'PART'
  )

  const poSchema = useMemo(
    () =>
      Yup.object().shape({
        number: Yup.string().required('PO Number Required'),
        vendor: Yup.object()
          .shape({
            id: Yup.string().required('Vendor Required'),
          })
          .typeError('Vendor Required'),
        paymentTerms: Yup.string().required('Payment Terms Required'),
        shippingAddress: Yup.object()
          .shape({
            id: Yup.string().required('Shipping Address Required'),
          })
          .typeError('Shipping Address Required'),
        billingAddress: Yup.object()
          .shape({
            id: Yup.string().required('Billing Address Required'),
          })
          .typeError('Billing Address Required'),
        requestedDeliveryDate: Yup.string().required('Delivery Date Required'),
        shippingMethod: Yup.object()
          .shape({
            id: Yup.string().required('Shipping Method Required'),
          })
          .typeError('Shipping method Required'),
        shippingInstructions: Yup.string().nullable(),
        parts: Yup.object().shape({
          ...partLineItems.reduce((acc, item) => {
            acc[item.id] = Yup.object().shape({
              partNumber: Yup.string().required('Part Number is required'),
              description: Yup.string(),
              condition: Yup.string().required('Condition is required'),
              quantity: Yup.number()
                .typeError('Quantity is required')
                .positive('Must be positive')
                .integer('Must be a whole number')
                .required('Quantity Required'),
              cost: Yup.number()
                .typeError('Cost must be a number')
                .min(0, 'Must be positive')
                .test(
                  'valid-decimal',
                  'No more than 2 decimal points',
                  isDecimalWithTwoOrLessPlacesNullable
                )
                .transform((value, originalValue) => {
                  // handles the case where the user unsets a value, and we want to treat empty strings as nulls
                  return originalValue === '' ? null : value
                })
                .nullable(),
            })
            return acc
          }, {}),
        }),
        services: Yup.object().shape({
          ...serviceLineItems.reduce((acc, item) => {
            acc[item.id] = Yup.object().shape({
              ataCode: Yup.string().nullable(),
              description: Yup.string().nullable(),
              cost: Yup.number()
                .typeError('Cost must be a number')
                .min(0, 'Must be positive')
                .transform((value, originalValue) => {
                  // handles the case where the user unsets a value, and we want to treat empty strings as nulls
                  return originalValue === '' ? null : value
                })
                .test(
                  'valid-decimal',
                  'No more than 2 decimal points',
                  isDecimalWithTwoOrLessPlacesNullable
                )
                .nullable(),
            })
            return acc
          }, {}),
        }),
        other: Yup.object().shape({
          ...otherLineItems.reduce((acc, item) => {
            acc[item.id] = Yup.object().shape({
              description: Yup.string().nullable(),
              cost: Yup.number()
                .typeError('Cost must be a number')
                .min(0, 'Must be positive')
                .transform((value, originalValue) => {
                  // handles the case where the user unsets a value, and we want to treat empty strings as nulls
                  return originalValue === '' ? null : value
                })
                .test(
                  'valid-decimal',
                  'No more than 2 decimal points',
                  isDecimalWithTwoOrLessPlacesNullable
                )
                .nullable(),
            })
            return acc
          }, {}),
        }),
      }),
    [partLineItems, serviceLineItems, otherLineItems]
  )

  const poFormMethods = useForm({
    resolver: yupResolver(poSchema),
  })

  const accountNumber = useWatch({
    name: 'shippingMethod.accountNumber',
    control: poFormMethods.control,
  })

  useEffect(() => {
    if (
      data &&
      hasLoaded &&
      partLineItems &&
      serviceLineItems &&
      !formInitialized
    ) {
      poFormMethods.reset({
        number: data.purchaseOrder.number,
        vendor: data.purchaseOrder.vendor
          ? {
              id: data.purchaseOrder.vendorId,
              name: data.purchaseOrder.vendor?.name,
            }
          : undefined,
        paymentTerms: data.purchaseOrder.paymentTerms,
        shippingAddress: data.purchaseOrder.shippingAddress
          ? {
              id: data.purchaseOrder.shippingAddressId,
              addressLine1: data.purchaseOrder.shippingAddress?.addressLine1,
              addressLine2: data.purchaseOrder.shippingAddress?.addressLine2,
              city: data.purchaseOrder.shippingAddress?.city,
              state: data.purchaseOrder.shippingAddress?.state,
              postalCode: data.purchaseOrder.shippingAddress?.postalCode,
              country: data.purchaseOrder.shippingAddress?.country,
            }
          : undefined,
        billingAddress: data.purchaseOrder.billingAddress
          ? {
              id: data.purchaseOrder.billingAddressId,
              addressLine1: data.purchaseOrder.billingAddress?.addressLine1,
              addressLine2: data.purchaseOrder.billingAddress?.addressLine2,
              city: data.purchaseOrder.billingAddress?.city,
              state: data.purchaseOrder.billingAddress?.state,
              postalCode: data.purchaseOrder.billingAddress?.postalCode,
              country: data.purchaseOrder.billingAddress?.country,
            }
          : undefined,
        requestedDeliveryDate: data.purchaseOrder.requestedDeliveryDate,
        shippingMethod: data.purchaseOrder.shippingMethod
          ? {
              id: data.purchaseOrder.shippingMethodId,
              name: data.purchaseOrder.shippingMethod?.name,
              accountNumber: data.purchaseOrder.shippingMethod?.accountNumber,
            }
          : undefined,
        shippingInstructions: data.purchaseOrder.shippingInstructions,
        parts: partLineItems.reduce((acc, item) => {
          acc[item.id] = {
            partNumber: item.partNumber,
            description: item.description,
            condition: item.condition,
            quantity: item.quantity,
            cost: item.cost ?? null,
          }
          return acc
        }, {}),
        services: serviceLineItems.reduce((acc, item) => {
          acc[item.id] = {
            ataCode: item.ataCode,
            description: item.description,
            cost: item.cost ?? null,
          }
          return acc
        }, {}),
        other: otherLineItems.reduce((acc, item) => {
          acc[item.id] = {
            description: item.description,
            cost: item.cost ?? null,
          }
          return acc
        }, {}),
      })
      setFormInitialized(true)
    }
  }, [
    data,
    poFormMethods,
    hasLoaded,
    partLineItems,
    serviceLineItems,
    otherLineItems,
    formInitialized,
  ])

  // Update form whenever partLineItems changes
  useEffect(() => {
    if (!formInitialized) return // Don't update until form is initially set up

    poFormMethods.setValue('parts', partLineItems.reduce((acc, item) => {
      acc[item.id] = {
        partNumber: item.partNumber,
        description: item.description,
        condition: item.condition,
        quantity: item.quantity,
        cost: item.cost ?? null,
      }
      return acc
    }, {}))
  }, [partLineItems, formInitialized, poFormMethods])

  const vendorId = useWatch({
    name: 'vendor.id',
    control: poFormMethods.control,
  })

  useEffect(() => {
    if (vendorId === 'CREATE_NEW_VENDOR') {
      dispatch(openModal({ name: 'vendorEditorModal' }))

      // reset the vendor dropdown
      poFormMethods.setValue('vendor', '')
    }
  }, [vendorId])

  const shippingAddressId = useWatch({
    name: 'shippingAddress.id',
    control: poFormMethods.control,
  })

  useEffect(() => {
    if (shippingAddressId === 'CREATE_NEW_ADDRESS') {
      dispatch(
        openModal({
          name: 'addressEditorModal',
          data: { discriminator: 'shippingAddress' },
        })
      )

      poFormMethods.setValue('shippingAddress', '')
    }
  }, [shippingAddressId])

  const billingAddressId = useWatch({
    name: 'billingAddress.id',
    control: poFormMethods.control,
  })

  useEffect(() => {
    if (billingAddressId === 'CREATE_NEW_ADDRESS') {
      dispatch(
        openModal({
          name: 'addressEditorModal',
          data: { discriminator: 'billingAddress' },
        })
      )

      poFormMethods.setValue('billingAddress', '')
    }
  }, [billingAddressId])

  const shippingMethodId = useWatch({
    name: 'shippingMethod.id',
    control: poFormMethods.control,
  })

  useEffect(() => {
    if (shippingMethodId === 'CREATE_NEW_SHIPPING_METHOD') {
      dispatch(openModal({ name: 'shippingMethodEditorModal' }))

      // reset the vendor dropdown
      poFormMethods.setValue('shippingMethod', '')
    }
  }, [shippingMethodId])

  const handlePOSubmit = async (values) => {
    // vendorName, accountNumber, shippingMethodName, and shippingAddress/billingAddress info only used for pdf generation
    const {
      vendor: { id: vendorId },
      shippingMethod: { id: shippingMethodId },
      shippingAddress: { id: shippingAddressId },
      billingAddress: { id: billingAddressId },
      parts,
      services,
      other,
      ...rest
    } = values
    await updatePurchaseOrder({
      variables: {
        id,
        input: {
          vendorId: vendorId ?? null,
          shippingMethodId: shippingMethodId ?? null,
          shippingAddressId: shippingAddressId ?? null,
          billingAddressId: billingAddressId ?? null,
          ...rest,
        },
      },
      onCompleted: () => {
        toast.success('Purchase Order Details Updated')
      },
    })

    const partsPayload: any = Object.keys(parts).map((id) => {
      const { partNumber, description, condition, quantity, cost } = parts[id]
      return {
        id,
        partNumber,
        description,
        condition,
        quantity,
        cost: dollarsToCents(cost),
      }
    })
    if (partsPayload.length > 0) {
      await multiUpdatePurchaseOrderItem({
        variables: {
          input: partsPayload,
        },
        onCompleted: () => {
          toast.success('Part Line Items Updated')
        },
      })
    }

    const servicesPayload: any = Object.keys(services).map((id) => {
      const { ataCode, description, cost } = services[id]
      return {
        id,
        ataCode,
        description,
        cost: dollarsToCents(cost),
      }
    })
    if (servicesPayload.length > 0) {
      await multiUpdatePurchaseOrderItem({
        variables: {
          input: servicesPayload,
        },
        onCompleted: () => {
          toast.success('Service Line Items Updated')
        },
      })
    }

    const otherLineItems: any = Object.keys(other).map((id) => {
      const { description, cost } = other[id]
      return {
        id,
        description,
        cost: dollarsToCents(cost),
      }
    })
    if (otherLineItems.length > 0) {
      await multiUpdatePurchaseOrderItem({
        variables: {
          input: otherLineItems,
        },
        onCompleted: () => {
          toast.success('Other Line Items Updated')
        },
      })
    }
  }

  if (error) {
    return <ApiError />
  }

  if (
    (loading && !hasLoaded) ||
    !data ||
    (vendorsLoading && !vendorsHasLoaded) ||
    !vendorsData ||
    (addressesLoading && !addressesHasLoaded) ||
    !addressesData ||
    (shippingMethodsLoading && !shippingMethodsHasLoaded) ||
    !shippingMethodsData
  ) {
    return <Loading />
  }

  const vendorOptions = vendorsData?.vendors?.map((vendor) => ({
    label: vendor.name,
    value: {
      id: vendor.id,
      name: vendor.name,
    },
  }))

  const AddressDisplay = ({ address }) => {
    return (
      <div>
        <Typography variant="body2">{address.addressLine1}</Typography>
        <Typography variant="body2">{address.addressLine2}</Typography>
        <Typography variant="body2">
          {address.city}, {address.state}, {address.postalCode}
        </Typography>
        <Typography variant="body2">{address.country}</Typography>
      </div>
    )
  }

  const addressOptions = [
    ...(addressesData && addressesData.addresses
      ? addressesData.addresses.map((address) => ({
          label: <AddressDisplay address={address} />,
          value: {
            id: address.id,
            addressLine1: address.addressLine1,
            addressLine2: address.addressLine2,
            city: address.city,
            state: address.state,
            postalCode: address.postalCode,
            country: address.country,
          },
        }))
      : []),
    {
      label: 'Add a New Address',
      value: {
        id: 'CREATE_NEW_ADDRESS',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
    },
  ]

  const shippingMethodOptions = shippingMethodsData?.shippingMethods?.map(
    (shippingMethod) => ({
      label: shippingMethod.name,
      value: {
        id: shippingMethod.id,
        name: shippingMethod.name,
        accountNumber: shippingMethod.accountNumber,
      },
    })
  )

  return (
    <>
      <Metadata title="PurchaseOrder" description="PurchaseOrder page" />

      <div className="flex h-full flex-col">
        <PurchaseOrderDraftHeader
          data={data}
          formMethods={poFormMethods}
          handlePOSubmit={handlePOSubmit}
        />
        <Divider />
        <Form
          className="h-full"
          id="poForm"
          formMethods={poFormMethods}
          onSubmit={handlePOSubmit}
        >
          <div className="flex h-full gap-4">
            <div className=" h-full pb-4 pl-4 pt-2">
              <div className="flex w-[300px] flex-col gap-3">
                <div className="flex flex-col gap-2">
                  <Typography variant="h6">General</Typography>
                  <div className="flex flex-col gap-2">
                    <TextField
                      label="PO Number"
                      required
                      name="number"
                      fullWidth
                      size="small"
                    />
                    <SelectDropdown
                      label="Vendor"
                      required
                      name="vendor"
                      fullWidth
                      options={[
                        ...vendorOptions,
                        {
                          label: 'Add a New Vendor',
                          value: {
                            id: 'CREATE_NEW_VENDOR',
                            name: '',
                          },
                        },
                      ]}
                      renderValue={(selectedValue: any) => {
                        return selectedValue.name
                      }}
                    />

                    <SelectDropdown
                      label="Payment Terms"
                      required
                      name="paymentTerms"
                      fullWidth
                      options={[
                        { label: 'Net 30', value: 'NET_30' },
                        { label: 'Wire Transfer', value: 'WIRE_TRANSFER' },
                        { label: 'Credit Card', value: 'CREDIT_CARD' },
                      ]}
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-2">
                  <Typography variant="h6">Shipping</Typography>
                  <div className="flex flex-col gap-2">
                    <div className="flex flex-col gap-2">
                      <SelectDropdown
                        label="Shipping Address"
                        name="shippingAddress"
                        required
                        fullWidth
                        options={addressOptions}
                        renderValue={(address: any) => {
                          if (address.id === null) return ''
                          return <AddressDisplay address={address} />
                        }}
                      />

                      <SelectDropdown
                        label="Billing Address"
                        name="billingAddress"
                        fullWidth
                        required
                        options={addressOptions}
                        renderValue={(address: any) => {
                          if (address.id === null) return ''
                          return <AddressDisplay address={address} />
                        }}
                      />
                    </div>

                    <DatePicker
                      label="Requested Delivery Date"
                      name="requestedDeliveryDate"
                      slotProps={{
                        textField: { size: 'small', required: true },
                        openPickerButton: { size: 'small' },
                      }}
                    />
                    <div className="flex flex-col gap-2">
                      <SelectDropdown
                        required
                        label="Shipping Method"
                        name="shippingMethod"
                        fullWidth
                        options={[
                          ...shippingMethodOptions,
                          {
                            label: 'Add a New Shipping Method',
                            value: {
                              id: 'CREATE_NEW_SHIPPING_METHOD',
                              name: '',
                            },
                          },
                        ]}
                        renderValue={(selectedValue: any) => {
                          return selectedValue.name
                        }}
                      />
                      {accountNumber && (
                        <div className="flex flex-col pl-1">
                          <Typography variant="body2" color="grey">
                            Account Number
                          </Typography>
                          <Typography variant="subtitle2">
                            {accountNumber}
                          </Typography>
                        </div>
                      )}
                    </div>
                    <TextField
                      label="Delivery Instructions"
                      name="shippingInstructions"
                      fullWidth
                      multiline
                      rows={3}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Divider orientation="vertical" flexItem />
            <div className="flex flex-1 flex-col gap-4 overflow-x-auto pb-4 pr-4 pt-2">
              <PartLineItemsTable partLineItems={partLineItems} />
              <ServiceLineItemsTable serviceLineItems={serviceLineItems} />
              <OtherLineItemsTable otherLineItems={otherLineItems} />
            </div>
          </div>
        </Form>
      </div>

      <PurchaseOrderReviewModal purchaseOrderId={id} />
      <VendorEditorModal
        onComplete={(data) => poFormMethods.setValue('vendor', data)}
      />
      <AddressEditorModal
        onComplete={({
          data,
          discriminator,
        }: {
          data
          discriminator: 'shippingAddress' | 'billingAddress'
        }) => poFormMethods.setValue(discriminator, data)}
      />
      <ShippingMethodEditor
        onComplete={(data) => poFormMethods.setValue('shippingMethod', data)}
      />
      <LowStockPickerDrawer
        purchaseOrderId={id}
        onComplete={(items) => {
          items.forEach((item) => {
            poFormMethods.setValue(`parts.${item.id}`, {
              partNumber: item.partNumber,
              quantity: item.quantity,
              description: item.description,
              condition: item.condition,
              cost: item.cost,
            })
          })
        }}
      />
      <RequestedLineItemsPickerModal
        purchaseOrderId={id}
        onComplete={(items) => {
          items.forEach((item) => {
            if (item.type === 'PART') {
              poFormMethods.setValue(`parts.${item.id}`, {
                partNumber: item.partNumber,
                quantity: item.quantity,
                description: item.description,
                condition: item.condition,
                cost: item.cost,
              })
            } else {
              poFormMethods.setValue(`services.${item.id}`, {
                ataCode: item.ataCode,
                description: item.description,
                cost: item.cost,
              })
            }
          })
        }}
      />

      <AddPartLineItemModal
        purchaseOrderId={id}
        onComplete={(item) => {
          poFormMethods.setValue(`parts.${item.id}`, {
            partNumber: item.partNumber,
            quantity: item.quantity,
            description: item.description,
            condition: item.condition,
            cost: centsToDollars(item.cost),
          })
        }}
      />
      <AddServiceLineItemModal
        purchaseOrderId={id}
        onComplete={(item) => {
          poFormMethods.setValue(`services.${item.id}`, {
            ataCode: item.ataCode,
            description: item.description,
            cost: centsToDollars(item.cost),
          })
        }}
      />
      <AddLineItemModal
        purchaseOrderId={id}
        onComplete={(item) => {
          poFormMethods.setValue(`other.${item.id}`, {
            description: item.description,
            cost: centsToDollars(item.cost),
          })
        }}
      />
      <ConfirmationModal
        discriminator="cancelPO"
        title="Cancel Purchase Order"
        message="Are you sure you want to cancel this Purchase Order?"
        confirmText="Yes, Cancel Order"
        denyText="No, Keep Order"
        onConfirm={() => {
          cancelPurchaseOrder({ variables: { id } })
          navigate(routes.purchaseOrders({ orgName }))
        }}
      />
    </>
  )
}

export default PurchaseOrderPage
