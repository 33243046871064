/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import AddTaskIcon from '@mui/icons-material/AddTask'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { LoadingButton } from '@mui/lab'
import { Button, IconButton, Typography, Drawer } from '@mui/material'
import dayjs from 'dayjs'
import { MaintenanceItem } from 'types/graphql'
import * as Yup from 'yup'

import { Form, useForm } from '@redwoodjs/forms'
import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import DatePicker from 'src/components/MUI/DatePicker'
import CustomRadioGroup from 'src/components/MUI/RadioGroup'
import SelectDropdown, { SelectOption } from 'src/components/MUI/SelectDropdown'
import TextField from 'src/components/MUI/TextField'
import TagModalForm from 'src/components/TagModal/TagModalForm'
import { adSbTaskTypeOptions, ataCodeOptions } from 'src/constants'
import useAddTagsToMtxItem from 'src/hooks/requests/useAddTagsToMtxItem'
import useGetUsers from 'src/hooks/requests/useGetUsers'
import { useOrgName } from 'src/hooks/useOrgName'

import TrackedByComponentsSelector from '../TrackedByComponentsSelector/TrackedByComponentsSelector'

import {
  CREATE_DISCREPANCY_ITEM_TRANSACTION,
  GET_TAGS,
  deferredOptions,
  melCategoryOptions,
  melCatToDaysMap,
} from './consts'

import useAircraftRecords from 'src/hooks/requests/useAircraftRecords'
import useHasPermission from 'src/hooks/useHasPermission'
import { Permissions } from '@wingwork/common/src/constants/permissions'
import getTrackingComponentEnumFromNameValue from 'src/utils/getTrackingComponentEnumFromNameValue'

interface CreateNonRoutineTaskSliderProps {
  onClose: (refreshTable: boolean) => void
  open: boolean
  selectedTask?: MaintenanceItem
}

const defaultValues = {
  trackedBy: '',
  manufactureCode: '',
  ataCode: '',
  aircraftId: '',
  isDeferred: 'no',
  adSbType: '',
  nextDueCategory: '',
  nextDueDate: '',
  nextDueHours: '',
  nextDueLandings: '',
  tags: [],
  title: '',
  description: '',
  notes: '',
  enteredBy: '',
  pin: '',
}

const schema = Yup.object().shape({
  aircraftId: Yup.string().required('Select aircraft'),
  trackedBy: Yup.object()
    .shape({
      name: Yup.string().required('Select tracked by'),
      id: Yup.string().required('Select tracked by'),
    })
    .typeError('Select tracked by'),
  isDeferred: Yup.string()
    .oneOf(['yes', 'no'])
    .required("Select 'yes' or 'no'"),
  adSbType: Yup.string().required('Select type'),
  nextDueCategory: Yup.string().when(['isDeferred', 'adSbType'], {
    is: (isDeferred, adSbType) => {
      return isDeferred === 'yes' && (adSbType === 'MEL' || adSbType === 'NEF')
    },
    then: (fieldSchema) => fieldSchema.required('Select category'),
  }),
  nextDueDate: Yup.string().required('Select date'),
  title: Yup.string().required('Enter title'),
  enteredBy: Yup.string().required('Select entered by'),
  pin: Yup.string().required('Enter pin'),
})

const getMassagedMtxItemInput = (values, orgName) => {
  const getDiscrepancyStatus = () => {
    if (['MEL', 'NEF', 'DISCREPANCY', 'WATCH_LIST'].includes(values.adSbType))
      return 'DEFERRED'
    return null
  }

  // const getNextDueStatus = () => {
  //   if (values.isDeferred === 'yes') return 'GOOD'
  //   if (values.statusClosed) return 'NOT_DUE'
  //   return 'GOOD'
  // }

  const getFieldsByMode = () => {
    return {
      verifyPin: true,
      verifyUserId: values.enteredBy,
      verifyUserPin: values.pin,
      createdById: values.enteredBy,
    }
  }

  const getNextDueStatus = () => {
    if (
      values.isDeferred === 'yes' &&
      dayjs(values.nextDueDate).isAfter(dayjs(), 'date')
    )
      return 'GOOD'
    return 'OVERDUE'
  }
  const trackedByEnum = getTrackingComponentEnumFromNameValue(
    values?.trackedBy.name
  )

  const massagedMtxItem = {
    orgSlug: orgName,
    cadenceType: 'CALENDAR_MONTHS',
    cadenceValue: {},
    disposition: '',
    isOptional: false,
    isRecurring: false,
    isParent: false,
    isAdSb: true,
    maintenanceType: 'UNSCHEDULED',
    metadata: {},
    status: 'ENABLED',
    remainingValue: -1,

    nextDueStatus: getNextDueStatus(),
    title: values.title,
    ataCode: values.ataCode,
    manufactureCode: values.manufactureCode,
    adSbType: values.adSbType,
    aircraftId: values.aircraftId,
    description: values.description,
    trackedBy: trackedByEnum,
    trackedByComponentId: values.trackedBy.id,
    notes: values.notes,
    discrepancyStatus: getDiscrepancyStatus(),
    importedDataCompliance: {},
    ...getFieldsByMode(),
  }

  const massagedMtxNextDue = getMassagedMtxNextDueInput(values, orgName)

  return {
    maintenanceItem: massagedMtxItem,
    maintenanceNextDue: massagedMtxNextDue,
  }
}

const getMassagedMtxNextDueInput = (values, orgName) => {
  const getFieldsByMode = () => {
    return {
      verifyPin: true,
      verifyUserId: values.enteredBy,
      verifyUserPin: values.pin,
    }
  }
  return {
    orgSlug: orgName,
    isCompleted: false,
    nextDueValue: {
      category: values.nextDueCategory || undefined,
      date: values.nextDueDate || undefined,
      flying_hours: values.nextDueHours || undefined,
      landings: values.nextDueLandings || undefined,
    },
    nextDueType: 'CALENDAR_MONTHS',
    ...getFieldsByMode(),
  }
}

const CreateNonRoutineTaskSlider: React.FC<CreateNonRoutineTaskSliderProps> = ({
  onClose,
  open,
  selectedTask,
}) => {
  const componentRef = React.useRef<HTMLDivElement>(null)
  const [areMutationsLoading, setAreMutationsLoading] = React.useState(false)
  const [usersOptions, setUsersOptions] = React.useState<SelectOption[]>([])
  const [aircraftOptions, setAircraftOptions] = React.useState<SelectOption[]>(
    []
  )
  const orgName = useOrgName()

  const { data: users } = useGetUsers()
  const { data: tags } = useQuery(GET_TAGS)

  const { aircrafts, loadAircraftList } = useAircraftRecords()
  useEffect(() => {
    loadAircraftList({ orgSlug: orgName })
  }, [])

  const [createDiscrepancyItemTransaction] = useMutation(
    CREATE_DISCREPANCY_ITEM_TRANSACTION
  )
  const { addTagsToMtxItem } = useAddTagsToMtxItem()

  const formMethods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    // context: { ...(data?.aircraft?.AircraftUsageLog?.[0] ?? {}) },
  })
  const { watch, setValue, reset } = formMethods
  const adSbType = watch('adSbType')
  const isDeferred = watch('isDeferred')
  const category = watch('nextDueCategory')
  const aircraftId = watch('aircraftId')

  useEffect(() => {
    // set users options
    if (users) {
      const options = users.users.map((user) => ({
        label: user.firstName + ' ' + user.lastName,
        value: user.id,
      }))
      setUsersOptions(options)
    }
  }, [users])

  useEffect(() => {
    // set aircraft options
    const options = Object.values(aircrafts).map((aircraft) => ({
      label: aircraft.tailNumber,
      value: aircraft.id,
      locked: !useHasPermission(
        Permissions.maintenanceItem.create,
        aircraft.id
      ),
    }))
    setAircraftOptions(options)
  }, [aircrafts])

  useEffect(() => {
    // set form values if selected task is present (update mode)
    if (selectedTask) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const notes: any = selectedTask?.notes
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const nextDueValue: any =
        selectedTask?.maintenanceNextDue[0]?.nextDueValue ?? {}
      let nextDueInfo = {}
      if (selectedTask.discrepancyStatus === 'DEFERRED') {
        nextDueInfo = {
          nextDueCategory: nextDueValue?.category ?? '',
          nextDueDate: nextDueValue?.date ?? '',
          nextDueHours: nextDueValue?.hours ?? '',
          nextDueLandings: nextDueValue?.landings ?? '',
        }
      }

      reset({
        ...selectedTask,
        isDeferred:
          selectedTask.discrepancyStatus === 'DEFERRED' ? 'yes' : 'no',
        notes: notes ?? '',
        ...nextDueInfo,
      })
    }
  }, [selectedTask])

  useEffect(() => {
    // set next due date based on category
    if (category) {
      const daysToAdd = melCatToDaysMap[category]
      const nextDate = dayjs().add(daysToAdd, 'day')
      setValue('nextDueDate', nextDate.toISOString())
    }
  }, [category])

  useEffect(() => {
    // reset values based on adSbType
    if (adSbType === 'MEL' || adSbType === 'NEF') {
      setValue('isDeferred', 'yes')
    } else {
      setValue('nextDueCategory', '')
    }
  }, [adSbType])

  useEffect(() => {
    // reset values based on isDeferred
    if (isDeferred === 'no') {
      setValue('nextDueDate', dayjs().toISOString())
    }
  }, [isDeferred])

  const resetFormAndClose = (refreshTable: boolean) => {
    reset(defaultValues)
    setAreMutationsLoading(false)
    onClose(refreshTable)
  }

  const somethingWentWrong = (message = '.', overrideMessage = false) => {
    setAreMutationsLoading(false)
    const toastMessage = overrideMessage
      ? message ?? '.'
      : `Something went wrong${message} Please try again later.`
    toast.error(toastMessage, {
      duration: 5000,
    })
  }

  const handleSubmit = async (values, event) => {
    event.preventDefault()
    event.stopPropagation()
    setAreMutationsLoading(true)
    const mtxItemInput = getMassagedMtxItemInput(values, orgName)

    // This is standard function to add tags to mtx item
    // this is executed at the end of all the mutations
    // because of the nature of it's low priority,
    // there is no rollback mechanism for it.
    const addTags = (mtxId) => {
      // Set it's internal aircraft since it's non routine task workflow
      addTagsToMtxItem(values.tags, mtxId, true)
        .then(() => {
          toast.success(`Discrepancy added successfully`, {
            duration: 5000,
          })
          resetFormAndClose(true)
        })
        .catch(() => {
          somethingWentWrong(
            ' while adding tags to discrepancy. However, discrepancy was added successfully.'
          )
          resetFormAndClose(true)
        })
    }

    // we need to create the mtx item and mtx next due
    createDiscrepancyItemTransaction({
      variables: {
        input: mtxItemInput,
      },
      onCompleted: async (mtxData) => {
        addTags(mtxData.createMaintenanceItemTransaction.id)
      },
      onError: (error) => {
        if (error.message === 'PIN_DOES_NOT_MATCH') {
          somethingWentWrong('PIN does not match', true)
        } else {
          somethingWentWrong()
        }
      },
    })
  }

  return (
    <Drawer
      anchor={'right'}
      open={open}
      sx={{ zIndex: 1202 }}
      ref={componentRef}
    >
      <Form
        formMethods={formMethods}
        onSubmit={handleSubmit}
        className="relative h-full"
      >
        <div
          className="h-full overflow-auto bg-[#EBECF3] pb-12"
          style={{ width: '450px' }}
        >
          <div className="bg-[#ffffff] p-2 pb-3">
            <div className="flex items-center justify-between">
              <IconButton
                onClick={() => resetFormAndClose(false)}
                className="mb-1"
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </div>
            <div>
              <Typography variant="h6" className="mb-2">
                Add Non Routine Task
              </Typography>
              <div className="mt-2 flex flex-col gap-2">
                <SelectDropdown
                  name="aircraftId"
                  label="Aircraft"
                  options={aircraftOptions}
                  fullWidth
                />
                <div className="flex flex-col gap-2">
                  <div className="flex gap-2">
                    <div className="w-1/2">
                      <TrackedByComponentsSelector aircraftId={aircraftId} />
                    </div>
                    <SelectDropdown
                      name="adSbType"
                      label="Type"
                      options={adSbTaskTypeOptions}
                      className="w-1/2"
                    />
                  </div>
                  <div className="flex gap-2">
                    <SelectDropdown
                      name="ataCode"
                      label="ATA Code"
                      options={ataCodeOptions}
                      className="w-1/2"
                    />
                    <TextField
                      placeholder="MFG Code"
                      size="small"
                      className="w-1/2"
                      name="manufactureCode"
                    />
                  </div>
                </div>

                <TextField placeholder="Title" size="small" name="title" />
                <TextField
                  placeholder="Description"
                  name="description"
                  multiline
                  fullWidth
                  rows={4}
                />
                <TagModalForm
                  control={formMethods.control}
                  options={tags?.tags ?? []}
                />
                <div>
                  <Typography variant="body2">Is deferred?</Typography>
                  <CustomRadioGroup
                    name="isDeferred"
                    required
                    options={deferredOptions}
                    disabled={adSbType === 'MEL' || adSbType === 'NEF'}
                    row
                  />
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <Typography variant="subtitle1" fontWeight={'bold'}>
                  Report Information
                </Typography>

                <div className="flex flex-col gap-2">
                  <Typography variant="subtitle2">Next Due</Typography>
                  {(adSbType === 'MEL' || adSbType === 'NEF') && (
                    <SelectDropdown
                      name="nextDueCategory"
                      label="Category"
                      options={melCategoryOptions}
                      fullWidth
                      value=""
                    />
                  )}
                  <DatePicker
                    className="col-span-2"
                    name={'nextDueDate'}
                    label="Date"
                    disabled={
                      (category && category !== 'catA') || isDeferred === 'no'
                    }
                    disablePast
                    closeOnSelect
                    slotProps={{
                      textField: { size: 'small', fullWidth: true },
                    }}
                  />

                  <TextField
                    placeholder="Hours"
                    size="small"
                    name="nextDueHours"
                    type="number"
                    disabled={
                      (adSbType && adSbType === 'DISCREPANCY') ||
                      isDeferred === 'no'
                    }
                    fullWidth
                  />
                  <TextField
                    placeholder="Landings"
                    size="small"
                    name="nextDueLandings"
                    type="number"
                    disabled={
                      (adSbType && adSbType === 'DISCREPANCY') ||
                      isDeferred === 'no'
                    }
                    fullWidth
                  />
                </div>

                <div className="flex flex-col gap-2">
                  <Typography variant="subtitle2">Additional</Typography>
                  <TextField
                    placeholder="Corrective Action"
                    name="notes"
                    multiline
                    fullWidth
                    rows={4}
                  />

                  <>
                    <SelectDropdown
                      required
                      name="enteredBy"
                      label="Entered By"
                      options={usersOptions}
                      fullWidth
                    />
                    <TextField
                      required
                      placeholder="Pin-Code"
                      size="small"
                      name="pin"
                      type="password"
                    />
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 left-0 right-0 z-[1] float-right flex justify-between gap-2 bg-[#FAFAFA] p-2">
          <Button
            variant="text"
            onClick={() => {
              setAreMutationsLoading(false)
              reset(defaultValues)
            }}
          >
            Reset
          </Button>
          <LoadingButton
            loading={areMutationsLoading}
            startIcon={<AddTaskIcon />}
            loadingPosition="start"
            variant="contained"
            type="submit"
          >
            Add Non-routine
          </LoadingButton>
        </div>
      </Form>
    </Drawer>
  )
}

export default CreateNonRoutineTaskSlider
