import RequestPartDrawer from 'src/components/workflow/RequestPartDrawer'
import WorkflowPartsTransactionSection from 'src/components/workflow/WorkflowPartsTransactionSection'
import WorkflowPartsTransactionDrawer from 'src/components/workflow/WorkflowPartsTransactionSection/WorkflowPartsTransactionDrawer'
import {
  GET_INTERNAL_WORK_ITEM_ATTACHMENTS,
  GET_WORK_ITEM_PARTS_TRANSACTION,
} from '../../queries'
import { useQuery } from '@redwoodjs/web'
import { useLazyQuery } from '@apollo/client'

interface PartTransactionSectionProps {
  workItemId: string
  flashSaved?: (text?: string) => void
  flashError?: (text?: string) => void
}

const PartTransactionSection = ({
  workItemId,
  flashSaved,
  flashError,
}: PartTransactionSectionProps) => {
  const { data, loading } = useQuery(GET_WORK_ITEM_PARTS_TRANSACTION, {
    variables: { id: workItemId },
    skip: !workItemId,
  })
  const [refreshAttachments] = useLazyQuery(
    GET_INTERNAL_WORK_ITEM_ATTACHMENTS,
    {
      variables: { id: workItemId },
      notifyOnNetworkStatusChange: false,
      onError: () => {
        flashError?.('Failed to refresh attachments')
      },
      onCompleted: () => {
        flashSaved?.('Attachments refreshed')
      },
    }
  )

  const partsTransaction = data?.internalWorkItem?.partsTransaction
  const currentPart = data?.internalWorkItem?.maintenanceItem?.aircraftComponent

  return (
    <div>
      <WorkflowPartsTransactionSection
        id={workItemId}
        data={data}
        loading={loading}
        partsTransaction={partsTransaction}
        currentPart={currentPart}
        flashSaved={flashSaved}
        flashError={flashError}
      />

      <RequestPartDrawer workItemId={workItemId} />

      <WorkflowPartsTransactionDrawer
        partsTransaction={partsTransaction}
        currentPart={currentPart}
        workItemId={workItemId}
        flashSaved={flashSaved}
        flashError={flashError}
        onSubmit={(data) => {
          const areFilesUploaded =
            data?.files?.added?.length > 0 || data?.files?.removed?.length > 0
          if (areFilesUploaded) {
            refreshAttachments()
          }
        }}
        upsertUpdateFn={(cache, { data: mutationData }) => {
          cache.modify({
            id: cache.identify({
              __typename: 'InternalWorkItem',
              id: workItemId,
            }),
            fields: {
              partsTransaction: () => {
                return {
                  // this is the thinnest, lightest way to tell the cache to relate an object to another object that is expected
                  // it mimics the way that the cache would have been updated if the mutation had been run with an explicit relationship
                  __ref: cache.identify(mutationData.upsertPartsTransaction),
                }
              },
            },
          })
        }}
      />
    </div>
  )
}

export default PartTransactionSection
