import React, { useState, useEffect, useRef } from 'react'

import styled from '@emotion/styled'
import {
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material'
import {
  FolderIcon as FolderClosedIcon,
  FolderOpenIcon,
  FileIcon,
  LinkIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  FileDownIcon,
  FolderDownIcon,
  FolderOpenDotIcon,
  CloudDownloadIcon,
  ExternalLinkIcon,
} from 'lucide-react'
import { NodeApi, NodeRendererProps } from 'react-arborist'

import theme from 'src/theme'

import { TreeViewNode, useFileTree } from '.'
import { Link } from '@redwoodjs/router'
import { SubdirectoryArrowRightOutlined } from '@mui/icons-material'

const StyledDiv = styled.div<{ isChild: boolean }>`
  padding: 8px 8px;
  padding-left: ${(props) => (props.isChild ? '18px' : '8px')};
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${(props) => (props.isChild ? 'gray' : 'black')};
  &.active-node {
    color: #2b83fd;
    border-right: 2px solid #2b83fd;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`

const ReviewItemNode: React.FC<NodeRendererProps<TreeViewNode>> = ({
  node,
  style,
  dragHandle,
}) => {
  const { data } = node
  const { activeNode, activeNodeDataLoading, setActiveNode } = useFileTree()
  const childrenLoading = activeNodeDataLoading && activeNode?.id === data.id

  const handleClick = () => {
    if (childrenLoading) return
    setActiveNode(node)
  }

  const getActiveClass = () => {
    if (activeNode && activeNode.id === data.id) {
      return 'active-node'
    }
    return ''
  }

  const titleRef = useRef<HTMLDivElement>(null)

  const [isTruncated, setIsTruncated] = useState(false)

  useEffect(() => {
    if (titleRef.current) {
      setIsTruncated(
        titleRef.current.clientHeight < titleRef.current.scrollHeight
      )
    }
  }, [titleRef.current])

  const isChild = node.data.fileType === 'file'

  return (
    <StyledDiv
      ref={dragHandle}
      className={`${getActiveClass()}`}
      role="button"
      tabIndex={0}
      onClick={handleClick}
      style={{
        cursor: childrenLoading ? 'not-allowed' : 'pointer',
      }}
      isChild={isChild}
    >
      <div
        className="flex items-center gap-1"
        style={{ opacity: childrenLoading ? 0.5 : 1 }}
      >
        {isChild && (
          <SubdirectoryArrowRightOutlined color="disabled" fontSize="small" />
        )}
        <Tooltip title={isTruncated ? data.name : ''}>
          <div
            role="button"
            tabIndex={0}
            onClick={(e) => {
              if (
                e.detail === 3 &&
                (activeNode?.data?.folderType === 'CUSTOM' ||
                  activeNode?.data?.fileType === 'file')
              ) {
                node.edit()
              }
            }}
          >
            <Typography
              ref={titleRef}
              variant="body2"
              className="line-clamp-1 pr-2"
            >
              {data.name}
            </Typography>
          </div>
        </Tooltip>
      </div>
      {childrenLoading && <CircularProgress size={12} thickness={5} />}
    </StyledDiv>
  )
}

export default ReviewItemNode
