import { Typography } from '@mui/material'
import { ComplianceLedger, FileEntry } from 'types/graphql'

import { Form, useForm } from '@redwoodjs/forms'
import { navigate, routes } from '@redwoodjs/router'

import Button from 'src/components/MUI/Button'
import TextField from 'src/components/MUI/TextField'

type LogbookEntrySignFormProps = {
  orgName: string
  userId: string
  ledger: ComplianceLedger
  signatureFile: FileEntry
  handleSubmit: (data: any) => void
}

const LogbookEntrySignForm = ({
  orgName,
  userId,
  ledger,
  signatureFile,
  handleSubmit,
}: LogbookEntrySignFormProps) => {
  const formMethods = useForm()

  const workerSignature = ledger?.signatures?.find(
    (sig) => sig.signedAs === 'WORKER'
  )
  const inspectorSignature = ledger?.signatures?.find(
    (sig) => sig.signedAs === 'INSPECTOR'
  )
  const workerSignatureRequested = !!ledger?.requestedWorker?.id
  const inspectorSignatureRequested = !!ledger?.requestedInspector?.id
  const signedByWorker = !!workerSignature || !workerSignatureRequested
  const signedByInspector = !!inspectorSignature || !inspectorSignatureRequested
  const userRequestedAsWorker = userId === ledger?.requestedWorker?.id
  const userRequestedAsInspector = userId === ledger?.requestedInspector?.id
  const userSignedAsWorker =
    signedByWorker && workerSignature?.signer.id == userId
  const userSignedAsInspector =
    signedByInspector && inspectorSignature?.signer.id == userId
  const userAwaitingWorkerSignature =
    workerSignatureRequested && !signedByWorker && userRequestedAsInspector
  const fullySigned = signedByWorker && signedByInspector

  const workerHelperText = () => {
    if (!workerSignatureRequested) {
      return 'No technician selected'
    }
    if (userSignedAsWorker) {
      return "You've already signed this document"
    }
    if (signedByWorker) {
      return `Already signed by ${workerSignature?.signer.firstName} ${workerSignature?.signer.lastName}`
    }
    if (userRequestedAsWorker) {
      return 'You are selected to sign here'
    }
    return `${ledger.requestedWorker.firstName} ${ledger.requestedWorker.lastName} is selected to sign this document`
  }

  const inspectorHelperText = () => {
    if (!inspectorSignatureRequested) {
      return 'No inspector selected'
    }
    if (userSignedAsInspector) {
      return "You've already signed this document"
    }
    if (signedByInspector) {
      return `Already signed by ${inspectorSignature?.signer.firstName} ${inspectorSignature?.signer.lastName}`
    }
    if (userRequestedAsInspector) {
      return 'You are selected to sign here'
    }
    return `${ledger.requestedInspector.firstName} ${ledger.requestedInspector.lastName} is selected to sign this document`
  }

  let submitDisabledReason: string | undefined
  if (fullySigned) {
    submitDisabledReason = 'All signatures have been collected'
  } else if (!userRequestedAsWorker && !userRequestedAsInspector) {
    submitDisabledReason = 'You are not selected to sign this document'
  } else if (
    (userSignedAsWorker && !userRequestedAsInspector) ||
    (userSignedAsInspector && !userRequestedAsWorker)
  ) {
    submitDisabledReason = "You've already signed this document"
  } else if (userAwaitingWorkerSignature) {
    submitDisabledReason = 'Awaiting technician signature'
  }

  if (!signatureFile?.url) {
    return (
      <div className="flex w-1/3 flex-col gap-1">
        <Typography variant="caption" className="self-center">
          You don&apos;t have a signature, please add one before continuing
        </Typography>
        <Button
          fullWidth
          size="large"
          className="mb-3"
          onClick={() => navigate(routes.settings({ orgName }))}
        >
          Add signature
        </Button>
        <span />
      </div>
    )
  }

  return (
    <Form formMethods={formMethods} onSubmit={handleSubmit}>
      <div className="grid grid-cols-2 gap-2">
        <TextField
          label="Technician"
          size="small"
          name="workedByPin"
          inputProps={{
            className: '[-webkit-text-security:disc]',
            autoComplete: 'off',
          }}
          disabled={
            !!ledger?.workedBy?.id || userId !== ledger?.requestedWorker?.id
          }
          color="success"
          focused={ledger?.requestedWorker?.id === userId}
          helperText={workerHelperText()}
        />
        <TextField
          label="Inspector"
          size="small"
          name="inspectedByPin"
          inputProps={{
            className: '[-webkit-text-security:disc]',
            autoComplete: 'off',
          }}
          disabledTooltip={
            userAwaitingWorkerSignature
              ? 'Technician must sign first'
              : undefined
          }
          TooltipProps={{ placement: 'top' }}
          disabled={
            !!ledger?.inspectedBy?.id ||
            userId !== ledger?.requestedInspector?.id ||
            userAwaitingWorkerSignature
          }
          color="success"
          focused={
            !userAwaitingWorkerSignature &&
            ledger?.requestedInspector?.id === userId
          }
          helperText={inspectorHelperText()}
        />
      </div>
      <div className="flex w-full flex-col gap-1">
        <Button
          fullWidth
          size="large"
          className="mt-3"
          type="submit"
          disabled={!!submitDisabledReason}
        >
          Sign
        </Button>
        {submitDisabledReason && (
          <Typography variant="caption" className="self-center">
            {submitDisabledReason}
          </Typography>
        )}
      </div>
    </Form>
  )
}

export default LogbookEntrySignForm
