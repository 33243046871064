import {
  IconButton as MUIIconButton,
  IconButtonProps as MUIIconButtonProps,
  Tooltip,
  TooltipProps,
} from '@mui/material'

export type IconButtonProps = MUIIconButtonProps & {
  tooltip?: string
  disabledTooltip?: string
  TooltipProps?: TooltipProps
  useGlobalTooltip?: boolean
}

const IconButton: React.FC<IconButtonProps> = ({
  tooltip,
  disabledTooltip,
  TooltipProps,
  disabled,
  useGlobalTooltip = false,
  ...buttonProps
}) => {
  const content = <MUIIconButton disabled={disabled} {...buttonProps} />
  const tooltipContent = (disabled && disabledTooltip) || tooltip
  const tooltipDelay = disabled ? 100 : 700

  if (!tooltipContent) {
    return content
  }

  if (!useGlobalTooltip) {
    return (
      <Tooltip
        enterDelay={tooltipDelay}
        {...TooltipProps}
        title={tooltipContent}
      >
        <span>{content}</span>
      </Tooltip>
    )
  }

  return (
    <div
      data-tooltip-id="global-tooltip"
      data-tooltip-content={tooltipContent}
      data-tooltip-delay-show={tooltipDelay}
    >
      {content}
    </div>
  )
}

export default IconButton
