import React from 'react'

import FlightIcon from '@mui/icons-material/Flight'
import LoadingButton from '@mui/lab/LoadingButton'

import { useDispatch } from 'src/hooks/useDispatch'
import { useSelector } from 'src/hooks/useSelector'
import { openModal } from 'src/slices/modal'
interface ApplyTimesProps {
  aircraftId: string | null
  loading: boolean
  disablePick?: boolean
  disableCreate?: boolean
}

const ApplyTimesButton: React.FC<ApplyTimesProps> = ({
  aircraftId,
  loading,
  disableCreate,
  disablePick,
}) => {
  const dispatch = useDispatch()
  const applyTimesLoading = useSelector(
    (state) => state.modal.modals.aircraftTimeModal.data?.loading
  )
  const selectAircraftLoading = useSelector(
    (state) => state.modal.modals.aircraftSelectorModal?.data?.loading
  )

  return (
    <LoadingButton
      variant="outlined"
      color="base"
      loading={applyTimesLoading || selectAircraftLoading}
      loadingPosition="start"
      onClick={() =>
        dispatch(
          openModal({
            name: 'aircraftTimeModal',
            data: {
              aircraftId: aircraftId,
              loading: loading,
              disablePick: disablePick,
              disableCreate: disableCreate,
            },
          })
        )
      }
      size="large"
    >
      APPLY TIMES
    </LoadingButton>
  )
}

export default ApplyTimesButton
