import { WorkCompletedFiltersData } from 'src/types'

import { RootState } from '../reducers'
import {
  initialFilterData,
  setWorkCompletedFiltersData,
} from '../slices/workCompletedFiltersSlice'

import { useDispatch } from './useDispatch'
import { useSelector } from './useSelector'

interface WorkCompletedTableFiltersHook {
  workCompletedFiltersData: WorkCompletedFiltersData
  setWorkCompletedFilters: (data: WorkCompletedFiltersData) => void
  setSearchTerm: (searchTerm: string) => void
  setAircraftFilter: (key: string, value: boolean) => void
  resetAircraftFilter: () => void
  resetFilters: () => void
  setTimestamp: (timestamp: string) => void
  resetTimestamp: () => void
  setLedgerStatusFilter: (key: string, value: boolean) => void
  resetLedgerStatusFilter: () => void
}

const useWorkCompletedFilters = (): WorkCompletedTableFiltersHook => {
  const dispatch = useDispatch()
  const workCompletedFiltersData: WorkCompletedFiltersData = useSelector(
    (state: RootState) => state.workCompletedFilters.data
  )

  const setWorkCompletedFilters = (data: WorkCompletedFiltersData): void => {
    dispatch(setWorkCompletedFiltersData(data))
  }

  const setSearchTerm = (searchTerm: string): void => {
    setWorkCompletedFilters({
      ...workCompletedFiltersData,
      searchTerm,
    })
  }

  const setAircraftFilter = (key: string, value: boolean): void => {
    setWorkCompletedFilters({
      ...workCompletedFiltersData,
      aircrafts: {
        ...workCompletedFiltersData.aircrafts,
        [key]: value,
      },
    })
  }

  const resetAircraftFilter = (): void => {
    const aircraftFilters = Object.keys(
      workCompletedFiltersData.aircrafts
    ).reduce((acc, aircraftId) => {
      return {
        ...acc,
        [aircraftId]: false,
      }
    }, {})
    setWorkCompletedFilters({
      ...workCompletedFiltersData,
      aircrafts: aircraftFilters,
    })
  }

  const setLedgerStatusFilter = (key: string, value: boolean): void => {
    if (!value) {
      const currentTrueCount = Object.values(
        workCompletedFiltersData.ledgerStatus
      ).filter(Boolean).length
      if (
        currentTrueCount <= 1 &&
        workCompletedFiltersData.ledgerStatus[key] === true
      ) {
        return
      }
    }

    setWorkCompletedFilters({
      ...workCompletedFiltersData,
      ledgerStatus: {
        ...workCompletedFiltersData.ledgerStatus,
        [key]: value,
      },
    })
  }

  const resetLedgerStatusFilter = (): void => {
    setWorkCompletedFilters({
      ...workCompletedFiltersData,
      ledgerStatus: {
        ...workCompletedFiltersData.ledgerStatus,
        ...initialFilterData.ledgerStatus,
      },
    })
  }

  const setTimestamp = (timestamp: string): void => {
    if (!timestamp || isNaN(Date.parse(timestamp))) {
      return
    }
    setWorkCompletedFilters({
      ...workCompletedFiltersData,
      timestamp,
    })
  }

  const resetTimestamp = (): void => {
    setWorkCompletedFilters({
      ...workCompletedFiltersData,
      timestamp: null,
    })
  }

  const resetFilters = (): void => {
    setWorkCompletedFilters(initialFilterData)
  }

  return {
    workCompletedFiltersData,
    setSearchTerm,
    setAircraftFilter,
    setWorkCompletedFilters,
    resetAircraftFilter,
    resetFilters,
    setTimestamp,
    resetTimestamp,
    setLedgerStatusFilter,
    resetLedgerStatusFilter,
  }
}

export default useWorkCompletedFilters
