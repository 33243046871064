import CloseIcon from '@mui/icons-material/Close'
import {
  Button,
  IconButton,
  List,
  ListItem,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { ComplianceLedger } from 'types/graphql'

import { Link, routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import useCreateComplianceRecords from 'src/hooks/requests/useCreateComplianceRecords'
import { useDispatch } from 'src/hooks/useDispatch'
import { useOrgName } from 'src/hooks/useOrgName'
import { useSelector } from 'src/hooks/useSelector'
import { closeModal } from 'src/slices/modal'

import { UPDATE_PURCHASE_ORDER_MUTATION } from '../PurchaseOrderDraftPage/queries'

interface PurchaseOrderComplianceModalProps {
  purchaseOrderId: string
  aircraftIdToLedgerMap: AircraftIdToLedgerMap
  mtxItemsGroupedByAircraftId: MtxItemsGroupedByAircraftId
  onClose: () => void
}

type MaintenanceItemDetail = {
  id: string // Assuming maintenanceItemId is a string
  ataCode: string
  manufactureCode: string
  title: string
  tailNumber: string
}

export type MtxItemsGroupedByAircraftId = {
  [aircraftId: string]: MaintenanceItemDetail[]
}

export type AircraftIdToLedgerMap = {
  [aircraftId: string]: ComplianceLedger
}

const modalName = 'purchaseOrderComplianceModal'
const PurchaseOrderComplianceModal: React.FC<
  PurchaseOrderComplianceModalProps
> = ({
  purchaseOrderId,
  aircraftIdToLedgerMap,
  mtxItemsGroupedByAircraftId,
  onClose,
}) => {
  const { createRecords } = useCreateComplianceRecords()
  const dispatch = useDispatch()

  const [updatePurchaseOrder] = useMutation(UPDATE_PURCHASE_ORDER_MUTATION, {
    onCompleted: () => {
      toast.success('Added to bulk compliance')
    },
  })

  const orgName = useOrgName()
  const open =
    useSelector((state) => state.modal.modals?.[modalName]?.isOpen) ?? false

  const handleClose = () => {
    dispatch(closeModal({ name: modalName }))
    onClose()
  }

  const addedToBulkCompliance = (aircraftId: string) => {
    return (
      (aircraftIdToLedgerMap[aircraftId] &&
        aircraftIdToLedgerMap[aircraftId].status === 'IN_PROGRESS') ||
      completedBulkCompliance(aircraftId)
    )
  }

  const completedBulkCompliance = (aircraftId: string) => {
    return (
      aircraftIdToLedgerMap[aircraftId] &&
      aircraftIdToLedgerMap[aircraftId].status === 'COMPLETED'
    )
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper className="absolute left-1/2 top-1/2 w-120 -translate-x-1/2 -translate-y-1/2 transform rounded-xl  bg-white p-3">
        <div className="flex items-start justify-between">
          <div>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Complete Compliance
            </Typography>
            <Typography
              variant="subtitle2"
              id="modal-modal-description"
              className="mt-1 text-[#9E9E9E]"
            >
              1. Please add service items to bulk compliance for each aircraft.
              <br />
              2. Complete bulk compliance for each aircraft.
            </Typography>
          </div>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <TableContainer className="max-h-49 overflow-y-scroll">
          <Table size={'medium'}>
            <TableHead>
              <TableRow>
                <TableCell className="w-1/2" align="left">
                  <Typography variant="caption">Maintenance Item(s)</Typography>
                </TableCell>
                <TableCell className="w-1/4" align="left">
                  <Typography variant="caption">Step 1</Typography>
                </TableCell>
                <TableCell className="w-1/4" align="left">
                  <Typography variant="caption">Step 2</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(mtxItemsGroupedByAircraftId).map((aircraftId) => {
                const tailNumber =
                  mtxItemsGroupedByAircraftId[aircraftId][0].tailNumber
                return (
                  <TableRow key={aircraftId}>
                    <TableCell>
                      <List sx={{ listStyleType: 'disc' }}>
                        {mtxItemsGroupedByAircraftId[aircraftId].map(
                          (mtxItem) => (
                            <ListItem
                              sx={{ display: 'list-item' }}
                              key={mtxItem.id}
                            >
                              {mtxItem.ataCode} {mtxItem.manufactureCode}:{' '}
                              {mtxItem.title}
                            </ListItem>
                          )
                        )}
                      </List>
                    </TableCell>
                    <TableCell className="w-1/4" align="left">
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        disabled={addedToBulkCompliance(aircraftId)}
                        onClick={async () => {
                          try {
                            const {
                              ledgerData: { id: ledgerId },
                              error,
                            } = await createRecords(
                              aircraftId,
                              mtxItemsGroupedByAircraftId[aircraftId].map(
                                (mtxItem) => mtxItem.id
                              )
                            )
                            if (!error) {
                              await updatePurchaseOrder({
                                variables: {
                                  id: purchaseOrderId,
                                  input: {
                                    complianceLedgerIds: [ledgerId],
                                  },
                                },
                              })
                            } else {
                              throw new Error(error)
                            }
                          } catch (error) {
                            toast.error(error.message)
                          }
                        }}
                      >
                        Add
                        {addedToBulkCompliance(aircraftId) ? 'ed' : ''} to{' '}
                        {tailNumber} Bulk Compliance
                      </Button>
                    </TableCell>
                    <TableCell className="w-1/4" align="left">
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        align="center"
                        component={Link}
                        disabled={
                          !aircraftIdToLedgerMap ||
                          !aircraftIdToLedgerMap[aircraftId] ||
                          completedBulkCompliance(aircraftId)
                        }
                        to={routes.bulkCompliance({
                          orgName,
                          ledgerId: aircraftIdToLedgerMap[aircraftId].id,
                        })}
                      >
                        Complete{completedBulkCompliance(aircraftId) ? 'd' : ''}{' '}
                        {tailNumber} Bulk Compliance
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Modal>
  )
}

export default PurchaseOrderComplianceModal
