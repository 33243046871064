import { yupResolver } from '@hookform/resolvers/yup'
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded'
import { Divider, InputAdornment, Typography } from '@mui/material'
import { Form } from '@redwoodjs/forms'
import { useForm } from 'react-hook-form'
import Button from 'src/components/MUI/Button'
import Drawer from 'src/components/MUI/Drawer/Drawer'
import DrawerActions from 'src/components/MUI/Drawer/DrawerActions'
import DrawerHeader from 'src/components/MUI/Drawer/DrawerHeader'
import TextField from 'src/components/MUI/TextField'
import { UsersDropDownSelector } from 'src/components/MUI/UsersDropDownSelector'
import useModal from 'src/hooks/useModal'
import useQuery from 'src/hooks/useQuery'
import * as yup from 'yup'
import { useEffect } from 'react'
import useGetUsers from 'src/hooks/requests/useGetUsers'
import Switch from 'src/components/MUI/Switch'
import { useAuth } from 'src/auth'
import { minutesToHours } from 'src/utils/helpers'

// Update the type to allow null for workMinutes
export type WorkPerformedFormData = {
  description: string
  notes: string
  workedById: string
  correctiveAction: string
  workMinutes: number | null // explicitly allow null
  overrideLaborTransactionHours: boolean
}

// Add props interface
interface WorkPerformedDrawerProps {
  id?: string | number
  initialValues?: WorkPerformedFormData
  onSubmit: (
    data: WorkPerformedFormData,
    id?: string | number
  ) => void | Promise<void>
}

const schema = yup
  .object({
    description: yup.string().required('Description is required'),
    correctiveAction: yup.string().required('Corrective action is required'),
    notes: yup.string().nullable(),
    workedById: yup.string().required('Mechanic is required'),
    workMinutes: yup.mixed().when('overrideLaborTransactionHours', {
      is: true,
      then: () =>
        yup
          .number()
          .required('Time worked is required')
          .positive('Time must be greater than 0')
          .min(0, 'Time must be greater than 0')
          .max(100, 'Time worked cannot exceed 100 hours'),
      otherwise: () => yup.mixed().nullable(),
    }),
    overrideLaborTransactionHours: yup.boolean(),
  })
  .required()

const defaultValues: WorkPerformedFormData = {
  description: '',
  notes: '',
  workedById: '',
  workMinutes: null,
  correctiveAction: '',
  overrideLaborTransactionHours: false,
}

const WorkPerformedDrawer = ({
  onSubmit,
  initialValues,
  id,
}: WorkPerformedDrawerProps) => {
  const { handleClose, isOpen } = useModal('workPerformedDrawer')

  const { currentUser } = useAuth()
  const { data: mechanicsData } = useGetUsers()
  const mechanics = mechanicsData?.users ?? []
  const currentUserMechanic = mechanics.find(
    (mechanic) => mechanic.clerkId === currentUser?.providerId
  )

  // Add React Hook Form setup with yup resolver
  const methods = useForm<WorkPerformedFormData>({
    resolver: yupResolver(schema),
    defaultValues: initialValues || defaultValues,
  })

  const overrideLaborTransactionHours = methods.watch(
    'overrideLaborTransactionHours'
  )

  // Add useEffect to reset form when drawer closes
  useEffect(() => {
    if (!isOpen) {
      methods.reset(defaultValues)
    } else if (isOpen && initialValues && currentUserMechanic) {
      // Reset to initial values if they exist when drawer opens
      const minsToHrs = parseFloat(minutesToHours(initialValues.workMinutes))

      methods.reset({
        ...initialValues,
        workedById: currentUserMechanic.id,
        workMinutes: minsToHrs,
      })
    }
  }, [isOpen, initialValues, methods, currentUserMechanic])

  const handleSubmit = async (data: WorkPerformedFormData) => {
    try {
      await onSubmit(
        {
          ...data,
          workMinutes:
            data.overrideLaborTransactionHours && data.workMinutes
              ? Math.ceil(data.workMinutes * 60)
              : null,
        },
        id
      )
      handleClose()
    } catch (error) {
      // If onSubmit throws an error, the drawer will stay open
      console.error('Failed to submit work performed:', error)
    }
  }

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose}>
      <DrawerHeader
        title={id ? 'Edit Work' : 'Add Work'}
        onClose={handleClose}
      />
      <Form
        formMethods={methods}
        onSubmit={handleSubmit}
        className="w-[800px] flex-grow overflow-y-auto px-3 py-2"
        id="workPerformedForm"
      >
        <div className="flex flex-col gap-2">
          <UsersDropDownSelector
            label="Mechanic"
            name="workedById"
            users={mechanics}
            required
            size="small"
          />
          <div className="grid grid-cols-2 gap-2">
            <TextField
              label="Description"
              name="description"
              multiline
              rows={3}
              size="small"
              required
            />

            <TextField
              label="Corrective Action"
              name="correctiveAction"
              multiline
              rows={3}
              size="small"
              required
            />
          </div>
          <TextField
            label="Task Notes"
            name="notes"
            multiline
            rows={3}
            size="small"
          />
          <Divider />
          <div className="grid grid-cols-2 gap-2">
            <div className="flex items-center gap-2">
              <Typography variant="caption">
                Override Labor Transaction Hours
              </Typography>
              <Switch
                name="overrideLaborTransactionHours"
                switchProps={{ size: 'small' }}
              />
            </div>

            <TextField
              label="Time Worked (hours)"
              name="workMinutes"
              required={overrideLaborTransactionHours}
              type="number"
              size="small"
              disabled={!overrideLaborTransactionHours}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <ScheduleRoundedIcon />
                    </InputAdornment>
                  ),
                },
              }}
            />
          </div>
        </div>
      </Form>
      <DrawerActions
        className="gap-1"
        leftActions={
          <Button variant="text" color="black" onClick={handleClose} fullWidth>
            Cancel
          </Button>
        }
        rightActions={
          <Button
            type="submit"
            form="workPerformedForm"
            variant="contained"
            color="primary"
            fullWidth
          >
            {id ? 'Update' : 'Save'}
          </Button>
        }
      />
    </Drawer>
  )
}

export default WorkPerformedDrawer
