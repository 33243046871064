import React, { useState, useRef, useEffect } from 'react'
import {
  InlineFieldWrapper,
  InlineFieldWrapperProps,
} from '../InlineFieldWrapper'
import clsx from 'clsx'

interface InlineTextInputProps
  extends Omit<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    'onChange' | 'onBlur' | 'onKeyDown'
  > {
  /** Current value */
  value: string
  /** Change handler */
  onChange: (value: string) => void
  /** Blur handler */
  onBlur?: (value: string) => void
  /** Key down handler */
  onKeyDown?: (value: string) => void
  /** Whether the field has an error */
  error?: string | null
  /** Whether the field is valid */
  isValid?: boolean
  /** Whether the field is currently submitting */
  isSubmitting?: boolean
  /** Whether the field is currently validating */
  isValidating?: boolean
  /** Whether the field is multiline */
  multiline?: boolean
}

/**
 * Base text input component for inline editing
 * Provides a simple controlled input with state indicators
 */
export const InlineTextInput: React.FC<InlineTextInputProps> = ({
  value,
  onChange,
  onBlur,
  onKeyDown,
  error,
  isValid,
  isSubmitting,
  isValidating,
  placeholder,
  className = '',
  disabled = false,
  multiline = false,
  ...otherProps
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  /**
   * Auto-height adjustment function
   */
  const adjustHeight = () => {
    const textarea = textareaRef.current
    if (textarea) {
      textarea.style.height = 'auto' // Reset height
      textarea.style.height = `${textarea.scrollHeight}px` // Set to scrollHeight
    }
  }

  /**
   * Adjust height on value change and window resize
   */
  useEffect(() => {
    if (textareaRef.current) {
      // Add resize observer to handle content changes
      const resizeObserver = new ResizeObserver(() => {
        adjustHeight()
      })
      resizeObserver.observe(textareaRef.current)

      // Cleanup
      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [value])

  return (
    <div
      className={clsx(
        'min-h-[28px] rounded-md border border-solid border-transparent transition-colors duration-0',
        {
          'bg-sys-lightgrey': !error && !isValid && !disabled,
          'hover:border-black':
            !disabled && !error && !isFocused && !isSubmitting,
          'border-red-500 bg-red-50': error,
          'cursor-pointer': !disabled && !isSubmitting,
          'cursor-not-allowed': disabled || isSubmitting,
        },
        className
      )}
      onClick={() => textareaRef.current?.focus()}
    >
      <textarea
        ref={textareaRef}
        value={value}
        onChange={(e) => {
          onChange(e.target.value)
          adjustHeight()
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={(e) => {
          setIsFocused(false)
          onBlur?.(e.target.value)
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && (!multiline || !e.shiftKey)) {
            e.preventDefault()
            textareaRef.current?.blur()
          }
        }}
        disabled={disabled || isSubmitting}
        placeholder={placeholder}
        rows={1}
        className={clsx(
          'my-0.5 flex w-full resize-none overflow-hidden border-0 border-b border-t border-solid border-transparent bg-transparent px-1 outline-none',
          !multiline && 'whitespace-nowrap',
          isFocused && 'border-b-black',
          !disabled && !isSubmitting ? 'cursor-pointer' : 'cursor-not-allowed'
        )}
        {...otherProps}
      />
    </div>
  )
}

interface InlineTextFieldProps
  extends Omit<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    'name' | 'onSubmit' | 'onError' | 'onSuccess'
  > {
  /** Field name/identifier */
  name: string
  /** Initial value */
  initialValue: string
  /** Optional validation schema */
  validation?: InlineFieldWrapperProps['validation']
  /** Submit handler */
  onSubmit?: InlineFieldWrapperProps['onSubmit']
  /** Optional error handler */
  onError?: InlineFieldWrapperProps['onError']
  /** Optional success handler */
  onSuccess?: InlineFieldWrapperProps['onSuccess']
  /** When to trigger validation/submission */
  triggerOn?: InlineFieldWrapperProps['triggerOn']
  /** Debounce delay for typing validation (ms) */
  debounceMs?: number
  /** Whether the field is multiline */
  multiline?: boolean
}

/**
 * Pre-wrapped inline text field component
 * Combines InlineFieldWrapper with InlineTextInput
 */
export const InlineTextField: React.FC<InlineTextFieldProps> = ({
  name,
  initialValue,
  validation,
  onSubmit,
  onError,
  onSuccess,
  placeholder,
  className,
  // For text fields, default to typing validation and blur/enter submission
  triggerOn = { typing: true, blur: true, enter: true, change: false },
  debounceMs = 300,
  multiline = false,
  ...otherProps
}) => {
  return (
    <InlineFieldWrapper
      name={name}
      initialValue={initialValue}
      validation={validation}
      onSubmit={onSubmit}
      onError={onError}
      onSuccess={onSuccess}
      triggerOn={triggerOn}
      debounceMs={debounceMs}
    >
      {({
        error,
        isValid,
        isSubmitting,
        isValidating,
        value,
        onChange,
        onBlur,
        onKeyDown,
      }) => (
        <InlineTextInput
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={(newValue) => onBlur(newValue)}
          error={error}
          isValid={isValid}
          isSubmitting={isSubmitting}
          isValidating={isValidating}
          placeholder={placeholder}
          className={className}
          multiline={multiline}
          {...otherProps}
        />
      )}
    </InlineFieldWrapper>
  )
}
