import { useEffect, useMemo } from 'react'

import { useLazyQuery } from '@apollo/client'
import { debounce } from '@mui/material'
import _ from 'lodash'

const FILE_FRAGMENT = gql`
  fragment FileFragmentForBulkComplianceEditModal on FileEntry {
    id
    title
    description
    originalFilename
    createdAt
    uploadedBy {
      id
      firstName
      lastName
    }
  }
`
const FETCH_SELECTED_COMPLIANCE_ATTACHMENTS = gql`
  ${FILE_FRAGMENT}
  query BulkFetchComplianceAttachments($filters: ComplianceActivityFilter!) {
    complianceActivities(filters: $filters) {
      id
      attachedFiles {
        id
        file {
          ...FileFragmentForBulkComplianceEditModal
        }
      }
    }
  }
`

const FETCH_SELECTED_INTERNAL_WORK_ITEM_ATTACHMENTS = gql`
  ${FILE_FRAGMENT}
  query BulkFetchInternalWorkOrderAttachments(
    $filters: InternalWorkItemFilter!
  ) {
    internalWorkItems(filters: $filters) {
      id
      attachedFiles {
        id
        file {
          ...FileFragmentForBulkComplianceEditModal
        }
      }
    }
  }
`

interface BulkComplianceEditModalFileUtils {
  loading: boolean
  refetch: () => void
  fileIdToAttachedFileIds: Record<string, string[]>
  overlappingWorkCompletedFiles: any[]
}

const useBulkComplianceEditModalFileUtils = ({
  fetchFrom,
  open,
  selectedTasks,
}: {
  fetchFrom: 'compliance' | 'internalWorkItem'
  open: boolean
  selectedTasks: readonly string[]
}): BulkComplianceEditModalFileUtils => {
  const [
    fetchSelectedComplianceAttachments,
    {
      loading: complianceAttachmentsLoading,
      data: complianceAttachmentsData,
      refetch: complianceAttachmentsRefetch,
    },
  ] = useLazyQuery(FETCH_SELECTED_COMPLIANCE_ATTACHMENTS)

  const [
    fetchSelectedInternalWorkItemsAttachments,
    {
      loading: internalWorkItemsAttachmentsLoading,
      data: internalWorkItemsAttachmentsData,
      refetch: internalWorkItemsAttachmentsRefetch,
    },
  ] = useLazyQuery(FETCH_SELECTED_INTERNAL_WORK_ITEM_ATTACHMENTS)

  const activities = useMemo(() => {
    if (fetchFrom === 'compliance') {
      return complianceAttachmentsData?.complianceActivities ?? []
    }
    if (fetchFrom === 'internalWorkItem') {
      return internalWorkItemsAttachmentsData?.internalWorkItems ?? []
    }
  }, [complianceAttachmentsData, internalWorkItemsAttachmentsData])

  useEffect(() => {
    if (open) {
      if (fetchFrom === 'compliance') {
        fetchSelectedComplianceAttachments({
          variables: {
            filters: {
              ids: selectedTasks,
            },
          },
        })
      }
      if (fetchFrom === 'internalWorkItem') {
        fetchSelectedInternalWorkItemsAttachments({
          variables: {
            filters: {
              ids: selectedTasks,
            },
          },
        })
      }
    }
  }, [fetchFrom, selectedTasks, open])

  const fileIdToAttachedFileIds = useMemo(() => {
    return activities
      .flatMap((activity) =>
        activity.attachedFiles.map((attachedFile) => ({
          attachedFileId: attachedFile.id,
          fileId: attachedFile.file.id,
        }))
      )
      .reduce((res, attachedFile) => {
        if (res[attachedFile.fileId]) {
          res[attachedFile.fileId].push(attachedFile.attachedFileId)
        } else {
          res[attachedFile.fileId] = [attachedFile.attachedFileId]
        }
        return res
      }, {})
  }, [activities])

  const overlappingWorkCompletedFiles = useMemo(() => {
    const allFilesFlat = _.chain(activities)
      .flatMap((activity) =>
        activity.attachedFiles.map((attachedFile) => attachedFile.file)
      )
      .sortBy('createdAt')
      .reverse()
      .value()

    const uniqueAllFilesFlat = [...new Set(allFilesFlat)]
    // Get an array of arrays of file IDs
    const allFileIds =
      activities.map((activity) =>
        activity.attachedFiles.map((attachedFile) => attachedFile.file.id)
      ) ?? []

    // Use lodash's _.intersectionBy function to find the common file IDs
    const overlappingFileIds = _.intersectionBy(...allFileIds)

    // Use the overlapping file IDs to get the overlapping files
    const overlappingFiles = uniqueAllFilesFlat.filter((file) =>
      overlappingFileIds.includes(file.id)
    )

    return overlappingFiles
  }, [activities])

  return {
    loading:
      complianceAttachmentsLoading || internalWorkItemsAttachmentsLoading,
    refetch:
      fetchFrom === 'compliance'
        ? complianceAttachmentsRefetch
        : internalWorkItemsAttachmentsRefetch,
    fileIdToAttachedFileIds,
    overlappingWorkCompletedFiles,
  }
}

export default useBulkComplianceEditModalFileUtils
