import { toast } from '@redwoodjs/web/toast'
import { ThreadNotificationData } from 'src/components/Notifications/ThreadNotification'
import ThreadNotificationToast from 'src/components/Notifications/ThreadNotificationToast'

const addNotificationToast = (notification: ThreadNotificationData) => {
  toast.custom(
    <ThreadNotificationToast
      notification={notification}
      onClose={
        () =>
          toast.remove(
            notification.id
          ) /* For some reason, toast.dismiss was not removing the last toast, so I had to use toast.remove */
      }
      isAdmin={false}
    />,
    { id: notification.id }
  )
}

export default addNotificationToast
