import CloseIcon from '@mui/icons-material/Close'
import {
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { Permissions } from '@wingwork/common/src/constants/permissions'

import { navigate, routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

import { useDispatch } from 'src/hooks/useDispatch'
import useHasPermission from 'src/hooks/useHasPermission'
import { useOrgName } from 'src/hooks/useOrgName'
import useQuery from 'src/hooks/useQuery'
import { useSelector } from 'src/hooks/useSelector'
import { closeModal, openModal } from 'src/slices/modal'

import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import Button from '../MUI/Button'

import {
  ADD_MAINTENANCE_ITEMS_TO_PURCHASE_ORDER,
  CREATE_PURCHASE_ORDER,
  GET_PURCHASE_ORDERS_FOR_SELECTOR,
} from './queries'
import useMaintenanceItemTable, {
  TableVariant,
} from 'src/hooks/useMaintenanceItemTable'

interface AddMtxItemsToPurchaseOrderModalProps {
  tableVariant: TableVariant
  selected: string[]
  discriminator: 'dueList'
}

const modalName = 'addMtxItemsToPurchaseOrderModal'

const AddMtxItemsToPurchaseOrderModal: React.FC<
  AddMtxItemsToPurchaseOrderModalProps
> = ({ selected, discriminator, tableVariant }) => {
  const dispatch = useDispatch()
  const open =
    useSelector((state) => state.modal.modals?.[modalName]?.isOpen) ?? false
  const handleClose = () => {
    dispatch(closeModal({ name: modalName }))
  }

  const orgName = useOrgName()
  const canCreate = useHasPermission(Permissions.purchaseOrder.create)
  const canAddToPurchaseOrder = useHasPermission(
    Permissions.purchaseOrder.addItems
  )

  const [addToPurchaseOrder] = useMutation(
    ADD_MAINTENANCE_ITEMS_TO_PURCHASE_ORDER
  )
  const [createPurchaseOrder] = useMutation(CREATE_PURCHASE_ORDER)
  const { data } = useQuery(GET_PURCHASE_ORDERS_FOR_SELECTOR, {
    variables: {
      orgSlug: orgName,
    },
  })
  const { purchaseOrders } = data ?? {}

  const { unselectAll, selectedInProgressItems } =
    useMaintenanceItemTable(tableVariant)

  const onCompleted = ({ purchaseOrder }) => {
    navigate(routes.purchaseOrder({ orgName: orgName, id: purchaseOrder.id }))
  }

  const handlePurchaseOrderItems = (id?: string) => {
    const itemsToAdd =
      discriminator === 'dueList'
        ? // Remove inProgress tasks from selected items
          selected.filter((itemId) => !selectedInProgressItems[itemId])
        : selected

    if (id) {
      addToPurchaseOrder({
        variables: {
          purchaseOrderId: id,
          maintenanceItemIds: itemsToAdd,
        },
        onCompleted: (data) => {
          onCompleted({
            purchaseOrder: data?.addMaintenanceItemsToPurchaseOrder,
          })
          if (discriminator === 'dueList') unselectAll()
        },
      })
    } else {
      createPurchaseOrder({
        variables: {
          maintenanceItemIds: itemsToAdd,
        },
        onCompleted: (data) => {
          onCompleted({
            purchaseOrder: data?.createPurchaseOrder,
          })
          if (discriminator === 'dueList') unselectAll()
        },
      })
    }
    handleClose()
  }

  const handleItemClick = (id?: string) => {
    if (
      discriminator === 'dueList' &&
      Object.keys(selectedInProgressItems).length > 0
    ) {
      const modalToOpen = id
        ? `inProgressPO${id}`
        : 'inProgressNewPurchaseOrder'
      dispatch(
        openModal({
          name: 'confirmationModal',
          data: { discriminator: modalToOpen },
        })
      )
    } else {
      handlePurchaseOrderItems(id)
    }
  }

  const selectedItemsString =
    discriminator === 'dueList'
      ? Object.values(selectedInProgressItems)
          .map(
            (item) => `${item.ataCode} ${item.manufactureCode}: ${item.title}`
          )
          .join('\n')
      : ''

  const isThereNewTasks =
    discriminator === 'dueList'
      ? selected.length !== Object.keys(selectedInProgressItems).length
      : true
  return (
    <Modal open={open} onClose={handleClose}>
      <Paper className="absolute left-1/2 top-1/2 w-80 -translate-x-1/2 -translate-y-1/2 transform rounded-xl bg-white p-3">
        <div className="flex items-center justify-between">
          <Typography variant="h6">
            Add Maintenance Items to Purchase Order
          </Typography>
          <IconButton className="" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        {purchaseOrders && purchaseOrders.length > 0 ? (
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>PO Name</TableCell>
                  <TableCell>Vendor</TableCell>
                  <TableCell>Number of Tasks</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {purchaseOrders.map((order) => (
                  <TableRow key={order.id}>
                    <TableCell>{order.number}</TableCell>
                    <TableCell>{order.vendor?.name || 'N/A'}</TableCell>
                    <TableCell>
                      {
                        order.purchaseOrderItem.filter(
                          (item) => item.type === 'SERVICE'
                        ).length
                      }
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => handleItemClick(order.id)}
                        disabled={!canAddToPurchaseOrder}
                        disabledTooltip={
                          'You do not have permissions to perform this action.'
                        }
                      >
                        Add to this PO
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <div className="mt-4">
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleItemClick()}
                disabled={!canCreate}
                disabledTooltip={
                  'You do not have permissions to perform this action.'
                }
              >
                Create New Purchase Order
              </Button>
            </div>
          </>
        ) : (
          <div className="py-4 text-center">
            <p className="mb-4">No existing Purchase Orders.</p>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleItemClick()}
              disabled={!canCreate}
              disabledTooltip={
                'You do not have permissions to perform this action.'
              }
            >
              Create New Purchase Order
            </Button>
          </div>
        )}
        <ConfirmationModal
          title="Continue to new Purchase Order"
          discriminator="inProgressNewPurchaseOrder"
          richMessage={
            isThereNewTasks
              ? `The following in-progress tasks will not be added to the purchase order:\n\n\n${selectedItemsString}`
              : `All selected tasks are in progress, no new tasks will be added to the purchase order.`
          }
          confirmText="Continue to Purchase Order"
          denyText="Cancel"
          confirmNote={
            isThereNewTasks ? 'Continue without the in-progress tasks.' : ''
          }
          onConfirm={handlePurchaseOrderItems}
        />
        {purchaseOrders?.map((order) => (
          <>
            <ConfirmationModal
              title="Continue to Purchase Order"
              discriminator={`inProgressPO${order.id}`}
              richMessage={
                isThereNewTasks
                  ? `The following in-progress tasks will not be added to the purchase order:\n${selectedItemsString}`
                  : `All selected tasks are in progress, no new tasks will be added to the purchase order.`
              }
              confirmText={'Continue to Purchase Order'}
              denyText="Cancel"
              confirmNote={
                isThereNewTasks ? 'Continue without the in-progress tasks.' : ''
              }
              onConfirm={() => handlePurchaseOrderItems(order.id)}
            />
          </>
        ))}
      </Paper>
    </Modal>
  )
}

export default AddMtxItemsToPurchaseOrderModal
