import React, { useEffect } from 'react'

import { Typography } from '@mui/material'
import { InternalWorkItem } from 'types/graphql'

import { Form, useForm } from '@redwoodjs/forms'

import ComplianceTimes from 'src/components/Compliance/FormSections/ComplianceTimes'
import TextField from 'src/components/MUI/TextField'
import { FormSubmitRef } from 'src/types'
import useHasPermission from 'src/hooks/useHasPermission'
import { Permissions } from '@wingwork/common/src/constants/permissions'
import { useSelector } from 'src/hooks/useSelector'
interface ItemInformationFormProps {
  workItem: InternalWorkItem
  handleSubmit: (values: any) => Promise<void>
  onError: (formName: string, errors: any) => void
}

const ItemInformationForm = React.forwardRef<
  FormSubmitRef,
  ItemInformationFormProps
>(({ workItem, handleSubmit, onError }, ref) => {
  const formMethods = useForm({
    defaultValues: {
      workItem: {
        description: workItem?.description,
        correctiveAction: workItem?.correctiveAction,
        notes: workItem?.notes,
      },
      nextDue: workItem?.nextDue ?? {},
    },
  })

  React.useImperativeHandle(ref, () => ({
    handleSubmit: async () => {
      let hasError = false
      await formMethods.handleSubmit(handleSubmit, (errors) => {
        hasError = true
        onError('workInformation', errors)
      })()
      return !hasError
    },
    isDirty: Object.keys(formMethods.formState.dirtyFields).length > 0,
  }))

  useEffect(() => {
    formMethods.reset({
      workItem: {
        description: workItem?.description,
        correctiveAction: workItem?.correctiveAction,
        notes: workItem?.notes,
      },
      nextDue: workItem?.nextDue ?? {},
    })
  }, [workItem, formMethods])

  const aircraftId = useSelector((state) => state.auditLog.aircraftId)
  const canUpdateWorkOrder = useHasPermission(
    Permissions.workOrder.update,
    aircraftId
  )

  return (
    <div className="p-3">
      {/* TODO - Add compliance times info readonly */}

      <Form formMethods={formMethods} onSubmit={handleSubmit}>
        <ComplianceTimes
          task={workItem}
          workFlow="internalWorkItem"
          disableEdit={!canUpdateWorkOrder}
        />
        <Typography variant="subtitle1" fontWeight="bold" className="mt-2">
          Work Information
        </Typography>
        <div className={'mt-2 grid grid-cols-2 gap-2'}>
          <TextField
            name="workItem.description"
            label="Description"
            multiline
            rows={4}
            disabled={!canUpdateWorkOrder}
          />
          <TextField
            name="workItem.correctiveAction"
            label="Corrective Action"
            multiline
            rows={4}
            disabled={!canUpdateWorkOrder}
          />
          <TextField
            name="workItem.notes"
            label="Task Notes"
            multiline
            rows={4}
            disabled={!canUpdateWorkOrder}
          />
        </div>
      </Form>
    </div>
  )
})

export default ItemInformationForm
