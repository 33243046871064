import React, { useCallback, useEffect, useMemo } from 'react'

import CheckIcon from '@mui/icons-material/Check'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import { Button, Chip, Paper, Typography } from '@mui/material'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { get } from 'lodash'
import { ComplianceActivity, MaintenanceItem } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'

import ApiError from 'src/components/ApiError'
import Loading from 'src/components/Loading'
import ReusableTimeline, {
  ReusableTimelineItem,
} from 'src/components/MUI/Timeline'
import useComplianceActivitiesRecords from 'src/hooks/requests/useComplianceActivitiesRecords'
import { useOrgName } from 'src/hooks/useOrgName'
import { formatDateForDisplay } from 'src/utils/helpers'
import {
  MaterialReactTable,
  MRT_ColumnDef,
  useMaterialReactTable,
} from 'material-react-table'
import { getDefaultMRTOptions } from 'src/utils/getDefaultMRTOptions'

interface WorkCompletedTabProps {
  parentTask: MaintenanceItem
}

const WorkCompletedTab: React.FC<WorkCompletedTabProps> = ({ parentTask }) => {
  // Memoized function to find usage log
  const findUsageLog = useCallback(
    (complianceLedger: ComplianceActivity['complianceLedger']) => {
      return complianceLedger?.aircraftUsageLog?.ComponentUsageLog?.find(
        (l) => l.componentId === parentTask?.trackedByComponentId
      )
    },
    [parentTask?.trackedByComponentId]
  )

  dayjs.extend(relativeTime)
  const { complianceActivities, loadComplianceActivities, loading, error } =
    useComplianceActivitiesRecords()

  const orgName = useOrgName()

  useEffect(() => {
    if (parentTask === undefined) return
    loadComplianceActivities({
      maintenanceItemIds: [parentTask.id],
      showCompletedOnly: true,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentTask])

  const defaultTableOptions = getDefaultMRTOptions<ComplianceActivity>()

  const columns = useMemo<MRT_ColumnDef<ComplianceActivity>[]>(
    () => [
      {
        header: 'Completion',
        accessorFn: (row) =>
          formatDateForDisplay(row?.complianceLedger?.timestamp),
        size: 120,
      },
      {
        header: 'Log Date',
        accessorFn: (row) =>
          formatDateForDisplay(findUsageLog(row.complianceLedger)?.usageAsOf),
        size: 120,
      },
      {
        header: 'Hours',
        accessorFn: (row) =>
          findUsageLog(row.complianceLedger)?.totalTimeSinceNew ?? '-',
        size: 100,
      },
      {
        header: 'Landings',
        accessorFn: (row) =>
          findUsageLog(row.complianceLedger)?.cycleSinceNew ?? '-',
        size: 100,
      },
      {
        header: 'Removed Part',
        accessorFn: (row) => row.partsTransaction?.removed?.partNumber ?? 'N/A',
        size: 150,
      },
      {
        header: 'Removed Serial',
        accessorFn: (row) =>
          row.partsTransaction?.removed?.serialNumber ?? 'N/A',
        size: 150,
      },
    ],
    [findUsageLog, complianceActivities]
  )

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    columns,
    data: complianceActivities,
    enableStickyHeader: true,
    enableTopToolbar: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: true,
    enableSorting: false,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        size: 180,
        header: '',
        grow: false,
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () =>
        navigate(
          routes.bulkComplianceV2({
            id: row.original.complianceLedger?.id,
            orgName,
          })
        ),
      sx: { cursor: 'pointer' },
    }),
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        border: '1px solid rgba(224, 224, 224, 1)',
        borderRadius: '8px',
        width: '100%',
      },
    },
    muiTableContainerProps: {
      sx: { overflow: 'auto' },
    },
    initialState: { density: 'comfortable' },
  })

  if (loading) return <Loading />

  if (error) return <ApiError />

  if (complianceActivities.length === 0) {
    return (
      <div className="flex items-center justify-center p-5">
        <Typography variant="body2">
          No work has been completed for this task.
        </Typography>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-2 px-3 py-1">
      <MaterialReactTable table={table} />
    </div>
  )
}

export default WorkCompletedTab
