import CustomWorkItemAttachments from './CustomWorkItemAttachments'
import WorkItemAttachments from './WorkItemAttachments'

const AttachmentsSection = ({
  workItemId,
  isCustomWorkItem = false,
  flashSaved,
  flashError,
}: {
  workItemId: string
  isCustomWorkItem: boolean
  flashSaved?: (text?: string) => void
  flashError?: (text?: string) => void
}) => {
  if (isCustomWorkItem) {
    return (
      <CustomWorkItemAttachments
        workItemId={workItemId}
        flashSaved={flashSaved}
        flashError={flashError}
      />
    )
  }

  return (
    <WorkItemAttachments
      workItemId={workItemId}
      flashSaved={flashSaved}
      flashError={flashError}
    />
  )
}

export default AttachmentsSection
