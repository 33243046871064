import React from 'react'
import WorkflowHeader from 'src/components/WorkflowHeader'
import { Chip } from '@mui/material'
import { openModal } from 'src/slices/modal'
import { useDispatch } from 'src/hooks/useDispatch'
import Button from 'src/components/MUI/Button'
import { GET_WORK_ORDER_BASE_FIELDS } from 'src/pages/WorkOrderCompliancePageV2/queries'
import useQuery from 'src/hooks/useQuery'
import { useMutation } from '@redwoodjs/web'
import { useOrgName } from 'src/hooks/useOrgName'
import { toast } from '@redwoodjs/web/toast'
import { navigate, routes } from '@redwoodjs/router'
import { CREATE_INVOICE } from '../queries'
import useUserId from 'src/hooks/useUserId'
import { statusMap } from 'src/pages/PastCompliancePage/PastComplianceListDisplay/PastComplianceTable'
import { ComplianceStatus } from 'types/graphql'
import { t } from 'i18next'
import { getComplianceLedgerStatusText, isUserRequestedAsNextSigner } from 'src/components/ViewLogbookEntryModal/helper'

interface WorkOrderCompletedHeaderProps {
  id: string
  createdLogbook: boolean
  ledgerStatus: ComplianceStatus
}

export const WorkOrderCompletedHeader: React.FC<
  WorkOrderCompletedHeaderProps
> = ({ id, createdLogbook, ledgerStatus }) => {
  const { data } = useQuery(GET_WORK_ORDER_BASE_FIELDS, {
    variables: { id },
    fetchPolicy: 'cache-first',
  })
  const [createInvoice] = useMutation(CREATE_INVOICE)
  const dispatch = useDispatch()
  const orgName = useOrgName()

  const { id: currentUserId, hasLoaded: currentUserIdHasLoaded } = useUserId()

  const userRequestedAsNextSigner = isUserRequestedAsNextSigner(
    currentUserId,
    data?.internalWorkOrder?.complianceLedger
  )

  return (
    <WorkflowHeader
      title={data?.internalWorkOrder?.workOrderNumber}
      createdAt={data?.internalWorkOrder?.createdAt}
      tailNumber={data?.internalWorkOrder?.aircraft?.tailNumber}
      slots={{
        statusChip: (
          <Chip
            className="px-1"
            size="small"
            variant="filled"
            label={getComplianceLedgerStatusText(
              ledgerStatus,
              currentUserId,
              data?.internalWorkOrder?.complianceLedger
            )}
            color={statusMap[ledgerStatus]?.color ?? 'success'}
            icon={statusMap[ledgerStatus]?.icon}
          />
        ),
        topRightFields: (
          <>
            {/* <Button
              variant="outlined"
              color="base"
              disabled
              disabledTooltip="available soon"
            >
              ERROR CORRECTION
            </Button> */}
            {data?.internalWorkOrder?.invoice?.id ? (
              <Button
                variant="outlined"
                color="base"
                onClick={() => {
                  navigate(
                    routes.invoice({
                      orgName,
                      id: data?.internalWorkOrder?.invoice?.id,
                    })
                  )
                }}
              >
                VIEW INVOICE
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="base"
                onClick={() => {
                  createInvoice({
                    variables: {
                      input: {
                        orgSlug: orgName,
                        internalWorkOrderId: id,
                      },
                    },
                    onCompleted: (data) => {
                      toast.success('Invoice created')
                      navigate(
                        routes.invoice({
                          orgName,
                          id: data.createInvoice.id,
                        })
                      )
                    },
                  })
                }}
              >
                GENERATE INVOICE
              </Button>
            )}
            {createdLogbook ? (
              <Button
                variant={
                  ledgerStatus === 'SIGN' && userRequestedAsNextSigner
                    ? 'contained'
                    : 'outlined'
                }
                color="primary"
                onClick={() => {
                  dispatch(openModal({ name: 'viewLogbookEntryModal' }))
                }}
              >
                {ledgerStatus === 'SIGN' && userRequestedAsNextSigner
                  ? 'SIGN LOGBOOK ENTRY'
                  : 'VIEW LOGBOOK ENTRY'}
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  dispatch(
                    openModal({
                      name: 'createLogbookEntryModal',
                    })
                  )
                }}
              >
                PUBLISH LOGBOOK ENTRY
              </Button>
            )}
          </>
        ),
      }}
    />
  )
}

export default WorkOrderCompletedHeader
