import {
  MECHANIC_FRAGMENT,
  customInternalWorkItemFragment,
  internalWorkItemFragment,
  internalWorkOrderFragment,
  internalWorkOrderMaintenanceItemFragment,
} from './fragments'

export const GET_WORK_ORDER_BY_ID = gql`
  ${internalWorkOrderFragment}
  query GetWorkOrderByIdForCreateWorkOrderPage($id: String!) {
    internalWorkOrder(id: $id) {
      ...InternalWorkOrderFragment
      aircraft {
        id
        tailNumber
      }
      attachedFiles {
        id
        file {
          id
          originalFilename
          url
          title
        }
      }
    }
  }
`

export const ADD_MAINTENANCE_ITEMS_TO_WORK_ORDER = gql`
  ${internalWorkItemFragment}
  mutation AddMaintenanceItemsToWorkOrder(
    $workOrderId: String!
    $ids: [String]
  ) {
    addMaintenanceItemsToInternalWorkOrder(
      input: { internalWorkOrderId: $workOrderId, ids: $ids }
    ) {
      id
      workItems {
        id
        ...InternalWorkItemFragment
      }
    }
  }
`

export const GET_WORK_ITEMS_BY_WORK_ORDER_ID = gql`
  ${internalWorkItemFragment}
  ${internalWorkOrderMaintenanceItemFragment}
  query getWorkItemsByWorkOrderId(
    $filters: InternalWorkItemFilter
    $sortField: String
    $sortOrder: String
  ) {
    internalWorkItems(
      filters: $filters
      sorting: { sortField: $sortField, sortOrder: $sortOrder }
    ) {
      id
      ...InternalWorkItemFragment
      maintenanceItem {
        ...InternalWorkOrderMaintenanceItemFragment
      }
    }
  }
`

export const GET_CUSTOM_WORK_ITEMS_BY_WORK_ORDER_ID = gql`
  ${customInternalWorkItemFragment}
  query getCustomWorkItemsByWorkOrderId(
    $filters: CustomInternalWorkItemFilter
    $sortField: String
    $sortOrder: String
  ) {
    customInternalWorkItems(
      filters: $filters
      sorting: { sortField: $sortField, sortOrder: $sortOrder }
    ) {
      id
      ...CustomInternalWorkItemFragment
    }
  }
`

export const GET_MECHANICS_QUERY = gql`
  ${MECHANIC_FRAGMENT}
  query GetMechanics($orgSlug: String!) {
    users(filter: { orgSlug: $orgSlug }) {
      ...UserFragment
    }
  }
`

export const UPDATE_WORK_ITEM_MUTATION = gql`
  ${internalWorkItemFragment}
  mutation UpdateWorkItemMutation(
    $id: String!
    $input: UpdateInternalWorkItemInput!
  ) {
    updateInternalWorkItem(id: $id, input: $input) {
      ...InternalWorkItemFragment
    }
  }
`

export const UPDATE_CUSTOM_WORK_ITEM_MUTATION = gql`
  ${customInternalWorkItemFragment}
  mutation UpdateCustomWorkItemMutation(
    $id: String!
    $input: UpdateCustomInternalWorkItemInput!
  ) {
    updateCustomInternalWorkItem(id: $id, input: $input) {
      ...CustomInternalWorkItemFragment
    }
  }
`

export const UPDATE_WORK_ORDER_MUTATION = gql`
  ${internalWorkOrderFragment}
  mutation UpdateWorkOrderForm(
    $id: String!
    $input: UpdateInternalWorkOrderInput!
  ) {
    updateInternalWorkOrder(id: $id, input: $input) {
      ...InternalWorkOrderFragment
    }
  }
`
