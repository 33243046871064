import { Fade, Modal as MUIModal, Paper } from '@mui/material'
import clsx from 'clsx'

import useModal from 'src/hooks/useModal'
import { ModalName } from 'src/slices/modal'

import { ModalProvider } from './ModalProvider'

type ModalProps = {
  children: React.ReactNode
  onBail?: () => void
  disableBail?: boolean
  modalName: ModalName
  classes?: {
    paper?: string
  }
}

const Modal: React.FC<ModalProps> = ({
  children,
  onBail,
  modalName,
  disableBail = false,
  classes = {},
}) => {
  const { isOpen, handleClose, zIndex } = useModal(modalName)

  // DX is important 🫂
  if (process.env.NODE_ENV === 'development' && disableBail && onBail) {
    console.warn(
      'The `onBail` prop is set but the `disableBail` prop is also set to `true`. The `onBail` callback will never be called.'
    )
  }

  const handleBail = () => {
    handleClose()
    onBail?.()
  }

  return (
    <ModalProvider modalName={modalName}>
      <MUIModal
        open={isOpen}
        onClose={!disableBail && handleBail}
        aria-labelledby={`modal-${modalName}-title`}
        disablePortal
        closeAfterTransition
        style={{ zIndex }}
      >
        <Fade in={isOpen}>
          <Paper
            className={clsx(
              'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform rounded-xl',
              classes?.paper
            )}
          >
            {children}
          </Paper>
        </Fade>
      </MUIModal>
    </ModalProvider>
  )
}

export default Modal
