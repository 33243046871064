import CloseIcon from '@mui/icons-material/Close'
import { Typography } from '@mui/material'
import clsx from 'clsx'

import useModal from 'src/hooks/useModal'

import IconButton from '../MUI/IconButton'

import { useModalName } from './ModalProvider'

type ModalHeaderProps = {
  title: string
  hideCloseButton?: boolean
  onClose?: () => void
  className?: string
  classes?: {
    root?: string
    title?: string
  }
}

const ModalHeader: React.FC<ModalHeaderProps> = ({
  title,
  className,
  classes = {},
  onClose,
  hideCloseButton = false,
}) => {
  const modalName = useModalName()
  const { handleClose } = useModal(modalName)

  const handleCloseClick = () => {
    handleClose()
    onClose?.()
  }

  return (
    <div
      className={clsx(
        'mx-3 mb-2 mt-3 flex items-center justify-between',
        classes?.root,
        className
      )}
    >
      <Typography
        id={`modal-${modalName}-title`}
        variant="h6"
        component="h2"
        className={clsx(classes?.title)}
      >
        {title}
      </Typography>

      {!hideCloseButton && (
        <IconButton onClick={handleCloseClick}>
          <CloseIcon />
        </IconButton>
      )}
    </div>
  )
}

export default ModalHeader
