import { Typography, Skeleton, Tooltip } from '@mui/material'
import IconButton from '../IconButton'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'

interface DrawerHeaderProps {
  title?: string
  loading?: boolean
  onBack?: () => void
  onNext?: () => void
  onClose?: () => void
  showNextItemActions?: boolean
  actions?: React.ReactNode
}

const DrawerHeader: React.FC<DrawerHeaderProps> = ({
  title,
  onBack,
  onNext,
  onClose,
  showNextItemActions = false,
  actions,
  loading = false,
}) => {
  return (
    <div className="flex items-center justify-between border-0 border-b border-solid border-black border-opacity-10 px-3 py-2">
      {loading ? (
        <Skeleton variant="text" width={200} height="2.5rem" />
      ) : title ? (
        <div className="mr-4 min-w-0 flex-1">
          <Tooltip title={title} arrow>
            <Typography variant="h6" noWrap>
              {loading ? <Skeleton width={200} /> : title}
            </Typography>
          </Tooltip>
        </div>
      ) : (
        <div />
      )}
      <div className="flex items-center gap-1">
        {showNextItemActions && (
          <NextItemActions onBack={onBack} onNext={onNext} />
        )}
        {actions}
        <IconButton onClick={onClose}>
          <CloseRoundedIcon />
        </IconButton>
      </div>
    </div>
  )
}

export const NextItemActions = ({
  onBack,
  onNext,
}: {
  onBack?: () => void
  onNext?: () => void
}) => {
  return (
    <>
      <IconButton onClick={onBack} disabled={!onBack}>
        <ArrowBackIosRoundedIcon />
      </IconButton>
      <IconButton onClick={onNext} disabled={!onNext}>
        <ArrowForwardIosRoundedIcon />
      </IconButton>
    </>
  )
}

export default DrawerHeader
