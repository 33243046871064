import { routes } from '@redwoodjs/router'
import { useBreadcrumbs } from 'src/layouts/components/BreadcrumbsContext'

import WorkOrderHeader from './components/WorkOrderHeader'
import WorkOrderTaskList from './components/WorkOrderTaskList'

const WorkOrderCompliancePageV2Page = ({ orgName, id }) => {
  useBreadcrumbs([
    { icon: 'dueList' },
    { label: 'Due List', to: routes.dueList({ orgName }) },
    { label: 'Work Order' },
  ])

  return (
    <div>
      <WorkOrderHeader id={id} />
      <WorkOrderTaskList id={id} />
    </div>
  )
}

export default WorkOrderCompliancePageV2Page
