export const GET_PURCHASE_ORDERS_FOR_SELECTOR = gql`
  query GetPurchaseOrdersForAddToPurchaseOrderButton {
    purchaseOrders(purchaseOrderFilters: { statuses: [DRAFT] }) {
      id
      status
      number
      vendor {
        id
        name
      }
      purchaseOrderItem {
        id
        type
      }
    }
  }
`

export const CREATE_PURCHASE_ORDER = gql`
  mutation CreatePurchaseOrderForAddToWorkOrderButton(
    $maintenanceItemIds: [String!]!
  ) {
    createPurchaseOrder(maintenanceItemIds: $maintenanceItemIds) {
      id
    }
  }
`

export const ADD_MAINTENANCE_ITEMS_TO_PURCHASE_ORDER = gql`
  mutation AddMaintenanceItemsToPurchaseOrder(
    $purchaseOrderId: String!
    $maintenanceItemIds: [String]
  ) {
    addMaintenanceItemsToPurchaseOrder(
      input: {
        purchaseOrderId: $purchaseOrderId
        maintenanceItemIds: $maintenanceItemIds
      }
    ) {
      id
    }
  }
`
