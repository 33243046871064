import { TextField as MUITextField, Tooltip, TooltipProps } from '@mui/material'
import { get } from 'lodash'

import { Controller, useFormContext, useWatch } from '@redwoodjs/forms'

export interface TextFieldProps
  extends React.ComponentProps<typeof MUITextField> {
  name: string
  label?: string
  helperText?: string
  required?: boolean
  watchValue?: boolean
  disabledTooltip?: string
  TooltipProps?: Partial<TooltipProps>
  renderValue?: (value: string | number) => string
  valueAsNumber?: boolean
}

const TextField: React.FC<TextFieldProps> = ({
  name,
  label,
  renderValue,
  required = false,
  watchValue = true,
  valueAsNumber = false,
  disabledTooltip,
  TooltipProps,
  ...otherProps
}) => {
  // We use the Redwood form context to get form methods
  if (!name) {
    throw new Error(`TextField {label: ${label}} must have a name prop`)
  }

  const {
    control,
    register,
    formState: { errors },
  } = useFormContext()
  const value = useWatch({ control, name })

  const fieldError = get(errors, name)?.message as string | undefined

  let tooltipText = disabledTooltip
  if (otherProps.disabled) {
    tooltipText = disabledTooltip
  }

  const inputLabelProps = {
    shrink:
      value === 0 || !!value || !!otherProps?.slotProps?.input?.startAdornment,
  }

  return watchValue ? (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { value, onChange, ...fieldProps } = field
        const renderedValue = renderValue ? renderValue(value) : value
        return (
          <Tooltip title={tooltipText} {...TooltipProps}>
            <MUITextField
              {...fieldProps}
              value={renderedValue || ''}
              onChange={(e) => {
                const newValue = valueAsNumber
                  ? Number(e.target.value)
                  : e.target.value
                onChange(newValue)
              }}
              fullWidth
              label={label}
              error={Boolean(fieldError)}
              helperText={fieldError}
              required={required}
              {...otherProps}
              slotProps={{
                inputLabel: inputLabelProps,
                ...otherProps.slotProps,
              }}
            />
          </Tooltip>
        )
      }}
      rules={{ required }}
    />
  ) : (
    <Tooltip title={tooltipText} {...TooltipProps}>
      <MUITextField
        {...register(name, {
          required,
          valueAsNumber,
        })}
        fullWidth
        label={label}
        error={Boolean(fieldError)}
        helperText={fieldError}
        required={required}
        {...otherProps}
        slotProps={{
          inputLabel: inputLabelProps,
          ...otherProps.slotProps,
        }}
      />
    </Tooltip>
  )
}

export default TextField
