import { t } from 'i18next'
interface ComplianceLedger {
  workedById?: string
  inspectedById?: string
  requestedWorkerId?: string
  requestedInspectorId?: string
}

export const isUserRequestedAsNextSigner = (
  currentUserId: string,
  complianceLedger: ComplianceLedger
): boolean => {
  if (!complianceLedger || !currentUserId) return false

  const signedByWorker = !!complianceLedger.workedById
  const signedByInspector = !!complianceLedger.inspectedById
  const requestedWorker = complianceLedger.requestedWorkerId

  const userRequestedAsWorker = currentUserId === requestedWorker
  const userRequestedAsInspector =
    currentUserId === complianceLedger.requestedInspectorId

  return (
    (userRequestedAsWorker && !signedByWorker) ||
    (userRequestedAsInspector &&
      (signedByWorker || !requestedWorker) &&
      !signedByInspector)
  )
}

export const getComplianceLedgerStatusText = (
  status: string,
  currentUserId: string,
  complianceLedger: ComplianceLedger
): string => {
  if (status === 'SIGN') {
    const userRequestedAsNextSigner = isUserRequestedAsNextSigner(
      currentUserId,
      complianceLedger
    )
    return userRequestedAsNextSigner ? 'Sign now' : 'Signature requested'
  }

  return t(`complianceLedger.status.${status}`, {
    defaultValue: status ?? 'Unknown',
  })
}
