import { useLazyQuery } from '@apollo/client'
import { useEffect } from 'react'
import { Autocomplete, Checkbox, TextField } from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import { GET_VENDOR_OPTIONS } from './queries'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

interface Vendor {
  id: string
  name: string
}

interface VendorSelectorProps<T extends FieldValues> {
  name: Path<T>
  control: Control<T>
  label?: string
  multi?: boolean
  limitTags?: number
}

const VendorSelector = <T extends FieldValues>({
  name,
  control,
  label,
  limitTags = 1,
  multi = false,
  className,
}: VendorSelectorProps<T>) => {
  const defaultLabel = multi ? 'Vendors' : 'Vendor'
  const [loadVendors, { data, loading, error }] =
    useLazyQuery(GET_VENDOR_OPTIONS)
  const vendors = data?.vendors || []
  const vendorsLoaded = vendors.length > 0

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value },
        fieldState: { error: fieldError },
      }) => {
        useEffect(() => {
          // if we have an initial value from react-hook-form, we need to load the vendors
          const hasInitialValue = multi
            ? (value as string[])?.length > 0
            : !!value

          if (hasInitialValue && !vendorsLoaded && !loading) {
            loadVendors()
          }
        }, [value, vendorsLoaded, loading])

        // Convert incoming form value(s) to full vendor object(s)
        const selectedVendors = multi
          ? vendors.filter((v) => ((value as string[]) ?? []).includes(v.id))
          : vendors.find((v) => v.id === value) || null

        return (
          <Autocomplete
            multiple={multi}
            limitTags={limitTags}
            className={className}
            options={vendors}
            loading={loading}
            disableCloseOnSelect={multi}
            getOptionLabel={(option: Vendor) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={selectedVendors}
            onChange={(_, newValue) => {
              // Transform the value back to just ID(s)
              const transformedValue = multi
                ? (newValue as Vendor[]).map((v) => v.id)
                : (newValue as Vendor)?.id || null
              onChange(transformedValue)
            }}
            renderOption={
              multi
                ? (props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )
                : undefined
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={label || defaultLabel}
                error={!!fieldError || !!error}
                helperText={
                  fieldError?.message ||
                  (error ? 'Failed to load vendors' : undefined)
                }
              />
            )}
            onOpen={() => {
              if (!vendorsLoaded) {
                loadVendors()
              }
            }}
          />
        )
      }}
    />
  )
}

export default VendorSelector
