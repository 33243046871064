import React, { useEffect, useMemo } from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import { get } from 'lodash'
import { Aircraft, MaintenanceItem } from 'types/graphql'

import ApiError from 'src/components/ApiError'
import PrimSecTextCombo from 'src/components/common/PrimSecTextCombo'
import DueStatusChip from 'src/components/DueStatusChip/DueStatusChip'
import Loading from 'src/components/Loading'
import {
  CADENCE_TYPE_TO_INTERVAL_KEY,
  CADENCE_TYPE_TO_LABEL,
} from 'src/constants'
import useDuelistRecords from 'src/hooks/requests/useDuelistRecords'
import { capitalizeFirstLetter } from 'src/utils/helpers'
import {
  MaterialReactTable,
  MRT_ColumnDef,
  useMaterialReactTable,
} from 'material-react-table'
import { getDefaultMRTOptions } from 'src/utils/getDefaultMRTOptions'
import useDueStatus from 'src/hooks/useDueStatus'
import {
  getNextDueString,
  getLastComplianceTimeString,
} from '@wingwork/common/src/maintenanceItem'
import { formatDateForDisplayInUtc } from 'src/utils/helpers'
import { MultiLineTypography } from 'src/components/MUI/StyledComponents'
import { navigate, routes } from '@redwoodjs/router'
import { useOrgName } from 'src/hooks/useOrgName'
import TypeAndDescriptionDisplay from 'src/components/TypeAndDescriptionDisplay'
import IconButton from 'src/components/MUI/IconButton'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined'
import AtaCodeCell from '../AtaCodeCell'

const defaultTableOptions = getDefaultMRTOptions<MaintenanceItem>()

interface ChildTasksTabProps {
  parentTask: MaintenanceItem
  aircraft: Aircraft
}

const ChildTasksTab: React.FC<ChildTasksTabProps> = ({
  parentTask,
  aircraft,
}) => {
  const orgName = useOrgName()
  const { loadMaintenanceItems, maintenanceItems, loading, error } =
    useDuelistRecords()

  useEffect(() => {
    if (parentTask === undefined) return
    loadMaintenanceItems({
      parentId: parentTask.id,
      isChildItem: true,
      onlyDueItems: false,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentTask])

  const columns = useMemo<MRT_ColumnDef<MaintenanceItem>[]>(
    () => [
      {
        accessorKey: 'ataCode',
        header: 'ATA Code',
        minSize: 20,
        Cell: ({ row }) => <AtaCodeCell row={row.original} />,
      },
      {
        accessorKey: 'title',
        header: 'Description',
        minSize: 200,
        Cell: ({ row }) => (
          <TypeAndDescriptionDisplay description={row.original.title} highlight />
        ),
      },
      {
        accessorKey: 'lastComplianceDate',
        header: 'Compliance',
        minSize: 20,
        Cell: ({ row }) => {
          const lastComplianceTimeArray = getLastComplianceTimeString(
            row.original
          )
          return (
            <MultiLineTypography>
              {lastComplianceTimeArray.map((val, index) => (
                <Typography key={index} variant="body2">
                  {val}
                </Typography>
              ))}
            </MultiLineTypography>
          )
        },
      },
      {
        accessorKey: 'interval',
        Cell: ({ row }) => {
          const { intervals, tolerances } = useDueStatus(row.original, aircraft)

          return (
            <MultiLineTypography>
              {intervals.length > 0 ? (
                intervals.map((item, index) => {
                  return (
                    <div className="flex items-center gap-1">
                      <Typography key={item} variant="body2">
                        {item}
                      </Typography>
                      {tolerances[index] && (
                        <Typography variant="caption">
                          + {tolerances[index]}
                        </Typography>
                      )}
                    </div>
                  )
                })
              ) : (
                <Typography> ---- </Typography>
              )}
            </MultiLineTypography>
          )
        },
        header: 'Interval',
        minSize: 20,
      },
      {
        accessorKey: 'calculatedNextDueAt',
        header: 'Next Due',
        minSize: 20,
        Cell: ({ row }) => {
          const projectedDate = formatDateForDisplayInUtc(
            get(row.original, 'calculatedNextDueAt')
          )
          return (
            <Tooltip title={`Projected Date: ${projectedDate}`}>
              <MultiLineTypography>
                {getNextDueString(row.original).length > 0 ? (
                  getNextDueString(row.original).map((item) => {
                    return (
                      <Typography key={item} variant="body2">
                        {item}
                      </Typography>
                    )
                  })
                ) : (
                  <Typography> ---- </Typography>
                )}
              </MultiLineTypography>
            </Tooltip>
          )
        },
      },
      {
        accessorKey: 'remaining',
        header: 'Remaining',
        minSize: 20,
        Cell: ({ row }) => (
          <DueStatusChip maintenanceItem={row.original} aircraft={aircraft} />
        ),
      },
      {
        accessorKey: 'actions',
        grow: false,
        size: 5,
        muiTableHeadCellProps: {
          align: 'right',
        },
        header: '',
        Cell: ({ row }) => (
          <IconButton
            id={`open-detail-page-button-${row.original.id}`}
            tooltip="Open detail page"
            onClick={() =>
              navigate(
                routes.maintenanceItemPageV2({
                  id: row.original.id,
                  orgName,
                })
              )
            }
          >
            <ArrowForwardOutlinedIcon />
          </IconButton>
        ),
      },
    ],
    [aircraft]
  )

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    columns,
    data: maintenanceItems,
    enableStickyHeader: true,
    enableTopToolbar: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: true,
    enableSorting: false,
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        border: '1px solid rgba(224, 224, 224, 1)',
        borderRadius: '8px',
        width: '100%',
      },
    },
    state: {
      columnPinning: {
        left: ['ataCode'],
        right: ['remaining', 'actions'],
      },
    },
  })

  if (loading) return <Loading />

  if (error) return <ApiError />

  if (maintenanceItems.length === 0)
    return (
      <div className="flex items-center justify-center p-5">
        <Typography variant="body2">
          No child tasks associated with this task
        </Typography>
      </div>
    )

  return (
    <div className="px-3 pt-1">
      <MaterialReactTable table={table} />
    </div>
  )
}

export default ChildTasksTab
