const PURCHASE_ORDER_ITEM_FRAGMENT = gql`
  fragment PurchaseOrderItemFields on PurchaseOrderItem {
    id
    type
    partNumber
    ataCode
    description
    condition
    quantity
    cost
    requestedBy {
      id
      firstName
      lastName
    }
  }
`

export const GET_PURCHASE_ORDER = gql`
  ${PURCHASE_ORDER_ITEM_FRAGMENT}
  query GetPurchaseOrder($id: String!) {
    purchaseOrder(id: $id) {
      id
      status
      number
      vendorId
      vendor {
        id
        name
      }
      paymentTerms
      authorizedBy {
        id
        firstName
        lastName
      }
      shippingAddressId
      shippingAddress {
        id
        addressLine1
        addressLine2
        city
        state
        postalCode
        country
      }
      billingAddressId
      billingAddress {
        id
        addressLine1
        addressLine2
        city
        state
        postalCode
        country
      }
      requestedDeliveryDate
      shippingMethodId
      shippingMethod {
        id
        name
        accountNumber
      }
      shippingInstructions
      purchaseOrderItem {
        ...PurchaseOrderItemFields
      }
    }
  }
`

export const GET_PURCHASE_ORDER_STATUS = gql`
  query GetPurchaseOrderStatus($id: String!) {
    purchaseOrder(id: $id) {
      id
      status
    }
  }
`

export const GET_VENDORS = gql`
  query GetVendors {
    vendors {
      id
      name
    }
  }
`

export const UPDATE_PURCHASE_ORDER_MUTATION = gql`
  mutation UpdatePurchaseOrder(
    $id: String!
    $input: UpdatePurchaseOrderInput!
  ) {
    updatePurchaseOrder(id: $id, input: $input) {
      id
      status
      complianceLedger {
        id
        status
        aircraft {
          id
          tailNumber
        }
      }
    }
  }
`

export const MULTI_UPDATE_PURCHASE_ORDER_ITEM = gql`
  mutation MultiUpdatePurchaseOrderItem(
    $input: [MultiUpdatePurchaseOrderItemInput]!
  ) {
    multiUpdatePurchaseOrderItem(input: $input) {
      id
    }
  }
`

export const REMOVE_ITEM_FROM_PURCHASE_ORDER = gql`
  mutation RemoveItemFromPurchaseOrder($id: String!) {
    deletePurchaseOrderItem(id: $id) {
      id
    }
  }
`

export const CANCEL_PURCHASE_ORDER = gql`
  mutation CancelPurchaseOrder($id: String!) {
    cancelPurchaseOrder(id: $id) {
      id
      status
    }
  }
`

export const CREATE_AND_ADD_PART_TO_PURCHASE_ORDER = gql`
  ${PURCHASE_ORDER_ITEM_FRAGMENT}
  mutation CreateAndAddPartToPurchaseOrder(
    $input: RequestPurchaseOrderPartInput!
    $purchaseOrderId: String!
  ) {
    createAndAddPartToPurchaseOrder(
      input: $input
      purchaseOrderId: $purchaseOrderId
    ) {
      ...PurchaseOrderItemFields
    }
  }
`

export const CREATE_AND_ADD_SERVICE_TO_PURCHASE_ORDER = gql`
  ${PURCHASE_ORDER_ITEM_FRAGMENT}
  mutation CreateAndAddServiceToPurchaseOrder(
    $input: RequestPurchaseOrderServiceInput!
    $purchaseOrderId: String!
  ) {
    createAndAddServiceToPurchaseOrder(
      input: $input
      purchaseOrderId: $purchaseOrderId
    ) {
      ...PurchaseOrderItemFields
    }
  }
`

export const CREATE_AND_ADD_LINE_ITEM_TO_PURCHASE_ORDER = gql`
  ${PURCHASE_ORDER_ITEM_FRAGMENT}
  mutation CreateAndAddLineItemToPurchaseOrder(
    $input: RequestPurchaseOrderOtherLineItemInput!
    $purchaseOrderId: String!
  ) {
    createAndAddLineItemToPurchaseOrder(
      input: $input
      purchaseOrderId: $purchaseOrderId
    ) {
      ...PurchaseOrderItemFields
    }
  }
`
