import React from 'react'

import AttachFileIcon from '@mui/icons-material/AttachFile'
import { Button, Typography } from '@mui/material'

import SelectDropdown, { SelectOption } from 'src/components/MUI/SelectDropdown'
import TextField from 'src/components/MUI/TextField'
const PerformanceInformation = () => {
  const aircraftOptions: SelectOption[] = [
    {
      label: 'N12345',
      value: 'N12345',
    },
    {
      label: 'N54321',
      value: 'N54321',
    },
  ]
  return (
    <div className="rounded border border-solid border-gray-300 p-1">
      <Typography variant="subtitle1" fontWeight="bold">
        Performance Information
      </Typography>
      <Typography variant="caption">
        Provide the information necessary to complete the task
      </Typography>
      <div className="my-2 flex flex-col gap-2">
        <Typography variant="subtitle2" fontWeight="bold">
          Work Process
        </Typography>
        <TextField placeholder="Description" name="ata" multiline rows={4} />
        <SelectDropdown
          label="Equipment Needed"
          options={aircraftOptions}
          onChange={() => {}}
          value=""
        />
        <SelectDropdown
          label="Parts Needed"
          options={aircraftOptions}
          onChange={() => {}}
          value=""
        />
        <Button
          className="w-fit"
          variant="outlined"
          color="base"
          startIcon={<AttachFileIcon />}
        >
          Attach File
        </Button>
      </div>
    </div>
  )
}

export default PerformanceInformation
