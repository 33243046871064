import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded'
import { Badge, IconButton, Popover, Typography } from '@mui/material'
import Loading from 'src/components/Loading'
import Button from 'src/components/MUI/Button'
import { useOrgName } from 'src/hooks/useOrgName'
import NotificationsList from 'src/components/Notifications/NotificationsList'
import useNotifications from 'src/hooks/useNotifications'

const NotificationPopover = () => {
  const orgSlug = useOrgName()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const {
    unreadNotificationsCount,
    markAllNotificationsAsRead,
    notifications,
    hasLoaded,
    refetch,
  } = useNotifications({ orgSlug })

  const handleNotificationsClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handleNotificationsClose = () => {
    setAnchorEl(null)
  }

  const onMarkAllAsReadClicked = async () => {
    await markAllNotificationsAsRead()
  }

  return (
    <>
      <IconButton
        aria-label={`show ${unreadNotificationsCount} new notifications`}
        color="inherit"
        onClick={handleNotificationsClick}
      >
        <Badge badgeContent={unreadNotificationsCount} color="error">
          <NotificationsNoneRoundedIcon />
        </Badge>
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleNotificationsClose}
        classes={{
          paper:
            'flex flex-col rounded-md w-46 max-h-45 mt-0.5 overflow-hidden',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className="m-1 flex items-center justify-between">
          <Typography variant="h6">Notifications</Typography>
          <Button size="small" variant="text" onClick={onMarkAllAsReadClicked}>
            Mark all as read
          </Button>
        </div>
        {!hasLoaded && (
          <div className="m-4 flex h-full items-center justify-center">
            <Loading />
          </div>
        )}
        {hasLoaded && notifications.length === 0 && (
          // handle no notifications
          <div className="flex h-full items-center justify-center">
            <Typography variant="subtitle2" className="my-4">
              No new notifications
            </Typography>
          </div>
        )}
        {hasLoaded && (
          <NotificationsList
            notifications={notifications}
            refetch={refetch}
            handleNotificationsClose={handleNotificationsClose}
          />
        )}
      </Popover>
    </>
  )
}

export default NotificationPopover
