import CORE_AIRCRAFT_USAGE_LOG_FRAGMENT from 'src/fragments/AircraftUsageLog'
import CORE_COMPONENT_USAGE_LOG_FRAGMENT from 'src/fragments/ComponentUsageLog'
import CORE_MAINTENANCE_DUE_STATUS_CALC_FRAGMENT from 'src/fragments/MaintenanceItem'

export const MECHANIC_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    firstName
    lastName
  }
`

export const internalWorkOrderFragment = gql`
  fragment InternalWorkOrderFragment on InternalWorkOrder {
    id
    name
    customName
    status
    workOrderNumber
    description
    estimatedWorkMinutes
    estimatedLaborCost
    vendor
    shop
    notes
    startedAt
    endedAt
    aircraftId
  }
`

export const internalWorkItemFragment = gql`
  fragment InternalWorkItemFragment on InternalWorkItem {
    id
    orgSlug
    maintenanceItemId
    description
    notes
    workMinutes
    estimatedWorkMinutes
    rate
    estimatedRate
    rateType
    estimatedRateType
    additionalLabor
    additionalNotes
    correctiveAction
    nextDueId
    workedBy {
      id
      firstName
      lastName
    }
    status
    partsTransactionId
    partCost
  }
`

export const customInternalWorkItemFragment = gql`
  fragment CustomInternalWorkItemFragment on CustomInternalWorkItem {
    id
    orgSlug
    title
    description
    notes
    workMinutes
    overrideLaborTransactionHours
    laborTransactionMinutes
    estimatedWorkMinutes
    rate
    estimatedRate
    rateType
    estimatedRateType
    additionalLabor
    additionalNotes
    correctiveAction
    workedById
    workedBy {
      id
      firstName
      lastName
    }
    laborTransactions {
      id
      description
      correctiveAction
      notes
      punchedInAt
      punchedOutAt
      workedBy {
        id
        firstName
        lastName
        clerkId
      }
    }
    status
  }
`

export const internalWorkOrderMaintenanceItemFragment = gql`
  ${CORE_MAINTENANCE_DUE_STATUS_CALC_FRAGMENT}
  fragment InternalWorkOrderMaintenanceItemFragment on MaintenanceItem {
    id
    ataCode
    manufactureCode
    title
    aircraftId
    ...CoreMaintenanceDueStatusCalcFields
  }
`
