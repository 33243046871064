import React, { useMemo } from 'react'

import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Button, Modal, Paper, Typography } from '@mui/material'

import { useDispatch } from 'src/hooks/useDispatch'
import { useSelector } from 'src/hooks/useSelector'
import { closeModal } from 'src/slices/modal'

import IconButton from '../MUI/IconButton'
import { MassagedComplianceActivity } from '../Compliance/SelectedTasksManager'
import TaskCountTextWithToolip from 'src/components/BulkReadyForReviewReassignmentModal.tsx/TaskCountTextWithToolip'

type BulkReadyForReviewReassignmentModalProps = {
  onConfirm: () => void
  selectedTasksAssignedToOthers: MassagedComplianceActivity[]
  unassignedSelectedTasks: MassagedComplianceActivity[]
}

const modalName = 'bulkReadyForReviewReassignmentModal'
const BulkReadyForReviewReassignmentModal: React.FC<
  BulkReadyForReviewReassignmentModalProps
> = ({ onConfirm, selectedTasksAssignedToOthers, unassignedSelectedTasks }) => {
  const dispatch = useDispatch()
  const open =
    useSelector((state) => state.modal.modals?.[modalName]?.isOpen) ?? false

  const handleClose = () => {
    dispatch(closeModal({ name: modalName }))
  }

  const onContinueClicked = () => {
    handleClose()
    onConfirm()
  }

  const workerGroupedAssignedTasks = useMemo<
    Record<string, MassagedComplianceActivity[]>
  >(() => {
    return selectedTasksAssignedToOthers.reduce((acc, task) => {
      if (!acc[task.workedById]) {
        acc[task.workedById] = []
      }
      acc[task.workedById].push(task)
      return acc
    }, {})
  }, [selectedTasksAssignedToOthers])

  const otherAssignedTaskMessages: React.ReactNode[] = []
  if (unassignedSelectedTasks.length) {
    otherAssignedTaskMessages.push(
      <TaskCountTextWithToolip
        taskDetial={`${unassignedSelectedTasks.length} unassigned task${
          unassignedSelectedTasks.length > 1 ? 's' : ''
        }`}
        tasks={unassignedSelectedTasks}
      />
    )
  }
  Object.entries(workerGroupedAssignedTasks).forEach(([worker, tasks]) => {
    const taskCountText = (
      <TaskCountTextWithToolip
        key={worker}
        taskDetial={`${tasks.length} task${tasks.length > 1 ? 's' : ''}`}
        tasks={tasks}
      />
    )
    otherAssignedTaskMessages.push(
      <>
        {taskCountText} assigned to {tasks[0].workedBy.firstName}{' '}
        {tasks[0].workedBy.lastName}
      </>
    )
  })

  const assignedTasksText = useMemo(() => {
    if (otherAssignedTaskMessages.length > 1) {
      return (
        <>
          {otherAssignedTaskMessages
            .slice(0, -1)
            .reduce((prev, curr) => [prev, ', ', curr])}
          {otherAssignedTaskMessages.length !== 2 ? ',' : ''} and{' '}
          {otherAssignedTaskMessages[otherAssignedTaskMessages.length - 1]}
        </>
      )
    }
    return otherAssignedTaskMessages[0]
  }, [otherAssignedTaskMessages])

  return (
    <Modal open={open}>
      <Paper className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform rounded-xl">
        <div className="p-3 pb-2">
          <div className="mb-6 flex gap-1">
            <div className="flex flex-col justify-between gap-1">
              <Typography variant="h6" component="h3">
                Assign yourself to selected tasks?
              </Typography>
              <Typography
                variant="body1"
                style={{ paddingBottom: 10, paddingTop: 5 }}
              >
                Marking tasks as ready for review will assign you as the
                mechanic for those tasks.
              </Typography>
              <Typography variant="body1" style={{ paddingBottom: 10 }}>
                If you continue, {assignedTasksText} will be assigned to you and
                marked as complete.
              </Typography>
              <Typography variant="body1">
                Are you sure you want to continue?
              </Typography>
            </div>

            <IconButton onClick={handleClose}>
              <CloseRoundedIcon />
            </IconButton>
          </div>
          <div className="flex flex-col gap-1">
            <div className="flex flex-col gap-0.25">
              <Button onClick={onContinueClicked} fullWidth>
                Continue
              </Button>
            </div>
            <Button
              onClick={handleClose}
              fullWidth
              variant="outlined"
              color="base"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Paper>
    </Modal>
  )
}

export default BulkReadyForReviewReassignmentModal
