import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table'
import React, { useMemo, useState } from 'react'
import { downloadFileFromUrl, formatDateForDisplay } from 'src/utils/helpers'
import { abbreviateFullName } from 'src/utils/stringFormatters'
import { AttachmentType, User } from 'types/graphql'
import FileUploadButton, { FileUploadButtonProps } from '../../FileUploadButton'
import Button from '../../MUI/Button'
import { DELETE_WORKFLOW_ATTACHMENT } from './queries'
import { useMutation } from '@redwoodjs/web'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
export interface WorkflowAttachment {
  id: string
  originalFilename: string
  uploadedBy: User
  createdAt: string
  category: AttachmentType
  url: string
}

const fileCategoryMap = {
  AddedPartTransaction: 'Added Part',
  RemovedPartTransaction: 'Removed Part',
  InternalWorkItem: 'Work Completed',
  CustomInternalWorkItem: 'Work Completed',
  ComplianceActivity: 'Compliance Activity',
  MaintenanceItem: 'Maintenance Item',
}

const AttachmentTable = ({
  data,
  onDelete,
  readonly,
}: {
  data: WorkflowAttachment[]
  onDelete: (id: string) => void
  readonly?: boolean
}) => {
  const columns = useMemo<MRT_ColumnDef<WorkflowAttachment>[]>(
    () => [
      {
        accessorKey: 'originalFilename',
        size: 200,
        header: 'File Name',
        Cell: ({ row }) => (
          <Tooltip title={row.original.originalFilename}>
            <Typography
              noWrap
              className="overflow-hidden text-ellipsis "
              sx={{ width: '100%' }}
            >
              {row.original.originalFilename}
            </Typography>
          </Tooltip>
        ),
      },
      {
        header: 'Uploaded By',
        size: 120,
        accessorFn: (row) => abbreviateFullName(row.uploadedBy),
      },
      {
        header: 'Date',
        size: 120,
        accessorFn: (row) => formatDateForDisplay(row.createdAt),
      },
      {
        accessorKey: 'category',
        size: 120,
        header: 'Category',
        Cell: ({ row }) => fileCategoryMap[row.original.category],
      },
      {
        id: 'actions',
        header: '',
        size: 120,
        Cell: ({ row }) => (
          <div className="flex justify-end gap-1">
            {row.original.url && (
              <IconButton
                onClick={() =>
                  downloadFileFromUrl(
                    row.original.url,
                    row.original.originalFilename
                  )
                }
              >
                <FileDownloadOutlinedIcon />
              </IconButton>
            )}
            {!readonly && (
              <IconButton onClick={() => onDelete(row.original.id)}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            )}
          </div>
        ),
      },
    ],
    []
  )

  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      enableColumnActions={false}
      enableColumnFilters={false}
      enablePagination={false}
      enableSorting={false}
      enableTopToolbar={false}
      enableBottomToolbar={false}
      muiTableProps={{
        className: 'table-fixed',
      }}
      muiTablePaperProps={{
        elevation: 0,
        className: 'border border-solid border-[#e0e0e0] rounded-lg w-full',
      }}
      renderEmptyRowsFallback={() => (
        <div className="my-2 flex w-full items-center justify-center">
          <Typography variant="body1">No attachments found</Typography>
        </div>
      )}
    />
  )
}

const DefaultAttachmentsSection = ({
  data,
  onDelete,
  attachables,
  refetchAttachments,
  onSaveFile,
  readonly,
}: {
  data: WorkflowAttachment[]
  onDelete: (id: string) => void
  attachables: FileUploadButtonProps['attachables']
  refetchAttachments: () => void
  onSaveFile: (file: File) => void
  readonly?: boolean
}) => {
  return (
    <div className="mx-3 mb-3 flex flex-col gap-2">
      <div className="flex justify-between">
        <Typography variant="h6">Attachments</Typography>
        {!readonly && (
          <FileUploadButton
            attachables={attachables}
            refetchAttachments={refetchAttachments}
            alternativeOnSave={onSaveFile}
            button={
              <Button
                variant="text"
                color="primary"
                startIcon={<FileUploadOutlinedIcon />}
              >
                Upload
              </Button>
            }
          />
        )}
      </div>
      <AttachmentTable data={data} onDelete={onDelete} readonly={readonly} />
    </div>
  )
}

const ReviewAttachmentsSection = ({
  data,
  onDelete,
  attachables,
  refetchAttachments,
  onSaveFile,
  readonly,
}: {
  data: WorkflowAttachment[]
  onDelete: (id: string) => void
  attachables: FileUploadButtonProps['attachables']
  refetchAttachments: () => void
  onSaveFile: (file: File) => void
  readonly?: boolean
}) => {
  const [expandedAccordion, setExpandedAccordion] = useState(false)
  const handleAccordionChange = (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    setExpandedAccordion(isExpanded)
  }
  return (
    <Accordion
      expanded={expandedAccordion}
      onChange={handleAccordionChange}
      disableGutters
      elevation={0}
      sx={{
        '&.MuiAccordion-root': {
          position: 'static',
          border: '1px solid #E0E0E0',
          borderRadius: 1,
        },
        '& .MuiAccordionDetails-root': {
          borderTop: '1px solid #E0E0E0', // Add border between header and content
        },
        '&::before': {
          display: 'none',
        },
        '& .MuiAccordionSummary-root': {
          borderRadius: 1,
        },
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div className="flex w-full items-center justify-between">
          <Typography variant="body2" fontWeight="bold">
            {data?.length ? `ATTACHMENTS (${data.length})` : 'ATTACHMENTS'}
          </Typography>
          {!readonly && (
            <FileUploadButton
              attachables={attachables}
              refetchAttachments={refetchAttachments}
              alternativeOnSave={onSaveFile}
              button={
                <Button
                  variant="text"
                  color="primary"
                  startIcon={<FileUploadOutlinedIcon />}
                >
                  Upload
                </Button>
              }
            />
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <AttachmentTable data={data} onDelete={onDelete} readonly={readonly} />
      </AccordionDetails>
    </Accordion>
  )
}

interface WorkflowAttachmentsProps {
  attachables: FileUploadButtonProps['attachables']
  data: WorkflowAttachment[]
  variant?: 'default' | 'review' | 'readonly'
  refetchAttachments?: () => void
  flashSaved?: (text?: string) => void
  flashError?: (text?: string) => void
  onSaveFile?: (file: File) => void
  onDeleteFile?: (id: string) => void
}

const WorkflowAttachmentsSection: React.FC<WorkflowAttachmentsProps> = ({
  attachables,
  data,
  variant = 'default',
  onSaveFile,
  refetchAttachments,
  flashSaved,
  flashError,
  onDeleteFile,
}) => {
  const [deleteInvalidFile] = useMutation(DELETE_WORKFLOW_ATTACHMENT, {
    onCompleted: () => {
      refetchAttachments?.()
      flashSaved?.('File deleted')
    },
    onError: () => {
      flashError?.('Error deleting file')
    },
  })

  const onDelete = (id: string) => {
    if (onDeleteFile) {
      onDeleteFile(id)
    } else {
      deleteInvalidFile({ variables: { id } })
    }
  }

  if (variant === 'default') {
    return (
      <DefaultAttachmentsSection
        readonly={false}
        data={data}
        onDelete={onDelete}
        attachables={attachables}
        refetchAttachments={refetchAttachments}
        onSaveFile={onSaveFile}
      />
    )
  }

  return (
    <ReviewAttachmentsSection
      readonly={variant === 'readonly'}
      data={data}
      onDelete={onDelete}
      attachables={attachables}
      refetchAttachments={refetchAttachments}
      onSaveFile={onSaveFile}
    />
  )
}

export default WorkflowAttachmentsSection
