export const GET_WORK_CARD_USERS = gql`
  query getWorkCardUsers {
    users {
      id
      firstName
      lastName
    }
  }
`

export const CREATE_WORK_CARD_PDF = gql`
  mutation CreateWorkCardPdf(
    $ids: [String!]!
    $variety: WorkCardPdfVariety!
    $options: WorkCardPdfOptions
  ) {
    createWorkCardPdf(ids: $ids, variety: $variety, options: $options)
  }
`
