import React, { useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import {
  Typography,
  IconButton,
  TextField as MuiTextField,
} from '@mui/material'
import { Permissions } from '@wingwork/common/src/constants/permissions'
import { InternalWorkItem } from 'types/graphql'
import * as Yup from 'yup'

import { Form, useForm } from '@redwoodjs/forms'

import DollarCentsTextField from 'src/components/DollarCentsTextField'
import FileUploadFieldMulti from 'src/components/FileUploadFieldMulti'
import RadioGroup from 'src/components/MUI/RadioGroup'
import SelectDropdown from 'src/components/MUI/SelectDropdown'
import Switch from 'src/components/MUI/Switch'
import TextField from 'src/components/MUI/TextField'
import PartsTimeInputTextField from 'src/components/PartsTimeInputTextField'
import RequestPartButton from 'src/components/RequestPartButton'
import useHasPermission from 'src/hooks/useHasPermission'
import useQuery from 'src/hooks/useQuery'
import {
  AIRCRAFT_COMPONENT_FRAGMENT,
  PARTS_TRANSACTION_FRAGMENT,
} from 'src/pages/BulkCompliancePage/ComplianceEditor/PartsForm'
import { FormSubmitRef } from 'src/types'
import {
  centsToDollars,
  isDecimalWithTwoOrLessPlaces,
  minutesToHours,
} from 'src/utils/helpers'
import Button from 'src/components/MUI/Button'
import { useSelector } from 'src/hooks/useSelector'

export const GET_PARTS_FOR_WORK_ITEM = gql`
  query getPartsForWorkItem($id: String!) {
    internalWorkItem(id: $id) {
      id
      maintenanceItem {
        id
        aircraftComponent {
          ...AircraftComponentFragment
        }
      }
      partsTransaction {
        ...PartsTransactionFragment
      }
    }
  }
  ${AIRCRAFT_COMPONENT_FRAGMENT}
  ${PARTS_TRANSACTION_FRAGMENT}
`

const FileValue = (value, clear) => (
  <div className="flex items-center justify-between">
    <Typography className="overflow-hidden text-ellipsis whitespace-nowrap">
      {value?.name}
    </Typography>
    <IconButton onClick={clear} size="small">
      <ClearRoundedIcon fontSize="small" />
    </IconButton>
  </div>
)

type PartsFormProps = {
  workItem: InternalWorkItem
  handleSubmit: (values: any) => Promise<void>
  onError: (formName: string, errors: any) => void
}

export const partFormSchema = Yup.object().shape({
  workItem: Yup.object().shape({
    rateType: Yup.string().required('Choose rate type'),
    rate: Yup.number()
      .typeError('Rate is required')
      .min(0, 'Must be positive')
      .test(
        'valid-decimal',
        'No more than 2 decimal points',
        isDecimalWithTwoOrLessPlaces
      )
      .required('Rate is required'),
    workHours: Yup.number()
      .typeError('Work hours is required')
      .min(0, 'Must be positive')
      .required('Work hours is required'),
    partCost: Yup.number()
      .nullable()
      .typeError('Please enter a positive number')
      .positive('Must be positive')
      .min(0, 'Must be positive')
      .test(
        'valid-decimal',
        'No more than 2 decimal points',
        isDecimalWithTwoOrLessPlaces
      ),
  }),
  parts: Yup.object().shape({
    removalReason: Yup.string().when(
      [
        'installedStatus',
        'added.partNumber',
        'added.serialNumber',
        'removedPartFile',
        'addedPartFile',
        'removed.partNumber',
        'removed.serialNumber',
      ],
      (
        [
          installedStatus,
          partNumber,
          serialNumber,
          removedPartFile,
          addedPartFile,
          removedPartNumber,
          removedSerialNumber,
        ],
        schema
      ) => {
        let required = false
        if (!removedPartNumber && !removedSerialNumber) {
          return schema.nullable()
        }

        if (installedStatus || partNumber || serialNumber) {
          required = true
        }

        if (removedPartFile?.length || addedPartFile?.length) {
          required = true
        }

        return required
          ? schema.required('Removal reason is a required field')
          : schema.nullable()
      }
    ),
    installedStatus: Yup.string().when(
      ['added.partNumber', 'added.serialNumber'],
      (allFields, schema) =>
        allFields.some(Boolean)
          ? schema.required('Installed Status is a required field')
          : schema.nullable()
    ),
    otherInstalledStatus: Yup.string().when(
      ['installedStatus'],
      (allFields, schema) =>
        allFields[0] === 'Other'
          ? schema.required('Other Installed Status is a required field')
          : schema.nullable()
    ),
    added: Yup.object().shape({
      partNumber: Yup.string().nullable(),
      serialNumber: Yup.string().nullable(),
    }),
    removedPartFile: Yup.mixed().nullable(),
    addedPartFile: Yup.mixed().nullable(),
  }),
})

const PartsForm = React.forwardRef<FormSubmitRef, PartsFormProps>(
  ({ workItem, handleSubmit, onError }, ref) => {
    const aircraftId = useSelector((state) => state.auditLog.aircraftId)
    const canCompleteWorkOrder = useHasPermission(
      Permissions.workOrder.complete,
      aircraftId
    )
    const canUpdateWorkOrder = useHasPermission(
      Permissions.workOrder.update,
      aircraftId
    )
    const {
      data: partData,
      loading,
      hasLoaded,
    } = useQuery(GET_PARTS_FOR_WORK_ITEM, {
      variables: { id: workItem.id },
      fetchPolicy: 'cache-first',
    })
    const formMethods = useForm({
      resolver: yupResolver(partFormSchema),
      defaultValues: {
        workItem: {
          rateType: 'HOURLY_RATE',
          rate: centsToDollars(0),
          estimatedWorkMinutes: 0,
          workHours: 0,
          overrideLaborTransactionHours: false,
          partCost: centsToDollars(0),
        },
        parts: {
          removalReason: '',
          otherRemovalReason: '',
          installedStatus: '',
          otherInstalledStatus: '',
          removed: {},
          added: {
            partNumber: '',
            serialNumber: '',
          },
        },
      },
    })
    const { reset, watch, setValue } = formMethods
    const workHoursInput = watch('workItem.workHours')
    const rateInput = watch('workItem.rate')
    const rateTypeInput = watch('workItem.rateType')
    const removalReason = watch('parts.removalReason')
    const installedStatus = watch('parts.installedStatus')
    const overrideLaborTransactionHours = watch(
      'workItem.overrideLaborTransactionHours'
    )

    React.useImperativeHandle(ref, () => ({
      handleSubmit: async () => {
        let hasError = false
        await formMethods.handleSubmit(handleSubmit, (err) => {
          hasError = true
          onError('partAndLabor', err)
        })()
        return !hasError
      },
      isDirty: Object.keys(formMethods.formState.dirtyFields).length > 0,
    }))

    useEffect(() => {
      if (overrideLaborTransactionHours) {
        setValue(
          'workItem.workHours',
          parseFloat(minutesToHours(workItem?.workMinutes))
        )
      } else {
        setValue(
          'workItem.workHours',
          parseFloat(minutesToHours(workItem?.laborTransactionMinutes))
        )
      }
    }, [overrideLaborTransactionHours])

    useEffect(() => {
      if (workItem && hasLoaded && !loading) {
        const partsTransaction = partData?.internalWorkItem?.partsTransaction

        const getWorkHoursVal = () => {
          if (!workItem?.overrideLaborTransactionHours) {
            return workItem?.laborTransactionMinutes
              ? parseFloat(minutesToHours(workItem?.laborTransactionMinutes))
              : 0
          } else {
            return workItem?.workMinutes
              ? parseFloat(minutesToHours(workItem?.workMinutes))
              : 0
          }
        }
        reset({
          workItem: {
            rateType: workItem?.rateType ?? 'HOURLY_RATE',
            rate: workItem?.rate
              ? centsToDollars(workItem?.rate)
              : centsToDollars(0),
            workHours: getWorkHoursVal(),
            overrideLaborTransactionHours:
              workItem?.overrideLaborTransactionHours ?? false,
            partCost: workItem.partCost
              ? centsToDollars(workItem.partCost)
              : centsToDollars(0),
          },
          parts: {
            removalReason: partsTransaction?.removalReason?.startsWith('Other')
              ? partsTransaction?.removalReason.split(' - ')[0]
              : partsTransaction?.removalReason ?? '',
            otherRemovalReason: partsTransaction?.removalReason?.startsWith(
              'Other'
            )
              ? partsTransaction?.removalReason.split(' - ')[1]
              : '',
            installedStatus: partsTransaction?.installedStatus?.startsWith(
              'Other'
            )
              ? partsTransaction?.installedStatus.split(' - ')[0]
              : partsTransaction?.installedStatus ?? '',
            otherInstalledStatus: partsTransaction?.installedStatus?.startsWith(
              'Other'
            )
              ? partsTransaction?.installedStatus.split(' - ')[1]
              : '',
            monthsSinceNew: partsTransaction?.monthsSinceNew ?? -1,
            monthsSinceOverhaul: partsTransaction?.monthsSinceOverhaul ?? -1,
            monthsSinceRepair: partsTransaction?.monthsSinceRepair ?? -1,
            hoursSinceNew: partsTransaction?.hoursSinceNew ?? -1,
            hoursSinceOverhaul: partsTransaction?.hoursSinceOverhaul ?? -1,
            hoursSinceRepair: partsTransaction?.hoursSinceRepair ?? -1,
            cyclesSinceNew: partsTransaction?.cyclesSinceNew ?? -1,
            cyclesSinceOverhaul: partsTransaction?.cyclesSinceOverhaul ?? -1,
            cyclesSinceRepair: partsTransaction?.cyclesSinceRepair ?? -1,

            removed: {
              partNumber:
                partsTransaction?.removedPartNumber ??
                workItem?.maintenanceItem?.aircraftComponent?.partNumber ??
                '',
              serialNumber:
                partsTransaction?.removedSerialNumber ??
                workItem?.maintenanceItem?.aircraftComponent?.serialNumber ??
                '',
            },
            added: {
              partNumber: partsTransaction?.addedPartNumber ?? '',
              serialNumber: partsTransaction?.addedSerialNumber ?? '',
            },
          },
        })
      }
    }, [workItem, loading, hasLoaded])

    const oldFiles =
      partData?.internalWorkItem?.partsTransaction?.removedPartFiles ?? []
    const newFiles =
      partData?.internalWorkItem?.partsTransaction?.addedPartFiles ?? []

    const disableRemovalReason = !workItem?.maintenanceItem?.componentID

    const getEstimatedSubtotal = () => {
      const estWorkHours = workItem.estimatedWorkMinutes
        ? parseFloat(minutesToHours(workItem.estimatedWorkMinutes))
        : 0
      const rate = workItem.estimatedRate
        ? parseFloat(centsToDollars(workItem.estimatedRate))
        : 0
      const rateType = workItem.estimatedRateType

      if (!rate || !rateType) return '----'

      switch (rateType) {
        case 'FLAT_RATE':
          return `$${rate}`
        case 'HOURLY_RATE':
          return `$${(estWorkHours * rate).toFixed(2)}`
        default:
          return '----'
      }
    }

    const getActualSubtotal = () => {
      const actualWorkHours = workHoursInput
        ? parseFloat(`${workHoursInput}`)
        : 0
      const rate = rateInput ? parseFloat(rateInput) : 0

      if (!rate || !rateTypeInput) return '----'

      switch (rateTypeInput) {
        case 'FLAT_RATE':
          return `$${rate}`
        case 'HOURLY_RATE':
          return `$${(actualWorkHours * rate).toFixed(2)}`
        default:
          return '----'
      }
    }

    return (
      <div className="p-3">
        <div className="flex items-start justify-between">
          <Typography variant="subtitle1" fontWeight="bold">
            Labor
          </Typography>
          <RequestPartButton workItemId={workItem?.id} />
        </div>
        <Form formMethods={formMethods} onSubmit={handleSubmit}>
          <div className="mt-2 grid grid-cols-2 gap-2">
            <RadioGroup
              name={'workItem.rateType'}
              required
              row
              options={[
                {
                  label: 'Hourly Rate',
                  value: 'HOURLY_RATE',
                },
                {
                  label: 'Flat Rate',
                  value: 'FLAT_RATE',
                },
              ]}
              disabled={!canCompleteWorkOrder || !canUpdateWorkOrder}
            />
            <DollarCentsTextField
              name="workItem.rate"
              required
              label="Rate"
              control={formMethods.control}
              disabled={!canCompleteWorkOrder || !canUpdateWorkOrder}
            />
          </div>

          <div className="mt-2 grid grid-cols-2 gap-2">
            <div className="flex flex-col gap-2">
              <div className="mb-[4px]">
                <Typography variant="subtitle2" fontWeight={'bold'}>
                  Estimate
                </Typography>
              </div>

              <MuiTextField
                size="small"
                required
                label="Work Hours"
                variant="outlined"
                value={
                  workItem?.estimatedWorkMinutes
                    ? parseFloat(minutesToHours(workItem?.estimatedWorkMinutes))
                    : 0
                }
                disabled
              />
              <MuiTextField
                size="small"
                label="Subtotal"
                variant="outlined"
                value={getEstimatedSubtotal()}
                disabled
              />
            </div>
            <div className="flex flex-col gap-2 ">
              <div className="flex items-center justify-between">
                <Typography variant="subtitle2" fontWeight={'bold'}>
                  Actual
                </Typography>
                <div className="flex items-center gap-2">
                  <Typography variant="caption">
                    Override Labor Transaction Hours
                  </Typography>
                  <Switch
                    name="workItem.overrideLaborTransactionHours"
                    switchProps={{ size: 'small' }}
                  />
                </div>
              </div>
              <div>
                <TextField
                  name="workItem.workHours"
                  size="small"
                  label="Work Hours"
                  variant="outlined"
                  type="number"
                  disabled={
                    !overrideLaborTransactionHours || !canUpdateWorkOrder
                  }
                  inputProps={{ step: '0.1', min: 0 }}
                />
              </div>
              <MuiTextField
                size="small"
                label="Subtotal"
                variant="outlined"
                value={getActualSubtotal()}
                disabled
              />
            </div>
          </div>
          <div>
            <Typography
              variant="subtitle2"
              fontWeight={'bold'}
              className="my-2"
            >
              Additional
            </Typography>

            <div className="grid grid-cols-2 gap-2">
              <DollarCentsTextField
                name="workItem.partCost"
                label="Part Cost"
                control={formMethods.control}
                disabled={!canUpdateWorkOrder}
              />
            </div>
          </div>
          <div className="mt-3">
            <Typography variant="subtitle1" fontWeight="bold">
              Parts Information
            </Typography>
          </div>
          <div className="mt-2 grid grid-cols-2 gap-2">
            <div className="flex flex-col gap-2 ">
              <Typography variant="subtitle2" fontWeight={'bold'}>
                Part Removed
              </Typography>
              <TextField
                name="parts.removed.partNumber"
                size="small"
                label="Number"
                variant="outlined"
                disabled
              />
              <TextField
                name="parts.removed.serialNumber"
                size="small"
                label="Serial"
                variant="outlined"
                disabled
              />
              <SelectDropdown
                name="parts.removalReason"
                disabled={disableRemovalReason || !canUpdateWorkOrder}
                options={[
                  'Scheduled',
                  'Premature Removal',
                  'Maintenance Issue',
                  'Life Limit Item',
                  'Timed Item',
                  'Failure',
                  'Worn',
                  'Other',
                ].map((status) => ({ label: status, value: status }))}
                label="Removal Reason"
              />
              {removalReason === 'Other' && (
                <TextField
                  name="parts.otherRemovalReason"
                  size="small"
                  label="Other Reason"
                  variant="outlined"
                  disabled={!canUpdateWorkOrder}
                />
              )}
              <FileUploadFieldMulti
                name="parts.removedPartFile"
                renderValue={FileValue}
                button={
                  <Button
                    className="w-fit"
                    variant="outlined"
                    color="base"
                    size="small"
                    startIcon={<AttachFileIcon />}
                    disabled={disableRemovalReason}
                    locked={!canUpdateWorkOrder}
                    lockedTooltip="You do not have permission to update this work order"
                  >
                    Attach File
                  </Button>
                }
              />
              {oldFiles.length > 0 && (
                <div>
                  <Typography
                    className="whitespace-nowrap"
                    variant="subtitle2"
                    fontWeight="bold"
                  >
                    Uploaded files:
                  </Typography>
                  {oldFiles.map((file) => (
                    <div
                      key={file.id}
                      className="my-0 flex items-center justify-between px-2"
                    >
                      <Typography
                        className="overflow-hidden text-ellipsis whitespace-nowrap"
                        variant="body2"
                      >
                        {file.originalFilename}
                      </Typography>
                      <IconButton
                        onClick={() => window.open(file.url, '_blank')}
                      >
                        <CloudDownloadOutlinedIcon fontSize="small" />
                      </IconButton>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="flex flex-col gap-2 ">
              <div className="flex items-center justify-between">
                <Typography variant="subtitle2" fontWeight={'bold'}>
                  Part Installed
                </Typography>
              </div>
              <TextField
                name="parts.added.partNumber"
                size="small"
                label="Number"
                variant="outlined"
                disabled={!canUpdateWorkOrder}
              />
              <TextField
                name="parts.added.serialNumber"
                size="small"
                label="Serial"
                variant="outlined"
                disabled={!canUpdateWorkOrder}
              />
              <SelectDropdown
                name="parts.installedStatus"
                options={[
                  'Altered',
                  'Inspected',
                  'Modified',
                  'New',
                  'Not Specified',
                  'Overhauled',
                  'Other',
                  'Repaired',
                  'Serviceable',
                ].map((status) => ({ label: status, value: status }))}
                label="Installed Status"
                onChange={() => {}}
                disabled={!canUpdateWorkOrder}
              />
              {installedStatus === 'Other' && (
                <TextField
                  name="parts.otherInstalledStatus"
                  size="small"
                  label="Other Installed Status"
                  variant="outlined"
                />
              )}
              <FileUploadFieldMulti
                name="parts.addedPartFile"
                renderValue={FileValue}
                button={
                  <Button
                    className="w-fit"
                    variant="outlined"
                    color="base"
                    size="small"
                    startIcon={<AttachFileIcon />}
                    locked={!canUpdateWorkOrder}
                    lockedTooltip="You do not have permission to update this work order"
                  >
                    Attach File
                  </Button>
                }
              />
              {newFiles.length > 0 && (
                <div>
                  <Typography variant="subtitle2" fontWeight="bold">
                    Uploaded files:
                  </Typography>
                  {newFiles.map((file) => (
                    <div
                      key={file.id}
                      className="flex items-center justify-between px-2"
                    >
                      <Typography variant="body2">
                        {file.originalFilename}
                      </Typography>
                      <IconButton
                        onClick={() => window.open(file.url, '_blank')}
                      >
                        <CloudDownloadOutlinedIcon fontSize="small" />
                      </IconButton>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="mt-3">
            <Typography variant="subtitle1" fontWeight="bold">
              Times Since Installed
            </Typography>
            <div className="mt-2 grid grid-cols-4 gap-2">
              <div className="flex flex-col gap-2 ">
                <div className="flex items-center justify-between">
                  <Typography variant="subtitle2" fontWeight={'bold'}>
                    Months
                  </Typography>
                </div>
                <PartsTimeInputTextField
                  name="parts.monthsSinceNew"
                  label="New"
                  disabled={!canUpdateWorkOrder}
                />
                <PartsTimeInputTextField
                  name="parts.monthsSinceOverhaul"
                  label="Overhaul"
                  disabled={!canUpdateWorkOrder}
                />
                <PartsTimeInputTextField
                  name="parts.monthsSinceRepair"
                  label="Repair"
                  disabled={!canUpdateWorkOrder}
                />
              </div>
              <div className="flex flex-col gap-2 ">
                <div className="flex items-center justify-between">
                  <Typography variant="subtitle2" fontWeight={'bold'}>
                    Hours
                  </Typography>
                </div>
                <PartsTimeInputTextField
                  name="parts.hoursSinceNew"
                  label="New"
                  disabled={!canUpdateWorkOrder}
                />
                <PartsTimeInputTextField
                  name="parts.hoursSinceOverhaul"
                  label="Overhaul"
                  disabled={!canUpdateWorkOrder}
                />
                <PartsTimeInputTextField
                  name="parts.hoursSinceRepair"
                  label="Repair"
                  disabled={!canUpdateWorkOrder}
                />
              </div>
              <div className="flex flex-col gap-2 ">
                <div className="flex items-center justify-between">
                  <Typography variant="subtitle2" fontWeight={'bold'}>
                    Landings
                  </Typography>
                </div>
                <PartsTimeInputTextField
                  name="parts.cyclesSinceNew"
                  label="New"
                  disabled={!canUpdateWorkOrder}
                />
                <PartsTimeInputTextField
                  name="parts.cyclesSinceOverhaul"
                  label="Overhaul"
                  disabled={!canUpdateWorkOrder}
                />
                <PartsTimeInputTextField
                  name="parts.cyclesSinceRepair"
                  label="Repair"
                  disabled={!canUpdateWorkOrder}
                />
              </div>
            </div>
          </div>
        </Form>
      </div>
    )
  }
)
PartsForm.displayName = 'PartsForm'

export default PartsForm
