import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded'
import ConstructionIcon from '@mui/icons-material/Construction'
import BackHandIcon from '@mui/icons-material/BackHand'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Fade, Typography } from '@mui/material'
import { useCallback, useState } from 'react'
import { useDispatch } from 'src/hooks/useDispatch'
import { setDetailDrawerTaskId } from 'src/slices/compliance'
import LoadingButton from 'src/components/MUI/LoadingButton'
import Drawer from '../MUI/Drawer/Drawer'
import DrawerActions from '../MUI/Drawer/DrawerActions'
import DrawerHeader from '../MUI/Drawer/DrawerHeader'
import Button, { ButtonProps } from 'src/components/MUI/Button'
import { keyframes } from '@emotion/react'
import { styled } from '@mui/material/styles'
import useModal from 'src/hooks/useModal'
import useWorkOrderVitals from 'src/hooks/useWorkOrderVitals'

type WorkflowTaskDetailDrawerProps = {
  onComplete: () => void
  close?: () => void
  onBack?: () => void
  onNext?: () => void
  isWorkOrder?: boolean
  isCustomWorkItem?: boolean
  open: boolean
  title: string
  loading: boolean
  aircraftId: string
  children: (props: {
    flashSaved: (text?: string) => void
    flashError: (text?: string) => void
  }) => React.ReactNode
  taskStatus?:
    | 'APPLY_TIMES'
    | 'READY_TO_COMPLETE'
    | 'IN_PROGRESS_LABOR_TRANSACTION'
    | 'COMPLETE'
  showNextItemActions?: boolean
  completeLoading?: boolean
  externalHandleClose?: () => void
  additionalRightActions?: React.ReactNode
  slotProps?: {
    completeButton?: ButtonProps
  }
}

const stampAnimation = keyframes`
  0% {
    transform: scale(1.5) rotate(0deg);
    opacity: 0;
  }
  50% {
    transform: scale(1.1) rotate(0deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
`

const StampContainer = styled('div')`
  animation: ${stampAnimation} 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275)
    forwards;
  transform-origin: center;
`

const WorkflowTaskDetailDrawer: React.FC<WorkflowTaskDetailDrawerProps> = ({
  onBack,
  onNext,
  onComplete,
  close,
  isWorkOrder = false,
  isCustomWorkItem = false,
  open,
  title,
  loading,
  completeLoading,
  taskStatus = 'READY_TO_COMPLETE',
  showNextItemActions,
  children,
  additionalRightActions,
  externalHandleClose,
  slotProps,
  aircraftId,
}) => {
  const dispatch = useDispatch()
  const { handleOpen: openAircraftTimeModal } = useModal('aircraftTimeModal')
  const {
    setWorkOrderDetailDrawerTaskId,
    setWorkOrderDetailDrawerCustomTaskId,
  } = useWorkOrderVitals()
  const handleClose = useCallback(() => {
    if (externalHandleClose) {
      externalHandleClose()
      return
    }
    if (isWorkOrder && !isCustomWorkItem) {
      setWorkOrderDetailDrawerTaskId(null)
    } else if (isWorkOrder && isCustomWorkItem) {
      setWorkOrderDetailDrawerCustomTaskId(null)
    } else {
      dispatch(setDetailDrawerTaskId(null))
    }
    close?.()
  }, [
    dispatch,
    close,
    isWorkOrder,
    isCustomWorkItem,
    setWorkOrderDetailDrawerTaskId,
    setWorkOrderDetailDrawerCustomTaskId,
  ])

  const [showSaved, setShowSaved] = useState(false)
  const [savedText, setSavedText] = useState<string>('Saved')
  const [showError, setShowError] = useState(false)
  const [errorText, setErrorText] = useState<string>('Error saving')
  const flashSaved = useCallback((text?: string) => {
    setShowSaved(true)
    setSavedText(text ?? 'Saved')
    setTimeout(() => {
      setShowSaved(false)
    }, 5000)
  }, [])
  const flashError = useCallback((text?: string) => {
    setShowError(true)
    setErrorText(text ?? 'Error saving')
    setTimeout(() => {
      setShowError(false)
    }, 5000)
  }, [])

  const getTaskStatusActions = useCallback(() => {
    if (taskStatus === 'APPLY_TIMES') {
      return (
        <div className="mr-1 flex items-center gap-0.5">
          <Button
            startIcon={<BackHandIcon fontSize="small" />}
            variant="outlined"
            color="error"
            onClick={() =>
              openAircraftTimeModal({
                aircraftId,
              })
            }
          >
            Apply Times First
          </Button>
        </div>
      )
    }
    if (taskStatus === 'IN_PROGRESS_LABOR_TRANSACTION') {
      return (
        <div className="mr-1 flex items-center gap-0.5">
          <ConstructionIcon fontSize="small" color="warning" />
          <Typography variant="body2" color="warning" fontWeight={500}>
            Mechanic clocked in
          </Typography>
        </div>
      )
    }
    if (taskStatus === 'COMPLETE') {
      return (
        <div className="mr-1 flex items-center gap-0.5">
          <BookmarkAddedIcon fontSize="small" color="success" />
          <Typography variant="body2" color="success" fontWeight={500}>
            Marked as complete
          </Typography>
        </div>
      )
    }
    return (
      <LoadingButton
        variant="contained"
        color="primary"
        onClick={async () => {
          onComplete?.()
        }}
        loading={completeLoading}
        {...slotProps?.completeButton}
      >
        Complete Task
      </LoadingButton>
    )
  }, [taskStatus, onComplete])

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={externalHandleClose || handleClose}
      classes={{ paper: 'w-100' }}
    >
      <DrawerHeader
        title={title}
        onClose={handleClose}
        showNextItemActions={showNextItemActions}
        loading={loading}
        onBack={onBack}
        onNext={onNext}
      />
      <div className="flex-grow overflow-y-auto">
        {children({ flashSaved, flashError })}
      </div>
      <DrawerActions
        leftActions={
          <Button
            variant="text"
            color="black"
            onClick={externalHandleClose || handleClose}
          >
            Cancel
          </Button>
        }
        rightActions={
          <div className="flex h-fit items-center gap-1">
            {showSaved && (
              <Fade in={showSaved}>
                <StampContainer>
                  <div className="mr-1 flex items-center gap-0.5">
                    <CheckCircleOutlineRoundedIcon
                      fontSize="small"
                      color="success"
                    />
                    <Typography
                      variant="body2"
                      color="success"
                      fontWeight={500}
                    >
                      {savedText}
                    </Typography>
                  </div>
                </StampContainer>
              </Fade>
            )}
            {showError && (
              <Fade in={showError}>
                <StampContainer>
                  <div className="mr-1 flex items-center gap-0.5">
                    <ErrorOutlineIcon fontSize="small" color="error" />
                    <Typography variant="body2" color="error" fontWeight={500}>
                      {errorText}
                    </Typography>
                  </div>
                </StampContainer>
              </Fade>
            )}
            {(showSaved || showError) && (
              <Fade in={showSaved || showError}>
                <StampContainer>
                  <div className="h-3 w-[2px] bg-gray-300" />
                </StampContainer>
              </Fade>
            )}
            {additionalRightActions}
            {getTaskStatusActions()}
          </div>
        }
      />
    </Drawer>
  )
}

export default WorkflowTaskDetailDrawer
