import React, { useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Button, InputAdornment } from '@mui/material'
import * as yup from 'yup'

import { Form, useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import Drawer from 'src/components/MUI/Drawer/Drawer'
import { hoursToMinutes } from 'src/utils/helpers'
import DrawerActions from 'src/components/MUI/Drawer/DrawerActions'
import DrawerHeader from 'src/components/MUI/Drawer/DrawerHeader'
import useModal from 'src/hooks/useModal'
import TextField from 'src/components/MUI/TextField'
import {
  CREATE_CUSTOM_WORK_ITEM_MUTATION,
  GET_WORK_ORDER_CUSTOM_TASK_LIST,
} from '../queries'

interface AddCustomWorkItemModalProps {
  workOrderId: string
}

const AddCustomTaskDrawer: React.FC<AddCustomWorkItemModalProps> = ({
  workOrderId,
}) => {
  const [createCustomWorkItem, { loading }] = useMutation(
    CREATE_CUSTOM_WORK_ITEM_MUTATION
  )

  const { handleClose, isOpen } = useModal('addCustomTaskDrawer')

  const formMethods = useForm({
    defaultValues: {
      title: '',
      estimatedWorkMinutes: 0,
      estimatedRate: 0,
    },
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().required('Title is required'),
      })
    ),
  })

  useEffect(() => {
    if (open) formMethods.reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const onSubmit = async (data) => {
    const parsedData = {
      ...data,
      estimatedRate: Math.floor(
        Math.abs(parseFloat(data.estimatedRate || '0')) * 100
      ),
      estimatedWorkMinutes: hoursToMinutes(
        Math.abs(parseFloat(data.estimatedWorkMinutes))
      ),
      rate: Math.floor(Math.abs(parseFloat(data.estimatedRate || '0')) * 100),
    }

    await createCustomWorkItem({
      variables: {
        input: { ...parsedData, status: 'IN_PROGRESS', workOrderId },
      },
      update: (cache, { data: { createCustomInternalWorkItem } }) => {
        // Read existing items
        const existingData = cache.readQuery({
          query: GET_WORK_ORDER_CUSTOM_TASK_LIST,
          variables: { id: workOrderId },
        })

        // Update cache with the new item
        cache.writeQuery({
          query: GET_WORK_ORDER_CUSTOM_TASK_LIST,
          variables: { id: workOrderId },
          data: {
            customInternalWorkItems: [
              ...(existingData?.customInternalWorkItems || []),
              createCustomInternalWorkItem,
            ],
          },
        })
      },
    })
    formMethods.reset()
    handleClose()
  }

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose}>
      <DrawerHeader title="Add Custom Task" onClose={handleClose} />
      <div className="flex h-full flex-col gap-2 p-2">
        <Form
          formMethods={formMethods}
          onSubmit={onSubmit}
          id="addCustomTaskForm"
        >
          <div className="flex h-full flex-col gap-2">
            <TextField name="title" label="Title" size="small" required />
            <div className="grid grid-cols-2 gap-2">
              <TextField
                name="estimatedWorkMinutes"
                label="Estimated Work Hours"
                size="small"
                inputProps={{ min: 0 }}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                name="estimatedRate"
                label="Estimated Rate"
                size="small"
                inputProps={{ min: 0 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
        </Form>
      </div>
      <DrawerActions
        className="gap-1"
        leftActions={
          <Button variant="text" color="black" onClick={handleClose} fullWidth>
            Cancel
          </Button>
        }
        rightActions={
          <Button
            type="submit"
            form="addCustomTaskForm"
            variant="contained"
            color="primary"
            fullWidth
          >
            Save
          </Button>
        }
      />
    </Drawer>
  )
}

export default AddCustomTaskDrawer
