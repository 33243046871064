import { gql } from '@apollo/client'

export const GET_VENDOR_OPTIONS = gql`
  query GetVendorOptions {
    vendors {
      id
      name
    }
  }
`
