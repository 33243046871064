import React, { useEffect, useRef, useState } from 'react'
import WorkflowHeader from 'src/components/WorkflowHeader'
import { Button, Chip, Typography } from '@mui/material'
import { useOrgName } from 'src/hooks/useOrgName'
import { useInlineFieldState } from 'src/hooks/useInlineFieldState'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { openModal } from 'src/slices/modal'
import { useDispatch } from 'src/hooks/useDispatch'
import useComplianceLedgerRefresh from 'src/hooks/requests/useComplianceLedgerRefresh'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'
import Sentry from 'src/lib/sentry'
import { formatDateForUpdatedAt } from 'src/utils/helpers'
import { UPDATE_WORK_ORDER } from 'src/pages/WorkOrderCompliancePageV2/queries'
import useModal from 'src/hooks/useModal'
import useUnresolvedComments from 'src/hooks/useUnresolvedComments'
import WorkflowAircraftDetailsDrawer from 'src/components/WorkflowAircraftDetailsDrawer'

interface WorkOrderHeaderDetails {
  internalWorkOrder: {
    id: string
    createdAt: string
    updatedAt: string
    aircraft: {
      id: string
      tailNumber: string
    }
  }
}

interface WorkOrderReviewHeaderProps {
  id: string
  data: WorkOrderHeaderDetails
}

export const WorkOrderReviewHeader: React.FC<WorkOrderReviewHeaderProps> = ({
  id,
  data,
}) => {
  const orgName = useOrgName()
  const dispatch = useDispatch()
  const { isSubmitting, hasError, isSettled } = useInlineFieldState()
  const [savedStatus, setSavedStatus] = useState(false)
  const prevIsSubmittingRef = useRef(isSubmitting)

  const [updateWorkOrder] = useMutation(UPDATE_WORK_ORDER)

  const { mutate: complianceLedgerRefresh } = useComplianceLedgerRefresh()

  const { handleOpen: openAircraftDetailsDrawer } = useModal(
    'workflowAircraftDetailsDrawer'
  )

  const {
    hasUnresolvedComments,
    unresolvedCommentThreads,
    isLoading: commentsLoading,
    error: commentsError,
  } = useUnresolvedComments()
  const [unresolvedCommentsModalOpen, setUnresolvedCommentsModalOpen] =
    React.useState(false)

  const [completeWorkOrder, { loading: completionLoading }] = useMutation(gql`
    mutation CompleteWorkOrder(
      $id: String!
      $input: completeInternalWorkOrderInput
    ) {
      completeInternalWorkOrder(id: $id, input: $input) {
        id
        status
        invoice {
          id
        }
        complianceLedger {
          id
        }
      }
    }
  `)

  useEffect(() => {
    if (prevIsSubmittingRef.current && !isSubmitting) {
      setSavedStatus(true)
      const timer = setTimeout(() => setSavedStatus(false), 1000)
      return () => clearTimeout(timer)
    }
    prevIsSubmittingRef.current = isSubmitting
  }, [isSubmitting])

  return (
    <>
      <WorkflowHeader
        title={data?.internalWorkOrder?.workOrderNumber}
        createdAt={data?.internalWorkOrder?.createdAt}
        tailNumber={data?.internalWorkOrder?.aircraft?.tailNumber}
        slots={{
          statusChip: (
            <Chip
              className="px-1"
              size="small"
              variant="filled"
              label={
                data?.internalWorkOrder?.status === 'IN_REVIEW'
                  ? 'In Review'
                  : 'In Progress'
              }
              color="warning"
            />
          ),
          topRightFields: (
            <>
              <div>
                {hasError ? (
                  <div className="flex items-center gap-1">
                    <ErrorOutlineOutlinedIcon color="error" />
                    <Typography variant="caption">SAVING FAILED</Typography>
                  </div>
                ) : savedStatus ? (
                  <div className="flex items-center gap-1">
                    <CheckCircleOutlinedIcon color="primary" />
                    <Typography variant="caption">SAVED!</Typography>
                  </div>
                ) : (
                  <div className="flex items-center gap-1">
                    <HistoryOutlinedIcon color="primary" />
                    <Typography variant="caption">
                      {formatDateForUpdatedAt(
                        data?.internalWorkOrder?.updatedAt
                      )}
                    </Typography>
                  </div>
                )}
              </div>

              <Button
                variant="outlined"
                color="base"
                onClick={openAircraftDetailsDrawer}
              >
                AIRCRAFT DETAILS
              </Button>
              <Button
                color="base"
                variant="outlined"
                onClick={() => {
                  updateWorkOrder({
                    variables: {
                      id,
                      input: {
                        status: 'IN_PROGRESS',
                      },
                    },
                    onError: (error) => {
                      toast.error('Failed to update work order status')
                      Sentry.captureException(error)
                    },
                    update: (cache, { data }) => {
                      if (!data?.updateInternalWorkOrder) return
                      cache.modify({
                        id: cache.identify(data.updateInternalWorkOrder),
                        fields: {
                          status: () => data.updateInternalWorkOrder.status,
                        },
                      })
                    },
                  })
                }}
              >
                BACK
              </Button>
              <Button
                disabled={!isSettled || completionLoading}
                onClick={async () => {
                  if (commentsError) {
                    Sentry.captureException(commentsError)
                    toast.error(
                      'There was an error submitting the work order.  Please try again.',
                      { duration: 10000 }
                    )
                    return
                  }

                  if (hasUnresolvedComments) {
                    setUnresolvedCommentsModalOpen(true)
                    return
                  }

                  completeWorkOrder({
                    variables: {
                      id,
                    },

                    onCompleted: (data) => {
                      const ledgerId =
                        data?.completeInternalWorkOrder?.complianceLedger?.id
                      complianceLedgerRefresh(
                        { id: ledgerId },
                        {
                          onCompleted: () =>
                            console.log('Maintenance Items Refreshed'),
                        }
                      )
                      dispatch(openModal({ name: 'workOrderCompletedModal' }))
                    },
                  })
                }}
              >
                COMPLETE
              </Button>
            </>
          ),
        }}
      />

      <WorkflowAircraftDetailsDrawer
        aircraftId={data?.internalWorkOrder?.aircraft?.id}
      />
    </>
  )
}

export default WorkOrderReviewHeader
