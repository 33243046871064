export const GET_OPEN_PURCHASE_ORDER_INVOICE_BY_ID = gql`
  query GetOpenPurchaseOrderInvoiceById($id: String!) {
    purchaseOrder(id: $id) {
      id
      invoiceFile {
        id
        url
        urlForPdfPreview
        createdAt
        originalFilename
      }
    }
  }
`

export const GET_OPEN_PURCHASE_ORDER_BY_ID = gql`
  query GetOpenPurchaseOrderById($id: String!) {
    purchaseOrder(id: $id) {
      id
      createdAt
      status
      number
      vendor {
        id
        email
      }
      pdfFile {
        id
        url
        urlForPdfPreview
        createdAt
      }
      purchaseOrderItem {
        id
        ataCode
        maintenanceItemId
        maintenanceItem {
          id
          aircraftId
          aircraft {
            id
            tailNumber
          }
          ataCode
          manufactureCode
          title
        }
        type
      }
      complianceLedger {
        id
        status
        aircraftId
      }
      authorizedBy {
        id
        firstName
        lastName
      }
    }
  }
`
