import { AvailableRoutes, matchPath } from '@redwoodjs/router'

const getCommentRoomRoute = (
  url: string,
  allRoutePaths: Record<keyof AvailableRoutes, string>
): {
  roomRoute: keyof AvailableRoutes
  matchedParams: Record<string, unknown>
} => {
  for (const [key, value] of Object.entries(allRoutePaths)) {
    const match = matchPath(value, url)
    if (match?.match) {
      return {
        roomRoute: key as keyof AvailableRoutes,
        matchedParams: match.params,
      }
    }
  }
  return { roomRoute: undefined, matchedParams: undefined }
}

export default getCommentRoomRoute
