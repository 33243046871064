import {
  AssignmentOutlined,
  BuildRounded,
  ProductionQuantityLimitsRounded,
  AutoGraphOutlined,
  CheckRounded,
  ClearAllRounded,
  HandymanOutlined,
  CorporateFareOutlined,
  DashboardOutlined,
  EngineeringOutlined,
  FlightTakeoffOutlined,
  History,
  InboxOutlined,
  SupportAgent,
  PeopleOutlineOutlined,
  QueryStats,
  RequestPageOutlined,
  ScheduleRounded,
  Settings,
  SnippetFolderOutlined,
  WorkOutlineOutlined,
  Inventory2Outlined,
  AdminPanelSettings,
  ExitToApp,
  Star,
} from '@mui/icons-material'
import { SvgIconProps } from '@mui/material/SvgIcon'
import clsx from 'clsx'

export type NavIconVariety =
  // Analytics section
  | 'dashboard'
  | 'reports'
  | 'documentHub'
  // Maintenance section
  | 'dueList'
  | 'maintenanceItems'
  | 'pastCompliance'
  | 'logbookEntries'
  | 'activityLog'
  | 'purchaseOrders'
  // Inventory section
  | 'inventory'
  | 'dueInventory'
  | 'tools'
  // Work Orders section
  | 'myWorkOrders'
  | 'scheduled'
  | 'completed'
  // Admin section
  | 'users'
  | 'organizations'
  | 'aircraft'
  | 'support'
  | 'features'
  | 'activity'
  // Other
  | 'inbox'
  | 'adminSettings'
  | 'settings'
  | 'backToApp'

const NavIcon = ({ variety, ...props }: { variety: NavIconVariety } & SvgIconProps) => {
  switch (variety) {
    // Analytics section
    case 'dashboard':
      return <DashboardOutlined {...props} />
    case 'reports':
      return <AutoGraphOutlined {...props} />
    case 'documentHub':
      return <SnippetFolderOutlined {...props} />
    // Maintenance section
    case 'dueList':
      return <ClearAllRounded {...props} />
    case 'maintenanceItems':
      return <HandymanOutlined {...props} />
    case 'pastCompliance':
      return <AssignmentOutlined {...props} />
    case 'logbookEntries':
      return <WorkOutlineOutlined {...props} />
    case 'activityLog':
      return <History {...props} />
    case 'purchaseOrders':
      return <RequestPageOutlined {...props} />
    // Inventory section
    case 'inventory':
      return <Inventory2Outlined {...props} />
    case 'dueInventory':
      return <ProductionQuantityLimitsRounded {...props} />
    case 'tools':
      return <BuildRounded {...props} />
    // Work Orders section
    case 'myWorkOrders':
      return <EngineeringOutlined {...props} />
    case 'scheduled':
      return <ScheduleRounded {...props} />
    case 'completed':
      return <CheckRounded {...props} />
    // Admin section
    case 'users':
      return <PeopleOutlineOutlined {...props} />
    case 'organizations':
      return <CorporateFareOutlined {...props} />
    case 'aircraft':
      return <FlightTakeoffOutlined {...props} />
    case 'support':
      return <SupportAgent {...props} />
    case 'features':
      return <Star {...props} />
    case 'activity':
      return <QueryStats {...props} />
    // Other
    case 'inbox':
      return <InboxOutlined {...props} />
    case 'adminSettings':
      return <AdminPanelSettings {...props} />
    case 'settings':
      return <Settings {...props} />
    case 'backToApp':
      return <ExitToApp className={clsx('rotate-180', props.className)} {...props} />
    default:
      return null
  }
}

export default NavIcon