import { MaintenanceComponent } from 'types/graphql'

const COMPONENTS = {
  AIRFRAME: 'airframe',
  ENGINE_1: 'engine 1',
  ENGINE_2: 'engine 2',
  APU: 'apu',
} as const

const getTrackingComponentEnumFromNameValue = (
  inputName: string
): MaintenanceComponent => {
  const name = inputName?.toLowerCase()
  if (name === COMPONENTS.AIRFRAME) return 'AIRFRAME'
  if (name === COMPONENTS.ENGINE_1) return 'ENGINE_1'
  if (name === COMPONENTS.ENGINE_2) return 'ENGINE_2'
  if (name === COMPONENTS.APU) return 'APU'
  throw new Error(`Unknown tracking component name: ${name}`)
}

export default getTrackingComponentEnumFromNameValue
