import React, { useState } from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'
import { Tooltip } from '@mui/material'
import { LockIcon } from 'lucide-react'
interface BaseMenuItem {
  label: string
  onClick?: () => void
  disabled?: boolean
  tooltip?: string
  disabledTooltip?: string
  locked?: boolean
  lockedTooltip?: string
}

interface StaticMenuItem extends BaseMenuItem {
  submenu?: BaseMenuItem[]
}

interface DynamicMenuItem extends BaseMenuItem {
  renderSubmenu?: (props: {
    anchorEl: HTMLElement | null
    onClose: () => void
  }) => React.ReactNode
}

export type MenuItemType = StaticMenuItem | DynamicMenuItem

interface CascadingMenuProps {
  buttonText: string
  buttonProps?: ButtonProps
  menuItems: MenuItemType[]
}

interface SubMenuAnchor {
  el: HTMLElement | null
  item: MenuItemType | null
}

export default function CascadingMenu({
  buttonText,
  buttonProps,
  menuItems,
}: CascadingMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [subMenuAnchor, setSubMenuAnchor] = useState<SubMenuAnchor>({
    el: null,
    item: null,
  })

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setSubMenuAnchor({ el: null, item: null })
  }

  const handleMenuItemHover = (
    event: React.MouseEvent<HTMLElement>,
    item: MenuItemType
  ) => {
    if ('submenu' in item || 'renderSubmenu' in item) {
      setSubMenuAnchor({ el: event.currentTarget, item })
    } else {
      setSubMenuAnchor({ el: null, item: null })
    }
  }

  const handleMenuItemClick = (item: MenuItemType) => {
    if (item.onClick) {
      item.onClick()
    }
    handleClose()
  }

  const renderStaticSubmenu = (
    items: BaseMenuItem[],
    anchorEl: HTMLElement | null
  ) => (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setSubMenuAnchor({ el: null, item: null })}
      disablePortal
      MenuListProps={{
        sx: { pointerEvents: 'auto' },
      }}
      sx={{
        pointerEvents: 'none',
        '& .MuiPaper-root': {
          minWidth: '200px',
        },
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {items.map((subItem, index) => (
        <MenuItem
          key={index}
          onClick={() => handleMenuItemClick(subItem)}
          disabled={subItem.disabled}
        >
          {subItem.label}
        </MenuItem>
      ))}
    </Menu>
  )

  const getTooltip = (item: MenuItemType) => {
    if (item.locked) {
      return item.lockedTooltip
    }
    if (item.disabled) {
      return item.disabledTooltip
    }
    return item.tooltip
  }

  return (
    <div onMouseLeave={handleClose}>
      <Button
        endIcon={<ArrowDropDownRoundedIcon />}
        onClick={handleClick}
        {...buttonProps}
      >
        {buttonText}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          sx: { pointerEvents: 'auto' },
        }}
        sx={{
          pointerEvents: 'none',
          '& .MuiPaper-root': {
            minWidth: '200px',
          },
        }}
      >
        {menuItems.map((item, index) => (
          <Tooltip title={getTooltip(item)}>
            <div>
              <MenuItem
                key={index}
                onClick={() => handleMenuItemClick(item)}
                onMouseEnter={(e) => handleMenuItemHover(e, item)}
                disabled={item.locked || item.disabled}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {item.locked && (
                  <LockIcon
                    fontSize="small"
                    height={16}
                    width={16}
                    className="mr-1"
                  />
                )}
                {item.label}
                {('submenu' in item || 'renderSubmenu' in item) && (
                  <ArrowRightIcon fontSize="small" />
                )}
              </MenuItem>
            </div>
          </Tooltip>
        ))}
      </Menu>

      {subMenuAnchor.item &&
        ('submenu' in subMenuAnchor.item && subMenuAnchor.item.submenu ? (
          renderStaticSubmenu(subMenuAnchor.item.submenu, subMenuAnchor.el)
        ) : 'renderSubmenu' in subMenuAnchor.item &&
          subMenuAnchor.item.renderSubmenu ? (
          <Menu
            anchorEl={subMenuAnchor.el}
            open={Boolean(subMenuAnchor.el)}
            onClose={() => setSubMenuAnchor({ el: null, item: null })}
            disablePortal
            MenuListProps={{
              sx: { pointerEvents: 'auto' },
            }}
            sx={{
              pointerEvents: 'none',
              '& .MuiPaper-root': {
                minWidth: '200px',
              },
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {subMenuAnchor.item.renderSubmenu({
              anchorEl: subMenuAnchor.el,
              onClose: handleClose,
            })}
          </Menu>
        ) : null)}
    </div>
  )
}
