import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Sentry from 'src/lib/sentry'

import useUploadFile from './useUploadFile'
import { useOrgName } from '../useOrgName'
import { BulkUpsertMaintenanceLogbookTransactionInput } from '$api/types/graphql'

const UPSERT_MTX_LOGBOOK = gql`
  mutation UpsertMtxLogbook(
    $id: String!
    $input: UpsertMaintenanceLogbookInput!
  ) {
    upsertMaintenanceLogbook(id: $id, input: $input) {
      id
      complianceLedger {
        id
        requestedWorkerId
        requestedInspectorId
      }
    }
  }
`

const BULK_UPSERT_MTX_LOGBOOK_ACTIVITY = gql`
  mutation BulkUpsertMtxLogbookActivity(
    $input: BulkUpsertMaintenanceLogbookActivityInput!
  ) {
    bulkUpsertMaintenanceLogbookActivity(input: $input) {
      id
      logbookId
      complianceActivityId
    }
  }
`

const BULK_UPSERT_MTX_LOGBOOK_TRANSACTION = gql`
  mutation BulkUpsertMtxLogbookTransaction(
    $input: BulkUpsertMaintenanceLogbookTransactionInput!
  ) {
    bulkUpsertMaintenanceLogbookTransaction(input: $input) {
      id
    }
  }
`

interface CreateResult {
  success: boolean
  upsertMaintenanceLogbook: any
}

const useCreateLogbookEntry = () => {
  // v1
  const [upsertMtxLogbook] = useMutation(UPSERT_MTX_LOGBOOK)
  const [bulkUpsertMtxLogbookActivity] = useMutation(
    BULK_UPSERT_MTX_LOGBOOK_ACTIVITY
  )
  const [bulkUpsertMaintenanceLogbookTransaction] = useMutation(
    BULK_UPSERT_MTX_LOGBOOK_TRANSACTION
  )

  const uploadFile = useUploadFile()
  const orgName = useOrgName()

  const somethingWentWrong = (error, message = '.') => {
    console.error(error)
    Sentry.captureException(error)
    toast.error(`Something went wrong${message} Please try again later.`, {
      duration: 3000,
    })
  }

  const handleCreateLogbookEntry = async (input): Promise<CreateResult> => {
    const {
      workDetails = [],
      attachments,
      logbookId,
      ...mtxLogbookData
    } = input

    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const mtxLogbookResponse: any = await upsertMtxLogbook({
        variables: {
          id: logbookId,
          input: { orgSlug: orgName, ...mtxLogbookData },
        },
      })

      const newLogbookId = mtxLogbookResponse.data.upsertMaintenanceLogbook.id
      await bulkUpsertMtxLogbookActivity({
        variables: {
          input: {
            orgSlug: orgName,
            logbookId: newLogbookId,
            activityItems: workDetails,
          },
        },
      })

      if (attachments) {
        Array.from(attachments).forEach(async (attachment: File) => {
          await uploadFile(attachment, [{ id: newLogbookId, type: 'Logbook' }])
        })
      }

      return {
        success: true,
        upsertMaintenanceLogbook: mtxLogbookResponse,
      }
    } catch (error) {
      somethingWentWrong(error)
      return {
        success: false,
        upsertMaintenanceLogbook: null,
      }
    }
  }

  const handleBulkCreateLogbookEntry = async (
    input: BulkUpsertMaintenanceLogbookTransactionInput
  ): Promise<CreateResult> => {
    try {
      const mtxLogbookResponse = await bulkUpsertMaintenanceLogbookTransaction({
        variables: {
          input,
        },
      })

      return {
        success: true,
        upsertMaintenanceLogbook: mtxLogbookResponse,
      }
    } catch (error) {
      somethingWentWrong(error)
      return {
        success: false,
        upsertMaintenanceLogbook: null,
      }
    }
  }

  return {
    // TODO v1 deprecate later
    createLogbookEntry: handleCreateLogbookEntry,
    //v2
    bulkCreateLogbookEntry: handleBulkCreateLogbookEntry,
  }
}

export default useCreateLogbookEntry
