import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import { get, isEmpty } from 'lodash'
import SparkMD5 from 'spark-md5'
import { AircraftComponent, ComponentUsageLog } from 'types/graphql'

// Use the UTC plugin
dayjs.extend(utc)
dayjs.extend(relativeTime)

export const getMd5 = async (file: File): Promise<string> => {
  //using spark-md5 library and with a buffer size of 2MB
  return new Promise((resolve, reject) => {
    const blobSlice =
      File.prototype.slice ||
      File.prototype.mozSlice ||
      File.prototype.webkitSlice
    const chunkSize = 2097152
    const chunks = Math.ceil(file.size / chunkSize)
    let currentChunk = 0
    const spark = new SparkMD5.ArrayBuffer()
    const fileReader = new FileReader()

    fileReader.onload = function (e) {
      spark.append(e.target.result) // Append array buffer
      currentChunk += 1

      if (currentChunk < chunks) {
        loadNext()
      } else {
        resolve(spark.end())
      }
    }

    fileReader.onerror = function () {
      reject(new Error('FileReader error'))
    }

    function loadNext() {
      const start = currentChunk * chunkSize,
        end = start + chunkSize >= file.size ? file.size : start + chunkSize

      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
    }

    loadNext()
  })
}

export const isNotEmpty = (value) => {
  if (typeof value === 'object') {
    return !isEmpty(value)
  }
  if (typeof value === 'string') {
    return value !== ''
  }
  return value !== false
}

export const capitalizeFirstLetter = (str: string): string => {
  return str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase()
}

export const formatDateForDisplayInUtc = (
  date: string | Date | dayjs.Dayjs | undefined,
  format = 'MMM DD, YYYY'
) => {
  return formatDateForDisplay(date, true, format)
}

export const formatDateForDisplayInLocal = (
  date: string | Date | dayjs.Dayjs | undefined,
  format = 'MMM DD, YYYY'
) => {
  return formatDateForDisplay(date, false, format)
}

export const formatDateForUpdatedAt = (
  date: string | Date | dayjs.Dayjs | undefined
): string => {
  if (!date) {
    return ''
  }

  const dateObj = dayjs(date)
  if (!dateObj.isValid()) {
    return '---'
  }

  return dateObj.format('MMM DD [AT] h:mm A').toUpperCase()
}

export const formatDateForDisplay = (
  date: string | Date | dayjs.Dayjs | undefined,
  showInUtc?: boolean,
  format = 'MMM DD, YYYY'
): string => {
  if (!date) {
    return ''
  }

  const dateObj = dayjs(date)
  const inputDate = showInUtc ? dayjs.utc(dateObj) : dateObj
  if (inputDate.isBefore('1970-01-01')) {
    return '---'
  }
  // if it's not a valid date
  if (!inputDate.isValid()) {
    return '---'
  }

  return inputDate.format(format)
}

export const formatDateDaysAgo = (dateInput?: string | Date): string => {
  if (!dateInput) {
    return ''
  }
  const date = dayjs(dateInput)
  const now = dayjs()

  if (date.isSame(now, 'day')) {
    return 'Today'
  } else if (date.isSame(now.subtract(1, 'day'), 'day')) {
    return 'Yesterday'
  } else {
    return date.fromNow()
  }
}

export const titleCase = (str: string): string => {
  const words = str.toLowerCase().split(' ')

  const capitalizedWords = words.map((word) => {
    if (word.length === 0) {
      return word
    }
    return word[0].toUpperCase() + word.slice(1)
  })

  return capitalizedWords.join(' ')
}

export const capitalizedCharacter = (str: string, index): string => {
  return str?.slice(index, 1)?.toUpperCase()
}

export const splitAndTitleCase = (str: string): string => {
  return str
    .split('_')
    .map((word) => titleCase(word))
    .join(' ')
}

export const roundToPrecision = (num: number, places = 2): number => {
  const multiplier = Math.pow(10, places)
  return Math.round(num * multiplier) / multiplier
}

export const flattenErrors = (errors, path = '') => {
  let result = {}
  for (const key in errors) {
    const fieldPath = path ? `${path}.${key}` : key
    if (typeof errors[key] === 'object') {
      if (key === 'ref') {
        continue
      }
      const nestedErrors = flattenErrors(errors[key], fieldPath)
      result = { ...result, ...nestedErrors }
    } else {
      if (fieldPath.endsWith('type')) {
        continue
      }
      result[fieldPath] = errors[key]
    }
  }
  return result
}

export const findNested = (obj, key) => {
  if (obj?.[key]) {
    return obj[key]
  }
  for (const i in obj) {
    if (typeof obj[i] === 'object') {
      const result = findNested(obj[i], key)
      if (result) {
        return result
      }
    }
  }
  return null
}

export const centsToDollars = (cents: number): string => {
  if (isNaN(cents) || cents === null || cents === undefined) {
    return '0.00'
  }
  return (cents / 100).toFixed(2)
}

export const minutesToHoursStr = (minutes: number): string => {
  if (isNaN(minutes) || minutes === null || minutes === undefined) {
    return '0h 0m'
  }
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60
  return `${hours}h ${remainingMinutes}m`
}

export const minutesToHours = (minutes: number): string => {
  return (minutes / 60).toFixed(2)
}

export const dollarsToCents = (dollars: number | string): number => {
  if (isNaN(dollars)) {
    return null
  }
  const dollarAmount =
    typeof dollars === 'string' ? parseFloat(dollars) : dollars
  if (isNaN(dollarAmount)) {
    return null
  }
  return Math.round(dollarAmount * 100)
}

export const hoursToMinutes = (hours: number): number => {
  return Math.floor(hours * 60)
}

export const addFragmentToUrl = (to: string, fragment: string): string => {
  return `${to}#${fragment}`
}

const euroStyleNumericalStringRegex = /^-?\d{1,2}(.\d{3})+,\d+$/

export function parseFloatOrUndefined(value: unknown): number | undefined {
  if (!value && value !== 0) {
    return undefined
  }
  const numberString = value.toString()
  if (euroStyleNumericalStringRegex.test(numberString)) {
    return parseFloat(numberString.replace(/\./g, '').replace(/,/g, '.'))
  }
  const castedToNumber = Number(numberString.replace(/,(\d{3})+/g, '$1'))
  return isNaN(castedToNumber) ? undefined : castedToNumber
}

export function parseIntOrUndefined(value: unknown): number | undefined {
  const floatValue = parseFloatOrUndefined(value)
  return floatValue || floatValue === 0 ? Math.trunc(floatValue) : undefined
}

export const sortComponentLikeObjects =
  <T>(nameString: string) =>
  (items: T[]): T[] => {
    if (!items) return []
    const arr = [...items] // create a copy of the array

    arr.sort((a, b) => {
      const nameA = get(a, nameString).toUpperCase() // ignore upper and lowercase
      const nameB = get(b, nameString).toUpperCase() // ignore upper and lowercase

      if (nameA === 'AIRFRAME') return -1
      if (nameB === 'AIRFRAME') return 1

      const isEngineA = nameA.includes('ENGINE')
      const isEngineB = nameB.includes('ENGINE')

      if (isEngineA && isEngineB) return nameA < nameB ? -1 : 1
      if (isEngineA) return -1
      if (isEngineB) return 1

      return nameA < nameB ? -1 : 1
    })

    return arr
  }

export const sortComponentUsageLogs =
  sortComponentLikeObjects<ComponentUsageLog>('component.name')
export const sortAircraftComponents =
  sortComponentLikeObjects<AircraftComponent>('name')

// used for yup validation of dollarCents fields (0.00)
export function isDecimalWithTwoOrLessPlaces(value: unknown) {
  return (
    value != null &&
    (value === 0 || value.toString().match(/^[0-9]+(\.[0-9]{1,2})?$/) !== null)
  )
}

// used for yup validation of dollarCents fields (0.00)
export function isDecimalWithTwoOrLessPlacesNullable(value: unknown) {
  if (value === null) {
    return true
  }

  return (
    value === 0 || value.toString().match(/^[0-9]+(\.[0-9]{1,2})?$/) !== null
  )
}

export const downloadBlobAsFile = (blob: Blob, filename: string): void => {
  // Create a URL for the blob object
  const blobUrl = URL.createObjectURL(blob)

  // Create an anchor (<a>) element
  const link = document.createElement('a')
  link.href = blobUrl
  link.setAttribute('download', filename)

  // Append the link to the body, trigger the download, and then remove the link
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)

  // Clean up by revoking the blob URL to release memory
  URL.revokeObjectURL(blobUrl)
}

export const downloadFileFromUrl = (url: string, filename: string) => {
  const link = document.createElement('a')
  link.href = url
  link.download = filename
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const downloadPdfFromUrl = async (
  pdfUrl: string,
  fileName: string
): Promise<void> => {
  try {
    const response = await fetch(pdfUrl)
    if (!response.ok) throw new Error('Network response was not ok.')
    const pdfBlob = new Blob([await response.blob()], {
      type: 'application/pdf',
    })
    downloadBlobAsFile(pdfBlob, fileName)
  } catch (error) {
    console.error('Failed to download PDF:', error)
  }
}

// Function to handle shallow comparisons, ignoring object values
export const findChanges = (original, updated) => {
  const changes = {}

  const allKeys = new Set([...Object.keys(original), ...Object.keys(updated)])

  for (const key of allKeys) {
    const originalIsObject =
      typeof original[key] === 'object' && original[key] !== null
    const updatedIsObject =
      typeof updated[key] === 'object' && updated[key] !== null

    if (originalIsObject || updatedIsObject) {
      if (originalIsObject !== updatedIsObject) {
        // One is an object and the other is not
        changes[key] = updated[key]
      }
    } else if (original[key] !== updated[key]) {
      // Both are primitive values and they are different
      changes[key] = updated[key]
    }
  }

  return changes
}
