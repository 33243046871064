import { createSlice, PayloadAction } from '@reduxjs/toolkit'

/**
 * State for an individual inline field
 */
interface FieldState {
  /**
   * Indicates if the field's current value differs from its original value.
   * - True when value !== originalValue
   * - False when value === originalValue
   * - Resets to false after successful submission
   */
  dirty: boolean

  /**
   * Indicates if the user has interacted with the field.
   * - True after the first focus/blur event
   * - Stays true even if the value returns to original
   * - Used primarily for validation UX (when to show errors)
   */
  touched: boolean

  /**
   * Indicates if the field is currently being saved
   * - True during API calls
   * - False when completed or error
   */
  submitting: boolean

  /**
   * Current error message, if any
   * - null/undefined when no error
   * - string containing error message when validation/submission fails
   */
  error?: string | null

  /**
   * Timestamp of last successful submission
   * - ISO8601 string format
   * - Used to track when the field was last saved
   */
  lastSubmitted?: string | null

  /**
   * Indicates if field is currently being validated
   * - True during async validation
   * - False when validation complete
   */
  validating?: boolean

  /**
   * Current validation state
   * - True if field passes validation
   * - False if validation fails
   * - undefined if not yet validated
   */
  valid?: boolean
}

/**
 * Global state for inline fields
 */
interface InlineState {
  /**
   * Global dirty state
   * - True if any field has unsaved changes
   * - False if all fields match their original values
   */
  dirty: boolean

  /**
   * Global submission state
   * - True if any field is currently saving
   * - False when all fields are idle
   */
  submitting: boolean

  /**
   * Global error state for submission/server errors
   * - True if any field has a submission/server error
   * - False when all fields are error-free
   */
  error: boolean

  /**
   * Global validation error state
   * - True if any field has failed validation
   * - False when all fields pass validation or haven't been validated
   */
  validationError: boolean

  /**
   * Global touched state
   * - True if any field has been interacted with
   * - False if no fields have been touched
   */
  touched: boolean

  /** Map of all registered fields */
  fields: {
    [key: string]: FieldState
  }
}

const initialState: InlineState = {
  dirty: false,
  submitting: false,
  error: false,
  validationError: false,
  touched: false,
  fields: {},
}

const createDefaultFieldState = (): FieldState => ({
  dirty: false,
  touched: false,
  submitting: false,
  error: null,
  validating: false,
  valid: undefined,
  lastSubmitted: null,
})

const inlineSlice = createSlice({
  name: 'inline',
  initialState,
  reducers: {
    /**
     * Sets the dirty state of a field
     * @param name - Field identifier
     * @param dirty - Whether the field's value differs from its original value
     *
     * Updates both field-level and global dirty states
     */
    setDirty: (
      state,
      action: PayloadAction<{ name: string; dirty: boolean }>
    ) => {
      const field =
        state.fields[action.payload.name] || createDefaultFieldState()
      field.dirty = action.payload.dirty
      state.fields[action.payload.name] = field
      state.dirty = Object.values(state.fields).some((f) => f.dirty)
    },

    /**
     * Marks a field as touched (user has interacted with it)
     * @param name - Field identifier
     * @param touched - Whether the field has been interacted with
     *
     * Updates both field-level and global touched states
     */
    setTouched: (
      state,
      action: PayloadAction<{ name: string; touched: boolean }>
    ) => {
      const field =
        state.fields[action.payload.name] || createDefaultFieldState()
      field.touched = action.payload.touched
      state.fields[action.payload.name] = field
      state.touched = Object.values(state.fields).some((f) => f.touched)
    },

    /**
     * Initiates a field submission
     * @param name - Field identifier
     *
     * Sets submitting state to true and clears any existing errors
     */
    startSubmit: (state, action: PayloadAction<{ name: string }>) => {
      const field =
        state.fields[action.payload.name] || createDefaultFieldState()
      field.submitting = true
      field.error = null
      state.fields[action.payload.name] = field
      state.submitting = Object.values(state.fields).some((f) => f.submitting)
    },

    /**
     * Handles successful field submission
     * @param name - Field identifier
     *
     * - Clears submitting state
     * - Resets dirty state
     * - Clears errors
     * - Updates lastSubmitted timestamp
     */
    submitSuccess: (state, action: PayloadAction<{ name: string }>) => {
      const field = state.fields[action.payload.name]
      if (field) {
        field.submitting = false
        field.dirty = false
        field.error = null
        field.lastSubmitted = new Date().toISOString()

        state.submitting = Object.values(state.fields).some((f) => f.submitting)
        state.dirty = Object.values(state.fields).some((f) => f.dirty)
        state.error = Object.values(state.fields).some((f) => f.error !== null)
      }
    },

    /**
     * Handles submission error
     * @param name - Field identifier
     * @param error - Error message from the server
     *
     * Sets error state and updates global error tracking
     */
    submitError: (
      state,
      action: PayloadAction<{ name: string; error: string }>
    ) => {
      const field = state.fields[action.payload.name]
      if (field) {
        field.submitting = false
        field.error = action.payload.error

        state.submitting = Object.values(state.fields).some((f) => f.submitting)
        state.error = Object.values(state.fields).some((f) => f.error !== null)
      }
    },

    /**
     * Initiates field validation
     * @param name - Field identifier
     *
     * Sets validating state and clears previous validation result
     */
    startValidation: (state, action: PayloadAction<{ name: string }>) => {
      const field =
        state.fields[action.payload.name] || createDefaultFieldState()
      field.validating = true
      field.valid = undefined
      state.fields[action.payload.name] = field
    },

    /**
     * Updates field validation state
     * @param name - Field identifier
     * @param valid - Whether the field passed validation
     * @param error - Optional validation error message
     *
     * Updates both validation state and error states
     */
    setValidation: (
      state,
      action: PayloadAction<{
        name: string
        valid: boolean
        error?: string | null
      }>
    ) => {
      const field = state.fields[action.payload.name]
      if (field) {
        field.validating = false
        field.valid = action.payload.valid
        field.error = action.payload.error || null

        state.error = Object.values(state.fields).some((f) => f.error !== null)
        state.validationError = Object.values(state.fields).some(
          (f) => f.valid === false
        )
      }
    },

    /**
     * Removes a field from state tracking
     * @param name - Field identifier
     *
     * Updates all global states after field removal
     */
    clearField: (state, action: PayloadAction<{ name: string }>) => {
      delete state.fields[action.payload.name]
      state.dirty = Object.values(state.fields).some((f) => f.dirty)
      state.submitting = Object.values(state.fields).some((f) => f.submitting)
      state.error = Object.values(state.fields).some((f) => f.error !== null)
      state.validationError = Object.values(state.fields).some(
        (f) => f.valid === false
      )
      state.touched = Object.values(state.fields).some((f) => f.touched)
    },
  },
})

export const {
  setDirty,
  setTouched,
  startSubmit,
  submitSuccess,
  submitError,
  startValidation,
  setValidation,
  clearField,
} = inlineSlice.actions

export default inlineSlice.reducer
