import { routes } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'
import { useBreadcrumbs } from 'src/layouts/components/BreadcrumbsContext'
import MaintenanceItemList from 'web/src/pages/MaintenanceItemsPage/MaintenanceItemListV2'

type DueListPageProps = {
  orgName: string
}

const DueListPage = ({ orgName }: DueListPageProps) => {
  const DEFAULT_QUERY_VARIABLES = {
    isChildItem: false,
    onlyDueItems: true,
    parentId: null,
    showInProgress: true,
  }

  useBreadcrumbs([{ icon: 'dueList' }, { label: 'Due List' }])

  return (
    <div className="flex h-full flex-col">
      <Metadata title="DueList" description="DueList page" />
      <MaintenanceItemList
        title="Fleet Due List"
        variant="duelist"
        orgName={orgName}
        queryVariables={DEFAULT_QUERY_VARIABLES}
      />
    </div>
  )
}

export default DueListPage