import { Typography } from '@mui/material'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { AircraftUsageLog } from 'types/graphql'

import CORE_AIRCRAFT_USAGE_LOG_FRAGMENT from 'src/fragments/AircraftUsageLog'
import { useDispatch } from 'src/hooks/useDispatch'
import useQuery from 'src/hooks/useQuery'
import { useSelector } from 'src/hooks/useSelector'
import { closeModal, setModalData } from 'src/slices/modal'

import Loading from '../Loading'
import Button from '../MUI/Button'
import { DataProp } from '../Table'
import {
  MaterialReactTable,
  MRT_ColumnDef,
  useMaterialReactTable,
} from 'material-react-table'
import { getDefaultMRTOptions } from 'src/utils/getDefaultMRTOptions'
import { useMemo } from 'react'
import { toast } from '@redwoodjs/web/toast'

const GET_USAGE_LOGS = gql`
  ${CORE_AIRCRAFT_USAGE_LOG_FRAGMENT}
  query SelectAircraftUsageLog($aircraftId: String!) {
    aircraftUsageLogs(aircraftId: $aircraftId) {
      ...CoreAircraftUsageLogFields
    }
  }
`

type RowData = DataProp & AircraftUsageLog

type SelectTimeProps = {
  onSuccess?: (aircraftUsageLogId: string) => void
  trackedByComponentName?: string
}

const CellData: React.FC<{
  columnKey: string
  data: RowData
  trackedByComponentName: string
}> = ({ data, trackedByComponentName, columnKey }) => {
  const componentLog = data.ComponentUsageLog.find(
    (log) => log.component.name === (trackedByComponentName ?? 'Airframe')
  )
  switch (columnKey) {
    case 'date':
      return (
        <Typography variant="body2" className="min-w-[60px]">
          {dayjs(data.usageAsOf).format('MMM DD, YYYY')}
        </Typography>
      )
    case 'hours':
      return (
        <Typography variant="body2">
          {componentLog.totalTimeSinceNew}
        </Typography>
      )
    case 'cycles':
      return (
        <Typography variant="body2">{componentLog.cycleSinceNew}</Typography>
      )
  }
}

const SelectTime: React.FC<SelectTimeProps> = ({
  onSuccess,
  trackedByComponentName,
}) => {
  const dispatch = useDispatch()
  const { aircraftId, loading = false } = useSelector(
    (state) => state.modal.modals?.aircraftTimeModal?.data
  )
  const { data, loading: aircraftUsageLogsLoading } = useQuery(GET_USAGE_LOGS, {
    variables: {
      aircraftId,
    },
    onCompleted: () => {
      dispatch(
        setModalData({
          name: 'aircraftTimeModal',
          data: { loading: false },
        })
      )
    },
  })

  const handleSelect = (aircraftUsageLogId: string) => {
    onSuccess(aircraftUsageLogId)
    dispatch(closeModal({ name: 'aircraftTimeModal' }))
  }

  const componentName = trackedByComponentName ?? 'Airframe'

  const cycleLabel = componentName === 'Airframe' ? 'Landings' : 'Cycles'

  const defaultTableOptions = getDefaultMRTOptions<any>()

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        id: 'date',
        header: 'Date',
        Cell: ({ row }) => (
          <CellData
            columnKey="date"
            data={row.original}
            trackedByComponentName={trackedByComponentName}
          />
        ),
      },
      {
        id: 'hours',
        header: `${componentName} Hours`,
        Cell: ({ row }) => (
          <CellData
            columnKey="hours"
            data={row.original}
            trackedByComponentName={trackedByComponentName}
          />
        ),
      },
      {
        id: 'cycles',
        header: `${componentName} ${cycleLabel}`,
        Cell: ({ row }) => (
          <div className="flex justify-between">
            <CellData
              columnKey="cycles"
              data={row.original}
              trackedByComponentName={trackedByComponentName}
            />
            <div className="flex gap-1">
              <Button
                disabledTooltip="Upcoming feature: Edit this time"
                tooltip="Edit this time"
                disabled
                variant="outlined"
                size="small"
              >
                EDIT
              </Button>

              <Button
                tooltip="Select this time"
                variant="outlined"
                size="small"
                onClick={() => {
                  handleSelect(row.original.id)
                }}
              >
                APPLY THIS
              </Button>
            </div>
          </div>
        ),
      },
    ],
    []
  )

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    columns,
    data: data?.aircraftUsageLogs || [],
    enableTopToolbar: false,
    enableColumnFilters: false,
    enablePagination: true,
    muiTablePaperProps: {
      elevation: 0,
      className: ' border border-solid border-gray-200 w-full',
    },
    muiTableContainerProps: {
      className: 'overflow-auto',
    },
    // Add this to control column sizing
    defaultColumn: {
      size: 'auto', // default column width
    },
    state: {
      isLoading: aircraftUsageLogsLoading,
    },
  })

  return (
    <>
      <div
        className={clsx({
          'flex h-52 items-center justify-center': true,
          hidden: !loading && !aircraftUsageLogsLoading,
        })}
      >
        <Loading />
      </div>
      <div
        className={clsx({
          'flex flex-col gap-2 px-2': true,
          hidden: loading || aircraftUsageLogsLoading,
        })}
      >
        <Typography variant="h6">Historical Usage Log</Typography>
        <MaterialReactTable table={table} />
      </div>
    </>
  )
}

export default SelectTime
