import React, { useEffect, useMemo, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import CloseIcon from '@mui/icons-material/Close'
import {
  IconButton,
  Modal,
  Paper,
  TableCell,
  TableRow,
  Typography,
  TableHead as MuiTableHead,
  TableBody,
  Table,
  TableContainer,
} from '@mui/material'
import dayjs from 'dayjs'
import { ComplianceEventLog } from 'types/graphql'

import useGetUsers from 'src/hooks/requests/useGetUsers'
import { useDispatch } from 'src/hooks/useDispatch'
import { useSelector } from 'src/hooks/useSelector'
import { closeModal, setModalData } from 'src/slices/modal'

import ComplianceEventTypeFilter, {
  ComplianceEventType,
  ComplianceEventTypes,
} from './ComplianceEventTypeFilter'
import MechanicFilter from './MechanicsFilter'
import TableRowSkeleton from './TableRowsLoading'
import Drawer from './MUI/Drawer'
import DrawerHeader from './MUI/Drawer/DrawerHeader'

const GET_COMPLIANCE_EVENT_LOGS = gql`
  query GetComplianceEventLogsForModal(
    $internalWorkOrderIds: [String!]
    $filters: ComplianceEventLogsFilter
    $sorting: SortingInput
  ) {
    complianceEventLogs(
      internalWorkOrderIds: $internalWorkOrderIds
      filters: $filters
      sorting: $sorting
    ) {
      id
      transactionId
      internalWorkOrder {
        id
        workOrderNumber
      }
      eventType
      description
      previousData
      currentData
      entityType
      user {
        id
        firstName
        lastName
      }
      createdAt
    }
  }
`

interface ComplianceEventLogsModalMetadata {
  targetId: string
  discriminator:
    | 'InternalWorkItem'
    | 'InternalWorkOrder'
    | 'CustomInternalWorkItem'
}

interface ComplianceEventLogsModalProps {
  onBail?: () => void
}

const ComplianceEventLogsModal: React.FC<ComplianceEventLogsModalProps> = ({
  onBail,
}) => {
  const dispatch = useDispatch()
  const [mechanicFilterData, setMechanicFilterData] = React.useState({})
  const [eventTypeFilterData, setEventTypeFilterData] = useState(
    ComplianceEventTypes.map((type) => ({ [type]: false }))
  )
  const [complianceEventLogsFilters, setComplianceEventLogsFilters] =
    useState(undefined)

  const [getComplianceEventLogs, { data: eventLogsData, loading }] =
    useLazyQuery(GET_COMPLIANCE_EVENT_LOGS)

  const { data: users } = useGetUsers({
    variables: {
      filter: {
        roles: ['ADMIN', 'MECHANIC', 'DIRECTOR_OF_MAINTENANCE'],
      },
    },
  })

  const mechanics = useMemo(() => {
    return users?.users ?? []
  }, [users])

  const open =
    useSelector(
      (state) => state.modal.modals?.complianceEventLogsModal?.isOpen
    ) ?? false

  const { targetId, discriminator }: ComplianceEventLogsModalMetadata =
    useSelector(
      (state) => state.modal.modals?.complianceEventLogsModal?.data
    ) ?? {}

  useEffect(() => {
    if (open) {
      dispatch(
        setModalData({
          name: 'complianceEventLogsModal',
          data: { loading: true },
        })
      )
      getComplianceEventLogs({
        variables: {
          filters: {
            entityId: targetId,
            entityType: discriminator,
            userIds: complianceEventLogsFilters?.userIds,
            eventTypes: complianceEventLogsFilters?.eventTypes,
          },
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, complianceEventLogsFilters])

  const handleClose = () => {
    dispatch(closeModal({ name: 'complianceEventLogsModal' }))
  }

  const handleBail = () => {
    onBail?.()
    handleClose()
  }

  useEffect(() => {
    setMechanicFilterData(
      mechanics.reduce((acc, user) => {
        return {
          ...acc,
          [user.id]: false,
        }
      }, {})
    )
  }, [mechanics])

  const setMechanicFilter = (id: string, value: boolean) => {
    setMechanicFilterData({
      ...mechanicFilterData,
      [id]: value,
    })
  }

  const setEventTypeFilter = (type: ComplianceEventType, value: boolean) => {
    setEventTypeFilterData({
      ...eventTypeFilterData,
      [type]: value,
    })
  }

  useEffect(() => {
    setComplianceEventLogsFilters({
      ...complianceEventLogsFilters,
      userIds: Object.keys(mechanicFilterData).filter(
        (key) => mechanicFilterData[key]
      ),
    })
  }, [mechanicFilterData])

  useEffect(() => {
    setComplianceEventLogsFilters({
      ...complianceEventLogsFilters,
      eventTypes: ComplianceEventTypes.filter(
        (type) => eventTypeFilterData[type]
      ),
    })
  }, [eventTypeFilterData])

  const getActivityDetailTitle = (row: ComplianceEventLog) => {
    switch (row.eventType.split('_')[0]) {
      case 'CREATE':
        return `Created ${row.description}`
      case 'ADD': {
        if (row.entityType === 'InternalWorkItem') {
          return `Added (${
            Object.values(row.currentData['workItems']).length
          }) ${row.description}`
        } else if (row.entityType === 'CustomInternalWorkItem') {
          return `Added (${
            Object.values(row.currentData['customWorkItems']).length
          }) ${row.description}`
        } else {
          return `Added (${
            Object.values(
              row.currentData['workItems'] || row.currentData['customWorkItems']
            ).length
          }) ${row.description}`
        }
      }
      case 'REMOVE': {
        if (row.entityType === 'InternalWorkItem') {
          return `Removed (${
            Object.values(row.currentData['workItems']).length
          }) ${row.description}`
        } else if (row.entityType === 'CustomInternalWorkItem') {
          return `Removed (${
            Object.values(row.currentData['customWorkItems']).length
          }) ${row.description}`
        } else {
          return `Added (${
            Object.values(
              row.currentData['workItems'] || row.currentData['customWorkItems']
            ).length
          }) ${row.description}`
        }
      }
      case 'APPLY':
        return `Applied ${row.description}`
      default:
        return `Updated ${row.description}`
    }
  }

  const shouldShowAdditionalActivityDetails = (row: ComplianceEventLog) => {
    return !['CREATE', 'ADD', 'APPLY', 'REMOVE'].includes(
      row.eventType.split('_')[0]
    )
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleBail}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disablePortal
    >
      <DrawerHeader title="Activity Log" onClose={handleBail} />
      <div>
        <div className="p-3">
          <div className="flex flex-row-reverse gap-2">
            <MechanicFilter
              dropdownStyles={{ maxHeight: 500, overflowY: 'scroll' }}
              userIdType="id"
              mechanics={mechanics}
              mechanicFilterData={mechanicFilterData}
              setMechanicFilter={setMechanicFilter}
              resetMechanicFilter={() =>
                setMechanicFilterData(
                  mechanics.reduce((acc, user) => {
                    return {
                      ...acc,
                      [user.id]: false,
                    }
                  }, {})
                )
              }
            />
            <ComplianceEventTypeFilter
              dropdownStyles={{ maxHeight: 500, overflowY: 'scroll' }}
              eventTypeFilterData={eventTypeFilterData}
              setEventTypeFilter={setEventTypeFilter}
              resetEventTypeFilter={() =>
                setEventTypeFilterData(
                  ComplianceEventTypes.map((type) => ({ [type]: false }))
                )
              }
            />
          </div>
          <TableContainer className="mt-2 h-full overflow-y-scroll">
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={'small'}
            >
              <TableHead />
              <TableBody>
                {loading ? (
                  <TableRowSkeleton cols={headCells.length} rows={5} />
                ) : (
                  <>
                    {eventLogsData?.complianceEventLogs.map(
                      (row: ComplianceEventLog) => {
                        return (
                          <React.Fragment key={row.id}>
                            <TableRow hover tabIndex={-1}>
                              <TableCell>
                                <Typography>
                                  {dayjs(row.createdAt).format('MMM DD, YYYY')}
                                </Typography>
                                <Typography variant="caption">
                                  {dayjs(row.createdAt).format('hh:mm A')}
                                </Typography>
                              </TableCell>
                              <TableCell sx={{ position: 'relative' }}>
                                <div className="flex items-center gap-1">
                                  <AccountCircleIcon
                                    color="primary"
                                    fontSize="small"
                                  />
                                  <Typography>
                                    {`${
                                      row.user?.firstName
                                    } ${row.user?.lastName
                                      .charAt(0)
                                      .toUpperCase()}.`}
                                  </Typography>
                                </div>
                              </TableCell>
                              <TableCell>
                                <Typography>
                                  {getActivityDetailTitle(row)}
                                </Typography>
                                {shouldShowAdditionalActivityDetails(row) &&
                                  row.currentData && (
                                    <Typography variant="caption">
                                      {`→ ${Object.values(row.currentData)[0]}`}
                                    </Typography>
                                  )}
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        )
                      }
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </Drawer>
  )
}

interface HeadCell {
  disablePadding: boolean
  id: string
  label: string
}

const headCells: readonly HeadCell[] = [
  {
    id: 'createdAt',
    disablePadding: false,
    label: 'DATE, TIME',
  },
  {
    id: 'userId',
    disablePadding: false,
    label: 'USER',
  },
  {
    id: 'activityDetails',
    disablePadding: false,
    label: 'ACTIVITY DETAILS',
  },
]

const TableHead: React.FC = () => {
  return (
    <MuiTableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.id === 'actions' ? 'right' : 'left'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  )
}

export default ComplianceEventLogsModal
