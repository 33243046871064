export const GET_ALL_ADDRESSES = gql`
  query GetAllAddresses {
    addresses {
      id
      orgSlug
      vendorId
      addressLine1
      addressLine2
      city
      state
      postalCode
      country
    }
  }
`

export const GET_ADDRESS_BY_ID = gql`
  query GetAddressById($id: String!) {
    address(id: $id) {
      id
      orgSlug
      vendorId
      addressLine1
      addressLine2
      city
      state
      postalCode
      country
    }
  }
`

export const CREATE_ADDRESS = gql`
  mutation CreateAddress($input: CreateAddressInput!) {
    createAddress(input: $input) {
      id
      orgSlug
      vendorId
      addressLine1
      addressLine2
      city
      state
      postalCode
      country
    }
  }
`

export const UPDATE_ADDRESS = gql`
  mutation updateAddress($id: String!, $input: UpdateAddressInput!) {
    updateAddress(id: $id, input: $input) {
      id
      orgSlug
      vendorId
      addressLine1
      addressLine2
      city
      state
      postalCode
      country
    }
  }
`

export const DELETE_ADDRESS = gql`
  mutation deleteAddress($id: String!) {
    deleteAddress(id: $id) {
      id
    }
  }
`

export const SHIPPING_METHOD_FIELDS = gql`
  fragment ShippingMethodFields on ShippingMethod {
    id
    orgSlug
    name
    accountNumber
  }
`

export const GET_ALL_SHIPPING_METHODS = gql`
  ${SHIPPING_METHOD_FIELDS}
  query GetShippingMethods {
    shippingMethods {
      ...ShippingMethodFields
    }
  }
`

export const GET_SHIPPING_METHOD_BY_ID = gql`
  ${SHIPPING_METHOD_FIELDS}
  query GetShippingMethodById($id: String!) {
    shippingMethod(id: $id) {
      ...ShippingMethodFields
    }
  }
`

export const CREATE_SHIPPING_METHOD = gql`
  ${SHIPPING_METHOD_FIELDS}
  mutation CreateShippingMethod($input: CreateShippingMethodInput!) {
    createShippingMethod(input: $input) {
      ...ShippingMethodFields
    }
  }
`

export const UPDATE_SHIPPING_METHOD = gql`
  ${SHIPPING_METHOD_FIELDS}
  mutation UpdateShippingMethod(
    $id: String!
    $input: UpdateShippingMethodInput!
  ) {
    updateShippingMethod(id: $id, input: $input) {
      ...ShippingMethodFields
    }
  }
`

export const DELETE_SHIPPING_METHOD = gql`
  mutation DeleteShippingMethod($id: String!) {
    deleteShippingMethod(id: $id) {
      id
    }
  }
`

export const GET_TENANT_SETTINGS = gql`
  query tenantSettings($orgSlug: String!) {
    tenant(identifier: { orgSlug: $orgSlug }) {
      id
      settings
    }
  }
`

export const UPDATE_TENANT_SETTINGS = gql`
  mutation updateTenantSettings($input: UpdateTenantInput!) {
    updateTenant(input: $input) {
      id
      settings
    }
  }
`
