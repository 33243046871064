import ConstructionIcon from '@mui/icons-material/Construction'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined'
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined'
import Divider from '@mui/material/Divider'
import { useMutation } from '@redwoodjs/web'
import { Permissions } from '@wingwork/common/src/constants/permissions'
import { useMemo } from 'react'
import { useAuth } from 'src/auth'
import ComplianceEventLogsModal from 'src/components/ComplianceEventLogsModal'
import LaborTransactionsModal from 'src/components/LaborTransactionsModal'
import Button from 'src/components/MUI/Button'
import SaveLaborTransactionModal from 'src/components/SaveLaborTransactionModal'
import WorkflowComplianceDetailsSection from 'src/components/workflow/WorkflowComplianceDetailsSection'
import WorkflowItemDetailsSection from 'src/components/WorkflowTaskDetailDrawer/sections/Details'
import WorkflowTaskDetailDrawer from 'src/components/WorkflowTaskDetailDrawer/WorkflowTaskDetailDrawer'
import useGetUsers from 'src/hooks/requests/useGetUsers'
import useHasPermission from 'src/hooks/useHasPermission'
import useModal from 'src/hooks/useModal'
import useQuery from 'src/hooks/useQuery'
import useWorkOrderVitals from 'src/hooks/useWorkOrderVitals'
import {
  CREATE_LABOR_TRANSACTION,
  GET_WORK_ORDER_BASE_FIELDS,
  GET_WORK_ORDER_TASK_DETAILS,
  UPDATE_WORK_ITEM_STATUS,
} from '../../queries'
import AttachmentsSection from '../sections/AttachmentsSection'
import LaborDetailsSection from '../sections/LaborDetailsSection'
import PartTransactionSection from '../sections/PartTransactionSection'
import WorkPerformedSection from '../sections/WorkPerformedSection'

interface WorkItemDetailsProps {
  workOrderId: string
  onBack?: () => void
  onNext?: () => void
  hasNextTask?: boolean
  hasPreviousTask?: boolean
}

const modalName = 'workOrderDetailDrawer'
const WorkItemDetails = ({
  workOrderId,
  onBack,
  onNext,
  hasNextTask,
  hasPreviousTask,
}: WorkItemDetailsProps) => {
  const {
    workOrderState: { detailDrawerTaskId },
  } = useWorkOrderVitals()

  const { handleOpen: handleOpenComplianceEventLogs } = useModal(
    'complianceEventLogsModal'
  )
  const { handleOpen: handleOpenLaborTransactions } = useModal(
    'laborTransactionsModal'
  )
  const { handleOpen: handleOpenSaveLaborTransactionModal } = useModal(
    'saveLaborTransactionModal'
  )

  const {
    data: taskDetails,
    refetch: refetchTaskDetails,
    loading: taskDetailsLoading,
  } = useQuery(GET_WORK_ORDER_TASK_DETAILS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first', // This ensures subsequent updates use the cache
    variables: {
      id: detailDrawerTaskId,
    },
    skip: !detailDrawerTaskId,
  })

  const { data: workOrderBaseFields } = useQuery(GET_WORK_ORDER_BASE_FIELDS, {
    variables: { id: workOrderId },
  })
  const aircraftUsageLogId =
    workOrderBaseFields?.internalWorkOrder?.aircraftUsageLogId

  const [updateWorkItemStatus, { loading: updateWorkItemStatusLoading }] =
    useMutation(UPDATE_WORK_ITEM_STATUS)
  const [createLaborTransaction] = useMutation(CREATE_LABOR_TRANSACTION)

  const { data: users } = useGetUsers()
  const { currentUser } = useAuth()
  const currentUserFromUser = useMemo(
    () => users?.users.find((user) => user.clerkId === currentUser?.providerId),
    [users, currentUser]
  )

  const aircraftId =
    taskDetails?.internalWorkItem?.maintenanceItem?.aircraft?.id

  const currentUserInProgressLaborTransaction =
    taskDetails?.internalWorkItem?.laborTransactions?.find((item) => {
      return (
        item.punchedOutAt === null &&
        item.workedBy?.clerkId === currentUser?.providerId
      )
    }) ?? null

  const isComplianceInProgressByCurrentUser =
    currentUserInProgressLaborTransaction !== null

  const canUpdateWorkOrder = useHasPermission(
    Permissions.workOrder.update,
    aircraftId
  )
  const canReadMtxItem = useHasPermission(
    Permissions.maintenanceItem.read,
    aircraftId
  )
  const canPerformWorkItem = useHasPermission(
    Permissions.workOrder.performWorkItem,
    aircraftId
  )

  const toggleLaborTransaction = async () => {
    if (isComplianceInProgressByCurrentUser) {
      handleOpenSaveLaborTransactionModal({
        laborTransaction: currentUserInProgressLaborTransaction,
        variant: 'punchOut',
      })
    } else {
      // If compliance is not in progress, start it
      await createLaborTransaction({
        variables: {
          id: '',
          input: {
            punchedInAt: new Date().toISOString(),
            internalWorkItemId: detailDrawerTaskId,
          },
        },
        update: (cache, { data: mutationData }) => {
          cache.modify({
            id: cache.identify({
              __typename: 'InternalWorkItem',
              id: detailDrawerTaskId,
            }),
            fields: {
              laborTransactions: (existingLaborTransactions = []) => [
                ...existingLaborTransactions,
                {
                  __ref: cache.identify(mutationData.upsertLaborTransaction),
                },
              ],
            },
          })
        },
      })
      if (taskDetails?.internalWorkItem?.status === 'REVIEW') {
        await updateWorkItemStatus({
          variables: { id: detailDrawerTaskId, status: 'IN_PROGRESS' },
        })
      }
    }
  }

  // Consider it loading if we're either in a loading state OR if we don't have data for the current ID
  const isLoading =
    taskDetailsLoading || (detailDrawerTaskId && !taskDetails?.internalWorkItem)
  const isTaskInReview = taskDetails?.internalWorkItem?.status === 'REVIEW'
  const isTaskInProgress =
    taskDetails?.internalWorkItem?.laborTransactions?.find(
      (item) => !item.punchedOutAt
    )
      ? true
      : false

  const taskStatus = useMemo(() => {
    if (!aircraftUsageLogId) {
      return 'APPLY_TIMES'
    }
    if (isTaskInProgress) {
      return 'IN_PROGRESS_LABOR_TRANSACTION'
    }
    if (isTaskInReview) {
      return 'COMPLETE'
    }
    return 'READY_TO_COMPLETE'
  }, [aircraftUsageLogId, isTaskInProgress, isTaskInReview])

  return (
    <WorkflowTaskDetailDrawer
      isWorkOrder
      open={!!detailDrawerTaskId}
      aircraftId={aircraftId}
      showNextItemActions={true}
      title={taskDetails?.internalWorkItem?.maintenanceItem?.title}
      loading={isLoading}
      onBack={hasPreviousTask ? onBack : null}
      onNext={hasNextTask ? onNext : null}
      onComplete={() => {
        updateWorkItemStatus({
          variables: { id: detailDrawerTaskId, status: 'REVIEW' },
        })
        onNext?.({ skipReview: true })
      }}
      taskStatus={taskStatus}
      completeLoading={updateWorkItemStatusLoading || taskDetailsLoading}
    >
      {({ flashSaved, flashError }) => (
        <>
          <div className="flex flex-col gap-2">
            <div>
              <div className="flex justify-between gap-1 p-2">
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={
                    isComplianceInProgressByCurrentUser ? (
                      <PendingActionsOutlinedIcon />
                    ) : (
                      <TimerOutlinedIcon />
                    )
                  }
                  onClick={toggleLaborTransaction}
                  disabled={!aircraftUsageLogId}
                  disabledTooltip="You must apply times first"
                  locked={!canPerformWorkItem}
                  lockedTooltip="You do not have permission"
                  color={
                    isComplianceInProgressByCurrentUser ? 'warning' : 'primary'
                  }
                >
                  {isComplianceInProgressByCurrentUser
                    ? 'CLOCK OUT'
                    : 'CLOCK IN'}
                </Button>
                <div className="flex gap-1">
                  <Button
                    variant="outlined"
                    color="base"
                    size="small"
                    startIcon={<MenuBookIcon fontSize="small" />}
                    onClick={() => {
                      handleOpenComplianceEventLogs({
                        targetId: detailDrawerTaskId,
                        discriminator: 'InternalWorkItem',
                      })
                    }}
                  >
                    TASK ACTIVITY
                  </Button>
                  <Button
                    variant="outlined"
                    color="base"
                    onClick={() => {
                      handleOpenLaborTransactions({
                        targetId: detailDrawerTaskId,
                        discriminator: 'InternalWorkItem',
                      })
                    }}
                    size="small"
                    className="rounded border border-solid"
                    startIcon={<ConstructionIcon fontSize="small" />}
                  >
                    LABOR ACTIVITY
                  </Button>
                </div>
              </div>
              <Divider />
            </div>
            <WorkflowItemDetailsSection
              task={taskDetails?.internalWorkItem}
              loading={isLoading}
            />
            <WorkflowComplianceDetailsSection
              aircraftUsageLogId={aircraftUsageLogId}
              task={taskDetails?.internalWorkItem}
              flashSaved={flashSaved}
              flashError={flashError}
            />
            <WorkPerformedSection
              id={detailDrawerTaskId}
              workItem={taskDetails?.internalWorkItem}
              flashSaved={flashSaved}
              flashError={flashError}
            />
            <LaborDetailsSection
              task={taskDetails?.internalWorkItem}
              refetch={() => {
                refetchTaskDetails({
                  id: detailDrawerTaskId,
                })
                  .then(() => {
                    flashSaved()
                  })
                  .catch(() => {
                    flashError()
                  })
              }}
            />
            <PartTransactionSection
              workItemId={detailDrawerTaskId}
              flashSaved={flashSaved}
              flashError={flashError}
            />
            <AttachmentsSection
              workItemId={detailDrawerTaskId}
              isCustomWorkItem={false}
              flashSaved={flashSaved}
              flashError={flashError}
            />
          </div>
          <ComplianceEventLogsModal />
          <LaborTransactionsModal />
          <SaveLaborTransactionModal
            refetch={() => {
              refetchTaskDetails({
                id: detailDrawerTaskId,
              })
                .then(() => {
                  flashSaved()
                })
                .catch(() => {
                  flashError()
                })
            }}
          />
        </>
      )}
    </WorkflowTaskDetailDrawer>
  )
}

export default WorkItemDetails
