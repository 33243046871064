import React from 'react'

import { Dialog, DialogTitle, DialogContent } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'

import { FieldValues, UseFormSetValue } from '@redwoodjs/forms'

import { closeModal } from 'src/slices/modal'

import AddressForm from './AddressForm' // Adjust import path as necessary

interface AddressEditorModalProps {
  onComplete?: (data: any) => void
}

const AddressEditorModal: React.FC<AddressEditorModalProps> = ({
  onComplete,
}) => {
  const dispatch = useDispatch()
  const isOpen =
    useSelector(
      (state: any) => state.modal.modals.addressEditorModal?.isOpen
    ) ?? false

  const addressId = useSelector(
    (state: any) => state.modal.modals.addressEditorModal?.data?.addressId
  )

  const discriminator: 'shippingAddress' | 'billingAddress' = useSelector(
    (state: any) => state.modal.modals.addressEditorModal?.data?.discriminator
  )

  const handleClose = () => {
    dispatch(closeModal({ name: 'addressEditorModal' }))
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth>
      <DialogTitle>{addressId ? 'Edit Address' : 'Create Address'}</DialogTitle>
      <DialogContent className="pt-1">
        <AddressForm
          id={addressId}
          onClose={handleClose}
          onComplete={onComplete}
          discriminator={discriminator}
        />
      </DialogContent>
    </Dialog>
  )
}

export default AddressEditorModal
